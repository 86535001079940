import {groupBy} from 'lodash';
import Moment from 'moment';
import Util from '../../../../util/util';
import {TYPES} from '../Theme/NodeContentRenderer';
import {GROUP_TYPE} from '../TaskTable';
import {handleAddTask} from './TableLogic';
import ProjectUtil from '../../../../util/project_util';
import {hasFeatureFlag} from '../../../../util/FeatureUtil';
import {ESTIMATION_UNIT, PROJECT_STATUS} from '../../../../../../constants';
import {RollUpValue} from './RollUpValue';
import {getValue} from '../../../../../project-tab/projects/scoping-page/ResourceUtil';
import {getGroupedFinancialNumbersByPhaseAndBaselineId} from '../../../../../../components/initial-plan/InitialPlanUtil';
import {idFromGlobalId} from '../../../../util/GlobalIdUtil';
import {getOrDefault} from '../../../../util/DataUtil';
import {sortByStartDate} from '../../../../../../components/new-ui/retainer_period/RetainerPeriodUtil';

export const sortBySortOrder = (a, b) => {
	if (a.node.sortOrder < b.node.sortOrder) return -1;
	if (a.node.sortOrder > b.node.sortOrder) return 1;

	return 0;
};

export const getNodeKey = ({node}) => {
	return node.id;
};

const compareSprintDates = (a, b) => {
	let aStartDate = Moment({
		y: a.startYear,
		M: a.startMonth - 1,
		d: a.startDay,
	});
	let aEndDate = Moment({
		M: a.endMonth - 1,
		y: a.endYear,
		d: a.endDay,
	});
	let bStartDate = Moment({
		y: b.startYear,
		M: b.startMonth - 1,
		d: b.startDay,
	});
	let bEndDate = Moment({
		y: b.endYear,
		M: b.endMonth - 1,
		d: b.endDay,
	});
	//Date used for sorting sprints with not selected dates
	const dummyDate = Moment({
		y: -10000,
		M: 1,
		d: 1,
	});

	if (!aStartDate.isValid()) aStartDate = dummyDate.clone();
	if (!aEndDate.isValid()) aEndDate = dummyDate.clone();
	if (!bStartDate.isValid()) bStartDate = dummyDate.clone();
	if (!bEndDate.isValid()) bEndDate = dummyDate.clone();

	if (aStartDate.isBefore(bStartDate)) return 1;
	if (bStartDate.isBefore(aStartDate)) return -1;
	//If same date, sort by end date ascending
	if (aEndDate.isBefore(bEndDate)) return 1;
	if (bEndDate.isBefore(aEndDate)) return -1;

	const aId = parseInt(atob(a.id).replace('Sprint:', ''), 10);
	const bId = parseInt(atob(b.id).replace('Sprint:', ''), 10);
	if (aId < bId) return 1;
	return -1;
};

export const getSharedOptions = (
	viewer,
	isConnectedParent,
	availableColumns,
	visibility,
	phases,
	createSubtaskTask,
	showTaskModal,
	onContextMenu,
	refetch,
	handleRowSelected,
	onCreateSubtask,
	predictionData,
	isBulkSelectMode,
	intl
) => {
	const {availableFeatureFlags} = viewer;
	const companyRoles = viewer.company.roles ? viewer.company.roles.edges : [];
	const disabledRoleIds = viewer.project.rateCard?.disabledRoles
		? viewer.project.rateCard.disabledRoles.map(role => role.id)
		: [];
	const roles = companyRoles.filter(role => !disabledRoleIds.includes(role.node.id));
	const disabledRoles = companyRoles.filter(role => disabledRoleIds.includes(role.node.id));

	const projectPersons = viewer.project.projectPersons.edges;
	const sprints = viewer.project.sprints.edges.map(sprint => sprint.node);
	const currency = viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency;
	const currencySymbol = Util.GetCurrencySymbol(currency);
	const useTaskHierarchy = viewer.project.useTaskHierarchy;
	const lowHighModelScore = predictionData && predictionData.viewer.company.availableMLModels.lowHighModelScore;

	const sprintOptions = [];
	sprintOptions.push({label: intl.formatMessage({id: 'project_sprints.backlog'}), value: null});
	sprints.sort(compareSprintDates).forEach(sprint => sprintOptions.push({value: sprint.id, label: sprint.name}));

	const phaseOptions = [];
	phaseOptions.push({label: intl.formatMessage({id: 'project_scopes.no-scope'}), value: null});
	phases.forEach(phase => {
		phaseOptions.push({
			label: phase.name,
			value: phase.id,
		});
	});

	return {
		company: viewer.company,
		companyId: viewer.company.id,
		isUsingProjectAllocation: viewer.company.isUsingProjectAllocation,
		isUsingMixedAllocation: viewer.company.isUsingMixedAllocation,
		currencySymbol: currencySymbol,
		currency: viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency,
		useTaskHierarchy: useTaskHierarchy,
		isConnectedParent: isConnectedParent,
		availableColumns: availableColumns,
		createSubtaskTask: createSubtaskTask,
		onCreateSubtask: onCreateSubtask,
		showTaskModal: showTaskModal,
		onContextMenu: onContextMenu,
		refetch: refetch,
		handleRowSelected: handleRowSelected,
		availableFeatureFlags: availableFeatureFlags,
		visibility,
		roles: roles,
		disabledRoles: disabledRoles,
		projectPersons: projectPersons,
		sprints: sprintOptions,
		phases: phaseOptions,
		isBulkSelectMode: isBulkSelectMode,
		lowHighModelScore: lowHighModelScore,
		timeRegOptions: {
			company: viewer.company,
			actualPersonId: viewer.actualPersonId,
			harvestUser: viewer.harvestUser,
			unit4User: viewer.unit4User,
			availableFeatureFlags: viewer.availableFeatureFlags,
			excludeFromCompanyLockedPeriod: viewer.excludeFromCompanyLockedPeriod,
			submitLockedDateYear: viewer.submitLockedDateYear,
			submitLockedDateMonth: viewer.submitLockedDateMonth,
			submitLockedDateDay: viewer.submitLockedDateDay,
			startDate: viewer.startDate,
			endDate: viewer.endDate,
			createdAt: viewer.createdAt,
			monday: viewer.monday,
			tuesday: viewer.tuesday,
			wednesday: viewer.wednesday,
			thursday: viewer.thursday,
			friday: viewer.friday,
			saturday: viewer.saturday,
			sunday: viewer.sunday,
		},
		estimationUnit: viewer.project.estimationUnit,
		minutesPerEstimationPoint: viewer.project.minutesPerEstimationPoint,
	};
};

const getIsPhaseExpanded = (phaseId, phasesExpansionMap) => {
	return phasesExpansionMap.get(phaseId) == null || phasesExpansionMap.get(phaseId);
};

const getIsTaskExpanded = (taskId, tasksExpansionMap) => {
	return tasksExpansionMap.get(taskId);
};
/**
 * Is the specific group expanded
 * By default a group is expanded
 * @param {String} groupId
 * @param {Map} groupExpansionMap
 * @returns bool
 */
const getIsGroupExpanded = (groupId, groupExpansionMap) => {
	// If either groupId or groupExpansionsMap is null or undefined return true
	if (!groupId || !groupExpansionMap) return true;
	const val = groupExpansionMap.get(groupId);
	// It returns true if the map returns undefined for a given group id
	// because the default state of a group is expanded
	if (val === undefined) return true;
	else return val;
};

const getIsTaskSelected = (taskId, selectedTasks) => {
	return selectedTasks.some(task => task.id === taskId);
};

/***
 *
 * @param groupingEntityId - role or person id
 * @param children - tasks
 * @param sharedOptions
 * @param project
 * @param phaseId
 * @param availabilityMap
 * @param assignedPersonsByTaskMap
 * @returns {{rollupProgress: null, rollupTimeEntries: null, rollupRemaining: null, rollupDifferenceFromEstimate: null, rollupEstimates: null, rollupActualBillableTimeDifference: null, rollupPlannedBillableTime: null}}
 */
const getGroupingRollUpValues = (
	groupingEntityId,
	children,
	sharedOptions,
	project,
	phaseId,
	availabilityMap,
	assignedPersonsByTaskMap = null
) => {
	const {isUsingProjectAllocation, company} = sharedOptions;
	const displayAvailability = isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(company);
	const rollUpValues = {
		rollupProgress: null,
		rollupEstimates: null,
		rollupTimeEntries: null,
		rollupBillableTimeEntries: null,
		rollupRemaining: null,
		rollupDifferenceFromEstimate: null,
		rollupPlannedBillableTime: null,
		rollupActualBillableTimeDifference: null,
	};
	const isPersonGrouping = sharedOptions.visibility.groupSetting === GROUP_TYPE.PERSON;

	if (displayAvailability) {
		if (children?.length > 0) {
			let doneTasksCount = 0;
			let estimates = 0;
			let timeEntries = 0;
			let billableTimeEntries = 0;
			let remaining = 0;
			let differenceFromEstimate = 0;
			let plannedBillableTime = 0;
			let actualBillableTimeDifference = 0;

			children.forEach(child => {
				const assignedPersonsCount = assignedPersonsByTaskMap ? assignedPersonsByTaskMap[child.task.id] : 0;
				const assignedPersonDivider = isPersonGrouping && assignedPersonsCount > 0 ? assignedPersonsCount : 1;

				if (child.task?.done) {
					doneTasksCount++;
				}

				estimates += child.rollUpValues.rollupEstimate.approvedValue / assignedPersonDivider;
				timeEntries += child.rollUpValues.rollupTimeRegistered.approvedValue;
				billableTimeEntries += child.rollUpValues.rollupBillableTimeRegistered.approvedValue;
				remaining += child.rollUpValues.rollupRemaining.approvedValue / assignedPersonDivider;
				differenceFromEstimate += child.rollUpValues.rollupDifferenceEstimate.approvedValue / assignedPersonDivider;
				plannedBillableTime += child.rollUpValues.rollupPrice.approvedValue;
				actualBillableTimeDifference += child.rollUpValues.rollupActualPrice.approvedValue;
			});

			rollUpValues.rollupEstimates = estimates;
			rollUpValues.rollupTimeEntries = timeEntries;
			rollUpValues.rollupBillableTimeEntries = billableTimeEntries;
			rollUpValues.rollupRemaining = remaining;
			rollUpValues.rollupProgress = Util.calculateElementProgress(
				estimates,
				timeEntries,
				remaining,
				children?.length || 0,
				doneTasksCount,
				sharedOptions.estimationUnit === ESTIMATION_UNIT.HOURS,
				sharedOptions.minutesPerEstimationPoint,
				false,
				project.status === PROJECT_STATUS.DONE
			);

			rollUpValues.rollupDifferenceFromEstimate = differenceFromEstimate;
			rollUpValues.rollupPlannedBillableTime = plannedBillableTime;
			rollUpValues.rollupActualBillableTimeDifference = actualBillableTimeDifference;
		}

		const groupingAvailability = getValue(`${phaseId}-${groupingEntityId}`, availabilityMap);
		rollUpValues.minutesAvailable = groupingAvailability.minutesAvailable;
		rollUpValues.remainingMinutesAvailable = groupingAvailability.remainingMinutesAvailable;
		rollUpValues.minutesRemaining = groupingAvailability.remainingMinutesAvailable - rollUpValues.rollupRemaining;
	}

	return rollUpValues;
};

const getRoleGroupElement = (
	role,
	children,
	level,
	phaseId,
	sharedOptions,
	roleGroupExpansionMap,
	project,
	availabilityMap,
	rollUpValues,
	isForBaseline = false,
	preventExpansion = false
) => {
	let id = (role ? role.id : 'no_role') + (phaseId || 'no_phase');
	if (isForBaseline) id = (role ? role.id : 'no_role') + '#' + (phaseId || 'no_phase');

	return {
		id: `${id}`,
		phaseId,
		type: role ? TYPES.ROLE_GROUPING : TYPES.NO_ROLE_GROUPING,
		level,
		role,
		children,
		expanded: getIsGroupExpanded(id, roleGroupExpansionMap),
		sharedOptions,
		rollUpValues,
		preventExpansion,
		isForBaseline,
	};
};

const getPersonGroupElement = (
	id,
	person,
	children,
	level,
	phaseId,
	sharedOptions,
	personGroupExpansionMap,
	project,
	availabilityMap,
	assignedPersonsByTaskMap,
	preventExpansion = false
) => {
	const rollUpValues = getGroupingRollUpValues(
		person.id,
		children,
		sharedOptions,
		project,
		phaseId,
		availabilityMap,
		assignedPersonsByTaskMap
	);

	return {
		id,
		phaseId,
		type: TYPES.PERSON_GROUPING,
		level,
		person,
		children,
		expanded: getIsGroupExpanded(id, personGroupExpansionMap),
		sharedOptions,
		rollUpValues,
		preventExpansion,
	};
};

const getBaselineElement = (
	id,
	phaseId,
	baselineTargetMinutes,
	baselineTargetPrice,
	baselineCost,
	availableColumns,
	currencySymbol,
	children,
	level = 0,
	groupExpansionMap
) => {
	return {
		id: `${id}#baseline`,
		phaseId,
		type: TYPES.BASELINE,
		baselineTargetMinutes: baselineTargetMinutes || 0,
		baselineTargetPrice: baselineTargetPrice || 0,
		baselineCost: baselineCost || 0,
		availableColumns,
		currencySymbol,
		expanded: getIsGroupExpanded(id, groupExpansionMap),
		children,
		level,
		topNodeId: phaseId,
	};
};

const getPeriodElement = (period, availableColumns, currencySymbol, rolledUpValues, sharedOptions, level) => {
	return {
		id: period.id,
		type: TYPES.PERIOD,
		availableColumns,
		currencySymbol,
		expanded: true,
		period: period,
		rolledUpValues,
		sharedOptions,
		level,
		topNodeId: period.id,
	};
};

const getPhaseElement = (
	phase,
	rolledUpValues,
	project,
	availableColumns,
	expanded,
	children,
	level,
	currencySymbol,
	sharedOptions
) => {
	return {
		id: phase.id,
		level,
		phaseId: phase.id,
		phase,
		project,
		availableColumns,
		type: TYPES.HEADER,
		title: phase.name,
		expanded,
		children,
		rolledUpValues,
		currencySymbol,
		topNodeId: phase.id,
		sharedOptions,
	};
};

const getAddTaskElement = (phaseId, project, sharedOptions, parentTaskId, topNodeId = phaseId) => {
	return {
		id: `${phaseId || 'no-phase'}${parentTaskId || ''}-add-task`,
		phaseId,
		isEstimatedInHours: project.isEstimatedInHours,
		type: TYPES.ADD_TASK,
		project: project,
		companyId: sharedOptions.companyId,
		parentTaskId,
		projectStatus: project.status,
		roles: sharedOptions.roles,
		projectPersons: sharedOptions.projectPersons,
		availableFeatureFlags: sharedOptions.availableFeatureFlags,
		handleAddTask,
		topNodeId,
	};
};

const getRollUpValuesFromTask = (task, groupSetting) => {
	const isEstimatedInHours = task.node.project.estimationUnit === ESTIMATION_UNIT.HOURS;
	const minutesPerEstimationPoint = task.node.project.minutesPerEstimationPoint;
	const isDone = task.node.done;

	const estimate = task.node.estimateForecast;
	const timeRegistered = task.node.timeRegistrations
		? task.node.timeRegistrations.edges.reduce((totalTime, tReg) => totalTime + tReg.node.minutesRegistered, 0)
		: 0;
	const timeRegisteredInPoints = timeRegistered / minutesPerEstimationPoint;
	const billableTimeRegistered = task.node.timeRegistrations
		? task.node.timeRegistrations.edges.reduce((totalTime, tReg) => totalTime + tReg.node.billableMinutesRegistered, 0)
		: 0;
	const remaining = task.node.timeLeft;
	const manualProgressValue = task.node.progressDetails ? task.node.progressDetails.progress : 0;
	const differenceEstimate = isEstimatedInHours
		? estimate - timeRegistered
		: estimate * minutesPerEstimationPoint - timeRegistered;

	const price = ProjectUtil.getEstimatedPrice(task.node);
	const actualPrice = ProjectUtil.getActualPriceForTask(task.node);
	const plannedCost = ProjectUtil.getPlannedCostForTask(task.node);
	const actualCost = ProjectUtil.getActualCostForTask(task.node);
	const totalPriceAtCompletion = ProjectUtil.getTotalPriceAtCompletionForTask(task.node);
	const allTotalTimeAndExpensesAtCompletion = ProjectUtil.getTotalAllTotalTimeAndExpensesAtCompletionForTask(task.node);
	const projectedCost = ProjectUtil.getProjectedCostForTask(task.node);

	const assigneeSet = new Set();
	if (task.node.assignedPersons && task.node.assignedPersons.length > 0) {
		assigneeSet.add(task.node.assignedPersons.map(assignee => assignee.id));
	} else {
		assigneeSet.add([]);
	}
	return {
		rollupChildrenCount: new RollUpValue(task, 1),
		rollupDoneChildrenCount: new RollUpValue(task, isDone ? 1 : 0),
		rollupEstimate: new RollUpValue(task, estimate),
		rollupDoneEstimate: new RollUpValue(task, isDone ? estimate : 0),
		rollupTimeRegistered: new RollUpValue(task, timeRegistered),
		rollupBillableTimeRegistered: new RollUpValue(task, billableTimeRegistered),
		rollupNotDoneTimeRegistered: new RollUpValue(task, isDone ? 0 : timeRegistered > estimate ? estimate : timeRegistered),
		rollupNotDoneTimeRegisteredInPoints: new RollUpValue(
			task,
			isDone ? 0 : timeRegisteredInPoints > estimate ? estimate : timeRegisteredInPoints
		),
		rollupRemaining: new RollUpValue(task, remaining),
		rollupProgress: new RollUpValue(task, manualProgressValue),
		rollupDifferenceEstimate: new RollUpValue(task, differenceEstimate),
		rollupPrice: new RollUpValue(task, price),
		rollupActualPrice: new RollUpValue(task, actualPrice),
		rollupPlannedCost: new RollUpValue(task, plannedCost),
		rollupActualCost: new RollUpValue(task, actualCost),
		rollupRoles: new Set().add(task.node.role?.id),
		rollupStatus: new Set().add(task.node.statusColumnV2.id),
		rollupAssignees: assigneeSet,
		rollupTotalPriceAtCompletion: new RollUpValue(task, totalPriceAtCompletion),
		rollupAllTotalTimeAndExpensesAtCompletion: new RollUpValue(task, allTotalTimeAndExpensesAtCompletion),
		rollupProjectedCost: new RollUpValue(task, projectedCost),
	};
};

/**
 * A function which takes a task and its children (sub-tasks) and sums up all the values on the parent task.
 * These values are stored in a RollUpValue object which contain a value that does not disregard unapproved tasks and one that does.
 * @param task - The parent task
 * @param children - The child tasks (sub-tasks)
 * @param groupSetting - The grouping of the page (eg. group by role, person, etc.)
 * @param project - Used for deprecated progress calculation.
 * @returns {*} - An object containing the sum of all the values of the parent and it's children. Each value stored in a RollUpValue object
 */
const getRollUpValuesFromChildren = (task, children, groupSetting, project) => {
	const baseRollup = getRollUpValuesFromTask(task, groupSetting);
	const rollup = children.reduce((acc, childElement) => {
		Object.keys(acc).forEach(key => {
			if (acc[key] instanceof Set) {
				childElement.rollUpValues[key].forEach(val => acc[key].add(val));
			} else {
				acc[key].addValue(childElement.rollUpValues[key]);
			}
		});
		return acc;
	}, baseRollup);

	// Calculated the rollupProgress, which is not stored in a RollUpValue object.
	if (ProjectUtil.projectUsesManualProgress(project)) {
		if (rollup.rollupEstimate.getApprovedValue() > 0) {
			rollup.rollupProgress = new RollUpValue(
				task,
				Util.getSimpleProgressPercentageFromValues(
					rollup.rollupEstimate.getApprovedValue(),
					rollup.rollupRemaining.getApprovedValue()
				)
			);
		} else {
			const numberOfSubtasksIncludingParentTasks = 1 + (children ? children.length || 0 : 0);
			const averageProgress = Math.round(rollup.rollupProgress.getApprovedValue() / numberOfSubtasksIncludingParentTasks);
			rollup.rollupProgress = new RollUpValue(task, averageProgress);
		}
	} else {
		rollup.rollupProgress = Util.calculateElementProgress(
			rollup.rollupEstimate.getApprovedValue(),
			rollup.rollupTimeRegistered.getApprovedValue(),
			rollup.rollupRemaining.getApprovedValue(),
			rollup.rollupChildrenCount.getApprovedValue(),
			rollup.rollupDoneChildrenCount.getApprovedValue(),
			project.estimationUnit === ESTIMATION_UNIT.HOURS,
			project.minutesPerEstimationPoint,
			false,
			project.status === PROJECT_STATUS.DONE
		);
	}
	return rollup;
};

const getRolledUpOrDefault = (rolleupValueOrNumber, defaultValue) => {
	if (rolleupValueOrNumber?.getApprovedValue) {
		return getOrDefault(rolleupValueOrNumber.getApprovedValue(), 0);
	} else {
		return getOrDefault(rolleupValueOrNumber, defaultValue);
	}
};
/**
 * This function is used to calculate the rollup values for retainer periods.
 *
 * It uses all the phases inside the period, and the task that are not in a phase, but still inside the period
 * to calculate the values.
 *
 * @param phases The phases inside the period.
 * @param tasks The task not in a phase, but inside the period.
 * @returns {{actualPrice: number, price: number, estimate: number, timeRegistered: number}} The Rollup Values for period.
 */
const getRollUpValuesForPeriod = (phases, tasks) => {
	const periodRolledUpValues = {
		totalAvailability: 0,
		remainingAvailability: 0,
		estimate: 0,
		timeRegistered: 0,
		billableTimeRegistered: 0,
		price: 0,
		actualPrice: 0,
		plannedCost: 0,
		actualCost: 0,
		estimateForecastPrice: 0,
	};

	phases?.forEach(phase => {
		periodRolledUpValues.totalAvailability += getRolledUpOrDefault(phase.rolledUpValues.totalAvailability, 0);
		periodRolledUpValues.remainingAvailability += getRolledUpOrDefault(phase.rolledUpValues.remainingAvailability, 0);
		periodRolledUpValues.estimate += getRolledUpOrDefault(phase.rolledUpValues.estimate, 0);
		periodRolledUpValues.timeRegistered += getRolledUpOrDefault(phase.rolledUpValues.timeRegistered, 0);
		periodRolledUpValues.billableTimeRegistered += getRolledUpOrDefault(phase.rolledUpValues.billableTimeRegistered, 0);
		periodRolledUpValues.price += getRolledUpOrDefault(phase.rolledUpValues.price, 0);
		periodRolledUpValues.actualPrice += getRolledUpOrDefault(phase.rolledUpValues.actualPrice, 0);
		periodRolledUpValues.plannedCost += getRolledUpOrDefault(phase.rolledUpValues.plannedCost, 0);
		periodRolledUpValues.actualCost += getRolledUpOrDefault(phase.rolledUpValues.actualCost, 0);
		periodRolledUpValues.estimateForecastPrice += getRolledUpOrDefault(phase.rolledUpValues.estimateForecastPrice, 0);
	});

	tasks?.forEach(task => {
		periodRolledUpValues.estimate += getRolledUpOrDefault(task.rollUpValues.rollupEstimate, 0);
		periodRolledUpValues.timeRegistered += getRolledUpOrDefault(task.rollUpValues.rollupTimeRegistered, 0);
		periodRolledUpValues.billableTimeRegistered += getRolledUpOrDefault(task.rollUpValues.rollupBillableTimeRegistered, 0);
		periodRolledUpValues.price += getRolledUpOrDefault(task.rollUpValues.rollupPrice, 0);
		periodRolledUpValues.actualPrice += getRolledUpOrDefault(task.rollUpValues.rollupActualPrice, 0);
		periodRolledUpValues.plannedCost += getRolledUpOrDefault(task.rollUpValues.rollupPlannedCost, 0);
		periodRolledUpValues.actualCost += getRolledUpOrDefault(task.rollUpValues.rollupActualCost, 0);
		periodRolledUpValues.estimateForecastPrice += getRolledUpOrDefault(task.rollUpValues.estimateForecastPrice, 0);
	});

	return periodRolledUpValues;
};

/**
 * This method is used to traverse the tree data to find specific types of rows.
 *
 * @param rows The rows to traverse
 * @param type The type to look for
 * @returns {*} An array of the specific type found
 */
const getRowsFromType = (rows, type) => {
	const specificRows = rows.filter(row => row.type === type);
	rows.filter(row => row.children && row.children.length > 0).forEach(row => {
		specificRows.push(...getRowsFromType(row.children, type));
	});
	return specificRows;
};

/**
 * Roll up all data the rolled up task values
 * @param {*} treeData
 * @returns
 */
export const getRollUpValuesFromProject = (treeData, project, projectRegisteredTime) => {
	let rolledUpProjectData = {
		totalAvailability: 0,
		remainingAvailability: 0,
		estimationUnit: ESTIMATION_UNIT.HOURS,
		estimate: 0,
		actualPrice: 0,
		plannedCost: 0,
		actualCost: 0,
		differenceEstimate: 0,
		price: 0,
		progress: 0,
		remaining: 0,
		timeRegistered: 0,
		billableTimeRegistered: 0,
		doneEstimate: 0,
		notDoneTimeRegistered: 0,
		notDoneTimeRegisteredInPoints: 0,
		doneCount: 0,
		taskCount: 0,
		totalPriceAtCompletion: 0,
		baselineTargetMinutes: 0,
		baselineTargetPrice: 0,
		allTotalTimeAndExpensesAtCompletion: 0,
	};

	if (project !== undefined) {
		rolledUpProjectData.estimationUnit = project.estimationUnit;
	}

	if (treeData !== undefined) {
		const baselines = getRowsFromType(treeData, TYPES.BASELINE);
		baselines.forEach(baseline => {
			rolledUpProjectData.baselineTargetMinutes += baseline.baselineTargetMinutes;
			rolledUpProjectData.baselineTargetPrice += baseline.baselineTargetPrice;
		});

		const phases = getRowsFromType(treeData, TYPES.HEADER);
		phases.forEach(phase => {
			rolledUpProjectData.totalAvailability += phase.rolledUpValues.totalAvailability;
			rolledUpProjectData.remainingAvailability += phase.rolledUpValues.remainingAvailability;
			rolledUpProjectData.differenceEstimate += phase.rolledUpValues.differenceEstimate;
			rolledUpProjectData.estimate += phase.rolledUpValues.estimate;
			rolledUpProjectData.remaining += phase.rolledUpValues.remaining;
			rolledUpProjectData.timeRegistered += phase.rolledUpValues.timeRegistered;
			rolledUpProjectData.billableTimeRegistered += phase.rolledUpValues.billableTimeRegistered;
			rolledUpProjectData.actualPrice += phase.rolledUpValues.actualPrice;
			rolledUpProjectData.plannedCost += phase.rolledUpValues.plannedCost;
			rolledUpProjectData.actualCost += phase.rolledUpValues.actualCost;
			rolledUpProjectData.price += phase.rolledUpValues.price;
			rolledUpProjectData.doneEstimate += phase.rolledUpValues.doneEstimate;
			rolledUpProjectData.notDoneTimeRegistered += phase.rolledUpValues.notDoneTimeRegistered;
			rolledUpProjectData.notDoneTimeRegisteredInPoints += phase.rolledUpValues.notDoneTimeRegisteredInPoints;
			rolledUpProjectData.doneCount += phase.rolledUpValues.doneCount;
			rolledUpProjectData.taskCount += phase.rolledUpValues.taskCount;
			rolledUpProjectData.totalPriceAtCompletion += phase.rolledUpValues.totalPriceAtCompletion;
			rolledUpProjectData.allTotalTimeAndExpensesAtCompletion += phase.rolledUpValues.allTotalTimeAndExpensesAtCompletion;
		});

		//Calculate progress
		if (ProjectUtil.projectUsesManualProgress(project)) {
			rolledUpProjectData.projectProgress = Util.getSimpleProgressPercentageFromValues(
				rolledUpProjectData.estimate,
				rolledUpProjectData.remaining
			);
		} else {
			rolledUpProjectData.projectProgress = Util.calculateElementProgress(
				rolledUpProjectData.estimate,
				rolledUpProjectData.timeRegistered + projectRegisteredTime,
				rolledUpProjectData.remaining,
				rolledUpProjectData.taskCount,
				rolledUpProjectData.doneCount,
				project.estimationUnit === ESTIMATION_UNIT.HOURS,
				project.minutesPerEstimationPoint,
				false,
				project.status === PROJECT_STATUS.DONE
			);
		}
	}

	return rolledUpProjectData;
};

/**
 * Get all the data needed for the project total header section
 * @param {*} treeData
 * @param {*} project
 * @param {*} availableFeatureFlags
 * @returns
 */
export const getProjectTotalData = (treeData, project, availableFeatureFlags) => {
	const projectData = {
		budgetType: project.budgetType,
		budget: project.budget,
		defaultPeriodBudgetType: project.defaultPeriodBudgetType,
		rolledUpData: null,
		projectStartDay: project.projectStartDay,
		projectStartMonth: project.projectStartMonth,
		projectStartYear: project.projectStartYear,
		projectEndDay: project.projectEndDay,
		projectEndMonth: project.projectEndMonth,
		projectEndYear: project.projectEndYear,
		projectTimeRegisteredNoTask: null,
		projectBillableTimeRegisteredNoTask: null,
		projectDifferenceEstimate: null,
		projectRevenueNoTask: null,
		projectTimeEntries: null,
		projectActualRevenue: null,
		projectTotalPriceAtCompletion: null,
		projectTotalPriceAtCompletionNoTask: null,
		projectAllTotalTimeAndExpensesAtCompletion: null,
		minutesPerEstimationPoint: project.minutesPerEstimationPoint,
	};

	// Project totals for timeregs on project (not on a task)
	let projectTimeRegistrations;
	let projectRegisteredTime; //Total time registered on the project (not on a task)
	let projectBillableRegisteredTime;
	let projectRegisteredTimePrice; //Total price of time registered on the project (not on a task)
	if (project.timeRegistrations) {
		projectTimeRegistrations = project.timeRegistrations.edges.filter(tReg => tReg.node.task == null);
		projectRegisteredTime = projectTimeRegistrations.reduce(
			(totalTime, tmpTimeReg) => totalTime + tmpTimeReg.node.minutesRegistered,
			0
		);
		projectBillableRegisteredTime = projectTimeRegistrations.reduce(
			(totalTime, tmpTimeReg) => totalTime + tmpTimeReg.node.billableMinutesRegistered,
			0
		);
		projectRegisteredTimePrice = projectTimeRegistrations.reduce(
			(totalPrice, tmpTimeReg) => totalPrice + tmpTimeReg.node.price,
			0
		);
	}

	projectData.budgetWork = project.budgetWork;
	projectData.rolledUpData = getRollUpValuesFromProject(treeData, project, projectRegisteredTime, availableFeatureFlags);
	projectData.projectDifferenceEstimate = projectData.rolledUpData.differenceEstimate - projectRegisteredTime;
	projectData.projectTimeRegisteredNoTask = projectRegisteredTime;
	projectData.projectBillableTimeRegisteredNoTask = projectBillableRegisteredTime;
	projectData.projectRevenueNoTask = projectRegisteredTimePrice;
	projectData.projectTimeEntries = projectData.rolledUpData.timeRegistered + projectData.projectTimeRegisteredNoTask;
	projectData.projectBillableTimeEntries =
		projectData.rolledUpData.billableTimeRegistered + projectData.projectBillableTimeRegisteredNoTask;
	projectData.projectActualRevenue = projectData.rolledUpData.actualPrice + projectData.projectRevenueNoTask;
	projectData.projectTotalPriceAtCompletionNoTask = projectRegisteredTimePrice;
	projectData.projectTotalPriceAtCompletion =
		projectData.rolledUpData.totalPriceAtCompletion + projectData.projectTotalPriceAtCompletionNoTask;
	projectData.projectAllTotalTimeAndExpensesAtCompletion =
		projectData.rolledUpData.allTotalTimeAndExpensesAtCompletion +
		projectRegisteredTimePrice +
		project.expenseFinancialNumbers.allTotalTimeAndExpensesAtCompletion;

	projectData.progress = project.progress;
	projectData.manualProgressOnProjectEnabled = project.manualProgressOnProjectEnabled;
	projectData.manualProgressOnPhasesEnabled = project.manualProgressOnPhasesEnabled;
	projectData.manualProgressOnTasksEnabled = project.manualProgressOnTasksEnabled;
	projectData.manualProgressEnabled =
		project.manualProgressOnProjectEnabled | project.manualProgressOnPhasesEnabled | project.manualProgressOnTasksEnabled;
	return projectData;
};

/**
 * A recursive function which takes any task and creates a tree like structure of the task and all of its children
 * and their children, which is the stored in an object and returned to the caller.
 * @param task - The input task
 * @param taskProject - The project of the task
 * @param phaseId - The phase of the task
 * @param tasksExpansionMap
 * @param selectedTasks
 * @param sharedOptions
 * @param subtaskMap - An array containing the immediate children of a task. eg. subtaskMap[0] is the subtasks of task with id = 0 which is an array.
 * @param taskDepth - The task's depth in the tree structure. eg a task which is the child of the initial task has a depth of 2.
 * @param idSuffix
 * @param level
 * @param allTasksFiltered
 * @param groupSetting
 * @returns {any} - The task and all of its children and their children, which is recursively stored in the children field
 */
const getTaskElement = (
	task,
	taskProject,
	phaseId,
	tasksExpansionMap,
	selectedTasks,
	sharedOptions,
	subtaskMap,
	taskDepth,
	idSuffix,
	level,
	allTasksFiltered,
	groupSetting
) => {
	const isGrouped = groupSetting !== GROUP_TYPE.NO_GROUPING;
	// The immediate children of the current task
	const children = subtaskMap[task.node.id] || [];

	const parentTask = allTasksFiltered[task.node.parentTaskId];
	const parent =
		(groupSetting === GROUP_TYPE.ROLE || groupSetting === GROUP_TYPE.PERSON) && parentTask ? parentTask[0] : null;
	children.sort((t1, t2) => t1.node.sortOrder - t2.node.sortOrder);

	const maxSubtaskDepth = Util.getMaxSubtaskDepth(!!(taskProject && taskProject.isJiraProject));

	const findAncestors = (task, count = 0) => {
		const parent = task && allTasksFiltered[task.node.parentTaskId];
		if (parent && parent[0] && count < maxSubtaskDepth) {
			count++;
			return findAncestors(parent[0], count);
		} else {
			return count;
		}
	};

	const ancestors = findAncestors(task);

	// Recursively gets the task's child elements and their child elements.
	const childElements =
		children.length > 0
			? children.map(child =>
					getTaskElement(
						child,
						taskProject,
						phaseId,
						tasksExpansionMap,
						selectedTasks,
						sharedOptions,
						subtaskMap,
						taskDepth + 1,
						idSuffix,
						level + 1,
						allTasksFiltered,
						groupSetting
					)
			  )
			: [];

	// Calculates rollUpValues which contains every value related to a task, like revenue, estimates, etc.
	const rollUpValues = childElements
		? getRollUpValuesFromChildren(task, childElements, groupSetting, taskProject, sharedOptions)
		: getRollUpValuesFromTask(task);
	const taskSuffixId = task.node.id + (idSuffix ? '#' + idSuffix : '');

	const isTaskExpanded = getIsTaskExpanded(taskSuffixId, tasksExpansionMap);
	const isTaskSelected = getIsTaskSelected(task.node.id, selectedTasks);
	const isParentSelected = task.node.parentTaskId && getIsTaskSelected(task.node.parentTaskId, selectedTasks) && !isGrouped;

	if (sharedOptions.createSubtaskTask === task.node.id) {
		childElements.push(getAddTaskElement(phaseId, taskProject, sharedOptions, task.node.id));
	}

	return {
		id: taskSuffixId,
		type: TYPES.TASK,
		phaseId,
		level,
		taskDepth,
		expanded: isTaskExpanded,
		selected: isTaskSelected,
		task: task.node,
		taskProject: taskProject,
		sharedOptions: sharedOptions,
		rollUpValues: rollUpValues,
		parent: parent,
		children: childElements,
		groupSetting: groupSetting,
		parentSelected: isParentSelected,
		ancestors,
	};
};

const mapTasksByPersons = (tasks, projectPersons) => {
	const tasksByPersonMap = {};

	projectPersons.forEach(projectPerson => {
		tasksByPersonMap[projectPerson.node.person.id] = [];
	});
	tasksByPersonMap['no_person'] = [];

	tasks.forEach(task => {
		if (task.node.assignedPersons.length > 0) {
			task.node.assignedPersons.forEach(person => {
				// Null-check to handle client assignments
				tasksByPersonMap[person.id] && tasksByPersonMap[person.id].push(task);
			});
		} else {
			tasksByPersonMap['no_person'].push(task);
		}
	});

	return tasksByPersonMap;
};

const getAssignedPersonsToTaskMap = taskNodes => {
	const map = {};

	taskNodes.forEach(taskNode => {
		const {node: task} = taskNode;
		map[task.id] = task.assignedPersons.length;
	});

	return map;
};

const mapTasksByRoles = (roles, tasks) => {
	const tasksByRoleMap = {};
	// create arrays for each role, so empty roles also have an arry
	roles.forEach(role => {
		tasksByRoleMap[role.node.id] = [];
	});
	tasksByRoleMap['no_role'] = [];

	tasks.forEach(task => {
		let roleId;
		if (task.node.role === null) {
			roleId = 'no_role';
		} else {
			roleId = task.node.role.id;
		}

		tasksByRoleMap[roleId]?.push(task);
	});
	return tasksByRoleMap;
};

const getRoleGroupings = (
	roles,
	sortedPhaseTasks,
	project,
	tasksExpansionMap,
	selectedTasks,
	sharedOptions,
	subtaskMap,
	phaseId,
	phaseBaselineRoles,
	level,
	allTasksFiltered,
	groupField,
	intl,
	roleGroupExpansionMap,
	availabilityMap
) => {
	const {isUsingProjectAllocation, company} = sharedOptions;
	const displayAvailability = isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(company);
	const tasksByRolesMap = mapTasksByRoles(roles, sortedPhaseTasks);
	const useBaselineRoleGrouping = sharedOptions.visibility.showBaselineInfo && phaseBaselineRoles;
	const groups = [];

	// No role
	const noRoleTasks = tasksByRolesMap['no_role'];
	if (noRoleTasks.length > 0) {
		const children = noRoleTasks.map(task =>
			getTaskElement(
				task,
				project,
				phaseId,
				tasksExpansionMap,
				selectedTasks,
				sharedOptions,
				subtaskMap,
				1,
				null,
				level + 1,
				allTasksFiltered,
				groupField
			)
		);
		const groupName = intl.formatMessage({id: 'scheduling.unassigned_tasks'});
		const rollUpValues = getGroupingRollUpValues(`no_role`, children, sharedOptions, project, phaseId, availabilityMap);
		const roleGroupElement = getRoleGroupElement(
			{id: `no_role`, name: groupName},
			children,
			level,
			phaseId,
			sharedOptions,
			roleGroupExpansionMap,
			project,
			availabilityMap,
			rollUpValues,
			useBaselineRoleGrouping
		);
		groups.push(roleGroupElement);
	}

	const rolesWithoutTasks = [];
	roles.forEach(role => {
		const roleTasks = tasksByRolesMap[role.node.id];
		if (roleTasks.length > 0) {
			const children = roleTasks.map(task =>
				getTaskElement(
					task,
					project,
					phaseId,
					tasksExpansionMap,
					selectedTasks,
					sharedOptions,
					subtaskMap,
					1,
					null,
					level + 1,
					allTasksFiltered,
					groupField
				)
			);
			const rollUpValues = getGroupingRollUpValues(
				role.node.id,
				children,
				sharedOptions,
				project,
				phaseId,
				availabilityMap
			);
			if (useBaselineRoleGrouping) {
				const roleGroupElement = getRoleGroupElement(
					role.node,
					[],
					level,
					phaseId,
					sharedOptions,
					roleGroupExpansionMap,
					project,
					availabilityMap,
					rollUpValues,
					useBaselineRoleGrouping
				);
				groups.push(roleGroupElement);

				const phaseBaselineRole = phaseBaselineRoles.edges.find(
					baselineRole => baselineRole.node.role.id === role.node.id
				);
				const baselineElement = getBaselineElement(
					`${role.node.id}#${phaseId}`,
					phaseId,
					phaseBaselineRole?.node.baselineMinutes,
					phaseBaselineRole?.node.baselinePrice,
					phaseBaselineRole?.node.baselineCost,
					sharedOptions.availableColumns,
					sharedOptions.currencySymbol,
					children,
					level,
					roleGroupExpansionMap
				);

				groups.push(baselineElement);
			} else {
				const roleGroupElement = getRoleGroupElement(
					role.node,
					children,
					level,
					phaseId,
					sharedOptions,
					roleGroupExpansionMap,
					project,
					availabilityMap,
					rollUpValues
				);
				groups.push(roleGroupElement);
			}
		} else if (displayAvailability) {
			rolesWithoutTasks.push(role);
		}
	});

	rolesWithoutTasks.forEach(role => {
		const isAllocatedToProject = availabilityMap.get(`${phaseId}-${role.node.id}`);
		const rollUpValues = getGroupingRollUpValues(role.node.id, [], sharedOptions, project, phaseId, availabilityMap);

		if (isAllocatedToProject) {
			if (useBaselineRoleGrouping) {
				groups.push(
					getRoleGroupElement(
						role.node,
						[],
						level,
						phaseId,
						sharedOptions,
						roleGroupExpansionMap,
						project,
						availabilityMap,
						rollUpValues,
						useBaselineRoleGrouping,
						true
					)
				);

				const phaseBaselineRole = phaseBaselineRoles.edges.find(
					baselineRole => baselineRole.node.role.id === role.node.id
				);

				groups.push(
					getBaselineElement(
						`${role.node.id}#${phaseId}`,
						phaseId,
						phaseBaselineRole?.node.baselineMinutes,
						phaseBaselineRole?.node.baselinePrice,
						phaseBaselineRole?.node.baselineCost,
						sharedOptions.availableColumns,
						sharedOptions.currencySymbol,
						[],
						level,
						roleGroupExpansionMap
					)
				);
			} else {
				groups.push(
					getRoleGroupElement(
						role.node,
						[],
						level,
						phaseId,
						sharedOptions,
						roleGroupExpansionMap,
						project,
						availabilityMap,
						rollUpValues,
						false,
						true
					)
				);
			}
		}
	});

	return groups;
};

const getPersonGroupings = (
	sortedPhaseTasks,
	project,
	tasksExpansionMap,
	selectedTasks,
	sharedOptions,
	subtaskMap,
	phaseId,
	level,
	allTasksFiltered,
	groupField,
	intl,
	personGroupExpansionMap,
	availabilityMap
) => {
	const {isUsingProjectAllocation, company} = sharedOptions;
	const displayAvailability = isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(company);
	const tasksByPersonMap = mapTasksByPersons(sortedPhaseTasks, project.projectPersons.edges);
	const assignedPersonsByTaskMap = getAssignedPersonsToTaskMap(sortedPhaseTasks);

	const groups = [];

	// No person
	const personTasks = tasksByPersonMap['no_person'];
	if (personTasks.length > 0) {
		const personGroupId = `no_person#${phaseId || 'no_phase'}`;
		const children = personTasks.map(task =>
			getTaskElement(
				task,
				project,
				phaseId,
				tasksExpansionMap,
				selectedTasks,
				sharedOptions,
				subtaskMap,
				1,
				null,
				level + 1,
				allTasksFiltered,
				groupField
			)
		);
		const personGroupElement = getPersonGroupElement(
			personGroupId,
			{id: `no_person`, fullName: intl.formatMessage({id: 'scheduling.unassigned_tasks'})},
			children,
			level,
			phaseId,
			sharedOptions,
			personGroupExpansionMap,
			project,
			availabilityMap,
			assignedPersonsByTaskMap
		);
		groups.push(personGroupElement);
	}

	const getPersonGroupId = projectPerson => `${projectPerson.node.person.id}#${phaseId || 'no_phase'}`;
	const projectPersonsWithoutTasks = [];
	project.projectPersons.edges.forEach(projectPerson => {
		const personTasks = tasksByPersonMap[projectPerson.node.person.id];
		if (personTasks.length > 0) {
			const personGroupId = getPersonGroupId(projectPerson);
			const children = personTasks.map(task =>
				getTaskElement(
					task,
					project,
					phaseId,
					tasksExpansionMap,
					selectedTasks,
					sharedOptions,
					subtaskMap,
					1,
					personGroupId,
					level + 1,
					allTasksFiltered,
					groupField
				)
			);
			const personGroupElement = getPersonGroupElement(
				personGroupId,
				projectPerson.node.person,
				children,
				level,
				phaseId,
				sharedOptions,
				personGroupExpansionMap,
				project,
				availabilityMap,
				assignedPersonsByTaskMap
			);
			groups.push(personGroupElement);
		} else if (displayAvailability) {
			projectPersonsWithoutTasks.push(projectPerson);
		}
	});

	projectPersonsWithoutTasks.forEach(projectPerson => {
		const {person} = projectPerson.node;
		const isAllocatedToProject = availabilityMap.get(`${phaseId}-${person.id}`);

		if (isAllocatedToProject) {
			const personGroupId = getPersonGroupId(projectPerson);
			groups.push(
				getPersonGroupElement(
					personGroupId,
					person,
					null,
					level,
					phaseId,
					sharedOptions,
					personGroupExpansionMap,
					project,
					availabilityMap,
					assignedPersonsByTaskMap,
					true
				)
			);
		}
	});

	return groups;
};

/**
 *
 * @param {*} phaseTasks
 * @param {*} project
 * @param {*} groupField
 * @param {*} sharedOptions
 * @param {*} phaseId
 * @param {*} roles
 * @param {*} subtaskMap
 * @returns {object[]}
 */
const getPhaseChildren = (
	phaseTasks,
	project,
	tasksExpansionMap,
	selectedTasks,
	groupField,
	sharedOptions,
	phaseId,
	phaseBaselineRoles,
	roles,
	subtaskMap,
	level,
	allTasksFiltered,
	intl,
	personGroupExpansionMap,
	roleGroupExpansionMap,
	availabilityMap
) => {
	const taskElements = [];
	const sortedPhaseTasks = phaseTasks ? phaseTasks.sort(sortBySortOrder) : [];

	let headerSum = {
		totalAvailability: 0,
		remainingAvailability: 0,
		estimate: 0,
		actualPrice: 0,
		differenceEstimate: 0,
		price: 0,
		plannedCost: 0,
		actualCost: 0,
		progress: 0,
		remaining: 0,
		timeRegistered: 0,
		billableTimeRegistered: 0,
		doneEstimate: 0,
		notDoneTimeRegistered: 0,
		notDoneTimeRegisteredInPoints: 0,
		doneCount: 0,
		taskCount: 0,
		totalPriceAtCompletion: 0,
		projectedCost: 0,
		allTotalTimeAndExpensesAtCompletion: 0,
		roleGroupWarning: false,
		personGroupWarning: false,
	};

	if (groupField === GROUP_TYPE.ROLE && roles) {
		const roleGroupings = getRoleGroupings(
			roles,
			sortedPhaseTasks,
			project,
			tasksExpansionMap,
			selectedTasks,
			sharedOptions,
			subtaskMap,
			phaseId,
			phaseBaselineRoles,
			level,
			allTasksFiltered,
			groupField,
			intl,
			roleGroupExpansionMap,
			availabilityMap
		);
		taskElements.push(...roleGroupings);
		roleGroupings.forEach(roleGrouping => {
			const {role} = roleGrouping;
			const isUnassignedTasks = roleGrouping.type !== 'baseline' && role.id === 'no_role';

			if (!isUnassignedTasks && roleGrouping.rollUpValues?.minutesRemaining < 0) {
				headerSum.roleGroupWarning = true;
			}
		});
	} else if (groupField === GROUP_TYPE.PERSON) {
		const personGroupings = getPersonGroupings(
			sortedPhaseTasks,
			project,
			tasksExpansionMap,
			selectedTasks,
			sharedOptions,
			subtaskMap,
			phaseId,
			level,
			allTasksFiltered,
			groupField,
			intl,
			personGroupExpansionMap,
			availabilityMap
		);
		taskElements.push(...personGroupings);
		personGroupings.forEach(personGrouping => {
			const {person} = personGrouping;
			const isUnassignedTasks = person.id === 'no_person';

			if (!isUnassignedTasks && personGrouping.rollUpValues?.minutesRemaining < 0) {
				headerSum.personGroupWarning = true;
			}
		});
	}

	if (sortedPhaseTasks) {
		// Get the task elements and calculates the header sum
		sortedPhaseTasks.forEach(task => {
			const taskElement = getTaskElement(
				task,
				project,
				phaseId,
				tasksExpansionMap,
				selectedTasks,
				sharedOptions,
				subtaskMap,
				1,
				null,
				level,
				allTasksFiltered,
				groupField
			);

			// Every rollup value is rapped in a RollUpValue object which contains value and approvedValue, the former is used to display in the table,
			// while the latter is used to calculate the header values where unapproved tasks are not included.
			headerSum.differenceEstimate += taskElement.rollUpValues.rollupDifferenceEstimate.getApprovedValue();
			headerSum.estimate += taskElement.rollUpValues.rollupEstimate.getApprovedValue();
			headerSum.remaining += taskElement.rollUpValues.rollupRemaining.getApprovedValue();
			headerSum.timeRegistered += taskElement.rollUpValues.rollupTimeRegistered.getApprovedValue();
			headerSum.billableTimeRegistered += taskElement.rollUpValues.rollupBillableTimeRegistered.getApprovedValue();
			headerSum.actualPrice += taskElement.rollUpValues.rollupActualPrice.getApprovedValue();
			headerSum.price += taskElement.rollUpValues.rollupPrice.getApprovedValue();
			headerSum.plannedCost += taskElement.rollUpValues.rollupPlannedCost.getApprovedValue();
			headerSum.actualCost += taskElement.rollUpValues.rollupActualCost.getApprovedValue();

			// For calculating the progress
			headerSum.doneEstimate += taskElement.rollUpValues.rollupDoneEstimate.getApprovedValue();
			headerSum.notDoneTimeRegistered += taskElement.rollUpValues.rollupNotDoneTimeRegistered.getApprovedValue();
			headerSum.notDoneTimeRegisteredInPoints +=
				taskElement.rollUpValues.rollupNotDoneTimeRegisteredInPoints.getApprovedValue();
			headerSum.doneCount += taskElement.rollUpValues.rollupDoneChildrenCount.getApprovedValue();
			headerSum.taskCount += taskElement.rollUpValues.rollupChildrenCount.getApprovedValue();
			headerSum.totalPriceAtCompletion += taskElement.rollUpValues.rollupTotalPriceAtCompletion.getApprovedValue();
			headerSum.projectedCost += taskElement.rollUpValues.rollupProjectedCost.getApprovedValue();
			headerSum.allTotalTimeAndExpensesAtCompletion +=
				taskElement.rollUpValues.rollupAllTotalTimeAndExpensesAtCompletion.getApprovedValue();

			// If no groupping add taskElement to taskElements
			if (groupField === GROUP_TYPE.NO_GROUPING) {
				taskElements.push(taskElement);
			}
		});
	}

	const phaseAvailability = getValue(phaseId, availabilityMap);
	headerSum.totalAvailability = phaseAvailability.minutesAvailable;
	headerSum.remainingAvailability = phaseAvailability.remainingMinutesAvailable - headerSum.remaining;

	if (ProjectUtil.projectUsesManualProgress(project)) {
		headerSum.progress = Util.getSimpleProgressPercentageFromValues(headerSum.estimate, headerSum.remaining);
	} else {
		headerSum.progress = Util.calculateElementProgress(
			headerSum.estimate,
			headerSum.timeRegistered,
			headerSum.remaining,
			headerSum.taskCount,
			headerSum.doneCount,
			project.estimationUnit === ESTIMATION_UNIT.HOURS,
			project.minutesPerEstimationPoint,
			false,
			project.status === PROJECT_STATUS.DONE
		);
	}

	return [headerSum, taskElements];
};

export const searchFilter = (task, searchFilterValue) => {
	if (!searchFilterValue || searchFilterValue.length === 0 || searchFilterValue === '') return true;
	const taskValue = 'T' + task.node.companyTaskId + task.node.name;
	const searchValue = searchFilterValue.trim();
	return Util.normalizedIncludes(taskValue, searchValue);
};

const taskFilter = (task, options, filterFunctions) => {
	if (!filterFunctions || !filterFunctions.taskFilter) return true;
	return filterFunctions.taskFilter(task.node, options);
};

const taskChildrenFilter = (task, filterFunctions, options, searchFilterValue, subtaskMap) => {
	const taskMatchesFilter = taskFilter(task, options, filterFunctions) && searchFilter(task, searchFilterValue);
	const children = task.node.hasChildren && subtaskMap[task.node.id];
	const childMatchesFilter =
		children &&
		children.length > 0 &&
		children.filter(child => taskChildrenFilter(child, filterFunctions, options, searchFilterValue, subtaskMap))?.length >
			0;
	return taskMatchesFilter || childMatchesFilter;
};

const dateInPast = (y, m, d) => {
	const today = Moment.utc();
	const dateAsMoment = Util.CreateMomentDate(y, m, d);
	return dateAsMoment && today.isAfter(dateAsMoment, 'day');
};

const setTopNodeId = (nodes, id) => {
	if (nodes) {
		nodes.forEach(node => {
			node.topNodeId = id;
			setTopNodeId(node.children, id);
		});
	}
};

const groupTasksByParent = (allTasks, useEpics) => {
	let grouped = {};

	allTasks.forEach(t => {
		if (useEpics && t.node.taskType === 'EPIC') return;
		const parentTaskId = t.node.parentTaskId;
		if (grouped[parentTaskId]) {
			grouped[parentTaskId].push(t);
		} else {
			if (useEpics && parentTaskId && t.node.parentIsEpic) {
				if (grouped[null]) {
					grouped[null].push(t);
				} else {
					grouped[null] = [t];
				}
			} else {
				grouped[parentTaskId] = [t];
			}
		}
	});

	return grouped;
};

export const generateTree = (
	viewer,
	phases,
	phasesExpansionMap,
	tasksExpansionMap,
	selectedTasks,
	availableColumns,
	groupSetting,
	filterFunctions,
	searchFilterValue,
	predictionDataMap,
	sharedOptions,
	intl,
	personGroupExpansionMap,
	roleGroupExpansionMap,
	availabilityMap,
	baselineRoleFinancialNumbers
) => {
	const level = 0;

	const {financialNumbersMap: groupedFinancialNumbers} =
		getGroupedFinancialNumbersByPhaseAndBaselineId(baselineRoleFinancialNumbers);

	const allTasks = viewer.project.tasks.edges;
	const allTasksWithParentIsEpicMarking = allTasks
		.filter(t => !hasFeatureFlag('forecast_epics') || t.node.taskType !== 'EPIC')
		.map(t => {
			let task = {
				node: {...t.node},
			};
			task.node.parentIsEpic =
				hasFeatureFlag('forecast_epics') &&
				allTasks.find(task => task.node.id === t.node.parentTaskId)?.node.taskType === 'EPIC';

			return task;
		});

	// Append lazy loaded prediction data to tasks if fetching is finished
	const allTasksWithPredictions = !predictionDataMap
		? allTasksWithParentIsEpicMarking
		: allTasksWithParentIsEpicMarking.map(t => {
				return {
					node: {
						...t.node,
						predictedEstimate: predictionDataMap[t.node.id]?.predictedEstimate,
					},
				};
		  });

	const subtaskMap = groupSetting === GROUP_TYPE.ROLE ? [] : groupBy(allTasksWithPredictions, 'node.parentTaskId');

	const options = {teams: viewer.company.teams ? viewer.company.teams.edges : []};

	const allTasksFiltered = allTasksWithPredictions.filter(task =>
		taskChildrenFilter(task, filterFunctions, options, searchFilterValue, subtaskMap)
	);

	const subtaskMapFiltered =
		groupSetting === GROUP_TYPE.ROLE || groupSetting === GROUP_TYPE.PERSON
			? []
			: groupTasksByParent(allTasksFiltered, hasFeatureFlag('forecast_epics'));

	const taskOuterLevel =
		groupSetting === GROUP_TYPE.ROLE || groupSetting === GROUP_TYPE.PERSON
			? allTasksFiltered
			: (subtaskMapFiltered[null] || []).concat(subtaskMapFiltered[undefined] || []);

	const allTasksMap = groupBy(allTasksFiltered, 'node.id');
	const phaseTaskMap = groupBy(taskOuterLevel, 'node.phase.id');
	let treeData = [];

	const currency = viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency;
	const currencySymbol = Util.GetCurrencySymbol(currency);
	//loop through the phases
	phases.forEach(phase => {
		const expanded = getIsPhaseExpanded(phase.id, phasesExpansionMap);
		const [rolledUpValues, children] = getPhaseChildren(
			phaseTaskMap[phase.id],
			viewer.project,
			tasksExpansionMap,
			selectedTasks,
			groupSetting,
			sharedOptions,
			phase.id,
			phase.phaseBaselineRoles,
			viewer.company.roles.edges,
			subtaskMapFiltered,
			level + 1,
			allTasksMap,
			intl,
			personGroupExpansionMap,
			roleGroupExpansionMap,
			availabilityMap
		);

		const phaseId = idFromGlobalId(phase.id);
		const phaseFinacialNumbers = groupedFinancialNumbers.get(phaseId);

		let phaseElement;

		// Use baselines
		if (sharedOptions.visibility.showBaselineInfo) {
			phaseElement = getPhaseElement(
				phase,
				rolledUpValues,
				viewer.project,
				availableColumns,
				expanded,
				[],
				level,
				currencySymbol,
				sharedOptions
			);
			treeData.push(phaseElement);
			if (expanded) {
				const baselineElement = getBaselineElement(
					phase.id,
					phase.id,
					phase.baselineTargetMinutes,
					hasFeatureFlag('baseline_financial_service')
						? phaseFinacialNumbers
							? phaseFinacialNumbers.totalBaselineTimeAndExpenses
							: 0
						: phase.baselineTargetPriceNoExpenses,
					hasFeatureFlag('baseline_financial_service')
						? phaseFinacialNumbers
							? phaseFinacialNumbers.totalCost
							: 0
						: phase.baselineCost,
					availableColumns,
					sharedOptions.currencySymbol,
					children
				);
				baselineElement.children.push(getAddTaskElement(phase.id, viewer.project, sharedOptions));
				treeData.push(baselineElement);

				// Set the top node ID - Phase ID
				setTopNodeId(baselineElement.children, phase.id);
			}
		} else {
			phaseElement = getPhaseElement(
				phase,
				rolledUpValues,
				viewer.project,
				availableColumns,
				expanded,
				children,
				level,
				currencySymbol,
				sharedOptions
			);
			phaseElement.children.push(getAddTaskElement(phase.id, viewer.project, sharedOptions));
			treeData.push(phaseElement);
		}

		// Set the top node ID - Phase ID
		setTopNodeId(phaseElement.children, phase.id);
	});

	// Push empty group
	const noPhaseObject = {id: 'no-phase', name: intl.formatMessage({id: 'project_scopes.no-scope'})};
	let noPhaseElement;
	if (phaseTaskMap[undefined] !== undefined) {
		//we don't show the no phase section if it is empty
		const [rolledUpValues, noPhaseChildren] = getPhaseChildren(
			phaseTaskMap[undefined],
			viewer.project,
			tasksExpansionMap,
			selectedTasks,
			groupSetting,
			sharedOptions,
			'no-phase',
			null,
			viewer.company.roles.edges,
			subtaskMapFiltered,
			level + 1,
			allTasksMap,
			intl,
			personGroupExpansionMap,
			roleGroupExpansionMap,
			availabilityMap
		);

		// Set the top node ID - No phase ID
		setTopNodeId(noPhaseChildren, noPhaseObject.id);

		noPhaseElement = getPhaseElement(
			noPhaseObject,
			rolledUpValues,
			viewer.project,
			availableColumns,
			true,
			noPhaseChildren,
			level,
			currencySymbol,
			sharedOptions
		);
		treeData.push(noPhaseElement);
		treeData.push(getAddTaskElement(null, viewer.project, sharedOptions));
	}

	// Retainer period - setup
	if (sharedOptions.visibility.showPeriods) {
		const periodTreeData = [];
		const periods = [...viewer.project.retainerPeriods?.edges].sort(sortByStartDate);
		const phaseInsidePeriods = new Set();

		// Loop through all the periods to find out, which to show.
		periods.forEach(periodNode => {
			const period = periodNode.node;
			const periodStart = Util.CreateNonUtcMomentDate(period.startYear, period.startMonth, period.startDay);
			const periodEnd = Util.CreateNonUtcMomentDate(period.endYear, period.endMonth, period.endDay);

			// Find all phase elements that is inside the period
			const phaseElements = treeData
				.filter(element => element.type === TYPES.HEADER) // Is a the phase
				.filter(element => element.phase.startYear) // Has a start date (year)
				.filter(element => {
					const phaseStartDate = Util.CreateNonUtcMomentDate(
						element.phase.startYear,
						element.phase.startMonth,
						element.phase.startDay
					);
					return phaseStartDate.isBetween(periodStart, periodEnd, null, '[]');
				});

			// If any phases are found inside the period, add the period to the tree - or if the show empty period option is selected.
			if (phaseElements.length > 0 || sharedOptions.visibility.showEmptyPeriods) {
				//Register the phases inside the period
				phaseElements.forEach(phase => phaseInsidePeriods.add(phase.id));

				// Overwrite top node ID with period ID
				setTopNodeId(phaseElements, period.id);

				//Find task without a phase in the period
				const taskNoPhaseInPeriod = noPhaseElement?.children
					.filter(element => element.type === TYPES.TASK)
					.filter(element => {
						const taskStartDate = Util.CreateNonUtcMomentDate(
							element.task.startYear,
							element.task.startMonth,
							element.task.startDay
						);
						return taskStartDate.isBetween(periodStart, periodEnd, null, '[]');
					});

				//Calculate rolledUpValues
				const rolledUpValues = getRollUpValuesForPeriod(phaseElements, taskNoPhaseInPeriod);

				// Create period Element
				let periodElement = getPeriodElement(
					period,
					availableColumns,
					currencySymbol,
					rolledUpValues,
					sharedOptions,
					level
				);
				periodTreeData.push(periodElement);
				periodTreeData.push(...phaseElements);
			}
		});

		// Crate the outside period
		const outsideId = 'outside-period';

		let outsidePeriodElement = getPeriodElement(
			{id: outsideId, name: 'Outside period'},
			availableColumns,
			currencySymbol,
			null,
			sharedOptions,
			level
		);
		const outsidePhaseElements = treeData
			.filter(element => element.type === TYPES.HEADER) // Is a the phase
			.filter(element => !phaseInsidePeriods.has(element.id)); // Not already inside another period

		// Overwrite top node ID with period ID
		setTopNodeId(outsidePhaseElements, outsideId);

		periodTreeData.push(outsidePeriodElement);
		periodTreeData.push(...outsidePhaseElements);

		// Use the period tree structure
		treeData = periodTreeData;
	}

	// Add the empty fills
	if (treeData.length > 0) {
		treeData.push({
			id: 'empty-fill-1',
			type: TYPES.EMPTY_FILL,
			// we need to have a phase id which is not null and not undefined
			// otherwise it breaks the sticky header bar
			phaseId: 'empty-fill-1',
		});
		treeData.push({
			id: 'empty-fill-2',
			type: TYPES.EMPTY_FILL,
			// we need to have a phase id which is not null and not undefined
			// otherwise it breaks the sticky header bar
			phaseId: 'empty-fill-2',
		});
	}

	return treeData;
};

export const phaseActiveFilter = (phase, selectedTab) => {
	if (!selectedTab || !selectedTab.value || selectedTab.value === 'all') return true;
	const isDone = dateInPast(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay);
	return selectedTab.value === 'done' ? isDone : !isDone;
};

const isSameOrParent = (task1, task2) => {
	return task1 && task2 && (task1.id === task2.id || (task1.parentTaskId && task1.parentTaskId === task2.id));
};

export const isTaskInHierarchy = (task, hierarchyNode) => {
	if (isSameOrParent(task, hierarchyNode.task)) return true;

	if (hierarchyNode.children.length > 0) {
		return hierarchyNode.children.some(child => isSameOrParent(task, child.task));
	}
	return false;
};
