import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Moment from 'moment';
import {injectIntl} from 'react-intl';
import Dropdown from '../dropdowns/dropdown';
import Button from '../buttons/button/button';
import TimeRegWSuggestionsInput from '../inputs/time-registration-input/time_reg_w_sugestions_input';
import DatePicker from '../date-picker/date_picker_v3';
import Util from '../../util/util';
import CreateTimeRegistrationMutation from '../../../../mutations/create_time_registration_mutation.modern';
import CreateMultipleTimeRegistrationMutation from '../../../../mutations/create_multiple_time_registration_mutation.modern';
import UpdateTimeRegistrationMutation from '../../../../mutations/update_time_registration_mutation.modern';
import {createToast} from '../toasts/another-toast/toaster';
import {formatProjectOption, formatTaskOption} from '../dropdowns/task-dropdown/add_time_entry_data_format';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import TaskDropdownRelayWrapper, {taskDropdownRelayWrapperQuery} from '../dropdowns/task-dropdown/task_dropdown_relay_wrapper';
import ProjectDropdownRelay from '../dropdowns/project-dropdown-b/Project_dropdown';
import LabelWithTooltip from './validation-info/LabelWithTooltip';
import {NoteRequiredError, NoteRequiredInfoIcon} from './validation-info/NoteRequiredInfo';
import {TaskRequiredError, TaskRequiredInfoIcon} from './validation-info/TaskRequiredInfo';

import {
	ButtonWrapper,
	DropdownLabel,
	DropdownLabelWrapper,
	DropdownWrapper,
	ElemWrapper,
	ModalWrapper,
	TabSelectorWrapper,
	TertiaryButtonStyleOverride,
	TextArea,
	TimeEntryWrapper,
} from '../../../../styles/components/timesheets/add_time_entry_component_styled';
import InlineLoader from '../inline-loader/inline_loader';
import {MODAL_TYPE, showModal} from '../modals/generic_modal_conductor';
import TooltipContainer from '../tooltips/tooltip_container';
import {cloneDeep} from 'lodash';
import DirectApi from '../../../../directApi';
import {updateTaskForScoping} from '../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {createDateFromMoment, isDateDisabled} from '../../util/DateUtil';
import {hasFeatureFlag} from '../../util/FeatureUtil';

import {ComboBox, DeprecatedText, FlexColumn, FlexRow, Text} from '@forecast-it/design-system';

import {canLoggedInPersonManageProgram, hasTopDownProgramBudgetFeature} from '../../util/ProgramFinancialLogic';
import {ACTION} from '../modals/program-management/ProgramBudgetErrorMessage';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {getStartAndEndDates} from '../../../my-work-tab/my-timesheets-page/timesheets_change_date_view';
import {
	isBillableSplitAllowed,
	isInternalTimeRegistrationAllowed,
	isProjectTimeRegistrationAllowed,
	isRolesAllowed,
	isTimeRegistrationNoteRequired,
} from '../../util/cache/TimeRegistrationSettingsUtil';
import {isTimeRegistrationNoteInvalid} from '../../util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import TimerTaskInvalidInfo from './TimerTaskInvalidInfo';
import {trackTimeRegistrationTargetSuggestionFeedback} from '../../../../tracking/tracking_types/TrackTimeRegistrationTargetSuggestion';
import {TimeRegGranularityInfoIcon} from './validation-info/TimeRegGranularityInfo';
import HoursInput from '../inputs/hours-input/hours_input_view';
import {workingHourForTheDay} from '../../../my-work-tab/my-timesheets-page/timesheets_person_data';
import {convertIntoFloatHoursFormatWithGranularity} from '../inputs/hours-input/hours_input_logic';
import {
	getIsDateDisabledForTaskAndProject,
	getIsDateDisabledForTaskAndProjectWithReason,
	getProjectDates,
} from '../../util/time-registration/time-registration-settings/TimeRegistrationDateUtil_BackwardsCompatibility';
import {
	getTotalMinutesRegisteredFromTimeRegistrationEdges,
	useTaskEstimateValidation,
} from '../../util/time-registration/time-registration-settings/useTaskEstimateValidation';
import {RemainingTimeAndError} from './validation-info/TimeExceedsEstimateInfo';
import {DateRestrictionInfoIcon} from './validation-info/DateRestrictionInfo';
import {getTimeRegistrationDateRestrictionInfoMessage} from '../../util/time-registration/time-registration-settings/TimeRegistrationDateFilter';
import {DateInvalidError} from './validation-info/DateInvalidError';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import RoleDropdownRelayWrapper from '../dropdowns/role-dropdown/RoleDropdownRelayWrapper';
import {RoleDropdownLabelFormat} from '../dropdowns/role-dropdown/RoleDropdown';
import {TRACKING_OBJECTS} from '../../../../tracking/amplitude/constants/TrackingObjects';
import OptIntoFeatureFlagMutation from '../../../../mutations/ts/OptIntoFeatureFlagMutation';
import {toEntityId} from '../modals/time-registration/time-registration-modal/util/TimeRegistrationModalUtil';
import {usePrevious} from '../../hooks/ts/usePreviousPersistent';
import {getDefaultRole} from '../../util/time-registration/time-role/TimeRegistrationRoleUtil';
import {updateRoleOnProjectChange} from '../modals/time-registration/time-registration-modal/util/TimeRegistrationModalRoleUtil';
import ProjectUtil from '../../util/project_util';
import {DesignSystemComboBoxOldLookAdapter} from '../dropdowns/DesignSystemComboBoxOldLookAdapter';
import {getFormattedValue} from '../../../my-work-tab/my-timesheets-page/bottom-section/timesheets-time-registration-table/timesheet-table-util/TimeRegUtil';

const getEntityIdFromSelected = (selectedProject, selectedTask) => {
	if (selectedTask) {
		return toEntityId({taskId: selectedTask.value});
	} else if (selectedProject?.idleTimeId) {
		return toEntityId({idleTimeId: selectedProject.idleTimeId});
	} else if (selectedProject?.projectId) {
		return isProjectTimeRegistrationAllowed() ? toEntityId({projectId: selectedProject.projectId}) : null;
	}
};

const optIntoFeatureFlag = (viewer, modalState, closeModal) => {
	const onSuccess = () => {
		trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Opted Into New Time Registration Modal');
		closeModal();
		showModal({
			type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
			personId: viewer.actualPersonId,
			entityId: getEntityIdFromSelected(modalState.selectedProject, modalState.selectedTask),
			initialMinutes: modalState.timeInputValue,
			initialBillableTime: modalState.billableTime,
			initialDate: modalState.selectedDate ? createDateFromMoment(modalState.selectedDate) : undefined,
			initialNote: modalState.notesInputValue,
			initialRole: modalState.selectedRole,
		});
	};

	Util.CommitMutation(OptIntoFeatureFlagMutation, {featureFlag: 'new_time_registration_modal'}, onSuccess);
};

const isTimeRegistrationTargetInvalid = (selectedProject, selectedTask) => {
	if (selectedProject?.projectId && !isProjectTimeRegistrationAllowed()) {
		return !selectedTask;
	}
	return !selectedProject && !selectedTask;
};

function getBillableTimeInputValue(targetEntityBillable, billableTime, timeInputValue) {
	if (!targetEntityBillable) {
		return 0;
	}
	if (billableTime !== null && billableTime !== undefined) {
		return billableTime / 60;
	}
	if (timeInputValue) {
		return timeInputValue / 60;
	}
	return 0;
}

function getBillableTimeInputValue_timeRegInputConversion(targetEntityBillable, billableTime, timeInputValue) {
	if (!targetEntityBillable) {
		return 0;
	}
	if (billableTime != null) {
		return `${billableTime}m`;
	}
	if (timeInputValue) {
		return `${timeInputValue}m`;
	}
	return 0;
}

function getInitialFormattedValue(roundedTimerDuration, passedTime) {
	return roundedTimerDuration || passedTime;
}
function getInitialFormattedValue_timeRegInputConversion(roundedTimerDuration, passedTime) {
	if (roundedTimerDuration) {
		return `${roundedTimerDuration}h`;
	}
	return passedTime;
}

const addTimeEntryComponent = ({
	intl,
	viewer,
	refetch,
	pageSize,
	clearTimer,
	registerTimeClearTimer,
	closeModal,
	updateTimeRegId,
	timerProject,
	timerTask,
	timerIdleTime,
	roundedTimerDuration,
	passedTime,
	initialBillableTime,
	initialRole,
	passedDate,
	passedNotes,
	selectedPerson,
	weekArray,
	isWeekView,
	isTimepage,
	setShowTimeEntryPortal,
	googleTask,
	source,
	lockedDate,
	passedHarvestTask,
	showFullNotes,
	hasOpportunityAccess,
	overBudgetProgramsByCompanyProjectId,
	timeRegistrationTargetSuggestionsData,
	invalidatedTimerTask,
	timeOffOnly,
}) => {
	const formatMessage = intl.formatMessage;
	const notesRef = useRef();
	const today = Moment();

	const projects = useMemo(() => {
		return {edges: viewer.projects.edges.filter(project => !ProjectUtil.projectUsesManualProgress(project))};
	}, [viewer.projects]);

	const [notesIsFocused, setNotesIsFocused] = useState(false);
	const [selectedProject, setSelectedProject] = useState(timerProject || timerIdleTime);
	const previousSelectedProjectId = usePrevious(selectedProject?.projectId);
	const [showMovedToProjectRoleWarning, setShowMovedToProjectRoleWarning] = useState(false);
	const [selectedTask, setSelectedTask] = useState(timerTask);
	const [harvestTasks, setHarvestTasks] = useState([]);
	const [harvestLoading, setHarvestLoading] = useState(false);
	const [selectedHarvestTask, setSelectedHarvestTask] = useState(passedHarvestTask ? {value: passedHarvestTask} : null);
	const [selectedUnit4Activity, setSelectedUnit4Activity] = useState(null);
	const [unit4Loading, setUnit4Loading] = useState(false);
	const [unit4Activities, setUnit4Activities] = useState([]);
	const [selectedDate, setSelectedDate] = useState(
		lockedDate && lockedDate.isSameOrAfter(today, 'day') ? lockedDate.clone().add(1, 'd') : today
	);
	const [selectedEndDate, setSelectedEndDate] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [timeInputValue, setTimeInputValue] = useState(roundedTimerDuration * 60 || passedTime * 60); // not the one shown in the input but the one that gonna be sent to the mutation
	const [billableTime, setBillableTime] = useState(initialBillableTime);
	const [notesInputValue, setNotesInputValue] = useState(passedNotes ? passedNotes : '');
	const [selectedTab, setSelectedTab] = useState(
		timeOffOnly
			? 'TIMEOFF'
			: timerProject || timerTask || googleTask
			? 'PROJECT'
			: timerIdleTime
			? timerIdleTime.isInternalTime
				? 'INTERNAL'
				: 'TIMEOFF'
			: !isInternalTimeRegistrationAllowed()
			? 'PROJECT'
			: localStorage.getItem('timer-modal-selected-tab') || 'PROJECT'
	);
	const [committingData, setCommittingData] = useState(false);
	const [showUnavailableTasksMessage, setShowUnavailableTasksMessage] = useState(false);
	const [timeRegistrationTargetError, setTimeRegistrationTargetError] = useState(false);
	const [noteError, setNoteError] = useState(false);
	const [timeError, setTimeError] = useState('');
	const [dateError, setDateError] = useState('');
	const [dateInfo, setDateInfo] = useState('');

	const targetEntityBillable = selectedTask ? selectedTask.billable : selectedProject ? selectedProject.billable : true;
	const billableSplitAllowed = isBillableSplitAllowed();

	const billableTimeInputValue = hasFeatureFlag('timereg_input_conversion')
		? getBillableTimeInputValue_timeRegInputConversion(targetEntityBillable, billableTime, timeInputValue)
		: getBillableTimeInputValue(targetEntityBillable, billableTime, timeInputValue);

	const workingHoursForDay = workingHourForTheDay(
		selectedPerson ? selectedPerson : {...viewer, id: viewer.actualPersonId},
		selectedDate || Moment()
	);

	const minutesRegistered =
		selectedTask?.totalMinutesRegistered ||
		getTotalMinutesRegisteredFromTimeRegistrationEdges(selectedTask?.timeRegistrations?.edges);

	const {remainingInfoMessage, validateTimeInputValue} = useTaskEstimateValidation(
		selectedTask?.estimateForecastMinutes,
		minutesRegistered,
		passedTime * 60,
		intl
	);

	const rolesAllowed = isRolesAllowed();

	const [selectedRole, setSelectedRole] = useState(initialRole);

	// In case component it's called from "new button -> time entry" and not my timesheet page
	if (!overBudgetProgramsByCompanyProjectId) {
		const hasTopDownProgramBudgetFeatureFlag = hasTopDownProgramBudgetFeature();
		overBudgetProgramsByCompanyProjectId = projects.edges
			.filter(
				project =>
					hasTopDownProgramBudgetFeatureFlag &&
					project.node.isProgramRevenueLocked &&
					project.node.billable &&
					project.node.programBudgetType === PROGRAM_BUDGET_TYPE.CAPPED
			)
			.map(project => ({companyProjectId: project.node.companyProjectId, programInfo: project.node.program}));
	}
	const isWeekendLocked = isDateDisabled(passedDate);

	const noteRequired = isTimeRegistrationNoteRequired();

	useEffect(() => {
		if (lockedDate && lockedDate.isSameOrAfter(selectedDate, 'day')) {
			setSelectedDate(lockedDate.clone().add(1, 'd'));
			setSelectedEndDate(null);
		}
	}, [lockedDate]);

	let innerRef = null;

	const scrollRef = useRef(null);
	const billableMinutesUpdated = useRef(false);

	const isFullyLocked = useMemo(() => {
		let fullyLocked = false;
		if (lockedDate && passedDate) {
			fullyLocked = isWeekView
				? lockedDate.isSameOrAfter(passedDate.clone().endOf('week'), 'd')
				: lockedDate.isSameOrAfter(passedDate, 'd');
		}
		if (isWeekendLocked) {
			fullyLocked = true;
		}
		return fullyLocked;
	}, [isWeekView, lockedDate, passedDate, isWeekendLocked]);

	const tooltipProps = isFullyLocked && {
		infoText: formatMessage({id: 'time-lock.tooltip-date-locked'}),
		showDelay: 300,
		tooltipPosition: 'bottom',
		canBeMiddle: true,
	};

	const lockedDateWithinTheWeek = useMemo(() => {
		if (lockedDate && isWeekView) {
			return (
				lockedDate.isBefore(passedDate.clone().endOf('week'), 'd') &&
				lockedDate.isAfter(passedDate.clone().startOf('week'), 'd')
			);
		}
		return false;
	}, [isWeekView, lockedDate, passedDate]);

	useEffect(() => {
		const newDefaultRole =
			getDefaultRole(null, viewer?.project?.projectPerson?.role, viewer?.task?.role, viewer.company?.person?.role) ||
			undefined;
		updateRoleOnProjectChange({
			newDefaultRole,
			initialRole,
			projectId: selectedProject?.projectId,
			previousProjectId: previousSelectedProjectId,
			selectedRole,
			setSelectedRole,
			setShowMovedToProjectRoleWarning,
		});
	}, [
		initialRole?.id,
		selectedProject?.projectId,
		previousSelectedProjectId,
		viewer?.task?.role?.id,
		viewer?.project?.projectPerson?.role?.id,
	]);

	const onRoleManuallySelected = useCallback(
		role => {
			setSelectedRole(role);
			setShowMovedToProjectRoleWarning(false);
		},
		[setSelectedRole, setShowMovedToProjectRoleWarning]
	);

	useEffect(() => {
		if (notesIsFocused) {
			scrollRef && scrollRef.current && scrollRef.current.animateScrollTop(1000);
		}
	}, [notesIsFocused]);

	useEffect(() => {
		if (selectedTask && selectedProject) {
			setDateInfo(
				getTimeRegistrationDateRestrictionInfoMessage({
					projectStartDate: selectedProject.projectStartDate,
					projectEndDate: selectedProject.projectEndDate,
					taskStartDate: selectedTask.taskStartDate,
					taskEndDate: selectedTask.taskEndDate,
				})
			);
		}
	}, [selectedTask]);

	useEffect(() => {
		if (selectedProject && selectedTask && selectedDate && !!dateError) {
			const dateInvalidReason = getIsDateDisabledForTaskAndProjectWithReason({
				date: selectedDate.toDate(),
				projectStartDate: selectedProject.projectStartDate,
				projectEndDate: selectedProject.projectEndDate,
				taskStartDate: selectedTask.taskStartDate,
				taskEndDate: selectedTask.taskEndDate,
			});
			if (!dateInvalidReason) {
				setDateError('');
			}
		} else {
			setDateError('');
		}
	}, [selectedDate, selectedTask]);

	const fetchHarvestTasks = project => {
		//If project dropdown is not empty
		if (project) {
			//If a project is selected, show only tasks that belong to that project
			if (project.projectId && project.harvestProject) {
				if (harvestLoading === true || harvestTasks.length > 0) return;
				setHarvestLoading(true);
				DirectApi.Fetch('harvest_tasks/' + project.projectId).then(tasks => {
					setHarvestTasks(tasks);
					setHarvestLoading(false);
				});
			} else {
				setHarvestTasks([]);
				setHarvestLoading(false);
			}
		} else {
			setHarvestTasks([]);
			setHarvestLoading(false);
		}
	};

	const fetchUnit4Activities = project => {
		//If project dropdown is not empty
		if (project) {
			//If a project is selected, show only tasks that belong to that project
			if (project.projectId && project.unit4Project) {
				if (unit4Loading === true || unit4Activities.length > 0) return;
				setUnit4Loading(true);
				DirectApi.Fetch('unit4_activities/' + project.projectId).then(activities => {
					setUnit4Activities(activities);
					setUnit4Loading(false);
				});
			} else {
				setUnit4Activities([]);
				setUnit4Loading(false);
			}
		} else {
			setUnit4Activities([]);
			setUnit4Loading(false);
		}
	};

	const handleChangeProjectDropdown = (_, ids) => {
		trackEvent('Time Entry Modal Project', 'Selected', {
			id: !!ids.length ? ids[0] : null,
		});
		if (ids.length === 0) {
			setSelectedProject(null);
		} else {
			// Hack to support old code
			const newProject = {
				...projects.edges.find(project => project.node.id === ids[0]).node,
			};
			newProject.projectId = newProject.id;
			const projectDates = getProjectDates(newProject);
			newProject.projectStartDate = projectDates.projectStartDate;
			newProject.projectEndDate = projectDates.projectEndDate;

			setShowUnavailableTasksMessage(false);
			if (
				!isBillableSplitAllowed() &&
				overBudgetProgramsByCompanyProjectId.find(project => project.companyProjectId === newProject.companyProjectId)
			) {
				setShowUnavailableTasksMessage(true);
			}

			setSelectedProject(newProject);
		}

		setSelectedTask(null);
		setUnit4Activities([]);
		setHarvestTasks([]);
	};

	const handleChangeInternalTimeDropdown = selectedOption => {
		const idleTime = viewer.company.idleTimes.edges.find(edge => edge.node.id === selectedOption);
		trackEvent(
			'Time Entry Modal Idle Time',
			'Selected',
			idleTime
				? {
						id: idleTime.node.id,
						name: idleTime.node.name,
				  }
				: {}
		);
		setSelectedProject(
			idleTime
				? {
						id: idleTime.node.id,
						value: idleTime.node.id,
						label: idleTime.node.name,
						idleTimeId: idleTime.node.id,
				  }
				: null
		);
	};

	const handleTaskChange = task => {
		trackEvent('Time Entry Modal Task', 'Selected', {
			id: task && task.value ? task.value : null,
			project: task && task.project ? task.project.projectId : null,
		});
		setTimeRegistrationTargetError(false);
		task && task.value ? setSelectedTask(task) : setSelectedTask(null);
		task && task.project ? setSelectedProject(task.project) : void 0;
	};

	const handleHarvestTaskChange = harvestTask => {
		setSelectedHarvestTask(harvestTask);
	};

	const handleUnit4ActivityChange = unit4Activity => {
		setSelectedUnit4Activity(unit4Activity);
	};

	const handleNoteChange = e => {
		setNoteError(false);
		setNotesInputValue(e.target.value);
	};

	const handleTabChange = tab => {
		if (tab !== selectedTab) {
			trackEvent('Time Entry Modal Category Tab', 'Selected', {
				tab: tab === 'PROJECT' ? 'TASK' : tab,
			});
			setSelectedTab(tab);
			setSelectedProject(tab === 'PROJECT' ? timerProject : null);
			setSelectedTask(tab === 'PROJECT' || tab === 'TASK' ? timerTask : null);
			setSelectedHarvestTask(null);
			setSelectedUnit4Activity(null);
			Util.localStorageSetItem('timer-modal-selected-tab', tab);
		}
	};

	const handleDateSelection = option => {
		if (option.value) {
			setSelectedDate(option.value);
		}
	};

	const onSearchValueChange = searchValue => {
		setSearchValue(searchValue);
	};

	const clearModal = () => {
		setSelectedProject(null);
		setSelectedTask(null);
		setSelectedHarvestTask(null);
		setHarvestTasks([]);
		setSearchValue('');
		setTimeInputValue('');
		setBillableTime(null);
		setNotesInputValue('');

		dispatch(EVENT_ID.RESET_INPUT);

		if (setShowTimeEntryPortal) {
			setShowTimeEntryPortal(false);
		}
	};

	// Set selectedDate to either a day in the week if week view, or the passedDate if dayview.
	useEffect(() => {
		// Prioritize setting selected date to a day in the week if weekView is selected

		if (lockedDateWithinTheWeek) {
			//check the first unlockedDay
			let day = null;
			weekArray.forEach(d => {
				if (lockedDate.isBefore(d, 'd') && day === null) {
					day = d;
				}
			});
			setSelectedDate(day);
			setSelectedEndDate(null);
			return;
		}

		if (isWeekView && weekArray && weekArray.length > 0) {
			if (passedDate) {
				let day = null;
				weekArray.forEach(d => {
					if (passedDate.isSame(d, 'day')) {
						day = d;
					}
				});
				if (day) {
					setSelectedDate(day);
					setSelectedEndDate(null);
					return;
				}
			}
			const today = Moment();
			let todayInWeek = null;
			weekArray.forEach(weekDay => {
				if (weekDay.isSame(today, 'day')) {
					todayInWeek = weekDay;
				}
			});
			// If today is not in selected week, default to first day of the week (this will be sunday for US).
			setSelectedDate(todayInWeek ? todayInWeek : weekArray[0]);
		} else if (passedDate) {
			setSelectedDate(passedDate);
		}
		setSelectedEndDate(null);
	}, [passedDate, weekArray]);

	useEffect(() => {
		if (googleTask) {
			const googleProject = formatProjectOption(googleTask.project);
			const googleT = formatTaskOption(googleTask);
			setSelectedProject(googleProject);
			setSelectedTask(googleT);
			innerRef.focus();
		}
	}, [googleTask]);

	useEffect(() => {
		if (passedHarvestTask) {
			fetchHarvestTasks(selectedProject);
		}
	}, []);

	useEffect(() => {
		const timeInvalidReason = validateTimeInputValue(timeInputValue);
		if (timeInvalidReason) {
			setTimeError(timeInvalidReason);
		} else {
			setTimeError(null);
		}
	}, [timeInputValue, selectedTask]);

	const setSuggestedValueToInput = value => {
		if (hasFeatureFlag('timereg_input_conversion')) {
			setTimeInputValue(Util.convertTimeInputToMinutes(value != null ? `${value}h` : undefined, true));
		} else {
			if (isNaN(value)) {
				setTimeInputValue(null);
			} else {
				setTimeInputValue(Util.convertTimeInputToMinutes(value));
			}
		}
	};

	const onHoursInputChange = value => {
		trackEvent('Time Entry Modal', 'Time Input Changed', {from: roundedTimerDuration || passedTime, to: value});

		setSuggestedValueToInput(value);

		if (!billableMinutesUpdated.current) {
			setBillableTime(null);
		}
	};

	const onBillableHoursInputChange = value => {
		const formattedValue = getFormattedValue(value);

		trackEvent('Time Entry Modal', 'Billable Time Input Changed', {
			from: billableTimeInputValue,
			to: formattedValue / 60,
		});

		setBillableTime(formattedValue);

		billableMinutesUpdated.current = true;
	};

	const showProgramRevenueLockedMessage = (program, companyProjectId) => {
		const loggedInPersonId = viewer.actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.ADD_TIME_ENTRY,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
			companyProjectId: companyProjectId,
			actualPersonId: loggedInPersonId,
		});

		trackEvent('Program Budget Error Message Modal', 'Shown');
	};

	const getTrackingProperties = () => {
		const projectId = selectedProject && selectedProject.companyProjectId ? selectedProject.companyProjectId : null;
		const taskId = selectedTask ? selectedTask.companyTaskId : null;
		const subTask = selectedTask ? !!selectedTask.parentTaskId : null;
		const idleTimeId = selectedProject && selectedProject.idleTimeId ? selectedProject.label : null;

		const timeRegPerson = selectedPerson
			? selectedPerson.id === viewer.actualPersonId
				? 'myself'
				: selectedPerson.email
			: 'myself';

		let dates = {};

		if (selectedEndDate) {
			dates.startDate = selectedDate.format('YYYY-MM-DD');
			dates.endDate = selectedEndDate?.format('YYYY-MM-DD');
		} else {
			dates.date = selectedDate.format('YYYY-MM-DD');
		}

		let properties = {
			id: '',
			item: '',
			timeRegPerson: timeRegPerson,
			weekView: !!isWeekView,
			notes: notesInputValue !== undefined ? notesInputValue : '',
			...dates,
		};

		if (idleTimeId) {
			properties.id = idleTimeId;
			properties.item = 'idleTime';
			properties.itemName = selectedProject.label;
		} else if (projectId && !taskId) {
			properties.id = projectId;
			properties.item = 'project';
		} else if (projectId && taskId && !subTask) {
			properties.id = taskId;
			properties.item = 'task';
		} else if (projectId && taskId && subTask) {
			properties.id = taskId;
			properties.item = 'subTask';
		}

		return properties;
	};

	const registerTime = () => {
		const timeRegTargetInvalid = isTimeRegistrationTargetInvalid(selectedProject, selectedTask);
		if (timeRegTargetInvalid) {
			setTimeRegistrationTargetError(true);
		}

		const noteInvalid = isTimeRegistrationNoteInvalid(notesInputValue);
		if (noteInvalid) {
			setNoteError(true);
		}

		const dateInvalidReason = getIsDateDisabledForTaskAndProjectWithReason({
			date: selectedDate?.toDate(),
			projectStartDate: selectedProject?.projectStartDate,
			projectEndDate: selectedProject?.projectEndDate,
			taskStartDate: selectedTask?.taskStartDate,
			taskEndDate: selectedTask?.taskEndDate,
		});

		if (dateInvalidReason) {
			setDateError(dateInvalidReason);
		} else {
			setDateError('');
		}

		if (timeRegTargetInvalid || noteInvalid || !!timeError || !!dateInvalidReason) {
			return;
		}

		trackEvent(`Time Entry Modal ${updateTimeRegId ? 'Update' : 'Add'} Button`, 'Clicked', getTrackingProperties());

		const onSuccess = () => {
			dispatch(EVENT_ID.TIME_REGISTRATION_MUTATION_SUCCESS, {
				timeRegisteredInHours: timeInputValue / 60,
				wasClickEvent: false,
			});
			createToast({
				duration: 5000,
				message: updateTimeRegId
					? formatMessage({id: 'time_page.time_reg_updated'})
					: formatMessage({id: 'timer_modal.registered_toast_text'}),
			});
			clearModal();
			if (closeModal) {
				closeModal();
			}
			if (registerTimeClearTimer) {
				registerTimeClearTimer();
			}
		};
		const handleResult = result => {
			const resultType = result.createTimeReg || result.updateTimeReg || result.createMultipleTimeRegs;
			if (resultType && resultType.errors && resultType.errors.length === 1) {
				if (resultType.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
					showModal({
						type: MODAL_TYPE.WARNING_DATE_LOCKED,
					});
				} else if (Util.checkForSageErrorAndShowModal(resultType.errors)) {
					return;
				} else if (resultType.errors[0] === 'PROGRAM_REVENUE_LOCKED') {
					showProgramRevenueLockedMessage(
						overBudgetProgramsByCompanyProjectId.find(
							project => project.companyProjectId === selectedProject.companyProjectId
						).programInfo,
						selectedProject.companyProjectId
					);
				}
			} else {
				updateTaskForScoping(selectedTask?.value, onSuccess);
			}
			setCommittingData(false);

			if (result.createMultipleTimeRegs && passedDate) {
				const dates = getStartAndEndDates(passedDate);
				refetch({
					startDateString: dates.start,
					endDateString: dates.end,
				});
			}
		};

		if (!isNaN(timeInputValue) && timeInputValue !== '' && timeInputValue !== null) {
			setCommittingData(true);
			const billableMinutesRegistered = targetEntityBillable ? billableTime : undefined;

			if (
				hasFeatureFlag('pto_timesheet_allocation_linking') &&
				selectedTab === 'TIMEOFF' &&
				!updateTimeRegId &&
				selectedDate &&
				selectedEndDate
			) {
				Util.CommitSchedulingModalUpdate(
					CreateMultipleTimeRegistrationMutation,
					{
						viewer: viewer,
						source: source ? source : 'Time-entry-component',
						projectId: selectedProject && selectedProject.projectId ? selectedProject.projectId : null,
						taskId: selectedTask ? selectedTask.value : null,
						subTask: selectedTask ? !!selectedTask.parentTaskId : null,
						idleTimeId: selectedProject && selectedProject.idleTimeId ? selectedProject.idleTimeId : null,
						minutesRegistered: timeInputValue,
						day: selectedDate.date(),
						month: selectedDate.month() + 1,
						year: selectedDate.year(),
						endDay: selectedEndDate.date(),
						endMonth: selectedEndDate.month() + 1,
						endYear: selectedEndDate.year(),
						personId: selectedPerson ? selectedPerson.id : viewer.actualPersonId,
						harvestTaskId: selectedHarvestTask ? selectedHarvestTask.value : undefined,
						unit4ActivityId: selectedUnit4Activity ? selectedUnit4Activity.value : undefined,
						unit4ActivityName: selectedUnit4Activity ? selectedUnit4Activity.label : undefined,
						notes: notesInputValue !== '' ? notesInputValue : null,
					},
					handleResult
				);
			} else if (updateTimeRegId) {
				Util.CommitSchedulingModalUpdate(
					UpdateTimeRegistrationMutation,
					{
						id: updateTimeRegId,
						source: source ? source : 'Time-entry-component',
						updateTimeRegLocation: true,
						projectId: selectedProject && selectedProject.projectId ? selectedProject.projectId : null,
						taskId: selectedTask ? selectedTask.value : null,
						idleTimeId: selectedProject && selectedProject.idleTimeId ? selectedProject.idleTimeId : null,
						minutesRegistered: timeInputValue,
						billableMinutesRegistered: billableMinutesRegistered,
						day: selectedDate.date(),
						month: selectedDate.month() + 1,
						year: selectedDate.year(),
						harvestTaskId: selectedHarvestTask ? selectedHarvestTask.value : undefined,
						unit4ActivityId: selectedUnit4Activity ? selectedUnit4Activity.value : undefined,
						unit4ActivityName: selectedUnit4Activity ? selectedUnit4Activity.label : undefined,
						notes: notesInputValue !== '' ? notesInputValue : null,
						roleId: selectedRole?.id,
					},
					handleResult
				);
			} else {
				Util.CommitSchedulingModalUpdate(
					CreateTimeRegistrationMutation,
					{
						viewer: viewer,
						source: source ? source : 'Time-entry-component',
						projectId: selectedProject && selectedProject.projectId ? selectedProject.projectId : null,
						taskId: selectedTask ? selectedTask.value : null,
						subTask: selectedTask ? !!selectedTask.parentTaskId : null,
						idleTimeId: selectedProject && selectedProject.idleTimeId ? selectedProject.idleTimeId : null,
						minutesRegistered: timeInputValue,
						billableMinutesRegistered: billableMinutesRegistered,
						day: selectedDate.date(),
						month: selectedDate.month() + 1,
						year: selectedDate.year(),
						personId: selectedPerson ? selectedPerson.id : viewer.actualPersonId,
						harvestTaskId: selectedHarvestTask ? selectedHarvestTask.value : undefined,
						unit4ActivityId: selectedUnit4Activity ? selectedUnit4Activity.value : undefined,
						unit4ActivityName: selectedUnit4Activity ? selectedUnit4Activity.label : undefined,
						notes: notesInputValue !== '' ? notesInputValue : null,
						roleId: selectedRole?.id,
					},
					handleResult
				);
				if (timeRegistrationTargetSuggestionsData?.timeRegistrationTargetSuggestions) {
					const timeRegistrationTargetSuggestions =
						timeRegistrationTargetSuggestionsData?.timeRegistrationTargetSuggestions;
					const oldTimeRegistrationTargetSuggestions =
						timeRegistrationTargetSuggestionsData?.oldTimeRegistrationTargetSuggestions;
					trackTimeRegistrationTargetSuggestionFeedback({
						companyId: viewer.company.id,
						viewerId: viewer.actualPersonId,
						personId: selectedPerson ? selectedPerson.id : viewer.actualPersonId,
						uiSource: 'timesheet-modal',
						modelVersion: timeRegistrationTargetSuggestions.modelVersion,
						timeRegistrationTargetTaskId: selectedTask ? selectedTask.value : null,
						timeRegistrationTargetProjectId:
							selectedProject && selectedProject.projectId ? selectedProject.projectId : null,
						timeRegistrationTargetIdleTimeId:
							selectedProject && selectedProject.idleTimeId ? selectedProject.idleTimeId : null,
						timeRegistrationDate: selectedDate.format('YYYY-MM-DD'),
						timeRegistrationTargetSuggestions: timeRegistrationTargetSuggestions?.sortedSuggestions,
						oldTimeRegistrationTargetSuggestions: oldTimeRegistrationTargetSuggestions,
					});
				}
			}
		} else {
			innerRef.select();
		}
	};

	// function for the new internal time dropdown
	/**
	 *
	 * @param {"internal" | "timeOff"} type
	 */
	const getIdleTimeOptions = type => {
		const options = [];
		const sortedIdleTimes = cloneDeep(viewer.company.idleTimes.edges).sort((a, b) =>
			Util.sortAlphabetically(a.node.name, b.node.name)
		);
		for (const idleTime of sortedIdleTimes) {
			const getItem = () => {
				return {
					cy: `option-${idleTime.node.name}`,
					value: idleTime.node.id,
					label: idleTime.node.name,
				};
			};

			if (!idleTime.node.disabled && type === 'internal' && idleTime.node.isInternalTime) {
				options.push(getItem());
			} else if (!idleTime.node.disabled && type === 'timeOff' && !idleTime.node.isInternalTime) {
				options.push(getItem());
			}
		}
		return options;
	};

	const harvestTaskOptions = harvestTasks
		? harvestTasks.map(harvestTask => {
				return {value: harvestTask.id, label: harvestTask.name};
		  })
		: [];

	const unit4ActivityOptions = unit4Activities
		? unit4Activities.map(unit4Activity => {
				return {value: unit4Activity.id, label: unit4Activity.name};
		  })
		: [];

	const weekDayOptions = weekArray
		? weekArray.map(weekDay => {
				return {
					value: weekDay,
					label: Util.upperCaseFirst(weekDay.format('dddd')) + ' (' + weekDay.format('l') + ')',
					disabled: (lockedDate && lockedDate.isSameOrAfter(weekDay, 'day')) || isDateDisabled(weekDay),
				};
		  })
		: [];

	const dateRangeChange = (start, end) => {
		setSelectedDate(start);
		setSelectedEndDate(end);
	};

	const cancelClicked = () => {
		trackEvent(
			`Time Entry Modal ${!roundedTimerDuration || !viewer.timerStartDate ? 'Cancel' : 'Reset'} Button`,
			'Clicked'
		);
		!roundedTimerDuration || !viewer.timerStartDate ? closeModal() : clearTimer(true);
	};

	const closeClicked = () => {
		trackEvent('Time Entry Modal Close Button', 'Clicked');
		clearModal();
	};

	const isDateInvalid = date => {
		const datesObject = {
			date: createDateFromMoment(date),
			taskStartDate: selectedTask ? selectedTask.taskStartDate : null,
			taskEndDate: selectedTask ? selectedTask.taskEndDate : null,
			projectStartDate: selectedProject ? selectedProject.projectStartDate : null,
			projectEndDate: selectedProject ? selectedProject.projectEndDate : null,
		};
		return getIsDateDisabledForTaskAndProject(datesObject);
	};

	const getDateSelector = () => {
		if (
			hasFeatureFlag('pto_timesheet_allocation_linking') &&
			selectedTab === 'TIMEOFF' &&
			((isWeekView && weekArray.length > 0) || timeOffOnly)
		) {
			return (
				<ElemWrapper isTimepage={isTimepage}>
					<DatePicker
						startDate={selectedDate}
						endDate={selectedEndDate}
						handleDateRangeChange={dateRangeChange}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						isSingleDatePicker={false}
						disabled={isFullyLocked}
						startDateLimite={lockedDate && lockedDate.clone().add(1, 'd')}
						disabledDates={isDateDisabled}
					/>
					<DateInvalidError dateError={dateError} />
				</ElemWrapper>
			);
		} else if (!isTimepage) {
			return (
				<ElemWrapper isTimepage={isTimepage}>
					<DropdownLabel className="card-select-label">
						{!!dateInfo ? (
							<LabelWithTooltip label={formatMessage({id: 'common.date'})} alwaysShowTooltip={!!dateInfo}>
								<DateRestrictionInfoIcon message={dateInfo} />
							</LabelWithTooltip>
						) : (
							formatMessage({id: 'common.date'})
						)}
					</DropdownLabel>
					<TooltipContainer {...tooltipProps}>
						<DatePicker
							startDate={selectedDate}
							handleDateRangeChange={setSelectedDate}
							datePickerStyle={DATE_PICKER_STYLE.STANDARD}
							isSingleDatePicker={true}
							disabled={isFullyLocked}
							startDateLimite={lockedDate && lockedDate.clone().add(1, 'd')}
							disabledDates={isDateInvalid}
						/>
					</TooltipContainer>
					<DateInvalidError dateError={dateError} />
				</ElemWrapper>
			);
		} else if (isWeekView && weekArray.length > 0) {
			return (
				<ElemWrapper isTimepage={isTimepage}>
					<DropdownLabel>
						{!!dateInfo ? (
							<LabelWithTooltip label={formatMessage({id: 'common.select_day'})} alwaysShowTooltip={!!dateInfo}>
								<DateRestrictionInfoIcon message={dateInfo} />
							</LabelWithTooltip>
						) : (
							formatMessage({id: 'common.select_day'})
						)}
					</DropdownLabel>
					<Dropdown
						customHeight={30}
						onChange={handleDateSelection}
						options={weekDayOptions}
						value={selectedDate}
						focusOnClick={true}
						placeholder={'Select day'}
						hideLabel={true}
						disabled={isFullyLocked}
						tooltipProps={tooltipProps}
					/>
					<DateInvalidError dateError={dateError} />
				</ElemWrapper>
			);
		} else if (!!dateError && !isWeekView) {
			return (
				<ElemWrapper isTimepage={isTimepage}>
					<DateInvalidError dateError={dateError} />
				</ElemWrapper>
			);
		}
	};

	const initialFormatedValue = hasFeatureFlag('timereg_input_conversion')
		? getInitialFormattedValue_timeRegInputConversion(roundedTimerDuration, passedTime)
		: getInitialFormattedValue(roundedTimerDuration, passedTime);

	const initialFocusElementRef = useRef(null);

	useEffect(() => {
		if (initialFocusElementRef?.current) {
			initialFocusElementRef.current.focus();
		}
	}, []);

	const content = (
		<>
			{invalidatedTimerTask ? <TimerTaskInvalidInfo invalidatedTimerTask={invalidatedTimerTask} /> : null}
			{timeOffOnly ? null : (
				<TabSelectorWrapper isTimepage={isTimepage}>
					<Button
						id={'add-time-view-task'}
						cy={'add-time-view-task'}
						text={formatMessage({id: 'common.task'})}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={selectedTab === 'PROJECT' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.FADEDGREY}
						onClick={handleTabChange.bind(this, 'PROJECT')}
						isDisabled={isFullyLocked || passedHarvestTask}
						ref={initialFocusElementRef}
					/>
					{!timerIdleTime?.isInternalTime && !isInternalTimeRegistrationAllowed() ? null : (
						<Button
							id={'add-time-view-internal'}
							cy={'add-time-view-internal'}
							text={formatMessage({id: 'common.internal_time'})}
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={selectedTab === 'INTERNAL' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.FADEDGREY}
							onClick={handleTabChange.bind(this, 'INTERNAL')}
							userpilot={'timesheets-internal-time-button'}
							isDisabled={isFullyLocked || passedHarvestTask || timerTask?.jiraId}
						/>
					)}
					<Button
						id={'add-time-view-time-off'}
						cy={'add-time-view-time-off'}
						text={formatMessage({id: 'common.time_off'})}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={selectedTab === 'TIMEOFF' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.FADEDGREY}
						onClick={handleTabChange.bind(this, 'TIMEOFF')}
						userpilot={'timesheets-time-off-button'}
						isDisabled={isFullyLocked || passedHarvestTask || !!viewer.company.bambooHREnabled || timerTask?.jiraId}
						tooltipProps={!!viewer.company.bambooHREnabled && Util.bambooHrTimeoffTooltipProps(formatMessage)}
					/>
				</TabSelectorWrapper>
			)}

			{!isTimepage && showUnavailableTasksMessage && selectedTab === 'PROJECT' ? (
				<ElemWrapper className="message-wrapper" isTimepage={isTimepage}>
					<FlexColumn gap={'s'}>
						<DeprecatedText type={'heading'} variant={'s'}>
							{formatMessage({id: 'common.unavailable_tasks'})}
						</DeprecatedText>
						<FlexRow>
							<FlexColumn>
								<DeprecatedText>{formatMessage({id: 'task_modal.unavialable_tasks_message'})}</DeprecatedText>
							</FlexColumn>
						</FlexRow>
					</FlexColumn>
				</ElemWrapper>
			) : null}

			{selectedTab === 'PROJECT' ? (
				<DropdownWrapper isTimepage={isTimepage}>
					<DropdownLabelWrapper>
						<DropdownLabel>{formatMessage({id: 'common.project'})}</DropdownLabel>

						<TooltipContainer {...tooltipProps}>
							<ProjectDropdownRelay
								showHalted={false}
								showDone={false}
								projects={projects.edges}
								isClearable
								optionsName={formatMessage({id: 'common.projects'})}
								selectedItems={selectedProject ? [selectedProject.projectId] : []}
								onRemove={handleChangeProjectDropdown}
								onSelect={handleChangeProjectDropdown}
								groupByStatus
								showRunning
								showPlanning
								showOpportunity={hasOpportunityAccess}
								showSecondaryIcon
								usePortal
								isHarvestUser={selectedPerson ? selectedPerson.harvestUser : viewer.harvestUser}
								maxHeight={255}
								name={formatMessage({id: 'common.all_projects'})}
								userpilot="timesheets-select-project"
								disabled={isFullyLocked || passedHarvestTask || timerTask?.jiraId}
								cy="time-entry-project-dropdown"
							/>
						</TooltipContainer>
					</DropdownLabelWrapper>
				</DropdownWrapper>
			) : null}
			<DropdownWrapper isTimepage={isTimepage}>
				{selectedTab === 'PROJECT' || selectedTab === 'TASK' ? (
					<DropdownLabelWrapper>
						<DropdownLabel>
							{!isProjectTimeRegistrationAllowed() ? (
								<LabelWithTooltip label={'task'} isRequired>
									<TaskRequiredInfoIcon />
								</LabelWithTooltip>
							) : (
								formatMessage({id: 'common.task'})
							)}
						</DropdownLabel>
						<ForecastQueryRenderer
							key="query-render-taskDropdownRelayWrapper"
							query={taskDropdownRelayWrapperQuery}
							customLoader={() => <InlineLoader />}
							variables={{
								pageSize: pageSize,
								modalSearchQuery: {filters: []},
								sortValue: 'project-id',
							}}
							render={(relayProps, retry) => {
								return (
									<TaskDropdownRelayWrapper
										{...relayProps}
										overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
										pageSize={pageSize}
										selectedProject={selectedProject}
										searchValue={searchValue}
										retry={retry}
										placeholder={
											selectedProject && selectedProject.label !== ''
												? formatMessage(
														{id: 'common.tasks_in_project'},
														{project: selectedProject.label}
												  )
												: formatMessage({id: 'common.all_tasks'})
										}
										expandedPlaceholder={formatMessage({id: 'common.type_to_search'})}
										externalSearch={onSearchValueChange}
										value={selectedTask && !isFullyLocked ? selectedTask.label : ''}
										onChange={handleTaskChange}
										isHarvestUser={selectedPerson ? selectedPerson.harvestUser : viewer.harvestUser}
										closeOnScroll={isTimepage}
										projectIdLabel={
											selectedTask
												? {
														companyProjectId: selectedTask.companyProjectId,
														customProjectId: selectedTask.customProjectId,
														name: selectedTask.name,
														projectColor: selectedTask.projectColor,
												  }
												: null
										}
										userpilot={'timesheets-select-task'}
										disabled={isFullyLocked || timerTask?.jiraId}
										personId={selectedPerson ? selectedPerson.id : viewer.actualPersonId}
									/>
								);
							}}
						/>
						{timeRegistrationTargetError ? <TaskRequiredError /> : null}
					</DropdownLabelWrapper>
				) : selectedTab === 'INTERNAL' ? (
					<DesignSystemComboBoxOldLookAdapter>
						<ComboBox
							data-cy="time-internal-select-dropdown"
							defaultItems={getIdleTimeOptions('internal')}
							value={selectedProject?.id || null}
							onChange={handleChangeInternalTimeDropdown}
							width={ComboBox.WIDTH.FLEX}
							helperText={'INTERNAL TIME'}
							placeholder={formatMessage({id: 'overview_time.select_internal'})}
							size={'small'}
							menuTrigger="focus"
						>
							{option => (
								<ComboBox.Item key={option.value} textValue={option.label}>
									<Text data-cy={'option-' + option.label} size={'2'}>
										{option.label}
									</Text>
								</ComboBox.Item>
							)}
						</ComboBox>
					</DesignSystemComboBoxOldLookAdapter>
				) : (
					<DesignSystemComboBoxOldLookAdapter>
						<ComboBox
							data-cy="time-off-select-dropdown"
							defaultItems={getIdleTimeOptions('timeOff')}
							value={selectedProject?.id || null}
							onChange={handleChangeInternalTimeDropdown}
							width={ComboBox.WIDTH.FLEX}
							helperText={'TIME OFF'}
							placeholder={formatMessage({id: 'overview_time.select_time-off'})}
							size={'small'}
							menuTrigger="focus"
						>
							{option => (
								<ComboBox.Item key={option.value} textValue={option.label}>
									<Text data-cy={'option-' + option.label} size={'2'}>
										{option.label}
									</Text>
								</ComboBox.Item>
							)}
						</ComboBox>
					</DesignSystemComboBoxOldLookAdapter>
				)}
			</DropdownWrapper>
			{selectedProject &&
			selectedProject.harvestProject &&
			(selectedPerson ? selectedPerson.harvestUser : viewer.harvestUser) &&
			viewer.company.harvestEnabled ? (
				<DropdownWrapper isTimepage={isTimepage}>
					<DropdownLabelWrapper>
						<Dropdown
							id={'time-harvest-dropdown'}
							options={harvestTaskOptions}
							onChange={handleHarvestTaskChange}
							value={selectedHarvestTask ? selectedHarvestTask.value : ''}
							label={formatMessage({id: 'card_modal.harvest_task'})}
							multi={false}
							clearable={true}
							focusOnClick={true}
							customHeight={30}
							placeholder={
								harvestLoading
									? formatMessage({id: 'common.loading'})
									: formatMessage({id: 'card_modal.harvest_task_placeholder'})
							}
							maxHeight={245}
							onClick={fetchHarvestTasks.bind(this, selectedProject)}
							integrationDropdown={true}
							integrationLoading={harvestLoading}
							integrationRetryToFetch={fetchHarvestTasks.bind(this, selectedProject)}
							disabled={isFullyLocked}
							tooltipProps={tooltipProps}
						/>
					</DropdownLabelWrapper>
				</DropdownWrapper>
			) : null}
			{selectedProject &&
			selectedProject.unit4Project &&
			selectedProject.unit4Project.activitiesEnabled &&
			(selectedPerson ? selectedPerson.unit4User : viewer.unit4User) &&
			viewer.company.unit4Enabled ? (
				<DropdownWrapper isTimepage={isTimepage}>
					<DropdownLabelWrapper>
						<Dropdown
							id={'time-unit4-dropdown'}
							options={unit4ActivityOptions}
							onChange={handleUnit4ActivityChange}
							value={selectedUnit4Activity ? selectedUnit4Activity.value : ''}
							label={formatMessage({id: 'integrations.unit4_activity'})}
							multi={false}
							clearable={true}
							focusOnClick={true}
							customHeight={30}
							placeholder={formatMessage({id: 'integrations.unit4_activity_placeholder'})}
							maxHeight={245}
							onClick={fetchUnit4Activities.bind(this, selectedProject)}
							integrationDropdown={true}
							integrationLoading={unit4Loading}
							integrationRetryToFetch={fetchUnit4Activities.bind(this, selectedProject)}
							disabled={isFullyLocked}
							tooltipProps={tooltipProps}
						/>
					</DropdownLabelWrapper>
				</DropdownWrapper>
			) : null}
			<ElemWrapper className="time-wrapper" isTimepage={isTimepage}>
				<TimeEntryWrapper>
					<TimeRegWSuggestionsInput
						id={'time-add-hours-input'}
						initialValue={initialFormatedValue}
						onInputChange={value => {
							onHoursInputChange(value);
						}}
						onHoursInputFormatted={value => {
							if (value !== null) {
								setSuggestedValueToInput(value);
							}
						}}
						placeholder={formatMessage({id: 'hours_input.placeholder'})}
						clearable={true}
						date={selectedDate}
						task={selectedTask}
						initialTaskId={updateTimeRegId && timerTask ? timerTask.value : null}
						idleTime={selectedProject && selectedProject.idleTimeId ? selectedProject : null}
						personId={selectedPerson ? selectedPerson.id : viewer.actualPersonId}
						person={selectedPerson ? selectedPerson : {...viewer, id: viewer.actualPersonId}}
						innerRef={div => (innerRef = div)}
						userpilot={'timesheets-hours-input'}
						disabled={isFullyLocked}
						infoText={isFullyLocked && formatMessage({id: 'time-lock.tooltip-date-locked'})}
						onlyFormatGranularityOnBlur={updateTimeRegId}
						isTimePage={isTimepage}
					/>
					{billableSplitAllowed && (selectedTab === 'PROJECT' || selectedTab === 'TASK') && (
						<div>
							<div className="icon-title-wrapper">
								<div className="input-title">
									<LabelWithTooltip label={'Billable time'} isTimePage={isTimepage}>
										<TimeRegGranularityInfoIcon />
									</LabelWithTooltip>
								</div>
							</div>
							<div style={{width: '90px'}}>
								<HoursInput
									customClassName={'hour-input'}
									cy={'new-billable-time-entry-input'}
									value={billableTimeInputValue}
									mutation={onBillableHoursInputChange}
									disabled={!targetEntityBillable || isFullyLocked}
									replicateDesignSystem={false}
									onlyFormatGranularityOnBlur={updateTimeRegId}
									granularityFormatter={(val, intl, minuteLimit, showAsterisk) =>
										convertIntoFloatHoursFormatWithGranularity(
											val,
											intl,
											minuteLimit,
											showAsterisk,
											workingHoursForDay
										)
									}
									isTimeregInput={hasFeatureFlag('timereg_input_conversion')}
								/>
							</div>
						</div>
					)}
				</TimeEntryWrapper>
				<RemainingTimeAndError
					remainingInfoMessage={remainingInfoMessage}
					timeError={timeError}
					marginTop={'12px'}
					marginBottom={'0px'}
					gap={'xs'}
				/>
			</ElemWrapper>
			{getDateSelector()}
			<ElemWrapper className="notes-wrapper" isTimepage={isTimepage}>
				<DropdownLabel className="card-select-label">
					{noteRequired ? (
						<LabelWithTooltip label={'notes'} isRequired>
							<NoteRequiredInfoIcon />
						</LabelWithTooltip>
					) : (
						formatMessage({id: 'common.notes'})
					)}
				</DropdownLabel>
				<TooltipContainer {...tooltipProps}>
					<TextArea
						ref={notesRef}
						onBlur={() => setNotesIsFocused(false)}
						onFocus={() => {
							setNotesIsFocused(true);
						}}
						disabled={isFullyLocked}
						maxLength={viewer.company.characterLimit > -1 ? viewer.company.characterLimit : 999}
						className={'foldout'}
						value={notesInputValue}
						rows={notesIsFocused || showFullNotes ? 3 : 1}
						onChange={handleNoteChange}
						placeholder={formatMessage({id: 'common.enter_notes'})}
					/>
				</TooltipContainer>
				{noteError ? <NoteRequiredError /> : null}
			</ElemWrapper>
			{rolesAllowed && (selectedTab === 'PROJECT' || selectedTab === 'TASK') ? (
				<ElemWrapper className="notes-wrapper" isTimepage={isTimepage}>
					<RoleDropdownRelayWrapper
						role={selectedRole}
						onSelect={onRoleManuallySelected}
						isDisabled={isFullyLocked}
						showMovedToProjectRoleWarning={showMovedToProjectRoleWarning}
						projectId={selectedProject?.projectId}
						taskId={selectedTask?.taskId}
						personId={selectedPerson?.id || viewer.actualPersonId}
						showLabel={true}
						labelFormat={RoleDropdownLabelFormat.DEFAULT}
					/>
				</ElemWrapper>
			) : null}
		</>
	);

	// Time page is in calendarView if on timePage and function to hide portal is not passed
	const isCalendarView = isTimepage && !setShowTimeEntryPortal;

	const addButtonDisabled =
		!!timeError ||
		!!dateError ||
		committingData ||
		isFullyLocked ||
		(!selectedProject && !selectedTask) ||
		timeInputValue == null ||
		!!(
			selectedProject &&
			selectedProject.harvestProject &&
			(selectedPerson ? selectedPerson.harvestUser : viewer.harvestUser) &&
			viewer.company.harvestEnabled &&
			!selectedHarvestTask
		);

	const modalState = {
		selectedProject,
		selectedTask,
		timeInputValue,
		billableTime,
		notesInputValue,
		selectedDate,
		selectedRole,
	};

	return (
		<ModalWrapper isTimepage={isTimepage} data-cy={'add-time-entry-component'}>
			{isCalendarView ? <CustomScrollDiv ref={scrollRef}>{content}</CustomScrollDiv> : content}
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				{closeModal && selectedTab !== 'TIMEOFF' && hasFeatureFlag('new_time_registration_modal_opt_in') ? (
					<TertiaryButtonStyleOverride>
						<Button
							text={'Try the new look'}
							buttonStyle={BUTTON_STYLE.TEXT}
							colorTheme={BUTTON_COLOR.PURPLE}
							onClick={() => optIntoFeatureFlag(viewer, modalState, closeModal)}
						/>
					</TertiaryButtonStyleOverride>
				) : null}

				<ButtonWrapper isTimepage={isTimepage}>
					{closeModal ? (
						<Button
							text={
								!roundedTimerDuration || !viewer.timerStartDate
									? formatMessage({id: 'common.cancel'})
									: formatMessage({id: 'timer_modal.reset_timer'})
							}
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={BUTTON_COLOR.WHITE}
							onClick={cancelClicked.bind(this)}
						/>
					) : setShowTimeEntryPortal ? (
						<Button
							text={formatMessage({id: 'common.filter-close'})}
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={BUTTON_COLOR.WHITE}
							onClick={closeClicked.bind(this)}
						/>
					) : null}
					<Button
						isDisabled={addButtonDisabled}
						text={updateTimeRegId ? formatMessage({id: 'common.update'}) : formatMessage({id: 'common.add'})}
						cy={'log-time-btn'}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.GREEN}
						onClick={registerTime.bind(this)}
						userpilot={'timesheets-add-time'}
						tooltipProps={tooltipProps}
					/>
				</ButtonWrapper>
			</div>
		</ModalWrapper>
	);
};

export default injectIntl(addTimeEntryComponent);
