import CreateTimeRegistrationMutation from '../../../../../../../mutations/create_time_registration_mutation.modern.js';
import UpdateTimeRegistrationMutation from '../../../../../../../mutations/update_time_registration_mutation.modern.js';
import { updateTaskForScoping } from '../../../../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import { MODAL_TYPE, showModal } from '../../../../../../shared/components/modals/generic_modal_conductor';
import { createToast } from '../../../../../../shared/components/toasts/another-toast/toaster';
import Util from '../../../../../../shared/util/util';
import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
function getMutationIdFromTargetEntity(targetEntity) {
    switch (targetEntity.entity) {
        case TimeRegistrationEntity.Task:
            return { taskId: targetEntity.id, projectId: targetEntity.project ? targetEntity.project.id : null };
        case TimeRegistrationEntity.Project:
            return { projectId: targetEntity.id };
        case TimeRegistrationEntity.IdleTime:
            return { idleTimeId: targetEntity.id };
    }
}
function handleMutationResult({ result, taskId, onSuccess }) {
    const resultType = result.createTimeReg || result.updateTimeReg;
    if (resultType &&
        resultType.errors &&
        resultType.errors.length === 1 &&
        resultType.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
        showModal({
            type: MODAL_TYPE.WARNING_DATE_LOCKED,
        });
    }
    else if (Util.checkForSageErrorAndShowModal(resultType.errors)) {
        return;
    }
    else {
        updateTaskForScoping(taskId, onSuccess);
    }
}
export function createTimeRegistration({ companyId, targetEntity, time, billableTime, date, personId, note, harvestTaskId, unit4ActivityId, unit4ActivityName, roleId, onCreateSuccess = () => {
    // Do nothing by default
}, }) {
    const mutationTargetId = getMutationIdFromTargetEntity(targetEntity);
    const onSuccess = () => {
        createToast({
            duration: 5000,
            message: 'Time Registration created',
        });
        onCreateSuccess();
    };
    const handleResult = result => handleMutationResult({
        result,
        taskId: 'taskId' in mutationTargetId ? mutationTargetId.taskId : null,
        onSuccess,
    });
    Util.CommitSchedulingModalUpdate(CreateTimeRegistrationMutation, Object.assign(Object.assign({}, mutationTargetId), { companyId: companyId, source: 'Time-registration-modal', minutesRegistered: time, billableMinutesRegistered: billableTime, day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear(), personId: personId, notes: note !== '' ? note : null, harvestTaskId,
        unit4ActivityId,
        unit4ActivityName,
        roleId }), handleResult);
}
export function updateTimeRegistration({ timeRegistrationId, targetEntity, time, billableTime, date, note, harvestTaskId, unit4ActivityId, unit4ActivityName, roleId, }) {
    const mutationTargetId = getMutationIdFromTargetEntity(targetEntity);
    const onSuccess = () => {
        createToast({
            duration: 5000,
            message: 'Time Registration updated',
        });
    };
    const handleResult = result => handleMutationResult({
        result,
        taskId: 'taskId' in mutationTargetId ? mutationTargetId.taskId : null,
        onSuccess,
    });
    Util.CommitSchedulingModalUpdate(UpdateTimeRegistrationMutation, Object.assign(Object.assign({}, mutationTargetId), { id: timeRegistrationId, source: 'Time-registration-modal', minutesRegistered: time, billableMinutesRegistered: billableTime, day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear(), updateTimeRegLocation: true, notes: note !== '' ? note : null, harvestTaskId,
        unit4ActivityId,
        unit4ActivityName,
        roleId }), handleResult);
}
