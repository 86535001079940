import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createTimeRegistrationMutationModernMutation($input: CreateTimeRegInput!, $fetchCustomFields: Boolean!) {
		createTimeReg(input: $input) {
			timeReg {
				node {
					id
					invoiced
					xeroInvoiceId
					lockedInPeriod
					year
					month
					day
					canvasTimelineDate
					minutesRegistered
					billableMinutesRegistered
					allocationId
					notes
					price
					unit4ActivityId
					unit4ActivityName
					harvestTaskIdInt
					harvestTask {
						name
					}
					harvestTimeId
					approvalStatus
					person {
						id
						fullName
						isViewer
						firstName
						lastName
						profilePictureId
						profilePictureDefaultId
						role {
							id
							name
						}
					}
					task {
						id
						name
						progress
						companyTaskId
						highPriority
						approved
						favoured
						fullAccessToProject
						latestUiUpdateAt
						timeLeft
						timeLeftMinutesWithoutFutureTimeRegs
						totalMinutesRegistered
						currentPrice
						allTotalTimeAndExpensesAtCompletion
						assignedPersons {
							id
						}
						project {
							id
							name
							status
							companyProjectId
							projectColor
							estimationUnit
							fullAccessToProject
							client {
								id
								name
							}
							harvestProject {
								id
								name
							}
						}
						phase {
							id
							name
						}
					}
					project {
						id
						projectPersons(first: 100, contactsOnly: true) {
							edges {
								node {
									person {
										id
										fullName
										profilePictureId
									}
								}
							}
						}
					}
					idleTime {
						id
					}
					role {
						id
						name
					}
					customFieldValues(first: 1000, fetchCustomFields: $fetchCustomFields)
						@connection(key: "TimeRegistrations_customFieldValues") {
						edges {
							node {
								key
								displayName
								value
								readOnly
							}
						}
					}
				}
			}
			errors
		}
	}
`;

function getConfigs(input) {
	const config = [
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			edgeName: 'timeReg',
			connectionInfo: [
				{
					key: 'Viewer_timeRegistrations',
					rangeBehavior: 'append',
				},
			],
		},
	];

	if (input.taskId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.taskId,
			edgeName: 'timeReg',
			connectionInfo: [
				{
					key: 'Task_timeRegistrations',
					rangeBehavior: 'append',
				},
			],
		});
	}

	if (input.projectId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			edgeName: 'timeReg',
			connectionInfo: [
				{
					key: 'Project_timeRegistrations',
					rangeBehavior: 'append',
				},
			],
		});
	}

	// Needed for Team Timesheet
	if (input.viewer && input.viewer.company && input.viewer.company.id) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.viewer.company.id,
			edgeName: 'timeReg',
			connectionInfo: [
				{
					key: 'Company_timeRegistrations',
					rangeBehavior: 'append',
				},
			],
		});
	} else if (input.companyId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.companyId,
			edgeName: 'timeReg',
			connectionInfo: [
				{
					key: 'Company_timeRegistrations',
					rangeBehavior: 'append',
				},
			],
		});
	}
	return config;
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Time Reported', {
		_Source: input.source ? input.source : 'NA',
		_On: input.taskId ? 'Card' : input.projectId ? 'Project' : 'Non Project Time',
		_AddedNote: !!input.notes,
		...(input.subTask !== undefined && input.subTask !== null && {_SubTask: input.subTask}),
	});

	const cleanedVariables = omit(input, ['source', 'viewer', 'companyId', 'newFormat', 'subTask']);

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {
				input: {
					csrfToken: Util.getCsrfValue(),
					socketClientId: Util.getClientId(),
					...cleanedVariables,
					minutesRegistered: input.newFormat // TODO remove this when the new time format flag is out
						? Math.round(input.minutesRegistered)
						: input.minutesRegistered
						? Util.clamp(Math.round(input.minutesRegistered), -600000, 600000)
						: input.minutesRegistered,
				},
				fetchCustomFields: Util.hasCustomFields(),
			},
			configs: getConfigs(input),
			onCompleted,
			onError,
		},
		false,
		undefined,
		{
			source: input.source ? input.source : 'NA',
			timeRegistrationTargetType: input.taskId ? 'Task' : input.projectId ? 'Project' : 'Non Project Time',
		}
	);
}

export default {commit};
