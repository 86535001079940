import Moment from 'moment';
export function getPeriodTitle(isWeekView, currentViewingDate) {
    const today = Moment();
    let title = '';
    if (isWeekView) {
        const weekDiff = currentViewingDate.week() - today.week();
        if (weekDiff === 0) {
            title = "This week's work";
        }
        else if (weekDiff === -1) {
            title = "Last week's work";
        }
        else if (weekDiff === 1) {
            title = "Next week's work";
        }
        else {
            title = "Selected week's work";
        }
    }
    else {
        const dayDiff = currentViewingDate.startOf('day').diff(today.startOf('day'), 'days');
        if (dayDiff === 0) {
            title = "Today's work";
        }
        else if (dayDiff === -1) {
            title = "Yesterday's work";
        }
        else if (dayDiff === 1) {
            title = "Tomorrow's work";
        }
        else {
            title = "Selected day's work";
        }
    }
    return title;
}
