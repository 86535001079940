import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  pointer-events: none;
`;

export const DirectionalIcon = () => {
	return (
		<Svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<svg >
				<path d="M0.190056 5.17957C0.0722067 5.05429 8.3194e-08 4.88559 8.3194e-08 4.70002C-8.00065e-05 4.53539 0.0576659 4.3704 0.173235 4.23834L3.67323 0.239017C3.92784 -0.0519066 4.37007 -0.0813522 4.661 0.17325C4.95192 0.427853 4.98137 0.870087 4.72677 1.16101L2.24221 4.00002L15.3 4.00002C15.6866 4.00002 16 4.31343 16 4.70002C16 5.08662 15.6866 5.40002 15.3 5.40002L2.24306 5.40002L4.72684 8.23911C4.9814 8.53007 4.95188 8.9723 4.66091 9.22686C4.36994 9.48141 3.92771 9.45189 3.67316 9.16092L0.190056 5.17957Z" fill="#393946"/>
				<path d="M0.7 12.6C0.313401 12.6 8.3194e-08 12.9134 8.3194e-08 13.3C8.3194e-08 13.6866 0.313401 14 0.7 14H13.7569L11.2731 16.8391C11.0186 17.13 11.0481 17.5723 11.3391 17.8268C11.63 18.0814 12.0723 18.0519 12.3268 17.7609L15.8097 13.7798C15.9277 13.6545 16 13.4857 16 13.3L15.9997 13.2797C15.9953 13.1218 15.9376 12.965 15.8268 12.8383L12.3268 8.83899C12.0721 8.54807 11.6299 8.51862 11.339 8.77323C11.0481 9.02783 11.0186 9.47006 11.2732 9.76099L13.7578 12.6H0.7Z" fill="#393946"/>
			</svg>
		</Svg>
	);
}