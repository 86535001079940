import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSavedReportMutation($input: UpdateSavedReportInput!) {
		updateSavedReport(input: $input) {
			savedReport {
				...GenericSavedReportConductor_savedReport
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Saved Report Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
