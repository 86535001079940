import React from 'react';
import {ContentBox, ContentBoxInfo, TimeSubtitle, TimeTitle} from '../TimeManagement_styled';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../shared/util/util';
import UpdateGeneralTimeRegistrationSettingsMutation from '../../../../mutations/time-registration-settings/UpdateGeneralTimeRegistrationSettingsMutation';
import {setTimeRegistrationSettings} from '../../../shared/util/cache/TimeRegistrationSettingsUtil';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';
import TimeRegistrationSetting from './TimeRegistrationSetting';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import useUpdateEffect from '../../../shared/hooks/useUpdateEffect';

export const hasBillableMinutesSplitFeature = () => {
	return hasModule(MODULE_TYPES.FINANCE);
};

export const isSageRestricted = () => {
	return hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
};

const updateTimeRegistrationSettings = input => {
	const updateCache = res => {
		setTimeRegistrationSettings(res?.updateGeneralTimeRegistrationSettings?.timeRegistrationSettings);
	};
	Util.CommitMutation(UpdateGeneralTimeRegistrationSettingsMutation, input, updateCache);
};

const TimeRegistrationSettingsSection = ({company}) => {
	const {timeRegistrationSettings} = company;
	const {
		id,
		allowDoneTasks,
		allowProjects,
		allowInternalTime,
		allowBillableSplit,
		allowExceedingEstimate,
		allowOutsideTaskDates,
		allowOutsideProjectDates,
		allowRoles,
		requireTaskAssignment,
		requireNote,
	} = timeRegistrationSettings;

	useUpdateEffect(() => {
		trackEvent('Time Registration Settings', 'Updated', timeRegistrationSettings);
	}, [timeRegistrationSettings]);

	return (
		<ContentBox>
			<ContentBoxInfo>
				<TimeTitle>Time registration settings</TimeTitle>
				<TimeSubtitle>
					Allow or prevent time registrations on the following parameters. These settings only apply to new time
					registrations created within Forecast, not those made via the API or integrations.
				</TimeSubtitle>
			</ContentBoxInfo>

			<TimeRegistrationSetting
				title={'Allow time entries on Done tasks'}
				subtitle={'Allow users to register time on Done tasks.'}
				checked={allowDoneTasks}
				cy={'allowDoneTasks'}
				callback={() => updateTimeRegistrationSettings({id, allowDoneTasks: !allowDoneTasks})}
			/>
			<TimeRegistrationSetting
				title={'Allow time entries at the project level'}
				subtitle={'Allow users to register time at the project level as well as the task level.'}
				checked={allowProjects}
				cy={'allowProjects'}
				callback={() => updateTimeRegistrationSettings({id, allowProjects: !allowProjects})}
			/>

			<TimeRegistrationSetting
				title={'Allow time entries on internal time categories'}
				subtitle={'Allow users to register time against internal time categories.'}
				checked={allowInternalTime}
				cy={'allowInternalTime'}
				callback={() => updateTimeRegistrationSettings({id, allowInternalTime: !allowInternalTime})}
			/>

			<TimeRegistrationSetting
				title={`Allow time entries exceeding a task's estimate`}
				subtitle={
					'Team members can register time on a task even if the total time will be higher than the estimate for the task.'
				}
				checked={allowExceedingEstimate}
				cy={'allowExceedingEstimate'}
				callback={() =>
					updateTimeRegistrationSettings({
						id,
						allowExceedingEstimate: !allowExceedingEstimate,
					})
				}
			/>

			<TimeRegistrationSetting
				title={`Allow time entries outside of task dates`}
				subtitle={'Users can register time on any date, even if it is outside of task start and end dates.'}
				checked={allowOutsideTaskDates}
				cy={'allowOutsideTaskDates'}
				callback={() => updateTimeRegistrationSettings({id, allowOutsideTaskDates: !allowOutsideTaskDates})}
			/>

			<TimeRegistrationSetting
				title={'Allow time entries outside of project dates'}
				subtitle={'Users can register time on any date, even if it is outside of project start and end dates.'}
				checked={allowOutsideProjectDates}
				cy={'allowOutsideProjectDates'}
				callback={() =>
					updateTimeRegistrationSettings({
						id,
						allowOutsideProjectDates: !allowOutsideProjectDates,
					})
				}
			/>
			{hasBillableMinutesSplitFeature() ? (
				<TimeRegistrationSetting
					title={'Allow billable time adjustment on time entry'}
					subtitle={'Allow for team members to adjust how much of the time logged in their work is billable.'}
					checked={allowBillableSplit}
					cy={'allowBillableSplit'}
					callback={() => updateTimeRegistrationSettings({id, allowBillableSplit: !allowBillableSplit})}
				/>
			) : null}
			{isSageRestricted() ? null : (
				<TimeRegistrationSetting
					title={'Allow selection of role on time entries'}
					subtitle={'Allow users to register time against any role.'}
					checked={allowRoles}
					cy={'allowRoles'}
					callback={() => updateTimeRegistrationSettings({id, allowRoles: !allowRoles})}
				/>
			)}
			<TimeRegistrationSetting
				title={'Require notes for time entries'}
				subtitle={'Allow users to add a time registration only if it includes a note.'}
				checked={requireNote}
				cy={'requireNote'}
				callback={() => updateTimeRegistrationSettings({id, requireNote: !requireNote})}
			/>
			<TimeRegistrationSetting
				title={'Require task assignment for time entries'}
				subtitle={'Allow users to add a time registration to a task only if they are assigned to it.'}
				checked={requireTaskAssignment}
				cy={'requireTaskAssignment'}
				callback={() => updateTimeRegistrationSettings({id, requireTaskAssignment: !requireTaskAssignment})}
			/>
		</ContentBox>
	);
};

export default createFragmentContainer(TimeRegistrationSettingsSection, {
	company: graphql`
		fragment TimeRegistrationSettingsSection_company on Company {
			timeRegistrationSettings {
				id
				allowDoneTasks
				allowProjects
				allowInternalTime
				allowBillableSplit
				allowRoles
				allowExceedingEstimate
				allowOutsideProjectDates
				allowOutsideTaskDates
				requireTaskAssignment
				requireNote
			}
		}
	`,
});
