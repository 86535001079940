import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
	DeprecatedButton as Button,
	DeprecatedContentContainer as ContentContainer,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedIcon as Icon,
	DeprecatedIllustration as Illustration,
	DeprecatedInput as Input,
	DeprecatedList as List,
	DeprecatedListCell as ListCell,
	DeprecatedListRow as ListRow,
	DeprecatedMainContainer as MainContainer,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ProjectTable from './ProjectTable';
import {ProgramContext} from './ProgramContextProvider';
import {useIntl} from 'react-intl';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import GroupingDropdown from '../../../shared/components/dropdowns/GroupingDropdown';
import ProgramProjectContextMenu from './contextmenu/project/ProgramProjectContextMenu';
import Util from '../../../shared/util/util';
import AttachProjectToProgramMutation from '../../../../mutations/project-service/attach_project_to_program_mutation';
import {createToast} from '../../../shared/components/toasts/toast';
import styled from 'styled-components';
import useDate from '../../../shared/hooks/useDate';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {useProgram} from '../hooks/useProgram';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import ProgramNovaInsights, {ProgramNovaInsightsQuery} from './nova-insights/ProgramNovaInsights';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import ProgramWorkBreakdown, {ProgramWorkBreakdownQuery} from './nova-insights/work-breakdown/ProgramWorkBreakdown';
import {BUDGET_TYPE, MODULE_TYPES, PROGRAM_BUDGET_TYPE, PROJECT_STATUS} from '../../../../constants';
import ForecastTooltip, {TooltipColor} from '../../../shared/components/tooltips/ForecastTooltip';
import Checkbox from '../../../../components/inputs/checkbox';
import UpdateProjectMutation from '../../../../mutations/update_project_budget_page_mutation';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

export const TablePlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const ColumnWrapper = styled.div`
	width: 100%;
	overflow: hidden;
`;

const RowWrapper = styled.div`
	padding: 8px 0px 8px 0px;
`;

const TitleIconWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const BASE_PREF_KEY = programId => `program-overview-${programId}`;
const LOCAL_GROUPING_KEY = programId => `${BASE_PREF_KEY(programId)}-grouping`;

const ProgramOverview = () => {
	const {formatDate} = useDate();
	const {program, projects, company, actualPersonId} = useContext(ProgramContext);
	const {formatMessage} = useIntl();
	const _program = useProgram(program, company.id);
	const canManage = _program.canManage(actualPersonId);
	const canAccess =
		hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY) || hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL);

	useEffect(() => {
		document.title = `${formatMessage({id: 'common.overview'})} - ${program.prefix}`;
	});
	useTrackPage('Program Overview');

	const [searchValue, setSearchValue] = useState('');
	const [grouping, setGrouping] = useState(Util.localStorageGetItemWithDefault(LOCAL_GROUPING_KEY(program.id), ''));
	const [showAllProjects, setShowAllProjects] = useState(false);
	const updateGrouping = nextGrouping => {
		trackEvent('Program Overview', 'Grouped By', {groupingBefore: grouping, groupingAfter: nextGrouping});
		Util.localStorageSetItem(LOCAL_GROUPING_KEY(program.id), nextGrouping);
		setGrouping(nextGrouping);
	};

	const openImportModal = () => {
		showModal({
			type: MODAL_TYPE.IMPORT_PROJECT_TO_PROGRAM,
			program: program,
		});
	};

	const attachProjectToProgram = (project, closeModal) => {
		const onSuccess = () => {
			if (program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE) {
				const onBudgetTypeChangeSuccess = () => {
					closeModal();
					createToast({
						duration: 5000,
						message: formatMessage(
							{id: 'common.action.created_entity'},
							{entity: formatMessage({id: 'common.project'})}
						),
					});
				};
				const mutationProject = {...project};
				mutationProject.id = Buffer.from(
					'ProjectType:' + hasFeatureFlag('custom_project_ids') ? project.id : project.serviceCompanyId
				).toString('base64');
				Util.CommitMutation(
					UpdateProjectMutation,
					{
						project: mutationProject,
						budgetType: BUDGET_TYPE.FIXED_PRICE_V2,
					},
					onBudgetTypeChangeSuccess
				);
			} else {
				closeModal();
				createToast({
					duration: 5000,
					message: formatMessage(
						{id: 'common.action.created_entity'},
						{entity: formatMessage({id: 'common.project'})}
					),
				});
			}
		};
		Util.CommitSchedulingModalUpdate(
			AttachProjectToProgramMutation,
			{
				programId: program.id,
				projectIds: project.id,
			},
			onSuccess
		);
	};

	const openCreateProjectModal = () =>
		showModal({
			type: hasFeatureFlag('custom_project_ids') ? MODAL_TYPE.NEW_PROJECT_V2 : MODAL_TYPE.CREATE_PROJECT,
			onCreateCallback: attachProjectToProgram,
		});

	useEffect(() => {
		if (projects.length === 0) localStorage.removeItem(LOCAL_GROUPING_KEY(program.id));
	}, [projects.length]);

	const activeProjects = projects.filter(
		project => project.stage === PROJECT_STATUS.PLANNING || project.stage === PROJECT_STATUS.RUNNING
	);
	const nonRetainerProjects = activeProjects.filter(project => {
		const budgetType = project.settings.edges.find(obj => obj.node.name === 'budgetType')?.node.value;
		return budgetType !== BUDGET_TYPE.RETAINER;
	});

	const programHasRetainerProject = activeProjects.length !== nonRetainerProjects.length;

	const visibleProjects = showAllProjects
		? projects
		: projects.filter(project => project.stage !== PROJECT_STATUS.DONE && project.stage !== PROJECT_STATUS.HALTED);

	return (
		<MainContainer>
			<FlexRow gap={'xl'}>
				<ContentContainer width={'25%'} height={'196px'}>
					<FlexColumn gap={'s'}>
						<Text type={'heading'} variant={'m'}>
							{formatMessage({id: 'common.details'})}
						</Text>
						<List>
							<RowWrapper>
								<ListRow>
									<ListCell fixed={'40px'}>
										<Text color={'secondary'}>{formatMessage({id: 'common.start'})}</Text>
									</ListCell>
									<ListCell>
										<Text>{program.startDate ? formatDate(program.startDate) : ''}</Text>
									</ListCell>
								</ListRow>
							</RowWrapper>
							<RowWrapper>
								<ListRow>
									<ListCell fixed={'40px'}>
										<Text color={'secondary'}>{formatMessage({id: 'common.end'})}</Text>
									</ListCell>
									<ListCell>
										<Text>{program.endDate ? formatDate(program.endDate) : ''}</Text>
									</ListCell>
								</ListRow>
							</RowWrapper>
							<RowWrapper>
								<ListRow>
									<ListCell fixed={'40px'}>
										<Text color={'secondary'}>{formatMessage({id: 'common.client'})}</Text>
									</ListCell>
									<ListCell>
										<Text>
											{program.clients.edges.length > 0
												? program.clients.edges[0].node.name
												: formatMessage({id: 'common.no_client'})}
										</Text>
									</ListCell>
								</ListRow>
							</RowWrapper>
							<RowWrapper>
								<ListRow>
									<ListCell fixed={'40px'}>
										<Text color={'secondary'}>{formatMessage({id: 'common.stage'})}</Text>
									</ListCell>
									<ListCell>
										<Text>{formatMessage({id: `project_status.${program.stage.name.toLowerCase()}`})}</Text>
									</ListCell>
								</ListRow>
							</RowWrapper>
						</List>
					</FlexColumn>
				</ContentContainer>
				{canAccess ? (
					<ContentContainer width={'40%'} height={'196px'}>
						<ColumnWrapper>
							<FlexColumn gap={'s'}>
								<TitleIconWrapper>
									<Text type={'heading'} variant={'m'}>
										Nova Insights
									</Text>
									{programHasRetainerProject ? (
										<ForecastTooltip
											content={`Nova Insights will not create predictions or inform you of risks for your Retainer projects.`}
											color={TooltipColor.PURPLE}
										>
											<Icon icon={'alert'} color={'error'} />
										</ForecastTooltip>
									) : null}
								</TitleIconWrapper>
								<ForecastQueryRenderer
									key={'query-render-programNovaInsights' + program.prefix}
									query={ProgramNovaInsightsQuery}
									variables={{
										prefix: program.prefix,
									}}
									customLoader={() => <InlineLoader />}
									render={(relayProps, retry) => (
										<ProgramNovaInsights {...relayProps} retry={retry} projects={nonRetainerProjects} />
									)}
								/>
							</FlexColumn>
						</ColumnWrapper>
					</ContentContainer>
				) : (
					<ContentContainer width={'40%'} height={'196px'}>
						<FlexColumn gap={'s'}>
							<Text type={'heading'} variant={'m'}>
								{formatMessage({id: 'common.nova_insights'})}
							</Text>
							<Text color={'secondary'}>Coming soon!</Text>
						</FlexColumn>
					</ContentContainer>
				)}
				<ContentContainer width={'35%'} height={'196px'}>
					<ColumnWrapper>
						<FlexColumn gap={'s'}>
							<Text type={'heading'} variant={'m'}>
								Work Breakdown
							</Text>
							<ForecastQueryRenderer
								key={'query-render-programWorkBreakdown' + program.prefix}
								query={ProgramWorkBreakdownQuery}
								variables={{
									prefix: program.prefix,
								}}
								customLoader={() => <InlineLoader />}
								render={(relayProps, retry) => (
									<ProgramWorkBreakdown {...relayProps} retry={retry} projects={projects} />
								)}
							/>
						</FlexColumn>
					</ColumnWrapper>
				</ContentContainer>
			</FlexRow>

			<FlexRow justifyContent={'space-between'} gap={'s'}>
				<FlexItem>
					<FlexRow gap={'s'}>
						<FlexItem>
							<Input
								icon={'search'}
								size={'m'}
								placeholder={formatMessage(
									{id: 'search.search_entity'},
									{entity: formatMessage({id: 'common.projects'}).toLowerCase()}
								)}
								onChange={e => setSearchValue(e.target.value)}
								onBlur={() => trackEvent('Project List', 'Searched', {searchString: searchValue})}
								disabled={!projects.length > 0}
							/>
						</FlexItem>
						<FlexItem>
							{useMemo(
								() => (
									<GroupingDropdown
										onSelectGroup={updateGrouping}
										initialGroup={grouping}
										disabled={!projects.length > 0}
									/>
								),
								[grouping, projects.length]
							)}
						</FlexItem>
						<FlexItem>
							<FlexRow gap={'s'}>
								<Checkbox
									checked={showAllProjects}
									onChange={() =>
										setShowAllProjects(prevState => {
											trackEvent('Program Overview', 'Toggle Show Done/Halted Programs', {
												state: !prevState ? 'With done/halted' : 'Without done/halted',
											});
											return !prevState;
										})
									}
								></Checkbox>
								<Text>
									{formatMessage(
										{id: 'common.show.done-halted'},
										{entity: formatMessage({id: 'common.projects'})}
									)}
								</Text>
							</FlexRow>
						</FlexItem>
					</FlexRow>
				</FlexItem>
				<FlexItem>
					<FlexRow gap={'s'}>
						{hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) &&
							canManage &&
							!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && (
								<FlexItem>
									<Button type={'creative'} onClick={openCreateProjectModal}>
										{formatMessage(
											{id: 'common.action.create_entity'},
											{entity: formatMessage({id: 'common.project'})}
										)}
									</Button>
								</FlexItem>
							)}
						{hasPermission(PERMISSION_TYPE.PROGRAMS_UPDATE) && canManage && (
							<FlexItem>
								<Button type={'secondary'} onClick={openImportModal}>
									{formatMessage(
										{id: 'common.action.add_entity'},
										{entity: formatMessage({id: 'common.project'})}
									)}
								</Button>
							</FlexItem>
						)}
					</FlexRow>
				</FlexItem>
			</FlexRow>
			{projects.length > 0 ? (
				<ProjectTable
					projects={visibleProjects}
					searchValue={searchValue}
					grouping={grouping}
					ContextMenu={ProgramProjectContextMenu}
					prefKey={BASE_PREF_KEY(program.id)}
				/>
			) : (
				<TablePlaceholder>
					<Illustration illustration={'noData'} />
					<Text type={'heading'} variant={'l'}>
						{formatMessage({id: 'common.no_entity'}, {entity: formatMessage({id: `common.projects`})})}
					</Text>
					<Text color={'secondary'}>{formatMessage({id: 'program.empty_program.table_description'})}</Text>
				</TablePlaceholder>
			)}
		</MainContainer>
	);
};

export default ProgramOverview;
