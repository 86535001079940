import jiraUtil from '../../../../../../../components/jira/jira_util';

const jiraType = 'cloud';

const getJiraBoards = async (deep, jiraProjectId, setters) => {
	const response = await jiraUtil.fetchJiraBoards(jiraProjectId, deep, jiraType);
	if (response) {
		const jiraBoardOptions =
			response.boards && response.boards.length > 0
				? response.boards.map(board => ({text: board.name, value: board.id}))
				: [];
		const selectedJiraBoard = jiraBoardOptions.length !== 0 ? jiraBoardOptions[0].value : null;
		setters.setSelectedJiraBoard(selectedJiraBoard);
		setters.setJiraBoardOptions(jiraBoardOptions);
		setters.setBoardsDeepFetched(response.deepFetch);
	}
};

const getJiraCustomFields = async (selectedJiraProjectStyle, setters) => {
	const response = await jiraUtil.fetchJiraCustomFields(jiraType, selectedJiraProjectStyle || 'any');
	const options = response.map(el => ({text: el.name, value: el.id}));
	setters.setCustomFieldOptions(options);
	setters.setSelectedCustomField(options.length > 0 ? options[0].value : null);
};

const getJiraProjectIssuesCount = async (jiraProjectId, setters) => {
	const response = await jiraUtil.fetchIssuesCountForJiraProject(jiraProjectId, jiraType);
	setters.setSyncTaskCount(response.tasks ? response.tasks : 0);
	setters.setSyncSubtasksCount(response.subtasks ? response.subtasks : 0);
};

const getJiraProjectEpicsCount = async (jiraProjectId, setters, cutoffDate) => {
	const response = await jiraUtil.fetchEpicsCountForJiraProject(jiraProjectId, jiraType, cutoffDate);
	setters.setSyncEpicCount(response.tasks ? response.tasks : 0);
};

const getJiraVersionsCount = async (jiraProjectId, setters, cutoffDate) => {
	const response = await jiraUtil.fetchVersionsCountForJiraProject(jiraProjectId, jiraType, cutoffDate);
	setters.setSyncVersionsCount(response.count ? response.count : 0);
};

const getJiraSprintsCount = async (boardId, setters) => {
	const response = await jiraUtil.fetchSprintsCountForJiraBoard(boardId, jiraType);
	setters.setSyncSprintCount(response.count ? response.count : 0);
};

// const getWorkLogsOnEpics = async (jiraProjectId, setters) => {
// 	await DirectApi.Fetch(`jira/epics/${jiraProjectId}`).then(epics => {
// 		if (epics && epics.length > 0) {
// 			const epicIds = epics.map(epic => epic.id);
// 			setters.setSyncEpicCount(epics.length);
// 			DirectApi.Fetch('jira/worklogs_on_unsynced_epics').then(worklogs => {
// 				if (worklogs && worklogs.length > 0) {
// 					setters.setUnSyncedEpicWorkLogs(worklogs.filter(worklog => epicIds.includes(worklog.issueId)));
// 				}
// 			});
// 		}
// 	});
// }

export {
	getJiraBoards,
	getJiraCustomFields,
	getJiraProjectIssuesCount,
	getJiraVersionsCount,
	getJiraProjectEpicsCount,
	getJiraSprintsCount,
};
