/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type INHERIT_DATES_FROM_TYPE = "BASELINE" | "%future added value";
export type UpdatePhaseInput = {|
  id?: ?string,
  projectId?: ?string,
  status?: ?string,
  name?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  baselineStartYear?: ?number,
  baselineStartMonth?: ?number,
  baselineStartDay?: ?number,
  baselineDeadlineYear?: ?number,
  baselineDeadlineMonth?: ?number,
  baselineDeadlineDay?: ?number,
  approveTasks?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updatePhaseMutationVariables = {|
  input: UpdatePhaseInput
|};
export type updatePhaseMutationResponse = {|
  +updatePhase: ?{|
    +phase: ?{|
      +name: ?string,
      +startYear: ?number,
      +startMonth: ?number,
      +startDay: ?number,
      +startFrom: ?string,
      +deadlineDay: ?number,
      +deadlineMonth: ?number,
      +deadlineYear: ?number,
      +deadlineFrom: ?string,
      +inheritDatesFrom: ?INHERIT_DATES_FROM_TYPE,
    |},
    +project: ?{|
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
            +startFrom: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +deadlineFrom: ?string,
          |}
        |}>
      |}
    |},
  |}
|};
export type updatePhaseMutation = {|
  variables: updatePhaseMutationVariables,
  response: updatePhaseMutationResponse,
|};
*/


/*
mutation updatePhaseMutation(
  $input: UpdatePhaseInput!
) {
  updatePhase(input: $input) {
    phase {
      name
      startYear
      startMonth
      startDay
      startFrom
      deadlineDay
      deadlineMonth
      deadlineYear
      deadlineFrom
      inheritDatesFrom
      id
    }
    project {
      tasks(first: 10000) {
        edges {
          node {
            startDay
            startMonth
            startYear
            startFrom
            deadlineDay
            deadlineMonth
            deadlineYear
            deadlineFrom
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePhaseInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startFrom",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineFrom",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inheritDatesFrom",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePhaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePhasePayload",
        "kind": "LinkedField",
        "name": "updatePhase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseType",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tasks(first:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePhaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePhasePayload",
        "kind": "LinkedField",
        "name": "updatePhase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseType",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tasks(first:10000)"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updatePhaseMutation",
    "operationKind": "mutation",
    "text": "mutation updatePhaseMutation(\n  $input: UpdatePhaseInput!\n) {\n  updatePhase(input: $input) {\n    phase {\n      name\n      startYear\n      startMonth\n      startDay\n      startFrom\n      deadlineDay\n      deadlineMonth\n      deadlineYear\n      deadlineFrom\n      inheritDatesFrom\n      id\n    }\n    project {\n      tasks(first: 10000) {\n        edges {\n          node {\n            startDay\n            startMonth\n            startYear\n            startFrom\n            deadlineDay\n            deadlineMonth\n            deadlineYear\n            deadlineFrom\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46f4977a59d9ac0f905d3e33b7a7bede';

module.exports = node;
