import React, {useEffect, useMemo, useState} from 'react';

import {createRefetchContainer, graphql} from 'react-relay';
import * as tracking from '../../../tracking';
import {useIntl} from 'react-intl';
import {HeaderBarStyled} from '../../../components/initial-plan/InitialPlan.styled';
import HeaderBar from '../../shared/components/headers/header-bar/header_bar';
import {BUTTON_COLOR, BUTTON_STYLE as BUTTON_STYLED, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {BulkSelectPopup, Table} from 'web-components';
import _ from 'lodash';
import LabelCategoryRow from '../../../containers/settings/labels/label_category_row';
import Util from '../../shared/util/util';
import {getFilterFunctions} from '../../shared/components/filters/filter_logic';
import {FILTER_SECTIONS} from '../../shared/components/filters/dropdown_section';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {getLabelsBulkOptions} from '../../../containers/settings/labels/LabelsBulkLogic';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const NO_CATEGORY = {id: undefined};
const SettingsLabelsPage = ({viewer}) => {
	const {formatMessage} = useIntl();

	const filterString = `admin-resources-labels-filters`;
	const localFilters = Util.localStorageGetItem(filterString) ? JSON.parse(Util.localStorageGetItem(filterString)) : null;
	const [filters, setFilters] = useState(localFilters ? localFilters : {});
	const [filterFunctions, setFilterFunctions] = useState(localFilters ? getFilterFunctions(localFilters) : () => true);
	const isFiltering = useMemo(() => filters && Object.keys(filters).length, [filters]);
	const hasFiltersApplied = useMemo(
		() => filterFunctions && filterFunctions.labelFilter && isFiltering,
		[filterFunctions, isFiltering]
	);

	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [selected, setSelected] = useState(new Set());

	useTrackPage('Labels');

	useEffect(() => {
		document.title = 'Labels - Account - Forecast';
		// Segment
		tracking.trackPage('labels');
	}, []);

	const labels = useMemo(() => {
		let labels = viewer.company.labels.edges.map(edge => edge.node);
		// apply filters
		if (hasFiltersApplied) {
			labels = labels.filter(label => filterFunctions.labelFilter(label));
		}

		// filter labels by search
		if (searchFilterValue) {
			labels = labels.filter(label => Util.normalizedIncludes(label.name, searchFilterValue));
		}
		return labels;
	}, [viewer.company.labels, hasFiltersApplied, searchFilterValue, filterFunctions]);

	let labelCategories = viewer.company.labelCategories.edges.map(edge => edge.node);
	labelCategories.push(NO_CATEGORY);

	useEffect(() => {
		const labelIds = labels.map(label => label.id);
		for (const selectedLabelId of selected) {
			if (!labelIds?.includes(selectedLabelId)) {
				selected.delete(selectedLabelId);
			}
		}
		setSelected(new Set(selected));
	}, [labels]);

	const isLabelSelected = labelId => selected.has(labelId);

	const toggleLabelSelected = labelId => {
		const existed = selected.delete(labelId);
		if (!existed) {
			selected.add(labelId);
		}
		setSelected(new Set(selected));
	};

	const onFilterChange = (filters, filterFunctions) => {
		setFilters(filters);
		setFilterFunctions(filterFunctions);
	};

	const topHeaderContent = [
		{
			type: TopHeaderBar.TYPE.FEEDBACK,
			link: 'https://www.forecast.app/feedback-labels?email=' + encodeURIComponent(viewer.email),
		},
	];

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		// search
		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: searchFilterValue,
			onChange: search => setSearchFilterValue(search),
			cy: 'labels-search',
		});

		// filters
		const labelFilters = [FILTER_TYPE.LABEL_APPLIED, FILTER_TYPE.LABEL_ALLOWED];

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.LABELS,
			labelFilters,
			viewer,
			appliedFiltersName: filterString,
			filterSection: FILTER_SECTION.LABELS,
			onFiltersChange: onFilterChange,
			userpilot: 'filter-button',
			cy: 'admin-resources-labels-filters',
		});

		// create label
		rightContent.push({
			id: 'create-label-button',
			text: formatMessage({id: 'settings_labels.create-label'}),
			type: ELEMENT_TYPE.BUTTON,
			style: BUTTON_STYLED.FILLED,
			color: BUTTON_COLOR.PURPLE,
			callback: () => {
				tracking.trackEvent('labels - create label modal opened');
				trackEvent('Create Label Modal', 'Opened');
				showModal({
					type: MODAL_TYPE.CREATE_LABEL,
				});
			},
			dataCy: 'labels-create-label',
		});

		// create category
		rightContent.push({
			id: 'create-category-button',
			text: formatMessage({id: 'common.create_label_category'}),
			type: ELEMENT_TYPE.BUTTON,
			style: BUTTON_STYLED.OUTLINE,
			color: BUTTON_COLOR.GREY,
			callback: () => {
				tracking.trackEvent('labels - create category modal opened');
				trackEvent('Create Category Modal', 'Opened');
				showModal({
					type: MODAL_TYPE.CREATE_LABEL_CATEGORY,
				});
			},
			dataCy: 'labels-create-label-category',
		});

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	};

	// group labels into categories by id
	const labelsByCategoryId = _.groupBy(labels, label => label.category?.id);

	// remove empty categories on filtering
	if (searchFilterValue || hasFiltersApplied) {
		labelCategories = labelCategories.filter(
			labelCategory => labelCategory === NO_CATEGORY || labelsByCategoryId[labelCategory.id]
		);
	}

	// bulk selection
	const selectedLabelsCount = selected.size;
	const clearLabelsSelection = () => setSelected(new Set());

	return (
		<div className="section-content settings-app settings-labels" data-cy={'settings-labels-page'}>
			<TopHeaderBarWrapper>
				<TopHeaderBar title={formatMessage({id: 'common.labels'})} content={topHeaderContent} />
			</TopHeaderBarWrapper>
			<HeaderBarStyled secondLevel>{getHeader()}</HeaderBarStyled>
			{selectedLabelsCount > 0 && (
				<BulkSelectPopup
					itemCount={selectedLabelsCount}
					counterText={formatMessage({id: 'bulk_edit.labels_selected'})}
					actionOptions={getLabelsBulkOptions(selected, viewer.company, formatMessage, clearLabelsSelection)}
					onClose={clearLabelsSelection}
				/>
			)}
			<CustomScrollDiv>
				<div className="section-body">
					<Table paddedCells>
						<Table.Header>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.EXTRA_LARGE}>
								<Table.HeaderColumn.Title>{formatMessage({id: 'common.label'})}</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.SMALL}>
								<Table.HeaderColumn.Title>{formatMessage({id: 'common.color'})}</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.MEDIUM}
								align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_labels.on_tasks'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.MEDIUM}
								align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_labels.on_projects'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.MEDIUM}
								align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_labels.on_people'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								flex={1}
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.SMALL}
								align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
							/>
						</Table.Header>
						<Table.Rows data={{rows: labelCategories}} canExpand initiallyExpanded keyFunc={row => row.id}>
							{({rowData: category, tableColumnsProps, nextLevelProps, expanded}) => (
								<LabelCategoryRow
									company={viewer.company}
									labelCategory={category.id ? category : null}
									labels={labelsByCategoryId[category.id] || []}
									expanded={expanded}
									isLabelSelected={isLabelSelected}
									toggleLabelSelected={toggleLabelSelected}
									tableColumnsProps={tableColumnsProps}
									nextLevelProps={nextLevelProps}
								/>
							)}
						</Table.Rows>
					</Table>
				</div>
			</CustomScrollDiv>
		</div>
	);
};

export const SettingsLabelsPageQuery = graphql`
	query settingsLabelsPage_Query {
		viewer {
			actualPersonId
			component(name: "settings_labels")
			...settingsLabelsPage_viewer
		}
	}
`;

export default createRefetchContainer(SettingsLabelsPage, {
	viewer: graphql`
		fragment settingsLabelsPage_viewer on Viewer {
			actualPersonId
			id
			email
			company {
				id
				skillLevelsEnabled
				...labelCategoryRow_company
				labels(first: 1000) @connection(key: "Company_labels") {
					edges {
						node {
							id
							name
							taskCount
							projectCount
							peopleCount
							category {
								id
								allowOnTasks
								allowOnProjects
								allowOnPeople
							}
							...labelCategoryRow_labels
						}
					}
				}
				labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
					edges {
						node {
							id
							...labelCategoryRow_labelCategory
						}
					}
				}
			}
			filters(first: 1000000, filterSection: LABELS) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
		}
	`,
});
