import { graphql } from 'react-relay';
import Util from '../../../forecast-app/shared/util/util';
import { trackAndCommitMutation } from '../../../tracking/amplitude/TrackMutation';
import { createRangeBulkAddUpdater } from '../../../relay-helpers/RelayCacheHelpers';
const mutation = graphql `
	mutation DuplicateTimeRegistrationsInDateRangeMutation($input: DuplicateTimeRegsInDateRangeInput!) {
		duplicateTimeRegsInDateRange(input: $input) {
			timeRegs {
				node {
					id
					invoiced
					xeroInvoiceId
					lockedInPeriod
					year
					month
					day
					canvasTimelineDate
					minutesRegistered
					billableMinutesRegistered
					allocationId
					notes
					price
					unit4ActivityId
					unit4ActivityName
					harvestTaskIdInt
					harvestTask {
						name
					}
					harvestTimeId
					approvalStatus
					person {
						id
						fullName
						isViewer
						firstName
						lastName
						profilePictureId
						profilePictureDefaultId
						role {
							id
							name
						}
					}
					task {
						id
						name
						progress
						companyTaskId
						highPriority
						approved
						favoured
						fullAccessToProject
						latestUiUpdateAt
						timeLeft
						timeLeftMinutesWithoutFutureTimeRegs
						totalMinutesRegistered
						currentPrice
						allTotalTimeAndExpensesAtCompletion
						assignedPersons {
							id
						}
						project {
							id
							name
							status
							companyProjectId
							projectColor
							estimationUnit
							fullAccessToProject
							client {
								id
								name
							}
							harvestProject {
								id
								name
							}
						}
						phase {
							id
							name
						}
					}
					project {
						id
						projectPersons(first: 100, contactsOnly: true) {
							edges {
								node {
									person {
										id
										fullName
										profilePictureId
									}
								}
							}
						}
					}
					idleTime {
						id
					}
					role {
						id
						name
					}
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return trackAndCommitMutation(environment, {
        mutation,
        variables,
        updater: createRangeBulkAddUpdater('Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=', 'duplicateTimeRegsInDateRange', 'timeRegs', 'Viewer_timeRegistrations'),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
