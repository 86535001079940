import React from 'react';
import {graphql, readInlineData} from 'react-relay';
import {REPORT_TYPE} from '../../../constants';
import ReportedTimePage, {
	reportedTimePageQuery,
} from '../../../components/insights/reported-time-portfolio-report/reported_time_page';
import TaskReportPageV2, {taskReportPageV2Query} from '../report/task-report-page/TaskReportPageV2';

import {GenericReportContextProvider} from './GenericReportContext';
import UtilizationReport, {UtilizationReportQuery} from '../../../components/insights/utilization-report/UtilizationReport';
import ProjectPortfolioReport, {
	ProjectPortfolioReportQuery,
} from '../../../components/insights/project-portfolio-report/ProjectPortfolioReport';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import TaskReportPage, {taskReportPageQuery} from '../../../components/insights/task-report/TaskReportPage';
import TimeRegistrationReportPage, {
	TimeRegistrationReportPageQuery,
} from '../report/time-registration-report-page/TimeRegistrationReportPage';

const GenericSavedReportConductor = ({actualPersonId, savedReport, sharedId, shareType}) => {
	const componentWrapper = (key, name, query, type, ReportComponent, authorizeAccessRoute) => {
		return (
			<ForecastQueryRenderer
				key={key}
				query={query}
				variables={{savedReportId: savedReport.id, sharedId: sharedId}}
				renderIfNotLoggedIn={!!sharedId}
				render={relayProps => {
					return (
						<GenericReportContextProvider
							actualPersonId={actualPersonId}
							savedReportRef={savedReport}
							reportType={type}
							authorizeAccessRoute={authorizeAccessRoute}
							sharedId={sharedId}
							shareType={shareType}
						>
							<ReportComponent {...relayProps} />
						</GenericReportContextProvider>
					);
				}}
			/>
		);
	};

	const newReportService = savedReport.reportService || sharedId;

	switch (savedReport.type) {
		case REPORT_TYPE.TIME_REG: {
			return componentWrapper(
				'query-render-reportedTimePage',
				'reported_time_page',
				newReportService ? TimeRegistrationReportPageQuery : reportedTimePageQuery,
				savedReport.type,
				newReportService ? TimeRegistrationReportPage : ReportedTimePage
			);
		}
		case REPORT_TYPE.PROJECT_PORTFOLIO_REPORT: {
			return componentWrapper(
				'query-render-projectPortfolioReportQuery',
				'project_portfolio_report',
				ProjectPortfolioReportQuery,
				savedReport.type,
				ProjectPortfolioReport,
				'project-portfolio-report'
			);
		}
		case REPORT_TYPE.UTILIZATION_REPORT: {
			return componentWrapper(
				'query-render-reportedTimePage',
				'utilization_report',
				UtilizationReportQuery,
				savedReport.type,
				UtilizationReport,
				'utilization-report'
			);
		}
		case REPORT_TYPE.TASK_REPORT: {
			return componentWrapper(
				'query-render-task-report',
				'task_report_page',
				newReportService ? taskReportPageV2Query : taskReportPageQuery,
				savedReport.type,
				newReportService ? TaskReportPageV2 : TaskReportPage,
				'task-report'
			);
		}

		default: {
		}
	}
};

export default GenericSavedReportConductor;

export function processSavedReport(savedReportRef) {
	const savedReport = readInlineData(
		graphql`
			fragment GenericSavedReportConductor_savedReport on SavedReportType @inline {
				id
				updatedAt
				updatedBy {
					id
					fullName
				}
				type
				name
				person {
					id
					active
					email
					fullName
					profilePictureId
				}
				groupingOne
				groupingTwo
				groupingThree
				groupingFour
				eyeApplied
				filterApplied
				filters {
					field
					value
					operator
				}
				startDate
				endDate
				periodType
				periodDate
				reportService
				shares(first: 100) @connection(key: "Report_shares", filters: [""]) {
					edges {
						node {
							id
							user {
								id
								email
							}
						}
					}
				}
				privateAccess
			}
		`,
		savedReportRef
	);
	return savedReport;
}
