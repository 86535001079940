import React, {useState} from 'react';
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import moment from 'moment';
import {HIDDEN_FEATURES, MODULE_TYPES} from '../../constants';
import Util from '../shared/util/util';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';
import OverviewPage, {overviewPageQuery} from '../../components/insights/invoicing/OverviewPage';
import InvoicesPage, {invoicesPageQuery} from '../../components/insights/invoicing/InvoicesPage';
import StandaloneEditInvoiceModalWrapper from '../../containers/modal/edit_invoice_modal/StandaloneEditInvoiceModalWrapper';
import {PRESET_DATE_RANGE_PICKER_OPTIONS} from '../shared/components/date-picker/preset_date_range_picker';
import CompanySetupUtil from '../shared/util/CompanySetupUtil';

const FINANCE_PAGES = {
	CREATION: 'creation',
	INVOICES: 'invoices',
};

const FinanceRoutes = () => {
	const history = useHistory();

	if (Util.isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES)) {
		history.push('/');
	}

	const {path} = useRouteMatch();

	const savedTimeMaterialDateRange = JSON.parse(localStorage.getItem('invoicing_overview_date_range'));
	const savedInvoiceDateRange = JSON.parse(localStorage.getItem('invoices_date_range'));
	const [timeMaterialDate, setTimeMaterialDate] = useState(
		savedTimeMaterialDateRange
			? {
					startDate: moment(savedTimeMaterialDateRange.startDate),
					endDate: moment(savedTimeMaterialDateRange.endDate),
					option: savedTimeMaterialDateRange.option,
			  }
			: {
					startDate: moment().subtract(2, 'month'),
					endDate: moment(),
					option: null,
			  }
	);
	const [invoiceDate, setInvoiceDate] = useState(
		savedInvoiceDateRange
			? {
					startDate: moment(savedInvoiceDateRange.startDate),
					endDate: moment(savedInvoiceDateRange.endDate),
					option: savedInvoiceDateRange.option,
			  }
			: {
					startDate: moment().subtract(30, 'day'),
					endDate: moment(),
					option: null,
			  }
	);

	const onInvoiceCreation = (page, invoiceDate) => {
		setInvoiceDate({
			startDate: invoiceDate.clone().startOf('month'),
			endDate: invoiceDate.clone().endOf('month'),
			option: PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
		});
	};

	return (
		<Switch>
			<Redirect exact from={`${path}/I-:companyInvoiceId`} to={`${path}/${FINANCE_PAGES.INVOICES}/I-:companyInvoiceId`} />
			<Redirect from={`${path}/overview`} to={`${path}/${FINANCE_PAGES.CREATION}`} />
			<Route
				path={`${path}/${FINANCE_PAGES.CREATION}`}
				render={() => (
					<ForecastQueryRenderer
						query={overviewPageQuery}
						key={'invoicing-overview'}
						authorizeAccessRoute={'invoicing'}
						authorizeModule={() => CompanySetupUtil.hasFinance(MODULE_TYPES.FINANCE)}
						variables={{
							startYear: timeMaterialDate.startDate.year(),
							startMonth: timeMaterialDate.startDate.month() + 1,
							startDay: timeMaterialDate.startDate.date(),
							endYear: timeMaterialDate.endDate.year(),
							endMonth: timeMaterialDate.endDate.month() + 1,
							endDay: timeMaterialDate.endDate.date(),
						}}
						render={relayProps => (
							<OverviewPage
								timeMaterialStartDate={timeMaterialDate.startDate}
								timeMaterialEndDate={timeMaterialDate.endDate}
								dateRangeOption={timeMaterialDate.option}
								setDate={setTimeMaterialDate}
								onInvoiceCreation={onInvoiceCreation}
								{...relayProps}
							/>
						)}
					/>
				)}
			/>
			<Route
				path={`${path}/${FINANCE_PAGES.INVOICES}`}
				render={() => (
					<ForecastQueryRenderer
						query={invoicesPageQuery}
						key={'invoicing-list-page'}
						authorizeAccessRoute={'invoicing'}
						authorizeModule={() => CompanySetupUtil.hasFinance(MODULE_TYPES.FINANCE)}
						variables={{
							startDate: invoiceDate.startDate.format('YYYY-MM-DD'),
							endDate: invoiceDate.endDate.format('YYYY-MM-DD'),
						}}
						render={(relayProps, retry) => (
							<>
								<InvoicesPage
									invoiceDate={invoiceDate}
									startDate={invoiceDate.startDate}
									endDate={invoiceDate.endDate}
									dateRangeOption={invoiceDate.option}
									setDate={setInvoiceDate}
									onInvoiceCreation={onInvoiceCreation}
									{...relayProps}
								/>
								<Route
									path={`${path}/${FINANCE_PAGES.INVOICES}/I-:companyInvoiceId`}
									render={compObj => (
										<StandaloneEditInvoiceModalWrapper
											retry={retry}
											{...compObj}
											companyInvoiceId={compObj.match.params.companyInvoiceId}
										/>
									)}
								/>
							</>
						)}
					/>
				)}
			/>
		</Switch>
	);
};

export default FinanceRoutes;
