import React, {useState} from 'react';
import styled from 'styled-components';
import {
	DeprecatedCreativeModal as CreativeModal,
	deprecatedTheme as theme,
	DeprecatedDropdown as Dropdown,
	DeprecatedDropdownMenuColor as DropdownMenuColor,
	FlexItem,
	FlexRow,
	DeprecatedInput as Input,
	DeprecatedLabelOverComponent as LabelOverComponent,
	DeprecatedRichTextField as RichTextField,
} from '@forecast-it/design-system';
import Util from '../../../util/util';
import CreateProjectMutation from '../../../../../mutations/project-service/create_project_service_project_mutation';
import {createToast} from '../../toasts/toast';
import {useIntl} from 'react-intl';
import DateRangePicker from '../../date-picker/date_picker_v3';
import {DATE_PICKER_STYLE} from '../../../../../constants';
import {useHistory} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {blocksFromHTML} from '../../../util/DraftJsUtil';
import ClientSearchDropdown from '../../../../project-tab/programs/shared/ClientSearchDropdown';
import {projectUrl} from '../../../../../directApi';

export const DatesWrapper = styled.div`
	.date-picker-button-container-v3 {
		width: 146px;
	}

	.date-picker-button-container-v3 .date-picker-button.date-picker-button-standard {
		width: 100%;
	}
`;

const CreateProjectModal = ({viewer, closeModal, onCreateCallback}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();

	const [isCreating, setCreating] = useState(false);
	const [name, setName] = useState('');
	const [color, setColor] = useState(theme.colors.entity.brand);
	const [client, setClient] = useState({});
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [description, setDescription] = useState('');

	const [validName, setValidName] = useState(true);
	const hasName = name => !!name;

	const createProject = () => {
		const trackProjectCreated = () =>
			trackEvent('Project', 'Created', {
				fromDateSet: !!startDate,
				toDateSet: !!endDate,
				clientAdded: !!client,
			});

		if (hasName(name)) {
			setCreating(true);
			Util.CommitMutation(
				CreateProjectMutation,
				{
					name: name,
					description: blocksFromHTML(description), // Convert to DraftJS until CKEditor is used in projects.
					color: color,
					clientId: client.id,
					startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
					endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
				},
				res => {
					trackProjectCreated();

					const project = res.createProjectServiceProject.project.node;
					if (onCreateCallback) {
						onCreateCallback(project, closeModal);
					} else {
						closeModal();
						history.push(`${projectUrl(project.companyProjectId, project.customProjectId)}/scoping`);
					}
				}
			);
		} else {
			setValidName(hasName(name));
			createToast({
				duration: 5000,
				message: formatMessage({id: 'modal.fields.required_missing'}),
			});
		}
	};

	return (
		<CreativeModal
			title={formatMessage({id: 'common.action.create_entity'}, {entity: formatMessage({id: 'common.project'})})}
			closeModal={closeModal}
			onCreateHandler={createProject}
			disabled={isCreating}
			cy={'new_project_modal'}
		>
			<LabelOverComponent label={formatMessage({id: 'modal.fields.field.name_required'})}>
				<Input
					size={'l'}
					error={!validName}
					onChange={e => {
						setValidName(true);
						setName(e.target.value);
					}}
					onBlur={e => setValidName(hasName(e.target.value))}
					data-cy={'project_name'}
				/>
			</LabelOverComponent>
			<FlexRow gap={'s'}>
				<FlexItem flex="0 1 20%">
					<LabelOverComponent label={formatMessage({id: 'common.color'})}>
						<Dropdown initialValue={color} onSelect={setColor} size={'l'} fit={true} data-cy={'project_color'}>
							<DropdownMenuColor />
						</Dropdown>
					</LabelOverComponent>
				</FlexItem>
				<FlexItem flex="0 1 80%">
					<LabelOverComponent label={formatMessage({id: 'common.dates'})}>
						<FlexRow>
							<DatesWrapper data-cy={'project_date'}>
								<DateRangePicker
									startDate={startDate}
									endDate={endDate}
									handleDateRangeChange={(startDate, endDate) => {
										setStartDate(startDate);
										setEndDate(endDate);
									}}
									isNewDateRange={true}
									datePickerStyle={DATE_PICKER_STYLE.STANDARD}
									highlighted={true}
								/>
							</DatesWrapper>
						</FlexRow>
					</LabelOverComponent>
				</FlexItem>
			</FlexRow>
			<ClientSearchDropdown
				clients={viewer.company.clients}
				setClient={setClient}
				selectedClient={client}
			></ClientSearchDropdown>

			<FlexRow>
				<LabelOverComponent label={formatMessage({id: 'common.description'})}>
					<RichTextField
						maxHeight={'150px'}
						limitations={[
							'BlockQuote',
							'Code',
							'CodeBlock',
							'List',
							'Table',
							'TableToolbar',
							'Indent',
							'MediaEmbed',
							'ImageUpload',
						]}
						editor={'InlineEditor'}
						onSaveHandler={setDescription}
						placeholder={formatMessage(
							{id: 'common.placeholder.add_entity'},
							{entity: formatMessage({id: 'common.description'})}
						)}
						cy={'project_description'}
					/>
				</LabelOverComponent>
			</FlexRow>
		</CreativeModal>
	);
};

const CreateProjectModalQuery = graphql`
	query CreateProjectModal_Query {
		viewer {
			actualPersonId
			component(name: "create_project_modal")
			...CreateProjectModal_viewer
		}
	}
`;

export {CreateProjectModalQuery};

export default createFragmentContainer(CreateProjectModal, {
	viewer: graphql`
		fragment CreateProjectModal_viewer on Viewer {
			component(name: "create_project_modal")
			company {
				clients(first: 10000) {
					edges {
						node {
							id
							name
							logoId
							logoDefaultId
						}
					}
				}
			}
		}
	`,
});
