import React from 'react';
import Styled from 'styled-components/macro';
import {DropdownHandler, Icon, Text, SubHeading, DeprecatedScrollbar as Scrollbar} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {useHistory, withRouter} from 'react-router-dom';
import Util from '../../../shared/util/util';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';
import {withSimpleSocketHandling} from '../../../../socket/withSimpleSocketHandling';
import ProjectIndicatorJS from '../../../shared/components/project-indicator/js/ProjectIndicatorJS';
import {projectUrl} from '../../../../directApi';

const ProjectDropdownWrapper = Styled.div`
	padding-right: 24px;
    width: 337px;
    min-height: 44px;
    display: flex;
    justify-content: right;
    align-items: center;  
    cursor: pointer;
`;

const ProjectDropdownLineWrapper = Styled.div`
    justify-content: space-between;
    display: flex;
    column-gap: 10px;
    width: 337px;
    min-height: 44px;    
    align-items: center;
    padding-left: 24px;
    padding-right: 38px;
	cursor: pointer;

	&:hover {
		background-color: #f0e7fe;
	}

	&:focus {
		border-color: #650ed8;
		outline: none;

		&:hover {
			border-color: transparent;
		}
	}
`;

const ProjectDropdownTriggerWrapper = Styled.div`
    display: flex;
    flex-direction: row;
	
	&[data-state='open'] {
		svg {
			transform: rotate(180deg);
		}
	}
`;

const ProjectDropdownContentContainer = Styled.div`
    width: 337px;
`;

const ProjectNameWrapper = Styled.div`
 	overflow: hidden;
  	display: -webkit-box;
  	-webkit-box-orient: vertical;
  	-webkit-line-clamp: 2;
  	white-space: pre-wrap; 
  	padding-top: 5px;
  	padding-bottom: 5px;
`;

const sortById = (p1, p2) => {
	if (p1.customProjectId && p2.customProjectId) {
		return p1.customProjectId.localeCompare(p2.customProjectId, undefined, {
			sensitivity: 'base',
			numeric: true,
		});
	} else {
		return p1.companyProjectId < p2.companyProjectId ? -1 : 1;
	}
};

const ProjectDropdown = ({viewer, projectId, setSocketConfig}) => {
	let projects =
		viewer?.projectGroup?.projects.edges?.map(project => project.node) ||
		viewer?.program?.projects.edges?.map(project => project?.node?.legacyProjectAsSingleProject);

	if (!projects) {
		return null;
	}

	projects = projects
		.filter(project => project)
		.filter(project => project.id !== Util.getIdFromBase64String(projectId))
		.sort(sortById);

	const projectsIds = projects?.map(project => project.id);

	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.UPDATE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.DELETE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.CREATE,
		},
	];
	setSocketConfig(socketEvents);

	const history = useHistory();
	const projectSection = localStorage.getItem('project-section-last-viewed') || 'workflow';
	const visible = projects && projects.length > 0;

	return visible ? (
		<ProjectDropdownWrapper>
			<DropdownHandler>
				<DropdownHandler.Trigger>
					<ProjectDropdownTriggerWrapper>
						<SubHeading>Switch Projects</SubHeading> <Icon icon="caretDown" size="l" color="default" />
					</ProjectDropdownTriggerWrapper>
				</DropdownHandler.Trigger>
				<DropdownHandler.Content>
					<ProjectDropdownContentContainer>
						<Scrollbar maxHeight={`530px`}>
							{projects?.map(project => (
								<ProjectDropdownLineWrapper
									onClick={() =>
										history.push(
											`${projectUrl(project.companyProjectId, project.customProjectId)}/${projectSection}`
										)
									}
								>
									<Text>
										<ProjectNameWrapper>{project.name}</ProjectNameWrapper>
									</Text>
									<ProjectIndicatorJS project={project} />
								</ProjectDropdownLineWrapper>
							))}
						</Scrollbar>
					</ProjectDropdownContentContainer>
				</DropdownHandler.Content>
			</DropdownHandler>
		</ProjectDropdownWrapper>
	) : null;
};

const ProjectDropdownQuery = graphql`
	query ProjectDropdown_Query($connectedProjectId: String, $programId: ID) {
		viewer {
			actualPersonId
			component(name: "breadcrumb_project_dropdown")
			...ProjectDropdown_viewer @arguments(connectedProjectId: $connectedProjectId, programId: $programId)
		}
	}
`;

export {ProjectDropdownQuery};

export default withSimpleSocketHandling(
	withRouter(
		createFragmentContainer(ProjectDropdown, {
			viewer: graphql`
				fragment ProjectDropdown_viewer on Viewer
				@argumentDefinitions(connectedProjectId: {type: "String"}, programId: {type: "ID"}) {
					id
					projectGroup(id: $connectedProjectId) {
						companyProjectGroupId
						name
						color
						projects(first: 1000000) {
							edges {
								node {
									id
									name
									companyProjectId
									customProjectId
									...ProjectIndicatorJS_project
								}
							}
						}
					}
					program(internalId: $programId) {
						id
						projects(first: 1000000) {
							edges {
								node {
									id
									legacyProjectAsSingleProject {
										id
										name
										companyProjectId
										customProjectId
										...ProjectIndicatorJS_project
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
