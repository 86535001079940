import React, {createContext, useContext, useState} from 'react';
import {BulkSelectPopup, CheckmarkIcon, CrossIcon, EmailIcon} from 'web-components';
import {BUTTON_VARIANT, SEAT} from '../../../constants';
import {personIsVirtualUser} from '../../shared/util/PermissionsUtil';
import Util from '../../shared/util/util';
import BulkUpdatePersonMutation from '../../../mutations/bulk_update_person_mutation';
import {createToast} from '../../shared/components/toasts/toast';
import {useIntl} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import UpdateCompanyContactMutation from '../../../mutations/UpdateCompanyContactMutation';

export const SettingsPeopleContext = createContext(null);

export const useSettingsPeopleContext = () => {
	const context = useContext(SettingsPeopleContext);
	if (!context) throw new Error('useSettingsPeopleContext must be used within SettingsPeopleContextProvider!');
	return context;
};

export const SettingsPeopleContextProvider = ({children, actualPersonId}) => {
	const {formatMessage} = useIntl();
	const [allPersons] = useState(new Map());
	const [selectedItems, setSelectedItems] = useState(new Map());
	const [groupPersons] = useState(new Map());
	const [selectedGroups, setSelectedGroups] = useState(new Map());
	const [company, setCompany] = useState(null);

	// Company states
	const isChargeBeeCustomer = company?.isChargebeeCustomer;
	const userSeats = company?.userSeats;
	const virtualSeats = company?.virtualSeats;

	// Person states
	const isPendingPerson = person => !personIsVirtualUser(person) && person.invited && !person.hasLoggedIn && person.active;
	const isAwaitingPerson = person => !personIsVirtualUser(person) && !person.invited && !person.hasLoggedIn && person.active;
	const isCurrentUser = person => actualPersonId === person.id;
	const getContactPersons = persons => {
		const personIds = persons.map(person => person.id);
		const contactPersons = company.contactPersons.edges
			.filter(contactPerson => personIds.includes(contactPerson.node.person.id))
			.map(contactPerson => contactPerson.node);

		// This is done because the "company.contactPersons" doesn't always return the PRIMARY.
		const primaryContactInList = !!contactPersons.find(
			contactPerson => contactPerson.person.id === company.contactPerson?.id && contactPerson.contactType === 'PRIMARY'
		);

		if (!primaryContactInList && personIds.includes(company.contactPerson?.id)) {
			contactPersons.push({
				id: '',
				contactType: 'PRIMARY',
				person: {
					id: company.contactPerson.id,
					fullName: persons.find(person => person.id === company.contactPerson.id).fullName,
				},
			});
		}

		return contactPersons;
	};

	const getAwaitingPersons = persons => persons.filter(person => isAwaitingPerson(person));
	const getPendingPersons = persons => persons.filter(person => isPendingPerson(person));

	const getPersonsWithEmail = persons => persons.filter(person => person.email);

	const getPersonStatus = person => {
		if (isAwaitingPerson(person)) {
			return formatMessage({id: 'settings_people.status.awaiting'});
		} else if (isPendingPerson(person)) {
			return formatMessage({id: 'settings_people.status.pending'});
		} else if (person.active) {
			return formatMessage({id: 'settings_people.status.active'});
		} else if (!person.active) {
			return formatMessage({id: 'team.deactivated-group-title'});
		}
	};

	const getSeatTypeName = seat => {
		switch (seat) {
			case SEAT.VIRTUAL:
				return formatMessage({id: 'settings_people.seat.virtual'});
			case SEAT.COLLABORATOR:
				return formatMessage({id: 'settings_people.seat.collaborator'});
			case SEAT.CORE:
				return formatMessage({id: 'settings_people.seat.core'});
			case SEAT.FULL:
				return formatMessage({id: 'settings_people.seat.full'});
			default:
				return formatMessage({id: 'settings_people.seat.full'});
		}
	};

	// Handling of selections - both persons and groups.
	const setAllPersons = person => {
		person.forEach(person => {
			allPersons.set(person.node.id, person.node);
		});
	};

	const isSelectedPerson = id => {
		return selectedItems.get(id);
	};

	const isSelectedGroup = name => {
		return selectedGroups.get(name);
	};

	const toggleSelectedPerson = id => {
		const map = new Map(selectedItems);
		const existing = map.get(id);
		map.set(id, !existing);
		setSelectedItems(map);
	};

	const setSelectedPersons = (ids, selected) => {
		const map = new Map(selectedItems);
		ids?.forEach(id => {
			if (!isCurrentUser(allPersons.get(id))) {
				map.set(id, selected);
			}
		});
		setSelectedItems(map);
	};

	const setPersonsInGroup = (name, persons) => {
		groupPersons.set(
			name,
			persons.map(person => person.id)
		);
	};

	const toggleSelectedGroup = name => {
		// Find persons and state for group
		const ids = groupPersons.get(name);
		const selected = selectedGroups.get(name);

		// Change the state for the person
		setSelectedPersons(ids, !selected);
		selectedGroups.set(name, !selected);
	};

	const selectedPersonIds = Array.from(selectedItems)
		.map(([key, value]) => {
			return {
				key,
				value,
			};
		})
		.filter(person => person.value)
		.map(person => person.key);

	const clearSelection = () => {
		setSelectedItems(new Map());
		setSelectedGroups(new Map());
	};

	// Filter functions of persons
	const getDeactivatedPersonSelected = () => selectedPersonIds.map(id => allPersons.get(id)).filter(person => !person.active);

	const getActivatedPersonSelected = () => selectedPersonIds.map(id => allPersons.get(id)).filter(person => person.active);

	const getInvitedPersonSelected = () => {
		const selectedPersons = selectedPersonIds.map(id => allPersons.get(id));
		return [...getPersonsWithEmail([...getAwaitingPersons(selectedPersons), ...getPendingPersons(selectedPersons)])];
	};

	// Mutations to change persons / Invite
	const showToast = message => {
		createToast({duration: 5000, message});
		clearSelection();
	};

	const deactivatePersons = persons => {
		const deactivateBulkOperation = persons => {
			showModal({
				type: MODAL_TYPE.PERSON_DEACTIVATE_WARNING,
				person: persons && persons.length === 1 && persons[0],
				deactivateCallback: () => {
					const mutationObject = {
						ids: persons.map(person => person.id),
						active: false,
					};

					Util.CommitMutation(
						BulkUpdatePersonMutation,
						mutationObject,
						() =>
							showToast(
								persons.length > 1
									? formatMessage({id: 'team-member.amount.has-been-deleted'}, {amount: persons.length})
									: formatMessage({id: 'team-member.has-been-deleted'})
							),
						true
					);
				},
			});
		};

		let changeContacts = [];

		const handleChangeContact = () => {
			if (changeContacts && changeContacts.length > 0) {
				const firstContact = changeContacts[0];

				const changeContactPerson = selected => {
					Util.CommitMutation(
						UpdateCompanyContactMutation,
						{
							contactType: firstContact.contactType,
							personId: selected.value,
							companyId: company.id,
						},
						() => {
							changeContacts.shift();
							handleChangeContact();
						}
					);
				};

				const personOptions = company.allPersons.edges
					.filter(p => p.node.email && p.node.active && p.node.id !== firstContact.person.id)
					.map(p => {
						return {
							value: p.node.id,
							label: p.node.fullName,
							profilePictureId: p.node.profilePictureId,
							firstName: p.node.firstName,
							lastName: p.node.lastName,
						};
					})
					.sort((a, b) => a.label - b.label);

				showModal({
					type: MODAL_TYPE.SELECT_V2,
					title: 'Select new contact person',
					subTitle: formatMessage(
						{id: 'settings_people.deactivate.contact-person'},
						{
							contactType: formatMessage({id: `settings_details.contact_type.${firstContact.contactType}`}),
							name: firstContact.person.fullName,
						}
					),
					defaultCallback: changeContactPerson,
					options: personOptions,
					label: 'Contact Person',
					multi: false,
					personSelect: true,
				});
			} else {
				deactivateBulkOperation(persons);
			}
		};

		// Check if person(s) being deactivated are contact persons - Then select another
		const contactPersons = getContactPersons(persons);

		if (contactPersons && contactPersons.length > 0) {
			changeContacts = contactPersons;
			handleChangeContact();
		} else {
			deactivateBulkOperation(persons);
		}
	};

	const activatePersons = persons => {
		const mutationObject = {
			ids: persons.map(person => person.id),
			active: true,
		};

		const onSuccess = res => {
			const errors = res.bulkUpdatePerson.errors;
			if (errors?.length) {
				if (errors.includes('NO_AVAILABLE_VIRTUAL_SEATS')) {
					showModal({
						type: MODAL_TYPE.NO_AVAILABLE_SEATS,
						seats: company.virtualSeats,
						seatType: 'virtual ',
						warningMessageId: 'team_modal.seats_warning_permission_change_failed',
					});
				} else if (errors.includes('NO_AVAILABLE_SEATS')) {
					showModal({
						type: MODAL_TYPE.NO_AVAILABLE_SEATS,
						seats: company.userSeats,
						seatType: company.tier === 'CORE' ? 'core ' : 'full ',
						warningMessageId: 'team_modal.seats_warning_permission_change_failed',
					});
				} else if (errors.includes('NO_AVAILABLE_COLLABORATOR_SEATS')) {
					showModal({
						type: MODAL_TYPE.NO_AVAILABLE_SEATS,
						seats: company.collaboratorSeats,
						seatType: 'collaborator ',
						warningMessageId: 'team_modal.seats_warning_permission_change_failed',
					});
				}
			} else {
				showToast(
					persons.length > 1
						? formatMessage({id: 'team-member.amount.has-been-enabled'}, {amount: persons.length})
						: formatMessage({id: 'team-member.has-been-enabled'})
				);
			}
		};
		Util.CommitMutation(BulkUpdatePersonMutation, mutationObject, onSuccess);
	};

	const invitePersons = persons => {
		const mutationObject = {
			ids: persons.map(person => person.id),
			invited: true,
		};

		Util.CommitMutation(
			BulkUpdatePersonMutation,
			mutationObject,
			() =>
				showToast(
					persons.length > 1
						? formatMessage({id: 'team-member.amount.has-been-invited'}, {amount: persons.length})
						: formatMessage({id: 'team-member.has-been-invited'})
				),
			true
		);
	};

	// Bulk stuff
	const bulkSelectionOptions = [
		{
			id: 'bulk update invite',
			label: formatMessage({id: 'common.invite'}),
			icon: color => <EmailIcon color={color} />,
			callback: () => invitePersons(getInvitedPersonSelected()),
			cy: 'bulk-invite-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !getInvitedPersonSelected().length > 0,
		},
		{
			id: 'bulk update activate',
			label: formatMessage({id: 'common.activate'}),
			icon: color => <CheckmarkIcon color={color} />,
			callback: () => activatePersons(getDeactivatedPersonSelected()),
			cy: 'bulk-invite-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !getDeactivatedPersonSelected().length > 0,
		},
		{
			id: 'bulk update deactivate',
			label: formatMessage({id: 'common.deactivate'}),
			icon: color => <CrossIcon color={color} size={'small'} />,
			callback: () => deactivatePersons(getActivatedPersonSelected()),
			cy: 'bulk-invite-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !getActivatedPersonSelected().length > 0,
		},
	];

	const getBulkSelectionPopup = () => {
		return selectedPersonIds.length > 0 ? (
			<BulkSelectPopup
				itemCount={selectedPersonIds.length}
				counterText={formatMessage({id: 'settings_people.bulk.users-selected'})}
				actionOptions={bulkSelectionOptions}
				onClose={clearSelection}
				complexOptions={[]}
			/>
		) : null;
	};

	// Actions links
	const getActionOptions = person => {
		const actionOptions = [];
		if (isCurrentUser(person)) {
			return actionOptions;
		}

		if (isAwaitingPerson(person) || isPendingPerson(person)) {
			const message = isAwaitingPerson(person)
				? 'settings_people.action.send-invitation'
				: 'settings_people.action.resend-invitation';
			actionOptions.push({
				locked: person && person.email === null,
				text: formatMessage({id: message}),
				onClick: () => invitePersons([person]),
				disabledTitle: person && person.email === null ? 'The person does not have an email address' : null,
			});
		}

		if (person.active) {
			actionOptions.push({
				text: formatMessage({id: 'common.deactivate'}),
				onClick: () => deactivatePersons([person]),
			});
		} else {
			actionOptions.push({
				text: formatMessage({id: 'common.activate'}),
				onClick: () => activatePersons([person]),
			});
		}
		return actionOptions;
	};

	const value = {
		setAllPersons,
		isSelectedPerson,
		toggleSelectedPerson,
		setPersonsInGroup,
		isSelectedGroup,
		toggleSelectedGroup,
		getBulkSelectionPopup,
		getAwaitingPersons,
		getPendingPersons,
		getSeatTypeName,
		getPersonStatus,
		getActionOptions,
		isCurrentUser,
		isChargeBeeCustomer,
		userSeats,
		virtualSeats,
		setCompany,
	};

	return <SettingsPeopleContext.Provider value={value}>{children}</SettingsPeopleContext.Provider>;
};
