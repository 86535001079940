import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import {useEffect} from 'react';

import taskTableTheme from './Theme';
import {SortableTreeWithoutDndContext as SortableTree} from '@forecasthq/react-virtualized-nested-dnd';
import Styled from 'styled-components/macro';
import {useIntl} from 'react-intl';
import {PhaseHeaderRow} from './elements/PhaseHeaderRow';
import {canNodeHaveChildren} from './table-util/TableLogic';
import {alignToFlex} from './elements/utils';
import {EMPTY_STATE} from '../../../../../constants';
import EmptyState from '../../empty-states/empty_state';
import {CustomScrollDiv} from 'web-components';
import {PeriodRow} from './elements/PeriodRow';
import {TYPES} from './Theme/NodeContentRenderer';
import ForecastTooltip from '../../tooltips/ForecastTooltip';
import {Scrollbar} from '@forecast-it/design-system';
import {hasFeatureFlag} from '../../../util/FeatureUtil';

const TaskTableWrapper = Styled.div`
	width: ${props => props.styleWidth}px;
	height: ${props => props.styleHeight}px;
	margin: 0 32px;
	.ReactVirtualized__Grid {
		outline: none;
	}
`;

export const GROUP_TYPE = {
	NO_GROUPING: 'no_grouping',
	ROLE: 'role',
	PERSON: 'person',
};

export const TaskTable = React.memo(
	({
		newTopNode = null,
		availableColumns = [],
		tableData = [],
		selectedTasks = [],
		setSelectedTasks = () => null,
		onMoveNode = () => null,
		onVisibilityToggle = () => null,
		canDrop = () => true,
		canDrag = () => true,
		slideRegionSize = 150,
		groupType,
		showBaselineInfo = false,
		useTaskHierarchy,
		onToggleExpansion = () => null,
	}) => {
		//useWhyDidYouUpdate('Task Table', {availableColumns, tableData, onMoveNode, onVisibilityToggle, canDrag, canDrop, slideRegionSize, groupType});
		const [topNodeId, setTopNodeId] = useState(undefined);

		// Used for handling the scrollbar Width for the top fixed header calculation.
		const [scrollbarWidth, setScrollbarWidth] = useState(0);

		// Width calculation
		const listWidth = useMemo(() => {
			let totalWidth = 62; // Start with 14 from draghandle and 48 from Margin
			availableColumns.forEach(column => {
				if (column.checked) {
					totalWidth += column.width;
				}
			});
			return totalWidth;
		}, [availableColumns]);

		/**
		 * This method will return the top node component base on the type.
		 *
		 * It currently supports Phase headers and Periods.
		 *
		 * @param topNode The top node to show
		 * @returns {JSX.Element|null} The JSX component
		 */
		const topNodeComponent = topNode => {
			switch (topNode?.type) {
				case TYPES.PERIOD:
					return <PeriodRow node={topNode} />;
				case TYPES.HEADER:
					return (
						<PhaseHeaderRow
							topHeader={true}
							node={topNode}
							onClick={() => onToggleExpansion({node: topNode})}
							cy="top-node"
						/>
					);
				default:
					return null;
			}
		};

		/**
		 * This method handles scrolling in the table. Setting the top node to the
		 * new ID when reaching it.
		 *
		 * @param node The node to set, if it matches the top node type.
		 */
		const handleTopRowRender = node => {
			if (node?.topNodeId) {
				setTopNodeId(node.topNodeId);
			}

			// Calculate the scrollbar width using the grid from the Sortable Tree
			const gridNode = document.querySelector('.ReactVirtualized__Grid');
			setScrollbarWidth(gridNode ? gridNode.offsetWidth - gridNode.clientWidth : 0);
		};

		useEffect(() => {
			if (newTopNode) {
				setTopNodeId(newTopNode);
			}
		}, [newTopNode]);

		/**
		 * Returns the top node
		 */
		const topNode = topNodeId && tableData.find(treeNode => treeNode.id === topNodeId);

		const getNodeKey = ({node}) => node.id;

		const minimumDropDepth = useMemo(() => {
			switch (groupType) {
				case GROUP_TYPE.NO_GROUPING:
					return 1;
				case GROUP_TYPE.ROLE:
				case GROUP_TYPE.PERSON:
					return 2;
				default:
					return 1;
			}
		}, [groupType]);

		if (tableData.length === 0) {
			return <EmptyState pageName={EMPTY_STATE.SCOPING} />;
		}

		if (hasFeatureFlag('native_scrollbar_replacement')) {
			return (
				<Scrollbar hideAxis={Scrollbar.Axis.Vertical} hasFocusableContent isPageScroller>
					<AutoSizer style={{width: '100%'}}>
						{({height, width}) => {
							const innerListWidth = (listWidth > width ? listWidth : width) - 64; // Subtract 64px of padding
							return (
								<TaskTableWrapper styleWidth={innerListWidth} styleHeight={height}>
									<div
										style={{
											width: innerListWidth,
											height,
											display: 'flex',
											flexDirection: 'column',
											position: 'relative',
										}}
									>
										<TableHeader availableColumns={availableColumns} scrollbarWidth={scrollbarWidth} />
										{!!topNode && (
											<div
												style={{
													height: '48px',
													padding: '4px 0',
													backgroundColor: '#fff',
													width: innerListWidth - scrollbarWidth,
													position: 'absolute',
													display: 'flex',
													alignItems: 'center',
													top: '38px',
													zIndex: 2,
												}}
												data-userpilot={'milestone-first'}
											>
												{topNodeComponent(topNode)}
											</div>
										)}
										{/* the 38px is from the table header*/}
										<div style={{width: innerListWidth, height: height - 38}} data-cy="task-table-wrapper">
											<SortableTree
												onTopRowRender={handleTopRowRender}
												minimumDropDepth={minimumDropDepth}
												treeData={tableData}
												onMoveNode={dragInfo =>
													onMoveNode(dragInfo, selectedTasks, groupType, setSelectedTasks)
												}
												onVisibilityToggle={onVisibilityToggle}
												canNodeHaveChildren={node =>
													canNodeHaveChildren(node, showBaselineInfo, useTaskHierarchy)
												}
												canDrag={canDrag}
												canDrop={canDrop}
												slideRegionSize={slideRegionSize}
												theme={taskTableTheme}
												getNodeKey={getNodeKey}
												onChange={() => null}
												reactVirtualizedListProps={{
													style: {outline: 'none'},
													width: innerListWidth,
												}}
											/>
										</div>
									</div>
								</TaskTableWrapper>
							);
						}}
					</AutoSizer>
				</Scrollbar>
			);
		} else {
			return (
				<AutoSizer style={{width: '100%'}}>
					{({height, width}) => {
						const innerListWidth = (listWidth > width ? listWidth : width) - 64; // Subtract 64px of padding
						return (
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={height}>
								<TaskTableWrapper styleWidth={innerListWidth} styleHeight={height}>
									<div
										style={{
											width: innerListWidth,
											height,
											display: 'flex',
											flexDirection: 'column',
											position: 'relative',
										}}
									>
										<TableHeader availableColumns={availableColumns} scrollbarWidth={scrollbarWidth} />
										{!!topNode && (
											<div
												style={{
													height: '48px',
													padding: '4px 0',
													backgroundColor: '#fff',
													width: innerListWidth - scrollbarWidth,
													position: 'absolute',
													display: 'flex',
													alignItems: 'center',
													top: '38px',
													zIndex: 2,
												}}
												data-userpilot={'milestone-first'}
											>
												{topNodeComponent(topNode)}
											</div>
										)}
										{/* the 38px is from the table header*/}
										<div style={{width: innerListWidth, height: height - 38}} data-cy="task-table-wrapper">
											<SortableTree
												onTopRowRender={handleTopRowRender}
												minimumDropDepth={minimumDropDepth}
												treeData={tableData}
												onMoveNode={dragInfo =>
													onMoveNode(dragInfo, selectedTasks, groupType, setSelectedTasks)
												}
												onVisibilityToggle={onVisibilityToggle}
												canNodeHaveChildren={node =>
													canNodeHaveChildren(node, showBaselineInfo, useTaskHierarchy)
												}
												canDrag={canDrag}
												canDrop={canDrop}
												slideRegionSize={slideRegionSize}
												theme={taskTableTheme}
												getNodeKey={getNodeKey}
												onChange={() => null}
												reactVirtualizedListProps={{
													style: {outline: 'none'},
													width: innerListWidth,
												}}
											/>
										</div>
									</div>
								</TaskTableWrapper>
							</CustomScrollDiv>
						);
					}}
				</AutoSizer>
			);
		}
	}
);

TaskTable.propTypes = {
	availableColumns: PropTypes.array.isRequired,
	tableData: PropTypes.array.isRequired,
	onMoveNode: PropTypes.func,
	onVisibilityToggle: PropTypes.func,
	canDrop: PropTypes.func,
	canDrag: PropTypes.func,
	slideRegionSize: PropTypes.func,
	groupType: PropTypes.oneOf(Object.values(GROUP_TYPE)),
};

const HeaderFillerElement = Styled.div`
	width: ${props => props.width}px;
`;

export const TableHeaderStyle = Styled.div`
	padding: 8px 0;
	display: flex;
`;

export const HeaderTitleStyle = Styled.div`
	display: flex;
	padding-left: 8px;
	padding-right: ${props => props.paddingRight && `${props.paddingRight}px`};
	height: 22px;
	align-items: center;
	flex-grow: ${props => props.growable && '1'};
	width: ${props => !props.growable && `${props.width}px`};
	min-width: ${props => props.growable && `${props.width}px`};
	justify-content: ${props => alignToFlex(props.align)};
	text-align: ${props => props.align};
	font-size: 9px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #A1A1A1;
`;

export const TableHeader = ({availableColumns, scrollbarWidth}) => {
	const intl = useIntl();
	return (
		<TableHeaderStyle>
			<HeaderTitleStyle align="left" growable width={200} />
			{availableColumns
				.filter(
					column =>
						!(
							column.name === 'task-id' ||
							column.name === 'task-name' ||
							column.name === 'empty' ||
							column.name === 'expand' ||
							column.name === 'selector'
						) && column.width > 0
				)
				.map(column => {
					const props = {
						align: column.align || 'left',
						width: column.width,
						paddingRight: column.name === 'forecast' && 16,
					};
					return (
						column.checked && (
							<ForecastTooltip
								disabled={!column.tooltipFunc}
								content={column.tooltipFunc && column.tooltipFunc(intl.formatMessage)}
								placement={'top'}
								maxWidth="none"
							>
								<HeaderTitleStyle key={column.name} {...props}>
									{!!column.translationId && intl.formatMessage({id: column.translationId})}
									{column.displayName}
								</HeaderTitleStyle>
							</ForecastTooltip>
						)
					);
				})}
			{scrollbarWidth ? <HeaderFillerElement width={scrollbarWidth} /> : null}
		</TableHeaderStyle>
	);
};
