import React, { useRef, useState } from 'react';
import { Avatar, Button, Checkbox, Detail, FlexColumn, FlexRow, Label, Link, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { isInValidEmailFormat } from '../../../../../shared/util/EmailUtil';
import { createToast } from '../../../../../shared/components/toasts/another-toast/toaster';
import Util from '../../../../util/util';
import UpdateAllReportSharesMutation from '../../../../../../mutations/update_all_report_shares_mutation';
import { ActualInput, AlreadySharedContainer, EmailsWrapper, FlexContainer, InputErrorMessage, InputHolder, SharedPersonInfoWrapper, } from './ShareReportModal_style';
import { EmailLabel } from 'web-components';
import { profilePicRectSrc } from '../../../../../../directApi';
const ShareReportModal = ({ closeModal, reportSharesInput, reportId, privateAccessInput, reportOwner, }) => {
    const intl = useIntl();
    const [disableShareButton, setDisableShareButton] = useState(true);
    const [inputErrorMessage, setInputErrorMessage] = useState(null);
    const [reportShares, setReportShares] = useState(reportSharesInput);
    const [newEmails, setNewEmails] = useState([]);
    const [privateAccess, setPrivateAccess] = useState(privateAccessInput);
    const inputRef = useRef(null);
    const newEmailRefsArray = useRef([]);
    const emailExistsInNewEmailList = email => {
        return newEmails.some(newEmail => newEmail === email);
    };
    const emailExistsInAlreadySharedPersonsList = email => {
        return reportShares.some(reportShare => reportShare.email === email);
    };
    const addNewEmail = e => {
        if (e.target.value) {
            const email = e.target.value.trim();
            if (emailExistsInNewEmailList(email)) {
                setInputErrorMessage('Email already exists in this list.');
                e.target.focus();
            }
            else if (emailExistsInAlreadySharedPersonsList(email)) {
                setInputErrorMessage('The report has been already shared with this email.');
                e.target.focus();
            }
            else if (isInValidEmailFormat(email)) {
                if (inputRef.current)
                    inputRef.current.value = '';
                setInputErrorMessage(null);
                setNewEmails([...newEmails, email]);
            }
            else {
                setInputErrorMessage('You have entered an invalid email address.');
                e.target.focus();
            }
        }
    };
    const removeNewEmail = index => {
        setDisableShareButton(false);
        const temp = [...newEmails];
        temp.splice(index, 1);
        setNewEmails(temp);
    };
    const handleKeyDown = e => {
        if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
            e.preventDefault();
            addNewEmail(e);
        }
        else {
            setInputErrorMessage(null);
        }
    };
    const handleBackspace = e => {
        var _a, _b, _c;
        if (e.key === 'Backspace') {
            // If the EmailLabel element is marked, delete it
            if (newEmailRefsArray.current.includes(document.activeElement)) {
                // Get the index of the marked element
                const index = newEmailRefsArray.current.indexOf(document.activeElement);
                // Remove the ref
                newEmailRefsArray.current.splice(index, 1);
                // Remove the email
                removeNewEmail(index);
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
            // else if the input field is empty, mark the EmailLabel
            else if (!e.target.value) {
                (_b = newEmailRefsArray.current[newEmailRefsArray.current.length - 1]) === null || _b === void 0 ? void 0 : _b.focus();
            }
        }
        else {
            // Focus the text field on other input
            (_c = inputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
        }
    };
    const handleOnBlur = e => {
        addNewEmail(e);
    };
    const handleCreateReportShare = () => {
        const emails = reportShares.map(reportShare => reportShare.email);
        setDisableShareButton(true);
        Util.CommitMutation(UpdateAllReportSharesMutation, {
            reportId: reportId,
            privateAccess: privateAccess,
            emails: emails,
        }, data => {
            var _a;
            if ((_a = data.updateAllReportShares.warnings) === null || _a === void 0 ? void 0 : _a.length) {
                const emails = data.updateAllReportShares.warnings
                    .map(warning => warning.substring(warning.indexOf(':') + 1))
                    .join(',');
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.person_added.error' }) + ' ' + emails,
                });
            }
            else {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'save_report.report_updated' }),
                });
            }
            setDisableShareButton(false);
            closeModal();
        });
    };
    const handleDeleteReportShare = sharedId => {
        setDisableShareButton(false);
        const filteredReportShares = reportShares.filter(reportShare => reportShare.sharedId !== sharedId);
        setReportShares([...filteredReportShares]);
    };
    const addEmails = () => {
        if (newEmails.length) {
            setDisableShareButton(false);
            const newReportShares = newEmails.map(email => ({ email: email, sharedId: null }));
            setReportShares([...reportShares, ...newReportShares]);
            setNewEmails([]);
        }
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'report.share' })),
        React.createElement(Modal.Content, null,
            React.createElement(Label, null, intl.formatMessage({ id: 'report.share-input-label' })),
            inputErrorMessage && React.createElement(InputErrorMessage, null, inputErrorMessage),
            React.createElement(FlexContainer, null,
                React.createElement(FlexRow, null,
                    React.createElement(InputHolder, null,
                        React.createElement(EmailsWrapper, { onKeyDown: handleBackspace },
                            newEmails.map((email, index) => (React.createElement(EmailLabel, { key: index, text: email, onCrossClick: () => removeNewEmail(index), ref: ref => {
                                    if (ref)
                                        newEmailRefsArray.current[index] = ref;
                                }, tabIndex: index }))),
                            React.createElement(ActualInput, { autoFocus: true, onBlur: handleOnBlur, onKeyDown: handleKeyDown, ref: inputRef, "data-cy": "share-email" }))),
                    React.createElement(Button, { emphasis: 'medium', onClick: addEmails }, intl.formatMessage({ id: 'common.add' })))),
            React.createElement(AlreadySharedContainer, null,
                React.createElement(SharedPersonInfoWrapper, null,
                    React.createElement(FlexRow, null,
                        React.createElement(Avatar, { src: reportOwner.profilePictureId ? profilePicRectSrc(reportOwner.profilePictureId) : undefined, size: 'm', name: reportOwner.fullName }),
                        React.createElement(FlexColumn, null,
                            React.createElement(Text, { size: '2' }, reportOwner.fullName),
                            React.createElement(Detail, { size: '1' }, reportOwner.email))),
                    React.createElement(Text, null, "Owner")),
                reportShares
                    .sort((rs1, rs2) => rs1.email.localeCompare(rs2.email))
                    .map(reportShare => (React.createElement(SharedPersonInfoWrapper, null,
                    React.createElement(FlexContainer, null,
                        React.createElement(Detail, { size: '1' }, reportShare.email)),
                    React.createElement(Link, { size: '2', onClick: () => handleDeleteReportShare(reportShare.sharedId) }, intl.formatMessage({ id: 'common.remove' })))))),
            React.createElement(Label, null, intl.formatMessage({ id: 'report.share-company-label' })),
            React.createElement(FlexRow, null,
                React.createElement(Checkbox, { checked: !privateAccess, onChange: () => {
                        setPrivateAccess(!privateAccess);
                        setDisableShareButton(false);
                    } }),
                React.createElement(Text, null, "Allow your colleagues to view and edit the report"))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: handleCreateReportShare, dataCy: 'modal-btn-share-report', disabled: disableShareButton },
                React.createElement(FormattedMessage, { id: "common.save" })))));
};
export default ShareReportModal;
