import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';

import CreateAutojoinDomainMutation from '../../../mutations/create_autojoin_domain_mutation';
import UpdateAutojoinDomainMutation from '../../../mutations/update_autojoin_domain_mutation';
import DeleteAutojoinDomainMutation from '../../../mutations/delete_autojoin_domain_mutation';

import {getDefaultMediumCompanyLogo} from '../../../components/default_avatars';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {injectIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE, currencies, HIDDEN_FEATURES, isoCountries, TIERS, timezones} from '../../../constants';
import * as tracking from '../../../tracking';
import InputField from '../../../components/inputs/input_field';
import SelectInput from '../../../components/inputs/select_input';

import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import UploadingOverlay from '../../shared/components/uploading-overlay/uploading_overlay';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';
import emailBlockedList from '../../../emailBlockedList';
import ReactDOM from 'react-dom';
import HoursInput from '../../shared/components/inputs/hours-input/hours_input_view';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import SettingsDemoData, {settingsDemoDataQuery} from '../../../containers/settings/demo-data/settings_demo_data';
import {Checkbox, Collapsible} from 'web-components';
import CompanySetupUtil, {isFeatureHidden} from '../../shared/util/CompanySetupUtil';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import ContactDetails from '../../../containers/settings/contacts/ContactDetails';
import ResourceManagement from '../../../containers/settings/setup/ResourceManagement';
import {BillableUtilisationTarget} from '../../../containers/settings/setup/BillableUtilisationTarget';
import {generateAgencyDemo, generateAgileDemo} from '../../../demo-data/DemoDataLogic';
import {DeprecatedButton as Button} from '@forecast-it/design-system';
import WeekendVisibility from '../../../containers/settings/setup/WeekendVisibility';
import {DAY_NAMES} from '../../../components/canvas-scheduling/canvas-timeline/canvas_timeline_util';
import {companyLogoSrc} from '../../../directApi';

class settingsCompany extends Component {
	constructor(props) {
		super(props);

		const company = this.props.viewer.company;

		this.state = {
			name: company.name,
			street: company.street || '',
			zip: company.zip || '',
			city: company.city || '',
			vatId: company.vatId || '',
			country: company.country,
			currency: company.currency,
			timezone: company.timezone,
			contactName: company.contactName,
			contactEmail: company.contactEmail,
			contactPhone: company.contactPhone,
			isUploadingLogo: false,
			companyTarget: company.companyTarget === null ? '' : company.companyTarget * 100,
			working_hours: this.getWorkingHours(),
			showOverlay: false,
			newDomainName: '',
			domainsInUpdate: [],
		};
		this.domainInputs = [];

		this.superPropertyChecksum = trackPage('Settings Details');
	}

	componentDidMount() {
		document.title = 'Details - Account - Forecast';
		// Segment
		tracking.trackPage('settings-details');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.updateCompanyWorkingHours(prevProps);
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	updateCompanyWorkingHours(prevProps) {
		const {company} = this.props.viewer;
		const oldCompany = prevProps.viewer.company;

		let hasChanged = false;
		for (const dayName of DAY_NAMES) {
			if (company[dayName] !== oldCompany[dayName]) {
				hasChanged = true;
				break;
			}
		}

		if (hasChanged) {
			this.setState({
				working_hours: this.getWorkingHours(),
			});
		}
	}

	getWorkingHours() {
		const {language, company} = this.props.viewer;

		return language === 'ENGLISH_US'
			? [
					company.sunday / 60,
					company.monday / 60,
					company.tuesday / 60,
					company.wednesday / 60,
					company.thursday / 60,
					company.friday / 60,
					company.saturday / 60,
			  ]
			: [
					company.monday / 60,
					company.tuesday / 60,
					company.wednesday / 60,
					company.thursday / 60,
					company.friday / 60,
					company.saturday / 60,
					company.sunday / 60,
			  ];
	}

	showChangesSavedToast() {
		createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
	}

	handleClientChange(property, value) {
		this.setState({[property]: value});
	}

	handleClientSave(property) {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				[property]: this.state[property],
			},
			() => this.showChangesSavedToast()
		);
	}

	handleChangeCountry(selection) {
		const country = selection ? selection.value : null;
		this.setState({country});
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				country,
			},
			() => this.showChangesSavedToast()
		);
	}

	handleLogoChange() {
		const {formatMessage} = this.props.intl;
		const file = this.file_input.files[0];

		const onSuccess = () => {
			this.showChangesSavedToast();
			this.setState({isUploadingLogo: false});
		};

		Util.confirmFilePreviewType(file, res => {
			if (res) {
				this.setState({isUploadingLogo: true});
				Util.CommitMutation(UpdateCompanyMutation, {id: this.props.viewer.company.id}, onSuccess, true, {
					id: this.props.viewer.company.id,
					file: file,
				});
			} else if (!this.state.isUploadingLogo && !res) {
				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div className="default-warning-modal">
							<Warning messageId="warning_modal.profile_picture_wrong_format_title" />
							<div className="warning-part-2">
								{formatMessage({id: 'warning_modal.profile_picture_wrong_format_info'})}
							</div>
						</div>
					),
					buttons: [
						{
							text: formatMessage({id: 'common.cancel'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}
		});
	}

	showLoadingOverlay() {
		this.setState({
			showOverlay: true,
		});
	}

	hideLoadingOverlay() {
		this.setState({
			showOverlay: false,
		});
	}

	handleRemoveLogo() {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				logoId: 'delete',
			},
			() => this.showChangesSavedToast()
		);
	}

	handleChangeCurrency(selected) {
		if (selected.value === undefined) return;
		const onSuccess = result => {
			this.showChangesSavedToast();
			this.setState({currency: result.updateCompany.company.currency});
		};
		const update = () => {
			Util.CommitMutation(
				UpdateCompanyMutation,
				{
					id: this.props.viewer.company.id,
					currency: selected.value,
				},
				onSuccess
			);
		};
		//if there are exchange rates show warning modal first with cancel and continue options.
		const hasExchangeRates = this.props.viewer.company.exchangeRates.edges.length !== 0;
		const hasRateCardsWithCompanyCurrency = this.props.viewer.company.rateCards.edges.some(
			rateCard => rateCard.node.currency === this.props.viewer.company.currency
		);
		if (hasExchangeRates || hasRateCardsWithCompanyCurrency) {
			const {formatMessage} = this.props.intl;
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div className="default-warning-modal">
						<Warning messageId={'warning-modal.edit-warning-title'} useInfoIcon={true} />
						{hasRateCardsWithCompanyCurrency && (
							<div className="warning-part-2">
								{formatMessage({id: 'settings_details.currency_change_warning_rate_cards'})}
							</div>
						)}
						{hasExchangeRates && (
							<div className="warning-part-2">
								{formatMessage({id: 'settings_details.currency_change_warning_exchange_rates'})}
							</div>
						)}
					</div>
				),
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.continue'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: update,
					},
				],
			});
		} else {
			update();
		}
	}

	handleChangeTimezone(selected) {
		if (selected.value === undefined) return;
		const onSuccess = result => {
			this.showChangesSavedToast();
			this.setState({timezone: result.updateCompany.company.timezone});
		};
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				timezone: selected.value,
			},
			onSuccess
		);
	}

	saveWorkingHours(i, e) {
		const newHours = this.state.working_hours.slice();
		if (newHours[i] === e) return; //Don't trigger if the value did not change
		newHours[i] = e == null ? 0 : e;

		const validateHours = val => {
			if (isNaN(val)) return false;
			if (parseFloat(val) < -600000) return false;
			else if (parseFloat(val) > 600000) return false;
			return true;
		};
		let monday, tuesday, wednesday, thursday, friday, saturday, sunday;
		if (this.props.viewer.language === 'ENGLISH_US') {
			sunday = newHours[0];
			monday = newHours[1];
			tuesday = newHours[2];
			wednesday = newHours[3];
			thursday = newHours[4];
			friday = newHours[5];
			saturday = newHours[6];
		} else {
			monday = newHours[0];
			tuesday = newHours[1];
			wednesday = newHours[2];
			thursday = newHours[3];
			friday = newHours[4];
			saturday = newHours[5];
			sunday = newHours[6];
		}
		let isValid = true;
		[monday, tuesday, wednesday, thursday, friday, saturday, sunday].forEach((val, index) => {
			if (!validateHours(val)) {
				isValid = false;
			}
		});
		if (!isValid) return;
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				monday: Math.round(monday * 60),
				tuesday: Math.round(tuesday * 60),
				wednesday: Math.round(wednesday * 60),
				thursday: Math.round(thursday * 60),
				friday: Math.round(friday * 60),
				saturday: Math.round(saturday * 60),
				sunday: Math.round(sunday * 60),
			},
			() => this.showChangesSavedToast()
		);
		this.setState({working_hours: newHours});
	}

	handleAddDomain() {
		if (!this.state.newDomainName) return;
		const onSuccess = res => {
			if (res.createAutojoinDomain.autojoinDomain) {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_created'})});
			} else {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_taken'})});
			}
		};
		if (emailBlockedList.emailBlockedList.includes(this.state.newDomainName)) {
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_is_blocked'})});
			this.setState({newDomainName: ''});
		} else {
			Util.CommitMutation(
				CreateAutojoinDomainMutation,
				{
					companyId: this.props.viewer.company.id,
					name: this.state.newDomainName,
				},
				onSuccess
			);
		}

		this.setState({newDomainName: ''});
	}

	handleDeleteDomain(domainId) {
		// confirmation window
		const onSuccess = test => {
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_deleted'})});
		};
		const callbackPositive = params => {
			Util.CommitMutation(
				DeleteAutojoinDomainMutation,
				{
					companyId: this.props.viewer.company.id,
					id: domainId,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	handleDomainNameBlur(domainId, inputIndex, e) {
		var domainBeforeUpdateAttempt = this.state.domainsInUpdate.filter(obj => {
			return obj.id === domainId;
		});
		const onSuccess = res => {
			if (res.updateAutojoinDomain.AutojoinDomain) {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_updated'})});
			} else {
				const domNode = ReactDOM.findDOMNode(this.domainInputs[inputIndex]);
				domNode.value = domainBeforeUpdateAttempt[0].name;
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_taken'})});
			}
			let domainsInUpdate = [...this.state.domainsInUpdate];
			let index = domainsInUpdate.findIndex(domain => domain.id === domainId);
			if (index !== -1) {
				domainsInUpdate.splice(index, 1);
				this.setState({domainsInUpdate: domainsInUpdate});
			}
		};

		if (emailBlockedList.emailBlockedList.includes(e.target.value)) {
			const domNode = ReactDOM.findDOMNode(this.domainInputs[inputIndex]);
			domNode.value = domainBeforeUpdateAttempt[0].name;
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_details.domain_is_blocked'})});
			this.setState({newDomainName: ''});
		} else {
			Util.CommitMutation(
				UpdateAutojoinDomainMutation,
				{
					id: domainId,
					name: e.target.value,
				},
				onSuccess
			);
		}
	}

	handleDomainNameFocus(e) {
		let domainUpdate = {id: e.target.id, name: e.target.value};
		const newDomainUpdate = [...this.state.domainsInUpdate, domainUpdate];
		this.setState({domainsInUpdate: newDomainUpdate});
	}

	handleKeyPressDomain(index, e) {
		if (e.key === 'Enter') {
			this.domainInputs[index].blur();
		}
	}

	getHeader() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.details'})} />
			</TopHeaderBarWrapper>
		);
	}

	render() {
		const domains = this.props.viewer.company.autojoinDomains.edges;

		const {formatMessage} = this.props.intl;
		const logoSrc =
			this.props.viewer.company.logoId !== null
				? companyLogoSrc(this.props.viewer.company.logoId)
				: getDefaultMediumCompanyLogo(this.props.viewer.company.logoDefaultId);
		const weekdays =
			this.props.viewer.language === 'ENGLISH_US'
				? [
						formatMessage({id: 'common.sunday'}),
						formatMessage({id: 'common.monday'}),
						formatMessage({id: 'common.tuesday'}),
						formatMessage({id: 'common.wednesday'}),
						formatMessage({id: 'common.thursday'}),
						formatMessage({id: 'common.friday'}),
						formatMessage({id: 'common.saturday'}),
				  ]
				: [
						formatMessage({id: 'common.monday'}),
						formatMessage({id: 'common.tuesday'}),
						formatMessage({id: 'common.wednesday'}),
						formatMessage({id: 'common.thursday'}),
						formatMessage({id: 'common.friday'}),
						formatMessage({id: 'common.saturday'}),
						formatMessage({id: 'common.sunday'}),
				  ];

		const contactPersons = this.props.viewer.company.contactPersons.edges.map(e => e.node);

		//Start : Fallback to old company contact - delete after Contact-Migration
		if (!contactPersons.find(contactPerson => contactPerson.contactType === 'PRIMARY')) {
			contactPersons.push({
				contactType: 'PRIMARY',
				person: {
					fullName: this.state.contactName,
					email: this.state.contactEmail,
					phone: this.state.contactPhone,
				},
			});
		}
		//End

		return (
			<div className={'section-content settings-app settings-details'} data-cy="settings-details">
				{this.props.children}
				{this.state.showOverlay ? <UploadingOverlay /> : null}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className={'section-body'}>
						<div className="inner">
							<fieldset className="company-logo">
								<h3>{formatMessage({id: 'settings_details.address_section'})}</h3>
								<p className="description">{formatMessage({id: 'settings_details.address_section_info'})}</p>
								<div className="details">
									<div className="titles-material">
										<InputField
											label={formatMessage({id: 'settings.client-company-name'})}
											type="text"
											placeholder={formatMessage({id: 'settings_details.company_name_placeholder'})}
											id="company_name"
											value={this.state.name}
											onChange={this.handleClientChange.bind(this, 'name')}
											onBlur={this.handleClientSave.bind(this, 'name')}
											blurOnEnter={true}
										/>
										<InputField
											label={formatMessage({id: 'settings.client-company-street'})}
											type="text"
											placeholder={formatMessage({id: 'settings_details.company_street_placeholder'})}
											id="company_street"
											value={this.state.street}
											onChange={this.handleClientChange.bind(this, 'street')}
											onBlur={this.handleClientSave.bind(this, 'street')}
											blurOnEnter={true}
										/>
										<InputField
											label={formatMessage({id: 'settings.client-company-zip'})}
											type="text"
											placeholder={formatMessage({id: 'settings_details.company_zip_placeholder'})}
											id="company_zip"
											value={this.state.zip}
											onChange={this.handleClientChange.bind(this, 'zip')}
											onBlur={this.handleClientSave.bind(this, 'zip')}
											blurOnEnter={true}
										/>
										<InputField
											label={formatMessage({id: 'settings.client-company-city'})}
											type="text"
											placeholder={formatMessage({id: 'settings_details.company_city_placeholder'})}
											id="company_city"
											value={this.state.city}
											onChange={this.handleClientChange.bind(this, 'city')}
											onBlur={this.handleClientSave.bind(this, 'city')}
											blurOnEnter={true}
										/>
										<SelectInput
											value={this.state.country}
											options={isoCountries}
											label={formatMessage({id: 'settings.client-company-country'})}
											onChange={this.handleChangeCountry.bind(this)}
											multi={false}
											clearable={true}
										/>
										<InputField
											label={formatMessage({id: 'settings.client-company-vat'})}
											type="text"
											placeholder={formatMessage({id: 'settings_details.company_vat_placeholder'})}
											id="company_vat"
											value={this.state.vatId}
											onChange={this.handleClientChange.bind(this, 'vatId')}
											onBlur={this.handleClientSave.bind(this, 'vatId')}
											blurOnEnter={true}
										/>
									</div>
									<div className="logo">
										<div
											className={this.state.isUploadingLogo ? 'upload-overlay' : 'upload-overlay-hidden'}
										>
											<div
												className={this.props.isUploading ? 'uploading-gif' : 'uploading-gif-hidden'}
											/>
										</div>
										<img crossOrigin="use-credentials" src={logoSrc} width="140" height="140" />
										<div className="controls">
											<div className="controls-update">
												<input
													id="file-input"
													type="file"
													onChange={this.handleLogoChange.bind(this)}
													ref={input => (this.file_input = input)}
													onClick={e => {
														e.target.value = null;
													}}
												/>
												<label className="control update">
													{formatMessage({id: 'settings_details.update-logo'})}
												</label>
											</div>
											{this.props.viewer.company.logoId ? (
												<div className="control remove" onClick={this.handleRemoveLogo.bind(this)}>
													{formatMessage({id: 'settings_details.remove-logo'})}
												</div>
											) : null}
										</div>
									</div>
								</div>
							</fieldset>
							<fieldset className="beta-opt-in">
								<h3>{'Join Forecast Beta Program'}</h3>
								<div style={{display: 'flex', marginTop: '16px'}}>
									<Checkbox
										checked={this.props.viewer.company.betaOptIn}
										onChange={() =>
											Util.CommitMutation(UpdateCompanyMutation, {
												id: this.props.viewer.company.id,
												betaOptIn: !this.props.viewer.company.betaOptIn,
											})
										}
									/>
									I agree to the&nbsp;
									<a
										href="https://3811310.fs1.hubspotusercontent-na1.net/hubfs/3811310/Forecast%20Beta%20T&Cs%20v1.0%20(online).pdf"
										target={'_blank'}
									>
										Beta Program terms and conditions
									</a>
								</div>
							</fieldset>
							{CompanySetupUtil.hasFinance() && (
								<fieldset className="currency">
									<h3>{formatMessage({id: 'settings_details.currency_section'})}</h3>
									<div className="titles">
										<p className="description">
											{formatMessage({id: 'settings_details.currency_section_info'})}
										</p>
										<SelectInput
											placeholder={formatMessage({id: 'settings_details.currency_placeholder'})}
											options={currencies}
											value={this.state.currency}
											onChange={this.handleChangeCurrency.bind(this)}
											clearable={false}
											label={formatMessage({id: 'settings_details.currency_section'})}
											multi={false}
										/>
									</div>
								</fieldset>
							)}
							<fieldset className="working-hours">
								<h3>{formatMessage({id: 'settings_details.default-working-hours'})}</h3>
								<div className="titles">
									<p className="description">
										{formatMessage({id: 'settings_details.default-working-hours-description'})}
									</p>
									<table className="company-default-hours">
										<thead>
											<tr>
												{weekdays.map((day, index) => (
													<th key={index}>{day}</th>
												))}
											</tr>
										</thead>

										<tbody>
											<tr>
												{[...Array(7)].map((_, i) => (
													<td key={i} className="input-cell">
														<HoursInput
															value={this.state.working_hours[i]}
															nonWorkingDay={this.state.working_hours[i] === 0}
															mutation={this.saveWorkingHours.bind(this, i)}
															disabled={Util.isWeekdayDisabled(
																i,
																this.props.viewer.company,
																this.props.viewer.language
															)}
														/>
													</td>
												))}
											</tr>
										</tbody>
									</table>
								</div>
							</fieldset>

							<fieldset id="weekend-visibility" className="section-content">
								<WeekendVisibility
									company={this.props.viewer.company}
									showLoading={this.showLoadingOverlay.bind(this)}
									hideLoading={this.hideLoadingOverlay.bind(this)}
								/>
							</fieldset>

							<fieldset className="timezone">
								<h3>{formatMessage({id: 'settings_details.timezone'})}</h3>
								<div className="titles">
									<p className="description">
										{formatMessage({id: 'settings_details.timezone_section_info'})}
									</p>
									<SelectInput
										placeholder={formatMessage({id: 'settings_details.timezone_placeholder'})}
										options={timezones}
										value={this.state.timezone}
										onChange={this.handleChangeTimezone.bind(this)}
										clearable={false}
										label={formatMessage({id: 'settings_details.timezone'})}
										multi={false}
									/>
								</div>
							</fieldset>
							{!isFeatureHidden(HIDDEN_FEATURES.REVENUE) && (
								<fieldset id="revenue-tracking" className="section-content">
									<BillableUtilisationTarget company={this.props.viewer.company} />
								</fieldset>
							)}
							<fieldset id="resource-management" className="section-content">
								<ResourceManagement company={this.props.viewer.company} />
							</fieldset>
							<fieldset className="general">
								<h3>{formatMessage({id: 'settings_details.contact_section'})}</h3>
								<ContactDetails contactPersons={contactPersons} companyId={this.props.viewer.company.id} />
							</fieldset>
							<fieldset>
								<h3>{formatMessage({id: 'settings_details.domains-list-title'})}</h3>
								<p className="description">{formatMessage({id: 'settings_details.sub-title'})}</p>
								<table>
									<thead>
										<tr>
											<th>{formatMessage({id: 'settings_details.add-domain-here'})}</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{domains.map((domain, index) => (
											<tr key={domain.node.id}>
												<td>
													<input
														ref={input => (this.domainInputs[index] = input)}
														type="text"
														id={domain.node.id}
														defaultValue={domain.node.name}
														placeholder={formatMessage({id: 'settings_details.domain'})}
														onBlur={this.handleDomainNameBlur.bind(this, domain.node.id, index)}
														onKeyPress={e => this.handleKeyPressDomain(index, e)}
														onFocus={this.handleDomainNameFocus.bind(this)}
													/>
												</td>
												<td className="controls">
													<span
														onClick={this.handleDeleteDomain.bind(this, domain.node.id)}
														className="remove"
													>
														{formatMessage({id: 'common.delete'})}
													</span>
												</td>
											</tr>
										))}
										<tr>
											<td>
												<InputField
													type="text"
													value={this.state.newDomainName}
													onChange={this.handleClientChange.bind(this, 'newDomainName')}
													placeholder={formatMessage({id: 'settings_details.domain'})}
													blurOnEnter={true}
													onBlur={this.handleAddDomain.bind(this)}
												/>
											</td>
											<td>&nbsp;</td>
											<td>
												<button className="submit-button" onClick={this.handleAddDomain.bind(this)}>
													{formatMessage({id: 'settings_details.add_domain'})}
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</fieldset>
							{this.props.viewer.isFirstPersonInCompany && this.props.viewer.company.tier === TIERS.TRIAL ? (
								<fieldset className="general">
									<h3>New Demo Data</h3>
									<div style={{display: 'flex'}}>
										<Button onClick={() => generateAgencyDemo()}>Create agency demo</Button>
										<div style={{width: '8px'}}></div>
										<Button onClick={() => generateAgileDemo()}>Create agile demo</Button>
									</div>
								</fieldset>
							) : null}
							<div className="demo-data-wrapper">
								<Collapsible
									title={formatMessage({id: 'settings_details.demo_data'})}
									initiallyCollapsed={true}
								>
									<ForecastQueryRenderer
										query={settingsDemoDataQuery}
										key="query-render-settingsDemoData"
										authorizeAccessRoute={'settings'}
										render={(relayProps, retry) => <SettingsDemoData retry={retry} {...relayProps} />}
									/>
								</Collapsible>
							</div>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const settingsCompanyQuery = graphql`
	query settingsCompany_Query {
		viewer {
			actualPersonId
			component(name: "settings_company")
			...settingsCompany_viewer
		}
	}
`;

export {settingsCompanyQuery};

export default injectIntl(
	createFragmentContainer(settingsCompany, {
		viewer: graphql`
			fragment settingsCompany_viewer on Viewer {
				id
				language
				email
				isFirstPersonInCompany
				availableFeatureFlags {
					key
				}
				company {
					...ResourceManagement_company
					id
					tier
					name
					backendId
					currency
					timezone
					logoId
					logoDefaultId
					companyTarget
					revenueEnabled
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					street
					zip
					city
					country
					vatId
					betaOptIn
					contactPersons(first: 10000) @connection(key: "Company_contactPersons") {
						edges {
							node {
								id
								contactType
								person {
									fullName
									email
									phone
									jobTitle
								}
							}
						}
					}
					contactPerson {
						id
						fullName
						email
					}
					contactName
					contactEmail
					contactPhone
					...WeekendVisibility_company
					weekendDisplayPerUser
					weekendDisplayShowAlways
					modules {
						moduleType
					}
					allPersons(first: 100000, onlyActive: true, excludeClientUsers: true) {
						edges {
							node {
								id
								fullName
								firstName
								lastName
								email
								profilePictureId
							}
						}
					}
					autojoinDomains(first: 10000) @connection(key: "Company_autojoinDomains") {
						edges {
							node {
								id
								name
							}
						}
					}
					exchangeRates(first: 10000) {
						edges {
							node {
								id
							}
						}
					}
					rateCards(first: 10000) {
						edges {
							node {
								id
								currency
							}
						}
					}
				}
			}
		`,
	})
);
