import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';
import { BulkSelector, Button, FlexColumn, FlexRow, Heading, IconTooltip, SearchField, Table, useTableFunctions, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import { REPORT_COLUMNS } from './Constants';
import { getNotNullishNodes } from '../../shared/util/NotNullPredicate';
import { deleteReport } from './actions/DeleteReport';
import { renameReport } from './actions/RenameReport';
import { profilePicRectSrc } from '../../../directApi';
import { createReport } from './actions/CreateReport';
import { shareReport } from './actions/ShareReport';
import { hasPermission } from '../../shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../Permissions';
import { bulkDeleteReports } from './actions/BulkDeleteReport';
import Util from '../../shared/util/util';
import { openReport } from './actions/OpenReport';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import ReportsPageChangeViewButton from './ReportsPageChangeViewButton';
import { toggleFavoriteReport } from './actions/ToggleFavoriteReport';
import { duplicateReport } from './actions/DuplicateReport';
import { mapReportToRow } from './ReportRowMapper';
const GROUP_BY_LOCALSTORAGE_KEY = 'report-page-group-by';
const Toolbar = ({ children }) => React.createElement(FlexRow, null, children);
Toolbar.Left = ({ children }) => React.createElement(FlexRow, { gap: 's' }, children);
Toolbar.Right = ({ children }) => (React.createElement(FlexRow, { gap: 's', justifyContent: 'end' }, children));
const PageWrapper = Styled.div `
  padding: 58px;
`;
const ReportsPage = ({ viewer }) => {
    var _a;
    const intl = useIntl();
    const history = useHistory();
    const { formatMessage } = intl;
    const scrollRef = useRef(null);
    const [tableRef, table] = useTableFunctions();
    const [search, setSearch] = useState('');
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    const companyId = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.id;
    const initialSorting = [{ id: REPORT_COLUMNS.EDITED, desc: true }];
    const [groupings, setGroupings] = useState(() => JSON.parse(Util.localStorageGetItem(GROUP_BY_LOCALSTORAGE_KEY) || JSON.stringify([])));
    const groupingColumnHeader = groupings.length
        ? formatMessage({ id: 'report.grouping' }) +
            ': ' +
            groupings.map(grouping => formatMessage({ id: 'report.column.' + grouping })).join(' / ')
        : 'Name';
    const onGroupingsChange = (groupings) => {
        Util.localStorageSetItem(GROUP_BY_LOCALSTORAGE_KEY, JSON.stringify(groupings));
        setGroupings(groupings);
        const groupingObj = groupings.reduce((result, item, index) => {
            result[`level_${index + 1}`] = item;
            return result;
        }, {});
        trackEvent('Group By', 'Changed', Object.assign({ groupBy: groupings }, groupingObj));
    };
    const reports = useMemo(() => {
        var _a, _b;
        return getNotNullishNodes((_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.allSavedReports) === null || _b === void 0 ? void 0 : _b.edges).map(report => mapReportToRow(report, viewer.actualPersonId));
    }, [viewer]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const onSelectionChange = useCallback((rows) => setSelectedRowIds(rows.map(row => row.id)), []);
    const selectedRows = useMemo(() => reports.filter(report => selectedRowIds.includes(report.id)), [selectedRowIds, reports]);
    return (React.createElement(CustomScrollDiv, { setScrollbarsRef: node => {
            scrollRef.current = node;
        } },
        React.createElement(PageWrapper, null,
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(Toolbar, null,
                    React.createElement(Toolbar.Left, null,
                        React.createElement(Heading, { size: '3' },
                            React.createElement(FormattedMessage, { id: "header.reports" }))),
                    React.createElement(Toolbar.Right, null,
                        React.createElement(SearchField, { value: search, onChange: setSearch }),
                        React.createElement(ReportsPageChangeViewButton, { groupings: groupings, onGroupingsChange: onGroupingsChange }),
                        React.createElement(Button, { onClick: () => createReport(history) },
                            React.createElement(FormattedMessage, { id: "reports.create-report" })))),
                React.createElement(Heading, { size: '4' },
                    "Created (",
                    reports.length,
                    ")"),
                React.createElement(Table, { "data-cy": 'reports-table', data: reports, searchValue: search, grouping: groupings, width: '100%', scrollRef: scrollRef, initialSorting: initialSorting, stickyHeader: true, tableRef: tableRef, onSelectionChange: isAdmin ? onSelectionChange : undefined, getRowId: (row) => row.id, autoResetExpanded: false },
                    React.createElement(Table.GroupingColumn, { header: groupingColumnHeader, defaultColumnKey: 'name', allowSorting: true },
                        React.createElement(Table.TextColumn, { id: REPORT_COLUMNS.NAME, accessorKey: 'name', "data-cy": 'report-name', header: formatMessage({ id: 'report.column.name' }), allowSorting: true, ellipsis: true, icon: row => (React.createElement(FlexRow, null,
                                row.isShared ? (React.createElement(IconTooltip, { icon: 'shared' }, formatMessage({ id: 'report.shared_with_externals' }))) : null,
                                !row.privateAccess ? (React.createElement(IconTooltip, { icon: 'internalTime' }, formatMessage({ id: 'report.company_access' }))) : null)), alignIconRight: true, onClick: (row) => () => openReport(row, history), width: '55%' }),
                        React.createElement(Table.TextColumn, { id: REPORT_COLUMNS.ACCESS, accessorKey: 'access', "data-cy": 'report-access', header: formatMessage({ id: 'report.column.privateAccess' }), width: '10%' })),
                    React.createElement(Table.TextColumn, { id: REPORT_COLUMNS.TYPE, accessorKey: 'type', "data-cy": 'report-type', header: formatMessage({ id: 'report.column.type' }), allowSorting: true, ellipsis: true, width: '10%' }),
                    React.createElement(Table.DateColumn, { id: REPORT_COLUMNS.EDITED, accessorKey: 'updatedAt', "data-cy": 'report-edited', header: formatMessage({ id: 'common.edited' }), allowSorting: true, width: '10%' }),
                    React.createElement(Table.AvatarColumn, { id: REPORT_COLUMNS.CREATED_BY, accessorKey: 'person.fullName', image: row => { var _a, _b; return ((_a = row.person) === null || _a === void 0 ? void 0 : _a.profilePictureId) ? profilePicRectSrc((_b = row.person) === null || _b === void 0 ? void 0 : _b.profilePictureId) : undefined; }, "data-cy": 'report-createdBy', header: formatMessage({ id: 'report.column.createdBy' }), allowSorting: true, width: '10%' }),
                    React.createElement(Table.IconColumn, { id: REPORT_COLUMNS.FAVORITES, accessorKey: 'favorite', "data-cy": 'report-favorites', header: formatMessage({ id: 'report.column.favorites' }), align: Table.Align.right, allowSorting: true, onClick: toggleFavoriteReport, icon: (value) => value
                            ? {
                                icon: 'starFill',
                                color: 'warning',
                            }
                            : {
                                icon: 'star',
                            }, width: '5%' }),
                    React.createElement(Table.ActionColumn, { header: '' },
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-duplicate', onClick: row => duplicateReport(row, companyId, intl) },
                            React.createElement(FormattedMessage, { id: 'common.duplicate' })),
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-share', onClick: row => shareReport(row), hidden: row => !row.reportService || !row.isReportOwner },
                            React.createElement(FormattedMessage, { id: 'share_insight.button.share' })),
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-rename', onClick: row => renameReport(row, intl), hidden: row => !row.isReportOwner && !isAdmin },
                            React.createElement(FormattedMessage, { id: 'common.rename' })),
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-delete', onClick: row => deleteReport(row, companyId, intl), hidden: row => !row.isReportOwner && !isAdmin },
                            React.createElement(FormattedMessage, { id: 'common.delete' })))))),
        React.createElement(BulkSelector, { label: formatMessage({ id: 'report.bulk_selector_label' }, { count: selectedRows.length }), onClear: table.clearSelections, show: selectedRows.length > 0, "data-cy": 'bulk-selector' },
            React.createElement(BulkSelector.MainAction, { onClick: () => console.log('DEBUG bulkShare'), "data-cy": 'bulk-share-reports' }, formatMessage({ id: 'share_insight.button.share' })),
            React.createElement(BulkSelector.MainAction, { onClick: () => bulkDeleteReports(selectedRows, companyId, intl, () => {
                    table.clearSelections();
                }), "data-cy": 'bulk-delete-reports' }, formatMessage({ id: 'common.delete' })))));
};
export default createRefetchContainer(ReportsPage, {
    viewer: graphql `
			fragment ReportsPage_viewer on Viewer {
				id
				actualPersonId
				availableFeatureFlags {
					key
				}
				company {
					id
					modules {
						moduleType
					}
					allSavedReports(first: 100000) @connection(key: "Company_allSavedReports") {
						edges {
							node {
								id
								name
								type
								person {
									id
									fullName
									email
									profilePictureId
								}
								reportService
								shares(first: 100) @connection(key: "Report_shares", filters: [""]) {
									edges {
										node {
											id
											user {
												id
												email
											}
										}
									}
								}
								updatedAt
								privateAccess
								favorite
							}
						}
					}
				}
			}
		`,
}, graphql `
		query ReportsPageRefetchQuery {
			viewer {
				component(name: "reports_page")
				...ReportsPage_viewer
			}
		}
	`);
