import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation duplicatePhaseMutation($input: DuplicatePhaseInput!) {
		duplicatePhase(input: $input) {
			project {
				tasks(first: 100000, simpleResponse: true) @connection(key: "Project_tasks", filters: []) {
					edges {
						node {
							id
							readOnly {
								isReadOnly
							}
							userCanDeleteTask
							userCantDeleteTaskReason
							hasInvoicedTime
							hasLockedTime
							hasTimeRegistrations
							approved
							name
							description
							sortOrder
							done
							billable
							blocked
							progress
							bug
							latestUiUpdateAt
							startFrom
							deadlineFrom
							startYear
							startMonth
							startDay
							deadlineDay
							deadlineMonth
							deadlineYear
							estimateForecast
							estimateForecastPrice
							currentPrice
							allTotalTimeAndExpensesAtCompletion
							companyTaskId
							timeLeft
							deadlineFrom
							canStart
							canBeSetToDone
							approved
							highPriority
							hasDependency
							jiraId
							vstsId
							isUnit4Task
							subTaskCount
							parentTaskId
							hasChildren
							favoured
							owner {
								id
							}
							phase {
								id
								name
								startYear
								startMonth
								startDay
								deadlineDay
								deadlineMonth
								deadlineYear
							}
							role {
								id
								name
							}
							sprint {
								id
								name
								startYear
								startMonth
								startDay
								endYear
								endMonth
								endDay
							}
							statusColumnV2 {
								id
								name
								category
							}
							project {
								id
								billable
								estimationUnit
								minutesPerEstimationPoint
								projectEndYear
								projectEndMonth
								projectEndDay
								name
								status
								isJiraProject
								vstsProject
								vstsAccount
								vstsTwoWaySync
								projectColor
								companyProjectId
								harvestProject {
									id
								}
								unit4Project {
									id
									activitiesEnabled
								}
							}
							taskLabels {
								id
								label {
									id
									name
									color
								}
							}
							timeRegistrations(first: 10000) @connection(key: "Task_timeRegistrations", filters: []) {
								edges {
									node {
										id
										minutesRegistered
										price
										invoiced
										year
										month
										day
										retainerConflictHandled
										person {
											id
											role {
												id
											}
										}
									}
								}
							}
							assignedPersons {
								id
								firstName
								lastName
								fullName
								active
								email
								profilePictureId
								profilePictureDefaultId
							}
							thisTaskDependsOn(first: 1000) {
								edges {
									node {
										id
										type
										thisDependsOnTask {
											id
											name
											deadlineYear
											deadlineMonth
											deadlineDay
											startYear
											startMonth
											startDay
										}
									}
								}
							}
						}
					}
				}
			}
			phase {
				node {
					id
					name
					startYear
					startMonth
					startDay
					startFrom
					deadlineDay
					deadlineMonth
					deadlineYear
					deadlineFrom
					phasePersons(first: 10000) {
						edges {
							node {
								id
								availableMinutes
								scheduledMinutes
								person {
									id
								}
							}
						}
					}
					baselineTargetPrice
					baselineTargetMinutes
					phaseBaselineRoles {
						edges {
							node {
								role {
									id
								}
								baselineMinutes
								baselinePrice
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_phases',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'phase',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Phase Duplicated');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
