import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {
	DeprecatedButton as Button,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedInput as Input,
	DeprecatedMainContainer as MainContainer,
	DeprecatedPageContainer as PageContainer,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {withSimpleSocketHandling} from '../../../../socket/withSimpleSocketHandling';
import ProjectTable from '../../../project-tab/programs/program-overview-page/ProjectTable';
import GroupingDropdown from '../../../shared/components/dropdowns/GroupingDropdown';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {PROJECT_STATUS, SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';
import Util from '../../../shared/util/util';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import Checkbox from '../../../../components/inputs/checkbox';

const BASE_PREF_KEY = 'project-list-page';
const LOCAL_GROUPING_KEY = `${BASE_PREF_KEY}-grouping`;

const ProjectListPage = ({viewer, setSocketConfig}) => {
	useEffect(() => {
		document.title = 'My projects - Forecast';
	});
	useTrackPage('Project List');

	const {formatMessage} = useIntl();

	const [searchValue, setSearchValue] = useState('');
	const [grouping, setGrouping] = useState(Util.localStorageGetItemWithDefault(LOCAL_GROUPING_KEY, ''));
	const [showAllProjects, setShowAllProjects] = useState(false);

	const updateGrouping = nextGrouping => {
		trackEvent('Project List', 'Grouped By', {groupingBefore: grouping, groupingAfter: nextGrouping});
		Util.localStorageSetItem(LOCAL_GROUPING_KEY, nextGrouping);
		setGrouping(nextGrouping);
	};

	const projects = viewer?.psProjects.edges.map(project => project.node);

	const visibleProjects = showAllProjects
		? projects
		: projects.filter(project => project.stage !== PROJECT_STATUS.DONE && project.stage !== PROJECT_STATUS.HALTED);

	const connectedProjects = viewer?.projectGroups.edges.map(project => project.node);

	const projectsIds = projects.map(project => Util.getUUIdFromBase64String(project.id));
	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.UPDATE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.DELETE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.CREATE,
		},
	];
	setSocketConfig(socketEvents);

	const openCreateProjectModal = () =>
		showModal({
			type: MODAL_TYPE.CREATE_PROJECT,
		});

	return (
		<PageContainer>
			<MainContainer>
				<FlexColumn gap={'l'}>
					<Text type={'heading'} variant={'xl'}>
						{formatMessage({id: 'common.projects'})}
					</Text>
					<FlexRow justifyContent={'space-between'} gap={'s'}>
						<FlexItem>
							<FlexRow gap={'s'}>
								<FlexItem>
									<Input
										icon={'search'}
										placeholder={formatMessage(
											{id: 'search.search_entity'},
											{entity: formatMessage({id: 'common.projects'}).toLowerCase()}
										)}
										onChange={e => setSearchValue(e.target.value)}
										onBlur={() => trackEvent('Project List', 'Searched', {searchString: searchValue})}
									/>
								</FlexItem>
								<FlexItem>
									{useMemo(
										() => (
											<GroupingDropdown
												onSelectGroup={updateGrouping}
												initialGroup={grouping}
												cy={'project-grouping-dropdown'}
											/>
										),
										[grouping]
									)}
								</FlexItem>
								<FlexItem>
									<FlexRow gap={'s'}>
										<Checkbox
											dataCy={'show-done-halted-projects-checkbox'}
											checked={showAllProjects}
											onChange={() => setShowAllProjects(prevState => !prevState)}
										></Checkbox>
										<Text>
											{formatMessage(
												{id: 'common.show.done-halted'},
												{entity: formatMessage({id: 'common.projects'})}
											)}
										</Text>
									</FlexRow>
								</FlexItem>
							</FlexRow>
						</FlexItem>
						<FlexItem>
							<Button type={'creative'} onClick={openCreateProjectModal}>
								{formatMessage(
									{id: 'common.action.create_entity'},
									{entity: formatMessage({id: 'common.project'})}
								)}
							</Button>
						</FlexItem>
					</FlexRow>
				</FlexColumn>
				<ProjectTable
					projects={visibleProjects}
					connectedProjects={connectedProjects}
					searchValue={searchValue}
					noHeader={true}
					grouping={grouping}
					prefKey={BASE_PREF_KEY}
				/>
			</MainContainer>
		</PageContainer>
	);
};

ProjectListPage.propTypes = {};

ProjectListPage.defaultProps = {};

const ProjectListPageQuery = graphql`
	query ProjectListPage_Query {
		viewer {
			actualPersonId
			component(name: "project_list_page")
			...ProjectListPage_viewer
		}
	}
`;

export {ProjectListPageQuery};

export default withSimpleSocketHandling(
	createFragmentContainer(ProjectListPage, {
		viewer: graphql`
			fragment ProjectListPage_viewer on Viewer {
				id
				backendId
				actualPersonId
				projectGroups(first: 1000000) @connection(key: "Viewer_projectGroups", filters: []) {
					edges {
						node {
							id
							companyProjectGroupId
							name
							color
							prefix
						}
					}
				}
				psProjects(first: 100000, filter: "NONE") @connection(key: "Viewer_psProjects", filters: []) {
					edges {
						node {
							id
							serviceCompanyId
							legacyProjectAsSingleProject {
								id
								customProjectId
							}
							companyProjectId
							name
							description
							prefix
							color
							startDate
							endDate
							stage
							clients {
								edges {
									node {
										id
										name
										logoId
									}
								}
							}
							status {
								edges {
									node {
										id
										description
										color
										addedAt
									}
								}
							}
							currentProjectStatus {
								id
								color
								description
								person {
									id
									firstName
									lastName
									profilePictureId
								}
							}
							userRelationTypes
							settings(names: ["connectedProjectId", "budgetType"]) {
								edges {
									node {
										id
										name
										value
									}
								}
							}
						}
					}
				}
				company {
					id
				}
			}
		`,
	})
);
