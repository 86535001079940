import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {Button, BUTTON_SIZE, BUTTON_COLOR, BUTTON_STYLE} from '../../../../../components/new-ui/ButtonV2';
import {Dropdown} from './bulk_select_popup.styled';
import {VARIANT} from '../../../../../containers/modal/project-automate/project_automate_modal';

export const AIBulkButton = ({viewer, intl, isButtonSelected, listOptions, showAutomateModal, additionalAutomateOptions}) => {
	const aiOptions = [];

	if (
		viewer.company.availableMLModels.labelModel &&
		viewer.availableFeatureFlags.some(flag => flag.key === 'use_suggested_labels_precision')
	) {
		aiOptions.push({
			label: intl.formatMessage({id: 'bulk_card_update_modal.add_labels'}),
			callback: showAutomateModal.bind(this, VARIANT.LABELS),
		});
	}
	if (viewer.company.availableMLModels.lowHighModel) {
		aiOptions.push({
			label: intl.formatMessage({id: 'common.add_estimates_high_low'}),
			callback: showAutomateModal.bind(this, VARIANT.ESTIMATES),
		});
	}
	if (viewer.company.availableMLModels.roleModel) {
		aiOptions.push({
			label: intl.formatMessage({id: 'common.assign_roles'}),
			callback: showAutomateModal.bind(this, VARIANT.ROLES),
		});
	}
	if (viewer.company.availableMLModels.assigneeModel) {
		aiOptions.push({
			label: intl.formatMessage({id: 'common.assign_people'}),
			callback: showAutomateModal.bind(this, VARIANT.ASSIGNEES),
		});
	}

	if (additionalAutomateOptions) {
		aiOptions.push(...additionalAutomateOptions);
	}

	const disabled =
		!viewer.company.availableMLModels.labelModel &&
		!viewer.company.availableMLModels.lowHighModel &&
		!viewer.company.availableMLModels.roleModel &&
		!viewer.company.availableMLModels.assigneeModel;

	return (
		<>
			{isButtonSelected && <Dropdown>{listOptions(aiOptions)}</Dropdown>}
			<Button
				id={'automate'}
				disabled={disabled}
				content={intl.formatMessage({id: 'common.automate'})}
				size={BUTTON_SIZE.STANDARD}
				buttonColor={isButtonSelected && !disabled ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.VERYLIGHTGREY}
				buttonStyle={isButtonSelected && !disabled ? BUTTON_STYLE.FILLED : BUTTON_STYLE.OUTLINE}
				userpilot={'bulk-automate-button'}
				cy={'bulk-automate-button'}
			/>
		</>
	);
};

const AIBulkButtonQuery = graphql`
	query AIBulkButton_Query {
		viewer {
			actualPersonId
			component(name: "ai_bulk_button")
			...AIBulkButton_viewer
		}
	}
`;

export {AIBulkButtonQuery};

export default injectIntl(
	createFragmentContainer(AIBulkButton, {
		viewer: graphql`
			fragment AIBulkButton_viewer on Viewer {
				availableFeatureFlags {
					key
				}
				company {
					availableMLModels {
						lowHighModel
						roleModel
						assigneeModel
						labelModel
					}
				}
			}
		`,
	})
);
