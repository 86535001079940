import { createToast } from '../../../shared/components/toasts/another-toast/toaster';
import DuplicateSavedReportMutation from '../../../../mutations/duplicate_saved_report_mutation';
import Util from '../../../shared/util/util';
export const duplicateReport = (report, companyId, intl) => {
    const onSuccess = () => {
        createToast({ duration: 5000, message: intl.formatMessage({ id: 'report.has-been-duplicated' }, { name: report.name }) });
    };
    Util.CommitMutation(DuplicateSavedReportMutation, {
        id: report.id,
        reportService: report.reportService,
        companyId: companyId,
    }, onSuccess);
};
