/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GenericSavedReportConductor_savedReport$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type UpdateSavedReportInput = {|
  id: string,
  name?: ?string,
  eyeApplied?: ?string,
  startDate?: ?string,
  endDate?: ?string,
  periodType?: ?string,
  periodDate?: ?string,
  groupingOne?: ?string,
  groupingTwo?: ?string,
  groupingThree?: ?string,
  groupingFour?: ?string,
  filterApplied?: ?string,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  reportService?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type updateSavedReportMutationVariables = {|
  input: UpdateSavedReportInput
|};
export type updateSavedReportMutationResponse = {|
  +updateSavedReport: ?{|
    +savedReport: ?{|
      +$fragmentRefs: GenericSavedReportConductor_savedReport$ref
    |}
  |}
|};
export type updateSavedReportMutation = {|
  variables: updateSavedReportMutationVariables,
  response: updateSavedReportMutationResponse,
|};
*/


/*
mutation updateSavedReportMutation(
  $input: UpdateSavedReportInput!
) {
  updateSavedReport(input: $input) {
    savedReport {
      ...GenericSavedReportConductor_savedReport
      id
    }
  }
}

fragment GenericSavedReportConductor_savedReport on SavedReportType {
  id
  updatedAt
  updatedBy {
    id
    fullName
  }
  type
  name
  person {
    id
    active
    email
    fullName
    profilePictureId
  }
  groupingOne
  groupingTwo
  groupingThree
  groupingFour
  eyeApplied
  filterApplied
  filters {
    field
    value
    operator
    id
  }
  startDate
  endDate
  periodType
  periodDate
  reportService
  shares(first: 100) {
    edges {
      node {
        id
        user {
          id
          email
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  privateAccess
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSavedReportInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "updatedBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    (v8/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingOne",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingTwo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingThree",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupingFour",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eyeApplied",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterApplied",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "field",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operator",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodType",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportService",
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReportShareTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportShare",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportShareUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateAccess",
  "storageKey": null
},
v26 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSavedReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSavedReportPayload",
        "kind": "LinkedField",
        "name": "updateSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportType",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "GenericSavedReportConductor_savedReport",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportFilterType",
                    "kind": "LinkedField",
                    "name": "filters",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": "shares",
                    "args": null,
                    "concreteType": "ReportShareTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Report_shares_connection",
                    "plural": false,
                    "selections": (v24/*: any*/),
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSavedReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSavedReportPayload",
        "kind": "LinkedField",
        "name": "updateSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportType",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportFilterType",
                "kind": "LinkedField",
                "name": "filters",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": (v26/*: any*/),
                "concreteType": "ReportShareTypeConnection",
                "kind": "LinkedField",
                "name": "shares",
                "plural": false,
                "selections": (v24/*: any*/),
                "storageKey": "shares(first:100)"
              },
              {
                "alias": null,
                "args": (v26/*: any*/),
                "filters": [
                  ""
                ],
                "handle": "connection",
                "key": "Report_shares",
                "kind": "LinkedHandle",
                "name": "shares"
              },
              (v25/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateSavedReportMutation",
    "operationKind": "mutation",
    "text": "mutation updateSavedReportMutation(\n  $input: UpdateSavedReportInput!\n) {\n  updateSavedReport(input: $input) {\n    savedReport {\n      ...GenericSavedReportConductor_savedReport\n      id\n    }\n  }\n}\n\nfragment GenericSavedReportConductor_savedReport on SavedReportType {\n  id\n  updatedAt\n  updatedBy {\n    id\n    fullName\n  }\n  type\n  name\n  person {\n    id\n    active\n    email\n    fullName\n    profilePictureId\n  }\n  groupingOne\n  groupingTwo\n  groupingThree\n  groupingFour\n  eyeApplied\n  filterApplied\n  filters {\n    field\n    value\n    operator\n    id\n  }\n  startDate\n  endDate\n  periodType\n  periodDate\n  reportService\n  shares(first: 100) {\n    edges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  privateAccess\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7dcda2c5135b74d4dc483c9a19acca2';

module.exports = node;
