import {useIntl} from 'react-intl';
import {
	DeprecatedDropdown as Dropdown,
	DeprecatedDropdownMenuText as DropdownMenuText,
	FLEX_ALIGN_ITEMS,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedNoticeBanner as NoticeBanner,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import React from 'react';
import RadioButton from '../../../../../../../../components/new-ui/radio_button';
import {FeatureHeaderWrapper, FeatureWrapper, MissingBoard, TextWrapper} from '../JiraProjectSyncModal.styled';
import {DirectionalIcon} from '../../../../../../../../images/directionalIcon';

export const SettingsSection = ({setters, states, deepGetJiraBoards}) => {
	const {formatMessage} = useIntl();

	const toForecastPhaseOptions = [
		{
			text: formatMessage({id: 'common.versions'}),
			value: true,
		},
		{
			text: formatMessage({id: 'integrations.jira.epics'}),
			value: false,
		},
	];

	const jiraEpicsToOptions = [
		{
			text: formatMessage({id: 'integrations.jira.epics'}),
			value: true,
		},
		{
			text: formatMessage({id: 'common.tasks'}),
			value: false,
		},
	];

	const jiraChildIssuesOptions = [
		{
			text: formatMessage({id: 'integrations.jira.task_subtasks'}),
			value: false,
		},
		{
			text: formatMessage({id: 'integrations.jira.todos'}),
			value: true,
		},
	];

	const handleToForecastPhaseChange = selection => {
		if (!selection) {
			// Syncing Jira Epics to Forecast Phases therefor Jira Epics can't be Forecast Epics
			setters.setSyncEpicsToEpics(false);
		}
		setters.setSyncVersionsToPhases(selection);
	};

	return (
		<FlexColumn gap={'xl'}>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'l'}>
							{formatMessage({id: 'sync_jira_project_modal.sync_project_settings'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<Text>{formatMessage({id: 'sync_jira_project_modal.choose_which_info'})}</Text>
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'sync_jira_project_modal.sync_sprints'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<FlexRow gap={'s'}>
							<FlexItem>
								<RadioButton
									value={true}
									stateValue={states.syncJiraSprints}
									onSelected={setters.setSyncJiraSprints}
								/>
							</FlexItem>
							<FlexItem>
								<Text>{formatMessage({id: 'common.yes'})}</Text>
							</FlexItem>
						</FlexRow>
					</FlexItem>
					<FlexItem>
						<FlexRow gap={'s'}>
							<FlexItem>
								<RadioButton
									value={false}
									stateValue={states.syncJiraSprints}
									onSelected={setters.setSyncJiraSprints}
								/>
							</FlexItem>
							<FlexItem>
								<Text>{formatMessage({id: 'common.no'})}</Text>
							</FlexItem>
						</FlexRow>
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			{states.syncJiraSprints && (
				<FlexItem>
					<FlexColumn gap={'xs'}>
						<FlexItem>
							<Text type={'heading'} variant={'s'}>
								{formatMessage({id: 'sync_jira_project_modal.jira_board'})}
							</Text>
						</FlexItem>
						<FlexItem>
							<TextWrapper>
								<Text type={'utility'} variant={'overline'}>
									{formatMessage({id: 'sync_jira_project_modal.select_jira_board'})}
								</Text>
							</TextWrapper>
						</FlexItem>
						<FlexItem>
							<Dropdown
								initialValue={states.jiraBoardOptions.find(board => board.value === states.selectedJiraBoard)}
								onSelect={selection => setters.setSelectedJiraBoard(selection)}
							>
								<DropdownMenuText values={states.jiraBoardOptions ? states.jiraBoardOptions : []} />
							</Dropdown>
						</FlexItem>
						{!states.boardDeepFetched && (
							<FlexItem>
								<div
									onClick={() =>
										deepGetJiraBoards(true, states.selectedJiraProject, {
											setSelectedJiraBoard: setters.setSelectedJiraBoard,
											setJiraBoardOptions: setters.setJiraBoardOptions,
											setBoardsDeepFetched: setters.setBoardsDeepFetched,
										})
									}
								>
									<MissingBoard>{formatMessage({id: 'sync_jira_project_modal.missing_board'})}</MissingBoard>
								</div>
							</FlexItem>
						)}
					</FlexColumn>
				</FlexItem>
			)}
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'sync_jira_project_modal.phase.label_2'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<TextWrapper>
							<Text type={'utility'} variant={'overline'}>
								{formatMessage({id: 'sync_jira_project_modal.phase.text'})}
							</Text>
						</TextWrapper>
					</FlexItem>
					<FlexItem>
						<FlexColumn gap={'s'}>
							<FeatureWrapper>
								<FlexRow gap={'s'}>
									<FlexItem flex="0 1 47%">
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'sync_jira_project_modal.feature.jira'})}
										</Text>
									</FlexItem>
									<FlexItem flex="0 1 6%" />
									<FlexItem flex="0 1 47%">
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'sync_jira_project_modal.feature.forecast'})}
										</Text>
									</FlexItem>
								</FlexRow>
							</FeatureWrapper>
							<FeatureWrapper>
								<FlexRow gap={'s'}>
									<FlexItem flex="0 1 47%">
										<Dropdown
											initialValue={toForecastPhaseOptions.find(
												option => option.value === states.syncVersionsToPhases
											)}
											onSelect={selection => handleToForecastPhaseChange(selection)}
										>
											<DropdownMenuText values={toForecastPhaseOptions} />
										</Dropdown>
									</FlexItem>
									<FlexItem flex="0 1 6%" alignSelf={FLEX_ALIGN_ITEMS.CENTER}>
										<DirectionalIcon />
									</FlexItem>
									<FlexItem flex="0 1 47%">
										<Text type={'base'} variant={'regular'}>
											{formatMessage({id: 'common.phases'})}
										</Text>
									</FlexItem>
								</FlexRow>
							</FeatureWrapper>
						</FlexColumn>
					</FlexItem>
					{!states.syncVersionsToPhases && (
						<FlexItem>
							<NoticeBanner type={'warning'}>
								<Text>{formatMessage({id: 'sync_jira_project_modal.warning.time_reg_epics'})}</Text>
							</NoticeBanner>
						</FlexItem>
					)}
				</FlexColumn>
			</FlexItem>
			{states.syncVersionsToPhases && (
				<FlexItem>
					<FlexColumn gap={'xs'}>
						<FlexItem>
							<Text type={'heading'} variant={'s'}>
								{formatMessage({id: 'sync_jira_project_modal.epics.label'})}
							</Text>
						</FlexItem>
						<FlexItem>
							<TextWrapper>
								<Text type={'utility'} variant={'overline'}>
									{formatMessage({id: 'sync_jira_project_modal.epics.text'})}
								</Text>
							</TextWrapper>
						</FlexItem>
						<FlexItem>
							<FlexColumn gap={'s'}>
								<FeatureWrapper>
									<FlexRow gap={'s'}>
										<FlexItem flex="0 1 47%">
											<Text type={'heading'} variant={'s'}>
												{formatMessage({id: 'sync_jira_project_modal.feature.jira'})}
											</Text>
										</FlexItem>
										<FlexItem flex="0 1 6%" />
										<FlexItem flex="0 1 47%">
											<Text type={'heading'} variant={'s'}>
												{formatMessage({id: 'sync_jira_project_modal.feature.forecast'})}
											</Text>
										</FlexItem>
									</FlexRow>
								</FeatureWrapper>
								<FeatureWrapper>
									<FlexRow gap={'s'}>
										<FlexItem flex="0 1 47%">
											<Text type={'base'} variant={'regular'}>
												{formatMessage({id: 'sync_jira_project_modal.epics'})}
											</Text>
										</FlexItem>
										<FlexItem flex="0 1 6%" alignSelf={FLEX_ALIGN_ITEMS.CENTER}>
											<DirectionalIcon />
										</FlexItem>
										<FlexItem flex="0 1 47%">
											<Dropdown
												initialValue={jiraEpicsToOptions.find(
													option => option.value === states.syncEpicsToEpics
												)}
												onSelect={selection => setters.setSyncEpicsToEpics(selection)}
											>
												<DropdownMenuText values={jiraEpicsToOptions} />
											</Dropdown>
										</FlexItem>
									</FlexRow>
								</FeatureWrapper>
							</FlexColumn>
						</FlexItem>
						{states.syncEpicsToEpics ? (
							<FlexItem>
								<NoticeBanner type={'warning'}>
									<Text>{formatMessage({id: 'sync_jira_project_modal.warning.epics_in_dev'})}</Text>
								</NoticeBanner>
							</FlexItem>
						) : (
							<FlexItem>
								<NoticeBanner type={'warning'}>
									<Text>{formatMessage({id: 'sync_jira_project_modal.warning.epics_to_tasks'})}</Text>
								</NoticeBanner>
							</FlexItem>
						)}
					</FlexColumn>
				</FlexItem>
			)}
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<FeatureHeaderWrapper>
							<Text type={'heading'} variant={'s'}>
								{formatMessage({id: 'sync_jira_project_modal.tasks_to_tasks'})}
							</Text>
						</FeatureHeaderWrapper>
					</FlexItem>
					<FlexItem>
						<FlexColumn gap={'s'}>
							<FeatureWrapper>
								<FlexRow gap={'s'}>
									<FlexItem flex="0 1 47%">
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'sync_jira_project_modal.feature.jira'})}
										</Text>
									</FlexItem>
									<FlexItem flex="0 1 6%" />
									<FlexItem flex="0 1 47%">
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'sync_jira_project_modal.feature.forecast'})}
										</Text>
									</FlexItem>
								</FlexRow>
							</FeatureWrapper>
							<FeatureWrapper>
								<FlexRow gap={'s'}>
									<FlexItem flex="0 1 47%">
										<Text type={'base'} variant={'regular'}>
											{formatMessage({id: 'common.tasks'})}
										</Text>
									</FlexItem>
									<FlexItem flex="0 1 6%" alignSelf={FLEX_ALIGN_ITEMS.CENTER}>
										<DirectionalIcon />
									</FlexItem>
									<FlexItem flex="0 1 47%">
										<Text type={'base'} variant={'regular'}>
											{formatMessage({id: 'common.tasks'})}
										</Text>
									</FlexItem>
								</FlexRow>
							</FeatureWrapper>
						</FlexColumn>
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'sync_jira_project_modal.sync.estimate.label'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<TextWrapper>
							<Text type={'utility'} variant={'overline'}>
								{formatMessage({id: 'sync_jira_project_modal.sync.estimate.text'})}
							</Text>
						</TextWrapper>
					</FlexItem>
					<FlexItem>
						<FlexRow gap={'s'}>
							<FlexItem>
								<RadioButton
									value={false}
									stateValue={states.useStoryPoints}
									onSelected={setters.setUseStoryPoints}
								/>
							</FlexItem>
							<FlexItem>
								<Text>{formatMessage({id: 'sync_jira_project_modal.radio.time_org'})}</Text>
							</FlexItem>
						</FlexRow>
					</FlexItem>
					<FlexItem>
						<FlexRow gap={'s'}>
							<FlexItem>
								<RadioButton
									value={true}
									stateValue={states.useStoryPoints}
									onSelected={setters.setUseStoryPoints}
								/>
							</FlexItem>
							<FlexItem>
								<Text>{formatMessage({id: 'sync_jira_project_modal.radio.story_points'})}</Text>
							</FlexItem>
						</FlexRow>
					</FlexItem>
					{states.useStoryPoints && states.selectedJiraProject && (
						<FlexItem>
							<Dropdown
								initialValue={states.customFieldOptions.find(
									option => option.value === states.selectedCustomField
								)}
								onSelect={selection => setters.setSelectedCustomField(selection)}
							>
								<DropdownMenuText values={states.customFieldOptions} />
							</Dropdown>
						</FlexItem>
					)}
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'sync_jira_project_modal.sync.remaining.label'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<TextWrapper>
							<Text type={'utility'} variant={'overline'}>
								{formatMessage({id: 'sync_jira_project_modal.sync.remaining.label'})}
							</Text>
						</TextWrapper>
					</FlexItem>
					<FlexItem>
						<FlexRow gap={'s'}>
							<FlexItem>
								<RadioButton
									value={true}
									stateValue={states.syncRemainingTime}
									onSelected={setters.setSyncRemainingTime}
								/>
							</FlexItem>
							<FlexItem>
								<Text>{formatMessage({id: 'common.yes'})}</Text>
							</FlexItem>
						</FlexRow>
					</FlexItem>
					<FlexItem>
						<FlexRow gap={'s'}>
							<FlexItem>
								<RadioButton
									value={false}
									stateValue={states.syncRemainingTime}
									onSelected={setters.setSyncRemainingTime}
								/>
							</FlexItem>
							<FlexItem>
								<Text>{formatMessage({id: 'common.no'})}</Text>
							</FlexItem>
						</FlexRow>
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<FeatureHeaderWrapper>
							<Text type={'heading'} variant={'s'}>
								{formatMessage({id: 'sync_jira_project_modal.sync.child.label'})}
							</Text>
						</FeatureHeaderWrapper>
					</FlexItem>
					<FlexItem>
						<FlexColumn gap={'s'}>
							<FeatureWrapper>
								<FlexRow gap={'s'}>
									<FlexItem flex="0 1 47%">
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'sync_jira_project_modal.feature.jira'})}
										</Text>
									</FlexItem>
									<FlexItem flex="0 1 6%" />
									<FlexItem flex="0 1 47%">
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'sync_jira_project_modal.feature.forecast'})}
										</Text>
									</FlexItem>
								</FlexRow>
							</FeatureWrapper>
							<FeatureWrapper>
								<FlexRow gap={'s'}>
									<FlexItem flex="0 1 47%">
										<Text type={'base'} variant={'regular'}>
											{formatMessage({id: 'sync_jira_project_modal.sync.child.type'})}
										</Text>
									</FlexItem>
									<FlexItem flex="0 1 6%" alignSelf={FLEX_ALIGN_ITEMS.CENTER}>
										<DirectionalIcon />
									</FlexItem>
									<FlexItem flex="0 1 47%">
										<Dropdown
											initialValue={jiraChildIssuesOptions.find(
												option => option.value === states.syncJiraChildIssuesToToDos
											)}
											onSelect={selection => setters.setSyncJiraChildIssuesToToDos(selection)}
										>
											<DropdownMenuText values={jiraChildIssuesOptions} />
										</Dropdown>
									</FlexItem>
								</FlexRow>
							</FeatureWrapper>
						</FlexColumn>
					</FlexItem>
					{states.syncJiraChildIssuesToToDos && (
						<FlexItem>
							<NoticeBanner type={'warning'}>
								<Text>{formatMessage({id: 'sync_jira_project_modal.warning.jira_child'})}</Text>
							</NoticeBanner>
						</FlexItem>
					)}
				</FlexColumn>
			</FlexItem>
		</FlexColumn>
	);
};
