import styled from 'styled-components';
export const FlexContainer = styled.div `
	width: 100%;
`;
export const EmailsWrapper = styled.div `
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
export const AlreadySharedContainer = styled.div `
	overflow-y: auto;
	overflow-x: hidden;
`;
export const SharedPersonInfoWrapper = styled.div `
	padding: 8px;
	display: flex;
	align-items: center;
`;
export const CrossIconWrapper = styled.div `
	margin-right: 4px;
	visibility: hidden;
	${SharedPersonInfoWrapper}:hover & {
		visibility: visible;
	}
`;
export const InputHolder = styled.div `
	border: solid 1px #6b6d7b;
	border-radius: 8px;
	padding: 4px;
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 44px;
	max-height: 96px;
	width: 100%;
`;
export const ActualInput = styled.input.attrs({ type: 'text' }) `
	border: 0;
	display: inline-block;
	padding: 8px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0;
	text-align: left;
	flex-grow: 1;

	&:focus {
		outline-width: 0;
	}
`;
export const InputErrorMessage = styled.div `
	margin-left: 8px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0;
	text-align: left;
	color: #e63946;
`;
