const useEpic = epics => {
	const _getTimeRegisteredOnEpic = epic =>
		epic.node.timeRegistrations
			? epic.node.timeRegistrations.edges.reduce((acc, tReg) => acc + tReg.node.minutesRegistered, 0)
			: 0;

	const getTimeEntriesOnEpics = () => epics.reduce((acc, epic) => acc + _getTimeRegisteredOnEpic(epic), 0);

	const _getRemainingOnEpic = epic => (epic.node.timeLeft ? epic.node.timeLeft : 0);

	const getRemainingOnEpics = () => epics.reduce((acc, epic) => acc + _getRemainingOnEpic(epic), 0);

	const _getEstimateOnEpic = epic => (epic.node.estimateForecast ? epic.node.estimateForecast : 0);

	const getEstimateOnEpics = () => epics.reduce((acc, epic) => acc + _getEstimateOnEpic(epic), 0);

	const getVarianceOnEpics = () =>
		getEstimateOnEpics() && getTimeEntriesOnEpics() ? getEstimateOnEpics() - getTimeEntriesOnEpics() : 0;

	return {getTimeEntriesOnEpics, getRemainingOnEpics, getEstimateOnEpics, getVarianceOnEpics};
};

export default useEpic;
