/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompanySavedReportWrapper_viewer$ref = any;
export type CompanySavedReportWrapperQuery_QueryVariables = {|
  savedReportId?: ?string
|};
export type CompanySavedReportWrapperQuery_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: CompanySavedReportWrapper_viewer$ref,
  |}
|};
export type CompanySavedReportWrapperQuery_Query = {|
  variables: CompanySavedReportWrapperQuery_QueryVariables,
  response: CompanySavedReportWrapperQuery_QueryResponse,
|};
*/


/*
query CompanySavedReportWrapperQuery_Query(
  $savedReportId: String
) {
  viewer {
    actualPersonId
    component(name: "company_saved_report_wrapper")
    ...CompanySavedReportWrapper_viewer_3ELs4J
    id
  }
}

fragment CompanySavedReportWrapper_viewer_3ELs4J on Viewer {
  actualPersonId
  company {
    savedReport(id: $savedReportId) {
      ...GenericSavedReportConductor_savedReport
      id
      type
      name
      reportService
    }
    id
  }
}

fragment GenericSavedReportConductor_savedReport on SavedReportType {
  id
  updatedAt
  updatedBy {
    id
    fullName
  }
  type
  name
  person {
    id
    active
    email
    fullName
    profilePictureId
  }
  groupingOne
  groupingTwo
  groupingThree
  groupingFour
  eyeApplied
  filterApplied
  filters {
    field
    value
    operator
    id
  }
  startDate
  endDate
  periodType
  periodDate
  reportService
  shares(first: 100) {
    edges {
      node {
        id
        user {
          id
          email
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  privateAccess
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "savedReportId",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "company_saved_report_wrapper"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"company_saved_report_wrapper\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanySavedReportWrapperQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "savedReportId",
                "variableName": "savedReportId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "CompanySavedReportWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanySavedReportWrapperQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "savedReportId"
                  }
                ],
                "concreteType": "SavedReportType",
                "kind": "LinkedField",
                "name": "savedReport",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "updatedBy",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groupingOne",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groupingTwo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groupingThree",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groupingFour",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eyeApplied",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterApplied",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportFilterType",
                    "kind": "LinkedField",
                    "name": "filters",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "field",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "operator",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportService",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "ReportShareTypeConnection",
                    "kind": "LinkedField",
                    "name": "shares",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportShareTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportShare",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReportShareUser",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "shares(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "filters": [
                      ""
                    ],
                    "handle": "connection",
                    "key": "Report_shares",
                    "kind": "LinkedHandle",
                    "name": "shares"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privateAccess",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CompanySavedReportWrapperQuery_Query",
    "operationKind": "query",
    "text": "query CompanySavedReportWrapperQuery_Query(\n  $savedReportId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"company_saved_report_wrapper\")\n    ...CompanySavedReportWrapper_viewer_3ELs4J\n    id\n  }\n}\n\nfragment CompanySavedReportWrapper_viewer_3ELs4J on Viewer {\n  actualPersonId\n  company {\n    savedReport(id: $savedReportId) {\n      ...GenericSavedReportConductor_savedReport\n      id\n      type\n      name\n      reportService\n    }\n    id\n  }\n}\n\nfragment GenericSavedReportConductor_savedReport on SavedReportType {\n  id\n  updatedAt\n  updatedBy {\n    id\n    fullName\n  }\n  type\n  name\n  person {\n    id\n    active\n    email\n    fullName\n    profilePictureId\n  }\n  groupingOne\n  groupingTwo\n  groupingThree\n  groupingFour\n  eyeApplied\n  filterApplied\n  filters {\n    field\n    value\n    operator\n    id\n  }\n  startDate\n  endDate\n  periodType\n  periodDate\n  reportService\n  shares(first: 100) {\n    edges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  privateAccess\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'db4d85bf941b7991633ab8ba3dc49322';

module.exports = node;
