import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createSavedReportMutation($input: CreateSavedReportInput!) {
		createSavedReport(input: $input) {
			savedReport {
				node {
					id
					type
					name
					person {
						id
						fullName
					}
					groupingOne
					groupingTwo
					groupingThree
					eyeApplied
					filterApplied
					startDate
					endDate
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_savedReports',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'savedReport',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Saved Report Created');
	const cleanedInput = omit(input, ['companyId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
