import {getPaymentStatusText} from '../../../../components/insights/invoicing/InvoicingUtil';
import {hasFeatureFlag} from '../FeatureUtil';
import Util from '../util';
import DataExportFormatter from './DataExportFormatter';

export default class InvoiceFormatter extends DataExportFormatter {
	_columnFormatHook(name) {
		return name
			.split(/(?=[A-Z])/)
			.map(word => word.toLocaleLowerCase())
			.join('_');
	}

	_getFormattedValueHook(column, invoice) {
		const invoiceTotal = invoice.invoicedWithoutTax;
		const invoicePaid = invoice.paidWithoutTax;

		switch (column) {
			case 'invoice_id':
				return invoice.companyInvoiceId;
			case 'invoice_name':
				return invoice.name;
			case 'client':
				return invoice.client ? invoice.client.name : '';
			case 'project_ids':
				const showCustomId = hasFeatureFlag('custom_project_ids');
				if (showCustomId) {
					return invoice.projects.map(project => project.customProjectId).join(',');
				} else {
					return invoice.projects.map(project => project.companyProjectId).join(',');
				}
			case 'project_names':
				return invoice.projects.map(project => project.name).join(',');
			case 'status':
				return invoice.status;
			case 'payment_status':
				return getPaymentStatusText(invoice.paymentStatus, this._intl);
			case 'xero_status':
			case 'quickbooks_status':
			case 'sage_intacct_status':
			case 'economic_status':
				return Util.getInvoiceStatusTranslation(invoice.externalStatus, this._intl);
			case 'reference':
				return invoice.invoiceReference;
			case 'date':
				return this._formatDate(invoice.createdDay, invoice.createdMonth, invoice.createdYear);
			case 'due_date':
				return this._formatDate(invoice.dueDay, invoice.dueMonth, invoice.dueYear);
			case 'invoiced_amount':
				return invoiceTotal;
			case 'paid_amount':
				return invoicePaid;
			case 'unpaid_amount':
				return invoiceTotal - invoicePaid;
			case 'currency':
				return invoice.currency;
			default:
				return null;
		}
	}
}
