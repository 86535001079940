import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation toggleReportFavoriteMutation($input: ToggleReportFavoriteInput!) {
		toggleReportFavorite(input: $input) {
			savedReport {
				node {
					id
					favorite
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const cleanedInput = omit(input, []);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
