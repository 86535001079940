import React from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {
	DropdownLabel,
	DropdownSection,
	DropdownWrapper,
	InfoText,
	InputWrapper,
	Label,
	MainContentWrapper,
	NotificationSectionWrapper,
	PeriodWrapper,
	RadioWrapper,
	RoleDropdownWrapper,
	RoleName,
	RoleRow,
	SettingsCompanyNotificationsPageWrapper,
	SubSection,
	SubSectionHeader,
	SwitchLabel,
} from '../../../containers/settings/settings_company_notifications.styled';
import {CrossIconOld, Dropdown, RadioButton, Switch} from 'web-components';
import Util from '../../shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import UpdateRoleMutation from '../../../mutations/update_role_mutation';
import {RoleDropdown} from '../../shared/components/dropdowns/Role_dropdown';
import {HIDDEN_FEATURES} from '../../../constants';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';

const NotificationSection = ({
	formatMessage,
	checked,
	handleToggle,
	switchLabel,
	infoText,
	handleChangeDay,
	handleChangeHour,
	selectedDay,
	selectedHour,
}) => {
	const days_options = [
		{value: 'monday', label: formatMessage({id: 'common.monday'})},
		{value: 'tuesday', label: formatMessage({id: 'common.tuesday'})},
		{value: 'wednesday', label: formatMessage({id: 'common.wednesday'})},
		{value: 'thursday', label: formatMessage({id: 'common.thursday'})},
		{value: 'friday', label: formatMessage({id: 'common.friday'})},
		{value: 'saturday', label: formatMessage({id: 'common.saturday'})},
		{value: 'sunday', label: formatMessage({id: 'common.sunday'})},
	];

	const hours_options = () => {
		const hours = [];
		for (let i = 0; i < 24; i++) {
			hours.push({value: i, label: i + ':00'});
		}
		return hours;
	};

	return (
		<NotificationSectionWrapper data-cy={'settings-notifications-page'}>
			<InputWrapper>
				<Switch checked={checked} onChange={handleToggle}></Switch>
				<SwitchLabel>{formatMessage({id: switchLabel})}</SwitchLabel>
			</InputWrapper>
			<InfoText>{formatMessage({id: infoText})}</InfoText>
			<DropdownSection>
				<DropdownLabel>{formatMessage({id: 'common.when_colon'})}</DropdownLabel>
				<DropdownWrapper>
					<Dropdown
						disabled={!checked}
						name={formatMessage({id: 'overview_time.day'})}
						selectedItems={[selectedDay]}
						onSelect={day => handleChangeDay(day[0])}
					>
						{days_options.map(option => (
							<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
								{option.label}
							</Dropdown.SingleText>
						))}
					</Dropdown>
				</DropdownWrapper>
			</DropdownSection>
			<DropdownSection>
				<DropdownLabel>{formatMessage({id: 'common.time_colon'})}</DropdownLabel>
				<DropdownWrapper>
					<Dropdown
						disabled={!checked}
						name={formatMessage({id: 'common.hour'})}
						selectedItems={[selectedHour]}
						onSelect={hour => handleChangeHour(hour[0])}
					>
						{hours_options().map(option => (
							<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
								{option.label}
							</Dropdown.SingleText>
						))}
					</Dropdown>
				</DropdownWrapper>
			</DropdownSection>
		</NotificationSectionWrapper>
	);
};

const SettingsCompanyNotifications = ({intl, viewer}) => {
	const {
		timesheetReminderEnabled,
		timesheetReminderDay,
		timesheetReminderHour,
		projectDigestNotificationEnabled,
		projectDigestNotificationDay,
		projectDigestNotificationHour,
		roles,
		useTimeApproval,
		timesheetReminderIncludeToday,
	} = viewer.company;
	const {formatMessage} = intl;

	const roles_not_notified = [],
		roles_notified = [],
		applied_roles = [];

	roles.edges.forEach(role => {
		if (role.node.notifyProjectOverview) {
			roles_notified.push({value: role.node.id, label: role.node.name});
			applied_roles.push(role.node.id);
		} else {
			roles_not_notified.push({value: role.node.id, label: role.node.name});
		}
	});

	const getHeader = () => {
		return (
			<TopHeaderBarWrapper bottomPadding={16}>
				<TopHeaderBar title={formatMessage({id: 'header.notifications'})} content={[]} />
			</TopHeaderBarWrapper>
		);
	};

	const handlesToggleSendTimesheetReminder = () => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			timesheetReminderEnabled: !timesheetReminderEnabled,
		});
	};

	const handleChangeDay = day => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			timesheetReminderDay: day,
		});
	};

	const handleChangeHour = hour => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			timesheetReminderHour: hour,
		});
	};

	const handlesToggleSendProjectDigestReminder = () => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			projectDigestNotificationEnabled: !projectDigestNotificationEnabled,
		});
	};

	const handleChangeProjectDigestDay = day => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			projectDigestNotificationDay: day,
		});
	};

	const handleChangeProjectDigestHour = hour => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			projectDigestNotificationHour: hour,
		});
	};
	const handleChangeIncludeToday = include => {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: viewer.company.id,
			timesheetReminderIncludeToday: include,
		});
	};

	const handleToggleNofifyRole = (roleId, notify) => {
		Util.CommitMutation(UpdateRoleMutation, {
			id: roleId,
			notifyProjectOverview: notify,
		});
	};

	return (
		<SettingsCompanyNotificationsPageWrapper>
			{getHeader()}
			<CustomScrollDiv>
				<MainContentWrapper>
					{!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
						<NotificationSection
							formatMessage={formatMessage}
							checked={timesheetReminderEnabled}
							handleToggle={handlesToggleSendTimesheetReminder}
							handleChangeDay={handleChangeDay}
							handleChangeHour={handleChangeHour}
							switchLabel={'settings_company_notifications.switch_timesheet_reminder'}
							infoText={
								useTimeApproval
									? 'settings_company_notifications.timesheet_reminder_info_approval_on'
									: 'settings_company_notifications.timesheet_reminder_info_approval_off'
							}
							selectedDay={timesheetReminderDay}
							selectedHour={timesheetReminderHour}
						/>
					)}
					{!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
						<SubSection>
							<SubSectionHeader>
								{formatMessage({
									id: useTimeApproval
										? 'settings_company_notifications.period_setting_time_approval'
										: 'settings_company_notifications.period_setting',
								})}
							</SubSectionHeader>
							<PeriodWrapper>
								<RadioWrapper>
									<RadioButton
										checked={!timesheetReminderIncludeToday}
										onClick={() => handleChangeIncludeToday(false)}
									/>
									<Label>
										{formatMessage({id: 'settings_company_notifications.period_setting_no_include_today'})}
									</Label>
								</RadioWrapper>
								<RadioWrapper>
									<RadioButton
										checked={timesheetReminderIncludeToday}
										onClick={() => handleChangeIncludeToday(true)}
									/>
									<Label>
										{formatMessage({id: 'settings_company_notifications.period_setting_include_today'})}
									</Label>
								</RadioWrapper>
							</PeriodWrapper>
						</SubSection>
					)}
					<NotificationSection
						formatMessage={formatMessage}
						checked={projectDigestNotificationEnabled}
						handleToggle={handlesToggleSendProjectDigestReminder}
						handleChangeDay={handleChangeProjectDigestDay}
						handleChangeHour={handleChangeProjectDigestHour}
						switchLabel={'settings_company_notifications.switch_project_digest_reminder'}
						infoText={'settings_company_notifications.project_digest_reminder_info'}
						selectedDay={projectDigestNotificationDay}
						selectedHour={projectDigestNotificationHour}
					></NotificationSection>
					<SubSection>
						<SubSectionHeader>{formatMessage({id: 'settings_company_notifications.recipients'})}</SubSectionHeader>
						<InfoText>{formatMessage({id: 'settings_company_notifications.recipients_info'})}</InfoText>
						<RoleRow>
							<RoleDropdownWrapper>
								<RoleDropdown
									selectedItems={applied_roles}
									onRemove={ids => handleToggleNofifyRole(ids[0], false)}
									onSelect={ids => handleToggleNofifyRole(ids[0], true)}
									isMultiSelect={true}
									roles={roles.edges}
									optionsName={intl.formatMessage({id: 'settings.roles'})}
									selectedGroupName={intl.formatMessage({id: 'common.applied'})}
									name={formatMessage({id: 'settings_company_notifications.select_recipients'})}
									disabled={!projectDigestNotificationEnabled}
								></RoleDropdown>
							</RoleDropdownWrapper>
						</RoleRow>
						{roles_notified.map(role => {
							return (
								<RoleRow hoverEffect>
									<RoleName>{role.label}</RoleName>{' '}
									{projectDigestNotificationEnabled && (
										<CrossIconOld
											color={'#A1A1A1'}
											onClick={() => handleToggleNofifyRole(role.value, false)}
										/>
									)}
								</RoleRow>
							);
						})}
					</SubSection>
				</MainContentWrapper>
			</CustomScrollDiv>
		</SettingsCompanyNotificationsPageWrapper>
	);
};

const SettingsCompanyNotificationsQuery = graphql`
	query settingsCompanyNotifications_Query {
		viewer {
			actualPersonId
			component(name: "settings_notifications")
			...settingsCompanyNotifications_viewer
		}
	}
`;

export {SettingsCompanyNotificationsQuery};

export default injectIntl(
	createFragmentContainer(SettingsCompanyNotifications, {
		viewer: graphql`
			fragment settingsCompanyNotifications_viewer on Viewer {
				id
				company {
					id
					useTimeApproval
					timesheetReminderEnabled
					timesheetReminderDay
					timesheetReminderHour
					timesheetReminderIncludeToday
					projectDigestNotificationEnabled
					projectDigestNotificationDay
					projectDigestNotificationHour
					roles(first: 10000) {
						edges {
							node {
								id
								name
								notifyProjectOverview
							}
						}
					}
				}
			}
		`,
	})
);
