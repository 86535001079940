import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DayCellStyle, Grid, HeaderContainer, HeaderText, RowWrapper, WeekCellStyle } from './TimesheetsCalendar_styled';
import { TIME_PERIOD } from '../timesheets_change_date_view';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { Alert, FlexColumn, FlexRow, Link } from '@forecast-it/design-system';
import { getFirstDayOfWeek, getHolidaysOfTheDay, getReadableSelectedDate, getSelectedDate, getStartDate, getWeekdaysShort, getWeeksOfMonth, LOCALSTORAGE_DISMISS_KEY, revokeTokens, } from './TimesheetsCalendarUtil';
import Moment from 'moment/moment';
import DayCell from './cells/DayCell';
import WeekCell from './cells/WeekCell';
import { openTimeRegModal } from './calendar-events/CalendarEventModalLogic';
import EventContextMenu from './calendar-events/EventContextMenu';
import CompanySetupUtil from '../../../shared/util/CompanySetupUtil';
import { useConvertedEventTracking } from './calendar-events/useConvertedEventTracking';
import { isSameDay } from 'date-fns';
import { AlertType } from '@forecast-it/design-system/dist/forecast-v2/components/data-display/alert/types';
import GoogleCalendarOAuth from '../../../../containers/settings/settingsCalendar/GoogleCalendarOAuth';
import { TRACKING_OBJECTS } from '../../../../tracking/amplitude/constants/TrackingObjects';
const TimesheetsCalendar = ({ timeRegistrations, currentViewingDate, currentViewingMonth, currentPersonViewed, selectDay, timePeriod, calendarEvents, isLoadingCalendarEvents, lockedDate, calendarTokenError, calendarFetchEnabled, }) => {
    const hideWeekend = CompanySetupUtil.weekendsAlwaysHidden();
    const [contextMenuItem, setContextMenuItem] = useState(null);
    const [bounds, setBounds] = useState({ x: null, y: null });
    const { markEventAsConverted, isEventConverted } = useConvertedEventTracking();
    const showContextMenu = useCallback((e, calendarEvent) => {
        setContextMenuItem({ id: calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.id, calendarEvent });
        setBounds({ x: e.pageX, y: e.pageY });
    }, [calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.length]);
    const [calendarInfoDismissCount, setCalendarInfoDismissCount] = useState(Number(localStorage.getItem(LOCALSTORAGE_DISMISS_KEY)));
    const [calendarInfoDismissed, setCalendarInfoDismissed] = useState(calendarInfoDismissCount > 2);
    const showCalendarInfo = calendarFetchEnabled != null && !calendarFetchEnabled && !calendarInfoDismissed && !calendarTokenError;
    const closeContextMenu = useCallback(() => {
        if (contextMenuItem) {
            setContextMenuItem(null);
            setBounds({ x: null, y: null });
        }
    }, [contextMenuItem, calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.length]);
    const [selectedDate, setSelectedDate] = useState(getSelectedDate(timePeriod === TIME_PERIOD.WEEK_VIEW, currentViewingDate));
    const firstDayOfWeek = useMemo(() => getFirstDayOfWeek(), []);
    useEffect(() => {
        const isWeek = timePeriod === TIME_PERIOD.WEEK_VIEW;
        const selected = getSelectedDate(isWeek, currentViewingDate);
        setSelectedDate(selected);
    }, [currentViewingDate, timePeriod]);
    const selectDateFromCalendar = (day, isWeek) => {
        const selected = getSelectedDate(isWeek, day);
        const readableSelection = getReadableSelectedDate(isWeek, day);
        trackEvent('Timesheet Calendar', 'Clicked', { selected: readableSelection });
        setSelectedDate(selected);
        selectDay(Moment(day), isWeek);
    };
    const weekArray = getWeeksOfMonth(currentViewingMonth, hideWeekend);
    const getWeekDayHeaders = () => {
        const weekDaysShort = getWeekdaysShort(hideWeekend);
        return weekDaysShort.map(weekDay => {
            return (React.createElement(DayCellStyle, { hoverable: false },
                React.createElement(HeaderContainer, null,
                    React.createElement(FlexRow, { justifyContent: 'center' },
                        React.createElement(HeaderText, null, weekDay)))));
        });
    };
    const openModal = (calendarEvent) => {
        openTimeRegModal(currentPersonViewed, calendarEvent, markEventAsConverted);
    };
    const dismissCalendarInfo = () => {
        trackEvent(TRACKING_OBJECTS.LINK_GOOGLE_CALENDAR_ACCOUNT, 'Dismissed');
        const newDismissCount = calendarInfoDismissCount + 1;
        setCalendarInfoDismissCount(newDismissCount);
        setCalendarInfoDismissed(true);
        localStorage.setItem(LOCALSTORAGE_DISMISS_KEY, newDismissCount.toString());
    };
    const getWeekRow = (week, firstWeekOfMonth) => {
        const holidays = week.days.map(day => getHolidaysOfTheDay(currentPersonViewed, day));
        const weekRow = week.days.map((day, index) => {
            const calendarEventsForDay = calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.filter(event => {
                const eventDate = getStartDate(event);
                return eventDate ? isSameDay(eventDate, day) : false;
            });
            const isDayOutsideViewingMonth = day.getMonth() !== currentViewingMonth.getMonth();
            return (React.createElement(DayCell, { timeRegistrations: timeRegistrations, currentPersonViewed: currentPersonViewed, selectedDate: selectedDate, selectDateFromCalendar: selectDateFromCalendar, lockedDate: lockedDate, day: day, isDayOutsideViewingMonth: isDayOutsideViewingMonth, holidaysOfTheDay: holidays[index], calendarEvents: calendarEventsForDay, isEventConverted: isEventConverted, isLoadingCalendarEvents: isLoadingCalendarEvents, currentViewingDate: currentViewingDate, openModal: openModal, showContextMenu: showContextMenu }));
        });
        const weekCell = (React.createElement(WeekCell, { week: week, firstWeekOfMonth: firstWeekOfMonth, lockedDate: lockedDate, timeRegistrations: timeRegistrations, currentPersonViewed: currentPersonViewed, selectedDate: selectedDate, selectDateFromCalendar: selectDateFromCalendar, hideWeekend: hideWeekend }));
        weekRow.unshift(weekCell);
        return React.createElement(RowWrapper, null, weekRow);
    };
    const gridRows = weekArray.map((week, index) => {
        return getWeekRow(week, index === 0);
    });
    return (React.createElement(FlexColumn, null,
        calendarTokenError ? (React.createElement(Alert, { text: 'For security reasons you need to re-authenticate with your Google account', type: AlertType.WARNING },
            React.createElement(Link, { onClick: () => revokeTokens(currentPersonViewed.id) }, "Unlink account"),
            React.createElement(GoogleCalendarOAuth, { calendarTokenError: calendarTokenError, personId: currentPersonViewed.id }))) : null,
        showCalendarInfo ? (React.createElement(Alert, { text: 'Create time registrations from your Google Calendar', type: AlertType.INFORMATION, onDismiss: () => dismissCalendarInfo() },
            React.createElement(GoogleCalendarOAuth, { personId: currentPersonViewed.id }))) : null,
        React.createElement(Grid, { firstDayOfWeek: firstDayOfWeek, hideWeekend: hideWeekend },
            React.createElement(RowWrapper, null,
                React.createElement(WeekCellStyle, { hoverable: false },
                    React.createElement(HeaderContainer, null,
                        React.createElement(FlexRow, { justifyContent: 'center' },
                            React.createElement(HeaderText, null, "Week total")))),
                getWeekDayHeaders()),
            gridRows),
        contextMenuItem ? (React.createElement(EventContextMenu, { closeContextMenu: closeContextMenu, contextMenuItem: contextMenuItem, bounds: bounds, person: currentPersonViewed, markEventAsConverted: markEventAsConverted })) : null));
};
export default TimesheetsCalendar;
