import { format, getDate, getMonth, getYear, isAfter } from 'date-fns';
import { APPROVAL_STATUS } from '../../../../constants';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { MODAL_TYPE, showModal } from '../modals/generic_modal_conductor';
import { createToast } from '../toasts/another-toast/toaster';
import Util from '../../../shared/util/util';
import UpdateTimeRegistration from '../../../../mutations/update_time_registration_mutation.modern';
export function isLocked(timeReg) {
    return !!timeReg.invoiced || !!timeReg.xeroInvoiceId || !!timeReg.lockedInPeriod;
}
export function getLockErrorMessage(dayOfInput, lockedDate, dayRegNodes, formatMessage, defaultMessage = '', excludeFromCompanyLockedPeriod) {
    var _a;
    if (!(dayRegNodes === null || dayRegNodes === void 0 ? void 0 : dayRegNodes.length)) {
        return defaultMessage;
    }
    const isDateLocked = lockedDate && !isAfter(dayOfInput, lockedDate);
    if (isDateLocked && !excludeFromCompanyLockedPeriod) {
        return formatMessage({ id: 'time_lock.date_picker_info' }, { date: format(lockedDate, 'PPPP') });
    }
    const firstLockedTimeReg = (_a = dayRegNodes.find(timeReg => isLocked(timeReg.node))) === null || _a === void 0 ? void 0 : _a.node;
    if (firstLockedTimeReg) {
        return firstLockedTimeReg.xeroInvoiceId
            ? formatMessage({ id: 'xero.cannot_edit_time_reg' })
            : firstLockedTimeReg.invoiced
                ? formatMessage({ id: 'invoice.cannot_edit_time_reg' })
                : firstLockedTimeReg.approvalStatus === APPROVAL_STATUS.APPROVED
                    ? formatMessage({ id: 'approval.approved_time' })
                    : firstLockedTimeReg.approvalStatus === APPROVAL_STATUS.SUBMITTED
                        ? formatMessage({ id: 'approval.submitted_time' })
                        : firstLockedTimeReg.lockedInPeriod
                            ? formatMessage({ id: 'retainer.cannot_edit_time_reg' })
                            : defaultMessage;
    }
    return defaultMessage;
}
export function getBambooHRLockErrorMessage(dayRegNodes, bambooHREnabled, lockMessage) {
    if (bambooHREnabled && (dayRegNodes === null || dayRegNodes === void 0 ? void 0 : dayRegNodes.length)) {
        const timeOffTimeReg = dayRegNodes.find(timeReg => timeReg.node.idleTime && !timeReg.node.idleTime.isInternalTime);
        if (timeOffTimeReg) {
            return lockMessage;
        }
    }
    return '';
}
/**
 * Function to check if idle time is time off and if os, if it's blocked by bambooHR
 * @param idleTime
 * @param bambooHREnabled
 */
export function isTimeOffBlockedByBambooHR(idleTime, bambooHREnabled) {
    return bambooHREnabled && idleTime && !idleTime.isInternalTime;
}
function onTimeUpdateSuccess(result, intl) {
    if (result.updateTimeReg.errors &&
        result.updateTimeReg.errors.length === 1 &&
        result.updateTimeReg.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
        showModal({
            type: MODAL_TYPE.WARNING_DATE_LOCKED,
        });
    }
    else if (Util.checkForSageErrorAndShowModal(result.updateTimeReg.errors)) {
        return;
    }
    else {
        createToast({ duration: 2500, message: intl.formatMessage({ id: 'time_page.time_reg_updated' }) });
    }
}
export function updateTimeRegistration({ hours, billableHours, notes, dayRegs, timeRegId, roleId, selectedPerson, currentViewingDate, skipApprovalValidation = false, intl, }) {
    var _a, _b, _c, _d;
    trackEvent('Company Timesheets Hours Input', 'Changed', {
        inputHours: hours,
        timeRegPerson: selectedPerson.email,
        date: format(currentViewingDate, 'yyyy-mm-dd'),
    });
    const timeReg = (_a = dayRegs.find(timeReg => timeReg.node.id === timeRegId)) === null || _a === void 0 ? void 0 : _a.node;
    if (!timeReg) {
        return;
    }
    if (isLocked(timeReg)) {
        return;
    }
    Util.CommitMutation(UpdateTimeRegistration, {
        source: 'Company-time-row-table',
        id: timeReg.id,
        day: getDate(currentViewingDate),
        month: getMonth(currentViewingDate) + 1,
        year: getYear(currentViewingDate),
        projectId: (_c = (_b = timeReg.task) === null || _b === void 0 ? void 0 : _b.project) === null || _c === void 0 ? void 0 : _c.id,
        taskId: (_d = timeReg.task) === null || _d === void 0 ? void 0 : _d.id,
        idleTimeId: timeReg.idleTimeId,
        minutesRegistered: hours ? hours * 60 : 0,
        billableMinutesRegistered: billableHours === null
            ? null
            : billableHours === undefined || isNaN(billableHours)
                ? undefined
                : billableHours * 60,
        notes: notes ? notes : undefined,
        roleId: roleId,
        removeOthersForTheDay: false,
        skipApprovalValidation,
    }, result => onTimeUpdateSuccess(result, intl));
}
