import { hasFeatureFlag } from '../../util/FeatureUtil';
function withPrefix(companyProjectId) {
    if (!companyProjectId)
        return '';
    return `P${companyProjectId}`;
}
export const useProjectIndicatorViewController = (project) => {
    if (!project) {
        return null;
    }
    const showCustomId = hasFeatureFlag('custom_project_ids') && !!project.customProjectId;
    const workId = showCustomId ? project.customProjectId : withPrefix(project.companyProjectId);
    return { workId, name: project.name || undefined, color: project.projectColor || '' };
};
