import {omit} from 'lodash';
import {ConnectionHandler} from 'relay-runtime';

import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createTaskMutation($input: CreateTaskInput!) {
		createTask(input: $input) {
			task {
				cursor
				node {
					id
					...DraggableCard_task
					fullAccessToProject
					userCanDeleteTask
					userCantDeleteTaskReason
					hasInvoicedTime
					hasLockedTime
					hasTimeRegistrations
					approved
					name
					description
					sortOrder
					done
					billable
					blocked
					progress
					bug
					latestUiUpdateAt
					startFrom
					deadlineFrom
					startYear
					startMonth
					startDay
					deadlineDay
					deadlineMonth
					deadlineYear
					estimateForecast
					estimateForecastPrice
					currentPrice
					allTotalTimeAndExpensesAtCompletion
					plannedCost
					actualCost
					companyTaskId
					timeLeft
					deadlineFrom
					canStart
					canBeSetToDone
					approved
					highPriority
					hasDependency
					jiraId
					subTaskCount
					favoured
					parentTaskId
					phase {
						id
						name
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						progress
						progressDetails {
							progress
						}
					}
					role {
						id
						name
					}
					sprint {
						id
					}
					statusColumnV2 {
						id
						name
						category
						projectGroupStatusColumnId
					}
					project {
						id
						billable
						estimationUnit
						minutesPerEstimationPoint
						projectEndYear
						projectEndMonth
						projectEndDay
						name
						completion
						remaining
						forecast
						isJiraProject
						harvestProject {
							id
						}
						progress
						progressDetails {
							progress
						}
					}
					taskLabels {
						id
					}
					timeRegistrations(first: 10000) @connection(key: "Task_timeRegistrations", filters: []) {
						edges {
							node {
								id
							}
						}
					}
					assignedPersons {
						id
						firstName
						lastName
						email
						profilePictureId
						profilePictureDefaultId
						permissions
					}
				}
			}
			parentTask {
				id
				hasChildren
				rollupEstimate
				rollupTimeEntries
				rollupRemaining
				childrenTasks(first: 1000000) @connection(key: "ParentTask_childrenTasks", filters: []) {
					edges {
						node {
							id
						}
					}
				}
			}
			sortOrderTasks {
				id
				sortOrder
			}
		}
	}
`;

function getConfigs(input) {
	let configs = [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionName: 'tasks',
			connectionInfo: [
				{
					key: 'Project_tasks',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'task',
		},
	];

	return configs;
}

function updater(store, input, payload) {
	const createdEdge = payload.getLinkedRecord('task');

	if (input.companyId) {
		const companyProxy = store.get(input.companyId);

		const creationColumn = createdEdge.getLinkedRecord('node').getLinkedRecord('statusColumnV2');
		const creationColumnId = creationColumn.getValue('id');
		const creationProjectGroupColumnId = creationColumn.getValue('projectGroupStatusColumnId');

		if (creationColumnId) {
			const newConn = ConnectionHandler.getConnection(companyProxy, 'Company_allTasks', {
				filterColumnId: creationColumnId,
			});
			if (newConn) {
				ConnectionHandler.insertEdgeBefore(newConn, createdEdge);
			}
		}
		if (creationProjectGroupColumnId) {
			const newConn = ConnectionHandler.getConnection(companyProxy, 'Company_allTasks', {
				filterColumnId: creationProjectGroupColumnId.toString(),
			});
			if (newConn) {
				ConnectionHandler.insertEdgeBefore(newConn, createdEdge);
			}
		}
	}

	// This is to handle the support of bulk sprint action on the new Sprint page V4.
	// It uses two different connections for the sprint task and the backlog.
	if (input.projectId) {
		const sprint = createdEdge.getLinkedRecord('node').getLinkedRecord('sprint');
		if (!sprint) {
			const projectProxy = store.get(input.projectId);
			if (projectProxy) {
				const newConn = ConnectionHandler.getConnection(projectProxy, 'Project_tasks', {backlog: true});
				if (newConn) {
					ConnectionHandler.insertEdgeBefore(newConn, createdEdge);
				}
			}
		}
	}
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	tracking.trackEvent('Card Created', {_SubTask: !!input.parentTaskId});

	const cleanedVariables = omit(input, ['companyId', 'statusId', 'projectGroupStatusId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		updater: store => {
			// Get payload
			const payload = store.getRootField('createTask');
			// Call updater function
			updater(store, input, payload);
		},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
