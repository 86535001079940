/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetSlackNotificationSettingInput = {|
  setting?: ?string,
  personId?: ?string,
  value?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SetSlackNotificationSettingMutationVariables = {|
  input: SetSlackNotificationSettingInput
|};
export type SetSlackNotificationSettingMutationResponse = {|
  +setSlackNotificationSetting: ?{|
    +slackNotificationSettings: ?{|
      +disableNotifications: ?boolean,
      +notifyOnProjectStatusChange: ?boolean,
      +notifyOnAssignedProject: ?boolean,
      +notifyOnPersonJoin: ?boolean,
      +notifyOnProjectDeadline: ?boolean,
      +notifyOnAssignedTask: ?boolean,
      +notifyOnAssignedTaskUpdate: ?boolean,
      +notifyOnTaskDeadline: ?boolean,
      +notifyOnMention: ?boolean,
      +notifyOnTaskDescriptionChange: ?boolean,
      +notifyOnTaskTitleChange: ?boolean,
      +notifyOnTaskEstimateChange: ?boolean,
      +notifyOnTaskStartDateChange: ?boolean,
      +notifyOnTaskEndDateChange: ?boolean,
      +notifyOnTaskSprintChange: ?boolean,
      +notifyOnTaskPhaseChange: ?boolean,
      +notifyOnTaskStatusColumnChange: ?boolean,
      +notifyOnTaskProjectChange: ?boolean,
      +notifyOnTaskSubtaskChange: ?boolean,
      +notifyOnTaskSubtaskEstimateChange: ?boolean,
      +notifyOnTaskCommentChange: ?boolean,
      +notifyOnTaskFileChange: ?boolean,
      +notifyOnTaskBugChange: ?boolean,
      +notifyOnTaskBlockedChange: ?boolean,
      +notifyOnTaskRepeatingChange: ?boolean,
    |}
  |}
|};
export type SetSlackNotificationSettingMutation = {|
  variables: SetSlackNotificationSettingMutationVariables,
  response: SetSlackNotificationSettingMutationResponse,
|};
*/


/*
mutation SetSlackNotificationSettingMutation(
  $input: SetSlackNotificationSettingInput!
) {
  setSlackNotificationSetting(input: $input) {
    slackNotificationSettings {
      disableNotifications
      notifyOnProjectStatusChange
      notifyOnAssignedProject
      notifyOnPersonJoin
      notifyOnProjectDeadline
      notifyOnAssignedTask
      notifyOnAssignedTaskUpdate
      notifyOnTaskDeadline
      notifyOnMention
      notifyOnTaskDescriptionChange
      notifyOnTaskTitleChange
      notifyOnTaskEstimateChange
      notifyOnTaskStartDateChange
      notifyOnTaskEndDateChange
      notifyOnTaskSprintChange
      notifyOnTaskPhaseChange
      notifyOnTaskStatusColumnChange
      notifyOnTaskProjectChange
      notifyOnTaskSubtaskChange
      notifyOnTaskSubtaskEstimateChange
      notifyOnTaskCommentChange
      notifyOnTaskFileChange
      notifyOnTaskBugChange
      notifyOnTaskBlockedChange
      notifyOnTaskRepeatingChange
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetSlackNotificationSettingInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetSlackNotificationSettingPayload",
    "kind": "LinkedField",
    "name": "setSlackNotificationSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationSettingsType",
        "kind": "LinkedField",
        "name": "slackNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disableNotifications",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnProjectStatusChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnAssignedProject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnPersonJoin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnProjectDeadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnAssignedTask",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnAssignedTaskUpdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskDeadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnMention",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskDescriptionChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskTitleChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskEstimateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskStartDateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskEndDateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskSprintChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskPhaseChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskStatusColumnChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskProjectChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskSubtaskChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskSubtaskEstimateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskCommentChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskFileChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskBugChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskBlockedChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskRepeatingChange",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetSlackNotificationSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetSlackNotificationSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetSlackNotificationSettingMutation",
    "operationKind": "mutation",
    "text": "mutation SetSlackNotificationSettingMutation(\n  $input: SetSlackNotificationSettingInput!\n) {\n  setSlackNotificationSetting(input: $input) {\n    slackNotificationSettings {\n      disableNotifications\n      notifyOnProjectStatusChange\n      notifyOnAssignedProject\n      notifyOnPersonJoin\n      notifyOnProjectDeadline\n      notifyOnAssignedTask\n      notifyOnAssignedTaskUpdate\n      notifyOnTaskDeadline\n      notifyOnMention\n      notifyOnTaskDescriptionChange\n      notifyOnTaskTitleChange\n      notifyOnTaskEstimateChange\n      notifyOnTaskStartDateChange\n      notifyOnTaskEndDateChange\n      notifyOnTaskSprintChange\n      notifyOnTaskPhaseChange\n      notifyOnTaskStatusColumnChange\n      notifyOnTaskProjectChange\n      notifyOnTaskSubtaskChange\n      notifyOnTaskSubtaskEstimateChange\n      notifyOnTaskCommentChange\n      notifyOnTaskFileChange\n      notifyOnTaskBugChange\n      notifyOnTaskBlockedChange\n      notifyOnTaskRepeatingChange\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '408ceef08be3434f3b309d5c426304a1';

module.exports = node;
