/* eslint no-useless-concat:0 */

import {REPORT_GROUPINGS} from '../../../../constants';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

export const getCsvLabels = (columns, groupings, customFieldDefinitions) => {
	const hasCustomProjectIds = hasFeatureFlag('custom_project_ids');
	// A column should be included in the CSV export if it is either in the list of shown columns or in one of the applied groupings
	const isInColumnsOrGroupings = (columnField, groupingField) => {
		return columns[columnField] || groupings?.includes(groupingField);
	};

	const dataInput = [
		{
			fieldNames: [
				'timeReg.project.companyProjectId',
				'timeReg.task.project.companyProjectId',
				'timeReg.project.customProjectId',
				'timeReg.task.project.customProjectId',
			],
			label: 'project_id',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: hasCustomProjectIds
					? 'return data["timeReg.project.customProjectId"] || data["timeReg.task.project.customProjectId"] || ""'
					: 'return (data["timeReg.project.companyProjectId"] || data["timeReg.task.project.companyProjectId"])' +
					  ' ? ("P" + (data["timeReg.project.companyProjectId"]' +
					  ' ? data["timeReg.project.companyProjectId"]' +
					  ' : data["timeReg.task.project.companyProjectId"])' +
					  ') : ""',
			},
		},
		{
			fieldNames: ['timeReg.project.name', 'timeReg.task.project.name', 'timeReg.idleTime.isInternalTime'],
			label: 'project_name',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script:
					'return data["timeReg.task.project.name"]' +
					' ? data["timeReg.task.project.name"]' +
					' : data["timeReg.project.name"]' +
					' ? data["timeReg.project.name"]' +
					' : data["timeReg.idleTime.isInternalTime"] === "true"' +
					' ? "Internal Time"' +
					' : "Time Off";',
			},
		},
		{
			fieldNames: ['timeReg.task.companyTaskId'],
			label: 'task_id',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["timeReg.task.companyTaskId"]' + ' ? "T" + data["timeReg.task.companyTaskId"]' + ' : "";',
			},
		},
		{
			fieldNames: ['timeReg.task.name', 'timeReg.project.name', 'timeReg.idleTime.name'],
			label: 'task_name',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script:
					'return data["timeReg.task.name"]' +
					' ? data["timeReg.task.name"]' +
					' : data["timeReg.project.name"]' +
					' ? data["timeReg.project.name"]' +
					' : data["timeReg.idleTime.name"]' +
					' ? data["timeReg.idleTime.name"]' +
					' : "";',
			},
		},
		{
			fieldNames: ['timeReg.timeRegistered'],
			label: 'time_entries',
			format: 'INTEGER',
		},
	];

	columns['actuals.timeReg.billableRegistered'] &&
		dataInput.push({
			fieldNames: ['timeReg.billableRegistered'],
			label: 'billable_time',
			format: 'INTEGER',
		});
	columns['actuals.timeReg.nonBillableRegistered'] &&
		dataInput.push({
			fieldNames: ['timeReg.nonBillableRegistered'],
			label: 'non_billable_time',
			format: 'INTEGER',
		});

	isInColumnsOrGroupings('timeReg.task.phase.info', REPORT_GROUPINGS.PHASE) &&
		dataInput.push({fieldNames: ['timeReg.task.phase.name'], label: 'phase'});
	isInColumnsOrGroupings('timeReg.task.sprint.info', REPORT_GROUPINGS.SPRINT) &&
		dataInput.push({fieldNames: ['timeReg.task.sprint.name'], label: 'sprint'});
	columns['timeReg.notes'] && dataInput.push({fieldNames: ['timeReg.notes'], label: 'notes'});
	columns['timeReg.date'] && dataInput.push({fieldNames: ['timeReg.date'], label: 'date'});
	dataInput.push({
		fieldNames: ['timeReg.person.firstName', 'timeReg.person.lastName'],
		label: 'person',
		format: 'STRING',
		processor: 'SCRIPT',
		processorData: {
			script: 'return data["timeReg.person.firstName"] + " " + data["timeReg.person.lastName"];',
		},
	});

	isInColumnsOrGroupings('timeReg.role.info', REPORT_GROUPINGS.ROLE) &&
		dataInput.push({fieldNames: ['timeReg.role.name'], label: 'role'});

	isInColumnsOrGroupings('timeReg.person.department.info', REPORT_GROUPINGS.DEPARTMENT) &&
		dataInput.push({fieldNames: ['timeReg.person.department.name'], label: 'department'});
	isInColumnsOrGroupings('timeReg.client.info', REPORT_GROUPINGS.CLIENT) &&
		dataInput.push({
			fieldNames: ['timeReg.task.project.client.name', 'timeReg.project.client.name'],
			label: 'client',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script:
					'return data["timeReg.task.project.client.name"]' +
					' ? data["timeReg.task.project.client.name"]' +
					' : data["timeReg.project.client.name"]' +
					' ? data["timeReg.project.client.name"]' +
					' : "";',
			},
		});
	columns['actuals.timeReg.billable'] &&
		dataInput.push({
			fieldNames: ['timeReg.billable'],
			label: 'billable',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["timeReg.billable"] === "true" ? "Yes" : "No";',
			},
		});
	columns['actuals.timeReg.price'] &&
		dataInput.push({
			fieldNames: ['timeReg.billable', 'timeReg.price'],
			label: 'actual_revenue',
			format: 'FLOAT',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["timeReg.billable"] === "true" ? (parseFloat(data["timeReg.price"]).toFixed(2) === "NaN" ? 0 : parseFloat(data["timeReg.price"]).toFixed(2)) : 0;',
			},
		});
	columns['actuals.timeReg.cost'] &&
		dataInput.push({
			fieldNames: ['timeReg.cost'],
			label: 'actual_cost',
			format: 'FLOAT',
			processor: 'SCRIPT',
			processorData: {
				script: 'return parseFloat(data["timeReg.cost"]).toFixed(2) === "NaN" ? 0 : parseFloat(data["timeReg.cost"]).toFixed(2);',
			},
		});
	columns['timeReg.invoiced'] &&
		dataInput.push({
			fieldNames: ['timeReg.invoiced'],
			label: 'invoiced',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["timeReg.invoiced"] === "true" ? "Yes" : "No";',
			},
		});
	columns['timeReg.approvalStatus'] &&
		dataInput.push({
			fieldNames: ['timeReg.approvalStatus'],
			label: 'approved',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["timeReg.approvalStatus"] === "APPROVED" ? "Yes" : "No";',
			},
		});

	// Loop all customfield
	const customFieldsKeys = customFieldDefinitions.map(c => c.key);
	customFieldsKeys.forEach(key => {
		const label = customFieldDefinitions
			.find(def => def.key === key)
			.displayName.replace(' ', '_')
			.toLowerCase();
		const col = 'company.reportCustomFieldDefinitions.' + key;
		columns[col] &&
			dataInput.push({
				fieldNames: ['timeReg.reportCustomFieldValues.' + key],
				label: label,
				format: 'STRING',
				processor: 'CUSTOM_FIELD',
				processorData: {
					key: key,
					fields: 'timeReg.reportCustomFieldValues',
					script: 'return data["value"] ? data["value"] : "";',
				},
			});
	});

	return dataInput;
};
