import { hasFeatureFlag } from '../../../../../shared/util/FeatureUtil';
import Util from '../../../../../shared/util/util';
export const TIME_REG_TYPES = {
    TASK_TIME: 'taskTime',
    PROJECT_TIME: 'projectTime',
    IDLE_TIME: 'idleTime',
    NO_ACCESS: 'noAccess',
};
export const timeRegMatchesEntityId = (timeReg, entityId) => {
    var _a, _b, _c;
    return ((_a = timeReg === null || timeReg === void 0 ? void 0 : timeReg.task) === null || _a === void 0 ? void 0 : _a.id) === entityId || ((_b = timeReg === null || timeReg === void 0 ? void 0 : timeReg.idleTime) === null || _b === void 0 ? void 0 : _b.id) === entityId || ((_c = timeReg === null || timeReg === void 0 ? void 0 : timeReg.project) === null || _c === void 0 ? void 0 : _c.id) === entityId;
};
export const timeRegEntityInIdList = (timeReg, entityIdList) => {
    return entityIdList.some(entityId => timeRegMatchesEntityId(timeReg, entityId));
};
export const getTimeRegType = (timeReg) => {
    if ((timeReg === null || timeReg === void 0 ? void 0 : timeReg.task) && timeReg.task.project) {
        return TIME_REG_TYPES.TASK_TIME;
    }
    else if (timeReg === null || timeReg === void 0 ? void 0 : timeReg.project) {
        return TIME_REG_TYPES.PROJECT_TIME;
    }
    else if (timeReg === null || timeReg === void 0 ? void 0 : timeReg.idleTime) {
        return TIME_REG_TYPES.IDLE_TIME;
    }
    else {
        return undefined;
    }
};
export const getFormattedValue = (value) => {
    if (hasFeatureFlag('timereg_input_conversion')) {
        return Util.convertTimeInputToMinutes(value, true);
    }
    else {
        if (Number.isNaN(value)) {
            return 0;
        }
        return Util.convertTimeInputToMinutes(value);
    }
};
