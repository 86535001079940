import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {DATE_PICKER_STYLE, INITIALS_SIZE, MODULE_TYPES} from '../../../constants';
import TdLink from '../../../components/td_link';
import Dropdown from '../../shared/components/dropdowns/dropdown';
import Util from '../../shared/util/util';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import HarvestIcon from '../../../images/integrations/harvest-logo.png';
import GitLabIcon from '../../../images/integrations/gitlab.png';
import JiraIcon from '../../../images/integrations/jira-logo.png';
import AsanaIcon from '../../../images/integrations/asana-logo.png';
import Unit4Icon from '../../../images/integrations/unit4-small-logo.svg';
import {
	AdoIcon,
	BambooHRIcon,
	Checkbox,
	EconomicIcon,
	GithubIcon,
	MSTeamsIcon,
	SageIntacctIcon,
	SlackIcon,
} from 'web-components';
import PersonInitials from '../../shared/components/person/person_initials';
import DatePicker from '../../shared/components/date-picker/date_picker_v3';
import {
	personHasPermission,
	personIsAdmin,
	personIsCollaboratorUser,
	personIsVirtualUser,
} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {hasModule} from '../../shared/util/ModuleUtil';
import {useSettingsPeopleContext} from './SettingsPeopleContext';
import hash from 'object-hash';
import ActionsMenu from '../../shared/components/action-menu/actions_menu';
import {usesSso} from '../../shared/util/FeatureUtil';
import {profilePicSrc} from '../../../directApi';

const comparePersonId = (p1, p2) => p1.person?.id === p2.person?.id;

const comparePersonIdAndSeat = (p1, p2) => p1.person?.id === p2.person?.id && p1.person?.seat === p2.person?.seat;

const NameInfo = React.memo(
	({person, getLinkPath}) => (
		<TdLink
			cy="name-width"
			className="person person-width"
			path={getLinkPath(person)}
			content={
				<div className="wrapper" data-cy="name-width">
					<div className="image-container">
						<div className="profile-picture">
							{person.profilePictureId ? (
								<img
									crossOrigin="use-credentials"
									src={profilePicSrc(person.profilePictureId)}
									width="36"
									height="36"
									alt=""
								/>
							) : (
								<PersonInitials initials={person.initials} size={INITIALS_SIZE.SMALL} />
							)}
						</div>
					</div>
					<div className="name-tile">
						<h3 className="name">
							{(person.firstName || '') + (person.lastName !== null ? ' ' + person.lastName : '')}
						</h3>
					</div>
				</div>
			}
		/>
	),
	comparePersonId
);

const Role = React.memo(
	({person, roles, onRoleChange}) => (
		<td className="role-width">
			<Dropdown
				inputCy={'role-dropdown'}
				focusOnClick={true}
				optionClickEvent={true}
				onChange={onRoleChange}
				options={roles.map(role => {
					return {label: role.node.name, value: role.node.id};
				})}
				value={person.role ? person.role.id : ''}
				hideLabel={true}
				disabled={false}
				listDataCy="role"
			/>
		</td>
	),
	(p1, p2) => p1.person?.role?.id === p2.person?.role?.id
);

const Skills = React.memo(
	({person, formatMessage, showPersonSkillsModal}) =>
		Util.hasSkills() && (
			<td
				style={{textTransform: 'lowercase'}}
				onClick={showPersonSkillsModal}
				className={'person-skill-link '}
				data-cy={'person-skill'}
			>
				{formatMessage({id: 'settings_skills.skills_count'}, {count: person.skillPersons?.length || 0})}
			</td>
		),
	(p1, p2) => p1.person.skillPersons?.length === p2.person.skillPersons?.length
);

const Department = React.memo(
	({person, departments, onDepartmentChange}) => (
		<td className="department-width">
			<Dropdown
				inputCy={'department-dropdown'}
				focusOnClick={true}
				optionClickEvent={true}
				onChange={onDepartmentChange}
				options={departments.map(department => {
					return {label: department.node.name, value: department.node.id};
				})}
				value={person.department ? person.department.id : ''}
				hideLabel={true}
				disabled={false}
				listDataCy="department"
			/>
		</td>
	),
	(p1, p2) => p1.person?.department?.id === p2.person?.department?.id
);

const Profiles = React.memo(
	({person, viewer, profiles, onProfileChange, disabled}) => (
		<td className="permissions-width">
			<Dropdown
				multiSelect={hasModule(MODULE_TYPES.SISENSE)}
				hideLabel={true}
				onChange={onProfileChange}
				options={profiles.map(profile => {
					if (
						viewer.actualPersonId === person.id &&
						(personIsAdmin(profile.profile) || personIsVirtualUser(profile.profile))
					) {
						return {...profile, disabled: true};
					}
					return profile;
				})}
				value={
					hasModule(MODULE_TYPES.SISENSE)
						? person.profiles.edges.map(e => e.node.id)
						: person.profiles.edges[0]?.node.id
				}
				disabled={(viewer.actualPersonId === person.id && !hasModule(MODULE_TYPES.SISENSE)) || disabled}
			/>
		</td>
	),
	(p1, p2) =>
		hash(p1.person?.profiles) === hash(p2.person?.profiles) &&
		hash(p1.disabled) === hash(p2.disabled) &&
		p1.person?.seat === p2.person?.seat
);

const Cost = React.memo(
	({currentCostPeriod, currencySymbol, showInternalCostPeriodsModal, _useIntl}) => (
		<td onClick={showInternalCostPeriodsModal} className={'cost cost-modal-link'}>
			{Util.getFormattedNumberWithCurrency(currencySymbol, currentCostPeriod ? currentCostPeriod.node.cost : 0, _useIntl)}
		</td>
	),
	(p1, p2) => p1.currentCostPeriod?.node?.cost === p2.currentCostPeriod?.node?.cost
);

const StartDate = React.memo(
	({person, handleStartDateChange, handleClearStartDate}) => (
		<td className="date-width">
			<DatePicker
				startDate={person.startDate ? Moment(person.startDate) : null}
				handleDateRangeChange={value => handleStartDateChange(person, value)}
				clearBothDates={value => handleClearStartDate(person, value)}
				datePickerStyle={DATE_PICKER_STYLE.SLIM}
				isSingleDatePicker
				clearable
				endDateLimite={person.endDate ? Moment(person.endDate).add(1, 'days') : null}
				hideLimitMessage
				startDateEmptyText="-"
			/>
		</td>
	),
	(p1, p2) => p1.person?.endDate === p2.person?.endDate
);

const EndDate = React.memo(
	({person, handleEndDateChange, handleClearEndDate}) => (
		<td className="date-width">
			<DatePicker
				startDate={person.endDate ? Moment(person.endDate) : null}
				handleDateRangeChange={value => handleEndDateChange(person, value)}
				clearBothDates={() => handleClearEndDate(person)}
				datePickerStyle={DATE_PICKER_STYLE.SLIM}
				isSingleDatePicker
				clearable
				startDateLimite={person.startDate ? Moment(person.startDate) : null}
				hideLimitMessage
				startDateEmptyText="-"
			/>
		</td>
	),
	(p1, p2) => p1.person?.startDate === p2.person?.startDate
);

const CreatedAt = React.memo(
	({createdAtMoment}) => (
		<td className="created-at-cell" title={createdAtMoment.format('LLL')}>
			{createdAtMoment.format('LL')}
		</td>
	),
	(p1, p2) => p1.createdAtMoment === p2.createdAtMoment
);

const CreatedBy = React.memo(
	({createdByPerson}) => <td className="created-by-cell">{createdByPerson ? createdByPerson.node.fullName : null}</td>,
	(p1, p2) => p1.createdByPerson === p2.createdByPerson
);

const Integrations = React.memo(
	({person, viewer, getLinkPath}) => (
		<td className="integrations-cell">
			{viewer.company.harvestEnabled && person.harvestUser && (
				<div className="harvest-icon">
					<img
						width={20}
						height={20}
						src={HarvestIcon}
						alt="Harvest"
						title="Harvest"
						onClick={e => {
							e.stopPropagation();
							window.open(getLinkPath(person), '_self');
						}}
					/>
				</div>
			)}
			{person.economicUser && <EconomicIcon />}
			{person.gitlabUser && person.gitlabUser.integrationUserId && (
				<img width={20} height={20} src={GitLabIcon} alt="GitLab" title="GitLab" />
			)}
			{person.gitlabServerUser && person.gitlabServerUser.integrationUserId && (
				<img width={20} height={20} src={GitLabIcon} alt="GitLab Server" title="GitLab Server" />
			)}
			{person.githubUser && person.githubUser.integrationUserId && <GithubIcon />}
			{person.jiraServerUser && <img width={20} height={20} src={JiraIcon} alt="Jira Server" title="Jira Server" />}
			{person.jiraCloudUser && <img width={20} height={20} src={JiraIcon} alt="Jira Cloud" title="Jira Cloud" />}
			{person.asanaUser && <img width={20} height={20} src={AsanaIcon} alt="Asana" title="Asana" />}
			{person.adoUserId && <AdoIcon />}
			{person.unit4User && <img width={20} height={20} src={Unit4Icon} alt="Unit4" title="Unit4" />}
			{person.msTeamsId && <MSTeamsIcon />}
			{person.bambooHRId && <BambooHRIcon />}
			{person.slackId && <SlackIcon />}
			{person.sageIntacctId && <SageIntacctIcon />}
		</td>
	),
	comparePersonId
);

const SeatType = React.memo(
	({person, getSeatTypeName}) => (
		<td data-cy={'person-seat'} className={'person-seat'}>
			{getSeatTypeName(person?.seat)}
		</td>
	),
	comparePersonIdAndSeat
);

const Status = React.memo(
	({person, getPersonStatus}) => (
		<td data-cy={'person-status'} className={'person-status'}>
			{getPersonStatus(person)}
		</td>
	),
	comparePersonId
);

const PeoplePerson = ({
	firstUser,
	hasFinancial,
	person,
	viewer,
	integrationsEnabled,
	theEyeOptions,
	roles,
	departments,
	profiles,
}) => {
	const _useIntl = useIntl();
	const {formatMessage} = _useIntl;

	const {isSelectedPerson, toggleSelectedPerson, getPersonStatus, getSeatTypeName, getActionOptions, isCurrentUser} =
		useSettingsPeopleContext();
	const {company} = viewer;

	const [isProfileUpdating, setIsProfileUpdating] = useState(false);

	const showChangesSavedToast = duration => {
		createToast({duration, message: formatMessage({id: 'common.changes_saved'})});
	};

	const onRoleChange = role => {
		if (!person || !role) return;

		// Prevent if the role is the same
		if (person.role && person.role.id === role.value) return;

		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: person.id,
				roleId: role.value,
			},
			() => showChangesSavedToast(2000),
			true
		);
	};

	const onDepartmentChange = department => {
		if (!person || !department) return;

		// Prevent if the role is the same
		if (person.department && person.department.id === department.value) return;

		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: person.id,
				departmentId: department.value,
			},
			() => showChangesSavedToast(2000),
			true
		);
	};

	const runProfileMutation = (person, profile) => {
		const data = {
			id: person.id,
		};
		if (!person.invited && personIsVirtualUser(person) && !personIsVirtualUser(profile.profile)) {
			data.invited = true;
		}
		const onSuccess = res => {
			setIsProfileUpdating(false);
			showChangesSavedToast(2000);
			const errors = res.updatePerson?.errors || [];
			if (errors.includes('NO_DESIGNER_SEATS_LEFT') || errors.includes('NO_VIEWER_SEATS_LEFT')) {
				showModal({
					type: MODAL_TYPE.WARNING,
					message: 'Not enough Advanced Analytics seats',
					warningInformation: ['Please contact your account manager if you need to increase your seat limit.'],
				});
			} else if (errors.includes('NO_AVAILABLE_SEATS')) {
				showModal({
					type: MODAL_TYPE.NO_AVAILABLE_SEATS,
					seats: company.userSeats,
					seatType: company.tier === 'CORE' ? 'core ' : 'full ',
					warningMessageId: 'team_modal.seats_warning_permission_change_failed',
				});
			} else if (errors.includes('NO_AVAILABLE_VIRTUAL_SEATS')) {
				showModal({
					type: MODAL_TYPE.NO_AVAILABLE_SEATS,
					seats: company.virtualSeats,
					seatType: 'virtual ',
					warningMessageId: 'team_modal.seats_warning_permission_change_failed',
				});
			} else if (errors.includes('NO_AVAILABLE_COLLABORATOR_SEATS')) {
				showModal({
					type: MODAL_TYPE.NO_AVAILABLE_SEATS,
					seats: company.collaboratorSeats,
					seatType: 'collaborator ',
					warningMessageId: 'team_modal.seats_warning_permission_change_failed',
				});
			}
		};
		const profileRealId = +atob(profile.profile.id).replace('Profile:', '');
		if (!personIsVirtualUser(person) && profileRealId === -1) {
			// Changing to virtual
			data.profileIds = [profile.profile.id];
		} else if (personIsVirtualUser(person) && profileRealId !== -1) {
			// Changing from virtual
			data.profileIds = [profile.profile.id];
		} else if (!personIsCollaboratorUser(person) && profileRealId === -4) {
			// Changing to Collaborator seat / Contributer profile
			data.profileIds = [profile.profile.id];
		} else if (personIsCollaboratorUser(person) && profileRealId !== -4) {
			// Changing from Collaborator seat / Contributer profile
			data.profileIds = [profile.profile.id];
		} else if (!hasModule(MODULE_TYPES.SISENSE)) {
			// Use only single select. So just set the profile id
			data.profileIds = [profile.profile.id];
		} else if (person.profiles.edges.some(e => e.node.id === profile.profile.id)) {
			// Person already has that profile. So remove it.
			// If person only has one profile, that cannot be removed.
			if (person.profiles.edges.length === 1) return;
			data.profileIds = person.profiles.edges.filter(e => e.node.id !== profile.profile.id).map(e => e.node.id);
		} else {
			// Person does not have the profile.
			data.profileIds = [...person.profiles.edges.map(e => e.node.id), profile.profile.id];
		}

		if (
			personHasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS, profile.profile) &&
			!person.profiles.edges.some(e => e.node.id === profile.profile.id) &&
			!usesSso(company)
		) {
			showModal({
				type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
				mutation: UpdatePersonMutation,
				mutationName: 'updatePerson',
				data,
				onSuccess,
			});
		} else {
			setIsProfileUpdating(true);
			Util.CommitMutation(UpdatePersonMutation, data, onSuccess);
		}
	};

	const onProfileChange = profile => {
		if (!person || !profile) return;

		if (personIsVirtualUser(person) && !person.email) {
			document.activeElement.blur();
			showModal({
				type: MODAL_TYPE.ADD_EMAIL,
				personId: person.id,
				callback: () => runProfileMutation(person, profile),
			});
		} else {
			runProfileMutation(person, profile);
		}
	};

	const updateStartDate = (person, startDate) => {
		const onSuccess = () => {
			showChangesSavedToast(2000);
		};
		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: person.id,
				startDate: startDate,
			},
			onSuccess
		);
	};

	const updateEndDate = (person, endDate) => {
		const onSuccess = () => {
			showChangesSavedToast(2000);
		};
		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: person.id,
				endDate: endDate,
			},
			onSuccess
		);
	};

	const handleStartDateChange = (person, value) => {
		updateStartDate(person, value.format('YYYY-MM-DD'));
	};

	const handleEndDateChange = (person, value) => {
		updateEndDate(person, value.format('YYYY-MM-DD'));
	};

	const handleClearStartDate = person => {
		updateStartDate(person, null);
	};

	const handleClearEndDate = person => {
		updateEndDate(person, null);
	};

	const getLinkPath = person => {
		if (person.isViewer) {
			return '/my-profile/account-configuration';
		} else {
			return '/admin/team/view-user/' + person.id;
		}
	};

	const showInternalCostPeriodsModal = () => {
		showModal({
			type: MODAL_TYPE.COST_PERIODS,
			personId: person.id,
		});
	};

	const showPersonSkillsModal = () => {
		showModal({
			type: MODAL_TYPE.PERSON_SKILLS,
			personId: person.id,
		});
	};

	const currencySymbol = Util.GetCurrencySymbol(company.currency);
	const currentCostPeriod = Util.getCurrentCostPeriod(person.costPeriods.edges);

	const createdByPerson = company.allPersons.edges.find(p => p.node.id === person.createdBy);
	const createdAtMoment = Moment(person.createdAt);

	const PersonSelector = () => (
		<td className={'selection'}>
			<Checkbox
				disabled={isCurrentUser(person)}
				checked={isSelectedPerson(person.id)}
				onClick={() => toggleSelectedPerson(person.id)}
				cy={'selector-checkbox'}
				userpilot={'bulk-update-checkbox'}
			/>
		</td>
	);

	const Actions = () => (
		<td data-cy={'person-actions'} className={'action-links'}>
			<ActionsMenu
				disabled={isCurrentUser(person)}
				customWidth={20}
				whiteInner={true}
				isWhite={false}
				options={getActionOptions(person)}
				leftOffset={-10}
			/>
		</td>
	);

	return (
		<tr
			data-cy="row-member"
			key={'row' + person.id}
			className="row-member person-row"
			data-userpilot={firstUser ? 'person-first' : null}
		>
			<PersonSelector />
			<NameInfo person={person} getLinkPath={getLinkPath} />
			<Role person={person} roles={roles} onRoleChange={onRoleChange} />
			{Util.hasSkills() && (
				<Skills person={person} formatMessage={formatMessage} showPersonSkillsModal={showPersonSkillsModal} />
			)}
			{Util.hasDepartments(company.modules) ? (
				<Department person={person} departments={departments} onDepartmentChange={onDepartmentChange} />
			) : null}
			<Profiles
				person={person}
				viewer={viewer}
				profiles={profiles}
				onProfileChange={profile => onProfileChange(profile)}
				disabled={isProfileUpdating}
			/>
			{hasFinancial ? (
				<Cost
					currentCostPeriod={currentCostPeriod}
					currencySymbol={currencySymbol}
					showInternalCostPeriodsModal={showInternalCostPeriodsModal}
					_useIntl={_useIntl}
				/>
			) : null}
			<StartDate
				person={person}
				handleClearStartDate={handleClearStartDate}
				handleStartDateChange={handleStartDateChange}
			/>
			<EndDate person={person} handleClearEndDate={handleClearEndDate} handleEndDateChange={handleEndDateChange} />
			{theEyeOptions && theEyeOptions.find(option => option.name === 'created-at').checked ? (
				<CreatedAt createdAtMoment={createdAtMoment} />
			) : null}
			{theEyeOptions && theEyeOptions.find(option => option.name === 'created-by').checked ? (
				<CreatedBy createdByPerson={createdByPerson} />
			) : null}
			{integrationsEnabled ? <Integrations person={person} viewer={viewer} getLinkPath={getLinkPath} /> : null}
			<SeatType person={person} getSeatTypeName={getSeatTypeName} />
			<Status person={person} getPersonStatus={getPersonStatus} />
			<Actions />
		</tr>
	);
};

PeoplePerson.propTypes = {
	person: PropTypes.object.isRequired,
	viewer: PropTypes.object,
	integrationsEnabled: PropTypes.bool,
};

export default PeoplePerson;
