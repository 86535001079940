import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {PageDeprecated} from './page-deprecated/PageDeprecated';
import {PageHeader} from 'web-components';

import GenericTooltip from '../../tooltips/generic_tooltip';
import OnboardingComponent from '../onboarding/onboarding_component';
import InputTitle from '../title/input_title';
import {FeedbackButton} from '../../buttons/feedback-button';
import Dropdown from '../../dropdowns/dropdown';
import {IconTooltip} from '@forecast-it/design-system';
import {hasFeatureFlag} from '../../../util/FeatureUtil';
import OptIntoFeatureFlagButton from './../../buttons/composite/OptIntoFeatureFlagButton';
import OptOutOfFeatureFlagButton from './../../buttons/composite/OptOutOfFeatureFlagButton';
import {scheduleUserPilotTriggerOnReloadAndReload} from '../../../util/UserPilotUtil';

const TYPE = {
	INPUT_REPORT_TITLE: 'input_report_title',
	ONBOARDING: 'onboarding',
	BETA: 'beta',
	FEEDBACK: 'feedback',
	DEPRECATED_PAGE: 'deprecated_page',
	BACK_BUTTON: 'back_button',
	DROPDOWN: 'dropdown',
	WARNING: 'warning',
	FEATURE_FLAG_OPT_BUTTON: 'feature_flag_opt_button',
};

export const TopHeaderBarWrapper = Styled.div`
    width: 100%;
    padding: ${({sidePadding = 24, bottomPadding = 0}) => `16px ${sidePadding}px ${bottomPadding}px`};
`;
export const TopHeaderBar = ({title, content = []}) => {
	const POSITION = {
		BEFORE: 'before',
		LEFT: 'left',
		RIGHT: 'right',
	};

	const getSortOrder = type => {
		switch (type) {
			case TYPE.INPUT_REPORT_TITLE:
				return {position: POSITION.LEFT, order: 1};
			case TYPE.ONBOARDING:
				return {position: POSITION.LEFT, order: 2};
			case TYPE.WARNING:
				return {position: POSITION.LEFT, order: 3};
			case TYPE.BETA:
				return {position: POSITION.LEFT, order: 4};
			case TYPE.FEEDBACK:
				return {position: POSITION.LEFT, order: 5};
			case TYPE.DROPDOWN:
				return {position: POSITION.LEFT, order: 6};
			case TYPE.FEATURE_FLAG_OPT_BUTTON:
				return {position: POSITION.LEFT, order: 7};
			case TYPE.DEPRECATED_PAGE:
				return {position: POSITION.RIGHT, order: 2};
			case TYPE.BACK_BUTTON:
				return {position: POSITION.BEFORE, order: 1};
			default:
				return {position: POSITION.LEFT, order: -1};
		}
	};

	// SORTING HERE
	const sortedLeftContent = useMemo(() => {
		return content
			.filter(element => getSortOrder(element.type).position === POSITION.LEFT)
			.sort((element1, element2) => (getSortOrder(element1.type).order < getSortOrder(element2.type).order ? -1 : 1));
	});
	const sortedRightContent = useMemo(() => {
		return content
			.filter(element => getSortOrder(element.type).position === POSITION.RIGHT)
			.sort((element1, element2) => (getSortOrder(element1.type).order < getSortOrder(element2.type).order ? -1 : 1));
	});
	const sortedBeforeContent = useMemo(() => {
		return content
			.filter(element => getSortOrder(element.type).position === POSITION.BEFORE)
			.sort((element1, element2) => (getSortOrder(element1.type).order < getSortOrder(element2.type).order ? -1 : 1));
	});

	const constructElement = (element, index) => {
		switch (element.type) {
			case TYPE.INPUT_REPORT_TITLE:
				return (
					<InputTitle
						key={`input-report-title-${index}`}
						userpilot={element.userpilot}
						defaultTitle={element.defaultTitle}
						width={element.width}
						value={element.value}
						onChange={element.onChange}
						placeholder={element.placeholder}
					/>
				);
			case TYPE.ONBOARDING:
				return (
					<OnboardingComponent
						key={`onboarding-component-${index}`}
						positionLeft
						title={element.title}
						options={element.options}
						helpCenterLink={element.helpCenterLink}
						noSubtitle={element.noSubtitle}
						noHelpCenter={element.noHelpCenter}
						thirdLevelOnboardingContainer
						subLink={element.subLink}
					></OnboardingComponent>
				);
			case TYPE.WARNING:
				return (
					<IconTooltip icon={'warning'} iconSize={'m'} width={element.width}>
						{element.tooltip}
					</IconTooltip>
				);
			case TYPE.BETA:
				return <GenericTooltip type="beta" key={'beta-tooltip' + index} />;

			case TYPE.FEEDBACK:
				return (
					<FeedbackButton
						key={'feedback-button' + index}
						link={
							element.link
								? element.link
								: 'https://experience.forecast.it/feedback?email=' + encodeURIComponent(element.userEmail)
						}
					/>
				);

			case TYPE.DEPRECATED_PAGE:
				return <PageDeprecated text={element.text} link={element.link} />;
			case TYPE.BACK_BUTTON:
				return <div className="back-button" onClick={element.onClick || element.history.goBack} />;
			case TYPE.DROPDOWN:
				return (
					<Dropdown
						dataCy={element.dataCy}
						listDataCy={element.listDataCy}
						buttonCy={element.buttonCy}
						key={`generic-dropdown-${index}`}
						customHeight={30}
						customWidth={element.customWidth}
						customClasses={element.customClasses}
						onChange={element.callback ? element.callback : null}
						options={element.dropdownOptions ? element.dropdownOptions : []}
						value={element.value}
						placeholder={element.placeholder ? element.placeholder : ''}
						clearable={element.clearable}
						clearText={element.clearText}
						clearTextDescription={element.clearTextDescription}
						clearTextDisabled={element.clearTextDisabled}
						disabled={element.disabled}
						tooltipEnabled={element.tooltipEnabled}
						tooltipProps={element.tooltipProps}
						userpilot={element.userpilot}
						hideLabel={true}
						multiSelect={element.multiSelect}
					/>
				);
			case TYPE.FEATURE_FLAG_OPT_BUTTON:
				if (hasFeatureFlag(element.featureFlag)) {
					return (
						<OptOutOfFeatureFlagButton
							featureFlag={element.featureFlag}
							userPilotContentId={element.userPilotContentId}
							onSuccess={() => window.location.reload()}
						/>
					);
				} else {
					return (
						<OptIntoFeatureFlagButton
							featureFlag={element.featureFlag}
							onSuccess={() => scheduleUserPilotTriggerOnReloadAndReload(element.userPilotContentId)}
						/>
					);
				}
			default:
				return element;
		}
	};

	return (
		<HeaderBarStyle>
			{sortedBeforeContent?.length ? (
				<TopHeaderBeforeContent>
					{sortedBeforeContent.map((element, index) => constructElement(element, index))}
				</TopHeaderBeforeContent>
			) : null}
			{title ? (
				<HeaderContainer>
					<div>
						<PageHeader key="title">{title}</PageHeader>
					</div>
				</HeaderContainer>
			) : null}
			{sortedLeftContent.map((element, index) => constructElement(element, index))}
			{sortedRightContent.length > 0 && (
				<TopHeaderRightContent>
					{sortedRightContent.map((element, index) => constructElement(element, index))}
				</TopHeaderRightContent>
			)}
		</HeaderBarStyle>
	);
};

TopHeaderBar.TYPE = TYPE;

TopHeaderBar.propTypes = {
	title: PropTypes.string,
	content: PropTypes.array,
};

const HeaderContainer = Styled.div`
	min-height:30px;
    margin-right: 24px;
    display: flex;
    align-items: center;
	white-space: nowrap;
`;

const HeaderBarStyle = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    > * {
			margin-right: 14px;
        }
    .custom-button-container {
        height: 30px;
    }

    .beta-button {
        height: 30px;
        margin-right: 0px;
    }
    
`;

const TopHeaderBeforeContent = Styled.div`
	position: relative;
	top: 1px;
`;

const TopHeaderRightContent = Styled.div`
	justify-content: flex-end;
	flex-grow: 1;
	display: flex;
`;
