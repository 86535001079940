import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import Info from '../../../components/info';
import ButtonOld from '../../shared/components/buttons/button/button';
import SettingsIdleTimesLineItem from '../../../components/settings-idle-times/settings_idle_times_line_item';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../../constants';
import {hasFeatureFlag, usingTimeOffApproval} from '../../shared/util/FeatureUtil';
import CreateIdleTimeMutation from '../../../mutations/create_idle_time_mutation';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import UpdateTimeLockMutation from '../../../mutations/update_time_lock_mutation.modern';
import * as tracking from '../../../tracking';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {FeatureToggle} from './FeatureToggle';
import {ContentBox, NoStylingNeeded, PageWrapper, TimeSubtitle, TimeTitle} from './TimeManagement_styled';
import {Button} from 'web-components';
import SetBambooHRIdleTimeMutation from '../../../mutations/SetBambooHRIdleTimeMutation';
import Warning from '../../../components/warning';
import {hasModule} from '../../shared/util/ModuleUtil';
import DirectApi from '../../../directApi';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {showToggleInternalTimeApprovalModal} from './TimeManagementFeatureToggles';
import TimeIncrementsSection from './time-registration-settings/TimeIncrementsSection';
import TimeRegistrationSettingsSection from './time-registration-settings/TimeRegistrationSettingsSection';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';

class settingsIdleTimes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInternalTIme: true,
			showTimeOff: true,
			newInternalIdleTimeName: '',
			newTimeOffTimeName: '',
			bambooHRTimeOffTypes: [],
			bambooHRLoading: false,
		};

		this.exceptions = {};

		this.superPropertyChecksum = trackPage('Settings Idle Times');
	}

	componentDidMount() {
		document.title = 'Time Management - Account - Forecast';
		// Segment
		tracking.trackPage('settings-idle-times');

		if (this.state.bambooHRTimeOffTypes.length === 0) {
			this.fetchBambooHRTimeOffTypes();
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	fetchBambooHRTimeOffTypes() {
		if (this.props.viewer.company.bambooHREnabled) {
			this.setState({bambooHRLoading: true});

			DirectApi.Fetch(`bamboohr/time_off_types`).then(timeOffTypes => {
				this.setState({
					bambooHRTimeOffTypes: timeOffTypes.map(t => ({
						value: t.id,
						label: t.name,
						forecastId: t.forecastId,
					})),
					bambooHRLoading: false,
				});
			});
		}
	}

	handleNewIdleTimeNameChange(type, e) {
		if (type === 'internal-time') {
			this.setState({newInternalIdleTimeName: e.target.value});
		} else {
			this.setState({newTimeOffTimeName: e.target.value});
		}
	}

	handleAddIdleTime(type) {
		const onSuccess = result => {
			const newStateObj = type === 'internal-time' ? {newInternalIdleTimeName: ''} : {newTimeOffTimeName: ''};
			this.setState(newStateObj);
		};

		const name = type === 'internal-time' ? this.state.newInternalIdleTimeName : this.state.newTimeOffTimeName;

		this.exceptions[name] = true;

		Util.CommitMutation(
			CreateIdleTimeMutation,
			{
				companyId: this.props.viewer.company.id,
				name: name,
				isInternalTime: type === 'internal-time',
			},
			onSuccess,
			true
		);
	}

	toggleSubSection(subSection, newValue) {
		this.setState({[subSection]: newValue});
	}

	handleKeyPressNewIdleTime(type, e) {
		if (e.key === 'Enter') {
			if (
				(type === 'internal-time' && this.state.newInternalIdleTimeName !== '') ||
				(type === 'time-off' && this.state.newTimeOffTimeName !== '')
			) {
				this.handleAddIdleTime(type);
			}
		}
	}

	importBambooHRTimeOffTypes() {
		showModal({
			type: MODAL_TYPE.BAMBOO_IMPORT_TIME_OFF_TYPES,
			companyId: this.props.viewer.company.id,
			callbackPositive: this.fetchBambooHRTimeOffTypes.bind(this),
		});
	}

	setBambooHRTimeOffType(idleTime, input) {
		const idleTimeId = idleTime.id;

		this.setState({bambooHRLoading: true}); // Is set to false by fetchBambooHRTimeOffTypes

		const onSuccess = () => {
			this.fetchBambooHRTimeOffTypes();
		};

		if (input) {
			// Linking
			Util.CommitMutation(
				SetBambooHRIdleTimeMutation,
				{forecastIdleTimeId: idleTimeId, bambooHRTimeOffTypeId: input[0]},
				() => onSuccess()
			);
		} else {
			// Unlinking
			const {formatMessage} = this.props.intl;

			showModal({
				type: MODAL_TYPE.GENERIC,
				className: 'default-warning-modal',
				content: <Warning messageId="settings_idle_times.bamboohr_time_off_types.unlink" />,
				defaultCallback: () => this.setState({bambooHRLoading: false}),
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.unlink'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: () =>
							Util.CommitMutation(
								SetBambooHRIdleTimeMutation,
								{forecastIdleTimeId: idleTimeId, bambooHRTimeOffTypeId: null},
								() => onSuccess()
							),
					},
				],
			});
		}
	}

	getSubSection(idleTimeType, title, expandedState, newIdleTimeName, newRecordNameInputPlaceholder, idleTimes, showBambooHR) {
		return (
			<NoStylingNeeded>
				<fieldset>
					<div className={'top-container' + (this.state[expandedState] ? '' : ' collapsed')}>
						<div data-userpilot={'settings-idle-times-' + idleTimeType}>
							<TimeTitle>
								{title} <span>({idleTimes.length})</span>
							</TimeTitle>
						</div>
						<Info infoMessage={''} useNewIcon={true} small={true} />
						<div className="controls">
							<div
								className={'expand-control' + (this.state[expandedState] ? ' collapse' : ' expand')}
								onClick={this.toggleSubSection.bind(this, expandedState, !this.state[expandedState])}
							/>
						</div>
					</div>
					{this.state[expandedState] ? (
						<>
							{showBambooHR ? (
								<div>
									<Button
										onClick={() => this.importBambooHRTimeOffTypes()}
										size={Button.SIZE.STANDARD}
										variant={Button.VARIANT.GREEN_FILLED}
									>
										{this.props.intl.formatMessage(
											{id: 'settings_clients.import_from'},
											{systemName: 'BambooHR'}
										)}
									</Button>
								</div>
							) : (
								''
							)}
							<TimeSubtitle>
								<FormattedMessage id={'settings_idle_times.description.' + idleTimeType} />
							</TimeSubtitle>
							<div className={'table-wrapper'}>
								<table>
									<thead>
										<tr>
											<th>{this.props.intl.formatMessage({id: 'common.name'})}</th>
											{showBambooHR ? <th>BambooHR</th> : null}
											<th />
										</tr>
									</thead>
									<tbody>
										{idleTimes.map((idleTime, index) => {
											return (
												<SettingsIdleTimesLineItem
													cy={idleTimeType}
													key={idleTimeType + index}
													hasAllocation={
														this.props.viewer.company.allocations &&
														this.props.viewer.company.allocations.edges.some(
															allocation => allocation.node.idleTime.id === idleTime.node.id
														)
													}
													companyId={this.props.viewer.company.id}
													idleTime={idleTime.node}
													viewerId={this.props.viewer.id}
													bambooHRTimeOffTypes={showBambooHR ? this.state.bambooHRTimeOffTypes : null}
													setBambooHRTimeOffType={input =>
														this.setBambooHRTimeOffType(idleTime.node, input)
													}
													bambooHRLoading={this.state.bambooHRLoading}
												/>
											);
										})}
									</tbody>
								</table>
								<div className="newIdleTimeControls">
									<input
										className="newIdleTimeNameInput"
										value={newIdleTimeName}
										onChange={this.handleNewIdleTimeNameChange.bind(this, idleTimeType)}
										placeholder={newRecordNameInputPlaceholder}
										onKeyPress={this.handleKeyPressNewIdleTime.bind(this, idleTimeType)}
										data-cy="new-idle-time-input"
										data-userpilot={'settings-idle-times-new-' + idleTimeType}
									/>
									<ButtonOld
										cy="add-new-idle-time-button"
										isDisabled={newIdleTimeName === ''}
										colorTheme={newIdleTimeName !== '' ? BUTTON_COLOR.GREEN : BUTTON_COLOR.LIGHTGREY}
										buttonStyle={BUTTON_STYLE.FILLED}
										className="addNewIdleTimeButton"
										onClick={this.handleAddIdleTime.bind(this, idleTimeType)}
									/>
								</div>
							</div>
						</>
					) : null}
				</fieldset>
			</NoStylingNeeded>
		);
	}

	toggleTimeApproval() {
		const {company} = this.props.viewer;
		if (!hasModule(MODULE_TYPES.TIME_LOCKING)) {
			return;
		}
		const usingTimeApproval = company.useTimeApproval;
		const toggleTimeApproval = () => {
			Util.CommitMutation(UpdateCompanyMutation, {
				id: company.id,
				useTimeApproval: !usingTimeApproval,
			});
		};
		const showActivateTimeApprovalModal = () => {
			showModal({
				type: MODAL_TYPE.ACTIVATE_TIME_APPROVAL,
				toggleTimeApproval: toggleTimeApproval,
			});
		};

		const incompatibleIntegrations = [];

		if (company.jiraCloudEnabled || company.jiraServerEnabled) incompatibleIntegrations.push('JIRA');
		if (company.unit4Enabled) incompatibleIntegrations.push('Unit4');
		if (company.harvestEnabled) incompatibleIntegrations.push('Harvest');

		if (!usingTimeApproval) {
			if (incompatibleIntegrations.length > 0) {
				showModal({
					type: MODAL_TYPE.INTEGRATION_INCOMPATIBLE,
					incompatibleIntegrations,
					featureName: 'Timesheet Approval',
					fullBlock: !hasFeatureFlag('time_approval_integrations'),
					handleConfirmButton: showActivateTimeApprovalModal,
				});
			} else {
				showActivateTimeApprovalModal();
			}
		} else {
			showModal({
				type: MODAL_TYPE.TEXT_BUTTONS,
				callbackPositive: toggleTimeApproval,
				headerTextId: 'approval.discard_approvals',
				buttonTextId: 'approval.discard',
				buttonDanger: true,
				modalTextIds: ['approval.discard_approvals_info', 'common.wish_to_continue'],
				cy: 'deactivate-time-approval',
			});
		}
	}

	toggleInternalTimeApproval() {
		const {formatMessage} = this.props.intl;
		const {company} = this.props.viewer;

		showToggleInternalTimeApprovalModal(
			company.id,
			{useTimeApproval: company.useTimeApproval, useInternalTimeApproval: company.useInternalTimeApproval},
			formatMessage
		);
	}

	toggleTimeOffApproval() {
		const {company} = this.props.viewer;
		if (!hasModule(MODULE_TYPES.TIME_LOCKING)) {
			return;
		}

		const useTimeOffApproval = usingTimeOffApproval(company.useTimeOffApproval);

		const incompatibleIntegrations = [];

		if (company.bambooHREnabled) incompatibleIntegrations.push('Bamboo');

		const toggleTimeOffApprovalMutation = () => {
			Util.CommitMutation(UpdateCompanyMutation, {
				id: company.id,
				useTimeOffApproval: !useTimeOffApproval,
				allUsersModifyTimeOff: !useTimeOffApproval ? false : company.allUsersModifyTimeOff,
			});
		};

		const showActivateTimeOffApprovalModal = () => {
			const {formatMessage} = this.props.intl;

			showModal({
				type: MODAL_TYPE.CONFIRM_GENERIC,
				title: formatMessage({
					id: useTimeOffApproval ? 'approval.disable_time_off_approval' : 'approval.enable_time_off_approval',
				}),
				warningMessage: formatMessage({
					id: useTimeOffApproval
						? 'approval.disable_time_off_approval_desc'
						: 'approval.enable_time_off_approval_desc',
				}),
				confirmText: formatMessage({id: 'settings.confirm'}),
				onConfirm: toggleTimeOffApprovalMutation,
			});
		};

		if (!useTimeOffApproval) {
			if (incompatibleIntegrations.length > 0) {
				showModal({
					type: MODAL_TYPE.INTEGRATION_INCOMPATIBLE,
					incompatibleIntegrations,
					featureName: 'Time Off Approval',
					fullBlock: !hasFeatureFlag('time_approval_integrations'),
					handleConfirmButton: toggleTimeOffApprovalMutation,
				});
			} else {
				showActivateTimeOffApprovalModal();
			}
		} else {
			showActivateTimeOffApprovalModal();
		}
	}

	toggleAllUsersModifyTimeOff() {
		const {company} = this.props.viewer;
		const allUsersModifyTimeOff = company.allUsersModifyTimeOff;

		Util.CommitMutation(UpdateCompanyMutation, {
			id: company.id,
			allUsersModifyTimeOff: !allUsersModifyTimeOff,
		});
	}

	toggleTimeLock() {
		const {company} = this.props.viewer;

		if (!hasModule(MODULE_TYPES.TIME_LOCKING)) {
			return;
		}

		const activateTimeLock = () => {
			const mutationObject = {companyId: company.id, useTimeLocking: !company.useTimeLocking};

			// Reset time lock if toggling off
			if (company.useTimeLocking) {
				mutationObject.lockedPeriodYear = null;
				mutationObject.lockedPeriodMonth = null;
				mutationObject.lockedPeriodDay = null;
			}

			Util.CommitMutation(UpdateTimeLockMutation, mutationObject);
		};

		const incompatibleIntegrations = [];

		if (company.jiraCloudEnabled || company.jiraServerEnabled) incompatibleIntegrations.push('JIRA');
		if (company.unit4Enabled) incompatibleIntegrations.push('Unit4');
		if (company.harvestEnabled) incompatibleIntegrations.push('Harvest');

		if (!company.useTimeLocking && incompatibleIntegrations.length > 0) {
			showModal({
				type: MODAL_TYPE.INTEGRATION_INCOMPATIBLE,
				incompatibleIntegrations,
				featureName: 'Timesheet Locking',
				fullBlock: false,
				handleConfirmButton: activateTimeLock,
			});
		} else {
			activateTimeLock();
		}
	}

	getHeader() {
		return (
			<TopHeaderBarWrapper bottomPadding={16}>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.time_management'})} content={[]} />
			</TopHeaderBarWrapper>
		);
	}

	getFeatureToggles = () => {
		const {formatMessage} = this.props.intl;
		const company = this.props.viewer.company;
		const hasTimeLockModule = hasModule(MODULE_TYPES.TIME_LOCKING);

		const features = [];
		features.push(
			<FeatureToggle
				key={'timesheet-approval'}
				featureTitleId={'approval.timesheet_approval'}
				featureSubtitleId={'approval.timesheet_approval_info'}
				formatMessage={formatMessage}
				checked={company.useTimeApproval}
				toggleFeature={this.toggleTimeApproval.bind(this)}
				locked={!hasTimeLockModule}
				cy={'time-approval'}
				helpCenterLink={'https://support.forecast.app/hc/en-us/articles/4611544983569'}
			/>
		);
		if (company.useTimeApproval) {
			features.push(
				<FeatureToggle
					key={'internal-timesheet-approval'}
					featureTitleId={'approval.internal_time_approval'}
					featureSubtitleId={'approval.internal_time_approval_info'}
					formatMessage={formatMessage}
					checked={company.useInternalTimeApproval}
					toggleFeature={this.toggleInternalTimeApproval.bind(this)}
					cy={'internal-time-approval'}
					helpCenterLink={'https://support.forecast.app/hc/en-us/articles/4611544983569'}
				/>
			);
		}
		if (hasFeatureFlag('pto_all_users_can_modify_time_off') && !company.bambooHREnabled) {
			features.push(
				<FeatureToggle
					key={'all_users_can_modify_time_off'}
					featureTitleId={'approval.modify_time_off'}
					featureSubtitleId={'approval.modify_time_off_info'}
					formatMessage={formatMessage}
					checked={company.allUsersModifyTimeOff}
					toggleFeature={this.toggleAllUsersModifyTimeOff.bind(this)}
					disabled={usingTimeOffApproval(company.useTimeOffApproval)}
					cy={'all_users_can_modify_time_off'}
				/>
			);
		}
		if (hasFeatureFlag('pto_timesheet_allocation_linking')) {
			features.push(
				<FeatureToggle
					key={'pto-timesheet-approval'}
					featureTitleId={'approval.pto_timesheet_approval'}
					featureSubtitleId={'approval.pto_timesheet_approval_info'}
					formatMessage={formatMessage}
					checked={usingTimeOffApproval(company.useTimeOffApproval)}
					toggleFeature={this.toggleTimeOffApproval.bind(this)}
					locked={!hasTimeLockModule}
					cy={'time-off-approval'}
					helpCenterLink={'https://support.forecast.app/hc/en-us/articles/16999831552529'}
				/>
			);
		}
		features.push(
			<FeatureToggle
				key={'timesheet-locking'}
				featureTitleId={'approval.timesheet_locking'}
				featureSubtitleId={'approval.timesheet_locking_info'}
				formatMessage={formatMessage}
				checked={company.useTimeLocking}
				toggleFeature={this.toggleTimeLock.bind(this)}
				locked={!hasTimeLockModule}
				cy={'time-locking'}
				helpCenterLink={'https://support.forecast.app/hc/en-us/articles/4918887270417'}
			/>
		);

		return features;
	};

	render() {
		const {formatMessage} = this.props.intl;
		const company = this.props.viewer.company;
		const internalIdleTimes = company.idleTimes.edges
			.filter(el => el.node.isInternalTime)
			.sort((a, b) => Util.sortAlphabetically(a.node.name, b.node.name, this.exceptions));
		const timeOffIdleTimes = company.idleTimes.edges
			.filter(el => !el.node.isInternalTime)
			.sort((a, b) => Util.sortAlphabetically(a.node.name, b.node.name, this.exceptions));

		const features = this.getFeatureToggles();

		return (
			<div className={'section-content settings-app settings-idle-times'} data-cy={'settings-idle-times-page'}>
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div style={{display: 'flex'}}>
							<PageWrapper>
								<TimeIncrementsSection company={this.props.viewer.company} />
								<TimeRegistrationSettingsSection company={this.props.viewer.company} />

								{features.length > 0 && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? (
									<ContentBox>{features}</ContentBox>
								) : null}
								<ContentBox>
									{this.getSubSection(
										'internal-time',
										formatMessage({id: 'common.internal_time'}),
										'showInternalTIme',
										this.state.newInternalIdleTimeName,
										formatMessage({id: 'settings_idle_times.new_internal_time_placeholder'}),
										internalIdleTimes
									)}
								</ContentBox>
								<ContentBox>
									{this.getSubSection(
										'time-off',
										formatMessage({id: 'common.time_off'}),
										'showTimeOff',
										this.state.newTimeOffTimeName,
										formatMessage({id: 'settings_idle_times.new_time_off_placeholder'}),
										timeOffIdleTimes,
										this.props.viewer.company.bambooHREnabled
									)}
								</ContentBox>
							</PageWrapper>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

settingsIdleTimes.propTypes = {};

const settingsIdleTimesQuery = graphql`
	query settingsIdleTimes_Query {
		viewer {
			actualPersonId
			component(name: "settings_idle_times")
			...settingsIdleTimes_viewer
		}
	}
`;

// Temporary solution, should belong to child component
graphql`
	fragment settingsIdleTimes_idleTime on IdleTime {
		id
		name
		isInternalTime
		disabled
		timeRegistrations(first: 1) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

export {settingsIdleTimesQuery};

export default injectIntl(
	createFragmentContainer(settingsIdleTimes, {
		viewer: graphql`
			fragment settingsIdleTimes_viewer on Viewer {
				id
				company {
					id
					useTimeApproval
					useInternalTimeApproval
					useTimeOffApproval
					allUsersModifyTimeOff
					useTimeLocking
					lockedPeriodYear
					lockedPeriodMonth
					lockedPeriodDay
					harvestEnabled
					jiraCloudEnabled
					jiraServerEnabled
					unit4Enabled
					bambooHREnabled
					...TimeRegistrationSettingsSection_company
					...TimeIncrementsSection_company
					allocations(first: 100000, idleTimeOnly: true) {
						edges {
							node {
								id
								idleTime {
									id
								}
							}
						}
					}
					idleTimes(first: 1000) @connection(key: "Company_idleTimes") {
						edges {
							node {
								...settingsIdleTimes_idleTime @relay(mask: false)
							}
						}
					}
				}
			}
		`,
	})
);
