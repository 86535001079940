import React, { useState } from 'react';
import { Modal, TextField } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
const RenameReportModal = ({ closeModal, reportName, renameReport }) => {
    const [name, setName] = useState(reportName);
    const [isNameValid, setIsNameValid] = useState(true);
    const validateName = (value) => {
        setName(value);
        if (value.length === 0) {
            setIsNameValid(false);
        }
        else {
            setIsNameValid(true);
        }
    };
    const handleRenameReport = () => {
        renameReport(name);
        closeModal();
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'report.rename' })),
        React.createElement(Modal.Content, null,
            React.createElement(TextField, { "data-cy": "input-report-name", onValueChange: validateName, value: name, size: "large", width: "flex" })),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: handleRenameReport, dataCy: 'modal-btn-rename-report', disabled: !isNameValid },
                React.createElement(FormattedMessage, { id: "common.rename" })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                React.createElement(FormattedMessage, { id: "common.cancel" })))));
};
export default RenameReportModal;
