import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {SavedFiltersSection} from './saved_filters_section';
import FilterQueryContent, {FilterQueryContentQuery} from './FilterQueryContent';
import {CloseContainer, Container, FilterButtonWrapper, QueryRenderContainer} from './filter.styled';
import {Button, CrossIconOld, FilterIcon} from 'web-components';
import {Button as DsButton} from '@forecast-it/design-system';
import {FILTER_SECTIONS, operatorOptionsPropType} from './dropdown_section';
import Util from '../../util/util';
import {ClickAwayListener} from '@material-ui/core';
import {
	deleteSavedFilter,
	filtersToArray,
	filtersToObject,
	filtersToOperators,
	getFilterFunctions,
	isOperator,
	renameSavedFilter,
	saveFilters,
	updateFilterOperator,
} from './filter_logic';
import tracking from '../../../../tracking';
import Moment from 'moment';
import {FilterSlider} from './FilterSlider';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import InlineLoader from '../inline-loader/inline_loader';
import {FILTER_TYPE, HIDDEN_FEATURES} from '../../../../constants';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {isEqual, union} from 'lodash';
import {hasFeatureFlag} from '../../util/FeatureUtil';
import {EVENT_ID} from '../../../../containers/event_manager';
import useSubscription from '../../hooks/useSubscription';

export {FILTER_SECTIONS};

function trackFilterSelection_old(filters, actionType) {
	filters.forEach(({section, filterType}) => {
		tracking.trackEvent('Filter Added', {section, filterType, actionType});
	});
}

const formatFilterValues = filterValues => {
	if (filterValues) {
		let spreadFilterValues = [];
		let nbFilter = 0;
		// Spread the values of the filter object into a single array of strings.
		Object.keys(filterValues).forEach(group => {
			const groupFilterValues = Object.keys(filterValues[group])
				.filter(key => !isOperator(key))
				.map(key => {
					nbFilter += filterValues[group][key].length;
					return filterValues[group][key].length > 0 ? group + 'Filter-' + key : null;
				})
				.filter(val => val !== null);
			spreadFilterValues = spreadFilterValues.concat(groupFilterValues);
		});

		return {filters: spreadFilterValues, nbFilterApplied: nbFilter};
	}
};

// See bottom of file for important implementation details
const FilterWrapper = ({
	intl,
	iconOnly,
	noMenu,
	viewer,
	operatorOptions,
	primaryFilters,
	taskFilters,
	timeRegFilters,
	invoiceFilters,
	expenseFilters,
	peopleFilters,
	projectFilters,
	skillFilters,
	labelFilters,
	placeholderFilters,
	resourceFilters,
	appliedFiltersName,
	useSavedReport,
	preAppliedFilters,
	defaultSection,
	onFiltersChange,
	controlledFiltersObject,
	filterSection,
	projectId,
	projectGroupId,
	programPrefix,
	companyProjectGroupId,
	companyProjectId,
	userpilot,
	cy,
	enableFilterMode,
	useDesignSystemButton,
	filterExpandedKey,
}) => {
	const allFilters = union(
		taskFilters,
		timeRegFilters,
		invoiceFilters,
		expenseFilters,
		peopleFilters,
		projectFilters,
		skillFilters,
		labelFilters,
		placeholderFilters,
		resourceFilters
	);

	const hasPhaseFilter = allFilters.includes(FILTER_TYPE.ALL_PROJECT_PHASES);

	if (
		Util.isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES) &&
		(projectFilters.includes('projectType') || projectFilters.includes('projectBudgetType'))
	) {
		const projectBudgetFilterIndex = projectFilters.includes('projectType')
			? projectFilters.indexOf('projectType')
			: projectFilters.indexOf('projectBudgetType');
		projectFilters.splice(projectBudgetFilterIndex, 1);
	}
	const containerRef = useRef();
	const [appliedFiltersList, setAppliedFiltersList] = useState([]);
	const [appliedFilterOperators, setAppliedFilterOperators] = useState({});
	const [show, setShow] = useState(false);
	const [savingFilter, setSavingFilter] = useState(false);
	const [renamingFilter, setRenamingFilter] = useState();
	const [selectedSection, setSelectedSection] = useState(defaultSection);
	const [preAppliedFiltersLoaded, setPreAppliedFiltersLoaded] = useState(false);

	useEffect(() => {
		setSelectedSection(defaultSection);
	}, [defaultSection]);

	useEffect(() => {
		const filtersList = filtersToArray(controlledFiltersObject);
		setAppliedFiltersList(filtersList);
		setAppliedFilterOperators(filtersToOperators(controlledFiltersObject));
	}, [controlledFiltersObject]);

	const savedFiltersTmp = [];
	viewer.filters.edges.forEach(filter => {
		if (filter.node.value && filter.node.name) {
			if (filterSection && filter.node.section !== filterSection) {
				return;
			}
			const filterValue = JSON.parse(filter.node.value);
			savedFiltersTmp.push({
				id: filter.node.id,
				name: filter.node.name,
				value: filtersToArray(filterValue),
				operators: filtersToOperators(filterValue),
				isFavoured: filter.node.isFavoured,
				updatedAt: Moment(filter.node.updatedAt),
			});
		}
	});

	//sort them by dates,  the newest one first
	const savedFilters = savedFiltersTmp.sort((a, b) => b.updatedAt.valueOf() - a.updatedAt.valueOf());

	const loadFilters = (appliedFilters, isPreApplied) => {
		const preAppliedAlreadyLoaded = isPreApplied && preAppliedFiltersLoaded;

		if (!preAppliedAlreadyLoaded) {
			trackEvent('Filters', 'Loaded', formatFilterValues(appliedFilters));

			const filtersList = filtersToArray(appliedFilters);
			trackFilterSelection_old(filtersList, 'page-load');
			setAppliedFiltersList(filtersList);
			setAppliedFilterOperators(filtersToOperators(appliedFilters));
			setPreAppliedFiltersLoaded(isPreApplied);
		}
	};

	useEffect(() => {
		const storedFilters = preAppliedFilters || JSON.parse(localStorage.getItem(appliedFiltersName));
		loadFilters(storedFilters, !!preAppliedFilters);
	}, [preAppliedFilters]);

	const trackFilterOperatorToggle = filterOperators => {
		// filterOperators example
		// {"project":{"label_operators":{"requireAll":true,"exclude":true},"contact_operators":{"exclude":false}}}

		let key = Object.keys(filterOperators)[0];

		// Using the state variable appliedFilterOperators as it contains the already stored
		// filter operators
		let currentFilterTypes = appliedFilterOperators[key];
		let newFilterTypes = filterOperators[key];

		let filterType = '';
		let operatorName = '';
		let enabled = false;
		let changedOperatorFound = false;

		// If clear is clicked
		if (newFilterTypes === undefined) {
			return;
		}

		for (const newFilterTypeKey of Object.keys(newFilterTypes)) {
			const newFilterType = newFilterTypes[newFilterTypeKey];
			const currentFilterType = currentFilterTypes?.[newFilterTypeKey];
			const operators = Object.keys(newFilterType);

			// If it's the first time using filter operators in general or the first time using a
			// specific operator of a filter type.
			if (currentFilterTypes === undefined || currentFilterType === undefined) {
				filterType = newFilterTypeKey.split('_')[0];
				operatorName = operators[0];
				enabled = newFilterType[operators[0]];
			} else {
				// Compare saved operators with new ones to find the one that has changed.
				if (!isEqual(newFilterType, currentFilterType)) {
					for (const operator of operators) {
						if (currentFilterType[operator] !== newFilterType[operator]) {
							filterType = newFilterTypeKey.split('_')[0];
							operatorName = operator;
							enabled = newFilterType[operator];
							changedOperatorFound = true;
							break;
						}
					}
				}
			}

			if (changedOperatorFound) {
				break;
			}
		}

		trackEvent('Filter Operator', 'Toggled', {filterType, operatorName, enabled});
	};

	const handleCloseFilter = () => {
		setShow(false);
		tracking.trackFilter(filtersToObject(appliedFiltersList));
		trackEvent('Filters', 'Applied', formatFilterValues(filtersToObject(appliedFiltersList)));
	};

	const changeAppliedFilters = filters => {
		const filterObject = filtersToObject(filters, appliedFilterOperators);
		!useSavedReport && Util.localStorageSetItem(appliedFiltersName, JSON.stringify(filterObject));
		setAppliedFiltersList(filters);
		onFiltersChange(filterObject, getFilterFunctions(filterObject));
	};

	const changeAppliedFiltersAndOperators = (filters, filterOperators, refresh = true) => {
		trackFilterOperatorToggle(filterOperators);
		const filterObject = filtersToObject(filters, filterOperators);
		!useSavedReport && Util.localStorageSetItem(appliedFiltersName, JSON.stringify(filterObject));
		setAppliedFiltersList(filters);
		setAppliedFilterOperators(filterOperators);
		if (refresh) {
			onFiltersChange(filterObject, getFilterFunctions(filterObject));
		}
	};

	const loadSavedFilters = (filters, operators) => {
		trackFilterSelection_old(filters, 'saved-filter');
		if (filters.length > 0) {
			trackEvent('Saved Filter', 'Selected', formatFilterValues(filtersToObject(filters)));
		}
		changeAppliedFiltersAndOperators(filters, operators);
	};

	const handleFilterModeChange = (section, filterType, operators, checked) => {
		const newAppliedFilterOperators = updateFilterOperator(appliedFilterOperators, section, filterType, operators, checked);
		const hasRelatedFilter = appliedFiltersList?.some(
			filter => filter.section === section && filter.filterType === filterType
		);
		changeAppliedFiltersAndOperators(appliedFiltersList, newAppliedFilterOperators, hasRelatedFilter);
	};

	function setFilterSelectionForFilterType(section, filterType, updatedSelection) {
		const updatedAppliedFilters = appliedFiltersList.filter(
			filter => !(filter.section === section && filter.filterType === filterType)
		);
		const newFilters = updatedSelection.map(selectedItem => ({
			section,
			filterType,
			id: selectedItem,
		}));
		changeAppliedFilters(updatedAppliedFilters.concat(newFilters));
	}

	// Adding and removing a single applied filter
	const onAddFilter = (section, filterType, ids, updatedSelection) => {
		if (updatedSelection) {
			setFilterSelectionForFilterType(section, filterType, updatedSelection);
		} else {
			const newFilters = ids.map(id => ({section, filterType: filterType, id}));
			changeAppliedFilters(appliedFiltersList.concat(newFilters));
		}

		trackFilterSelection_old([{section, filterType}], 'added');
		trackEvent('Filter', 'Selected', {section, filterType});
	};

	// Adding and removing a single applied filter
	const onRemoveFilter = (section, filterType, ids, updatedSelection) => {
		if (updatedSelection) {
			setFilterSelectionForFilterType(section, filterType, updatedSelection);
		} else {
			const newAppliedFilters = appliedFiltersList.filter(filter => {
				return !(filter.section === section && filter.filterType === filterType && ids.some(id => filter.id === id));
			});
			changeAppliedFilters(newAppliedFilters);
		}
		trackEvent('Filter', 'Deselected', {section, filterType});
	};

	const handleClearFilters = useCallback(() => {
		trackEvent('Filter', 'Cleared');
		changeAppliedFiltersAndOperators([], {});
	}, []);

	useSubscription(EVENT_ID.CLEAR_FILTERS, () => handleClearFilters());

	const handleClearShortCut = event => {
		if (event.altKey && event.shiftKey && event.keyCode === 88) {
			trackEvent('Shortcut', 'Pressed', {value: 'clear-filters'});
			handleClearFilters();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleClearShortCut);
		return () => {
			document.removeEventListener('keydown', handleClearShortCut);
		};
	}, []);
	const saveFilterHandler = filterName => {
		if (filterName === '' || filterName === null) {
			const newFilterNamesCount = savedFilters.filter(savedFilter =>
				savedFilter.name.toLowerCase().includes('new filter')
			).length;
			filterName = `New Filter ${newFilterNamesCount + 1}`;
		}
		saveFilters(
			filterName,
			viewer.id,
			filtersToObject(appliedFiltersList, appliedFilterOperators),
			filterSection,
			projectId,
			projectGroupId,
			() => setSavingFilter(false)
		);
	};

	const onClickAway = () => {
		const filterRect = containerRef.current.getBoundingClientRect();
		const bodyRect = document.body.getBoundingClientRect();
		if (Math.round(filterRect.x + filterRect.width) === Math.round(bodyRect.width)) {
			handleCloseFilter();
		}
	};

	const deleteFilterHandler = filterId => {
		deleteSavedFilter(filterId, viewer.id);
	};

	const renameFilter = (id, name) => {
		renameSavedFilter(id, name);
		setRenamingFilter(null);
	};

	const renderButton = () => {
		if (useDesignSystemButton) {
			return (
				<DsButton
					emphasis={'medium'}
					icon={'filter'}
					onClick={() => (show ? handleCloseFilter() : setShow(true))}
					data-cy={cy ? `${cy}-button` : ''}
				>
					{`${intl.formatMessage({id: 'common.filter-as-verb'})} ${
						appliedFiltersList.length > 0 ? `(${appliedFiltersList.length})` : ''
					}`}
				</DsButton>
			);
		} else if (iconOnly) {
			return (
				<Button
					isSquare
					onClick={() => (show ? handleCloseFilter() : setShow(true))}
					iconPosition={appliedFiltersList.length === 0 && 'last'}
					icon={appliedFiltersList.length === 0 ? color => <FilterIcon color={color} /> : null}
					variant={
						appliedFiltersList.length > 0 ? Button.VARIANT.PURPLE_FILLED : Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE
					}
					showText={appliedFiltersList.length > 0}
					userpilot={userpilot || 'filter-button-icon'}
					cy={cy ? `${cy}-button` : ''}
				>
					{appliedFiltersList.length > 0 ? `(${appliedFiltersList.length})` : ''}
				</Button>
			);
		} else {
			return (
				<FilterButtonWrapper>
					{savedFilters.length > 0 ? (
						<FilterSlider
							intl={intl}
							favouredFilters={savedFilters.slice(0, 3)}
							appliedFiltersList={appliedFiltersList}
							appliedFilterOperators={appliedFilterOperators}
							renamingFilter={renamingFilter}
							changeAppliedFilters={(filters, operators) => loadSavedFilters(filters, operators)}
							renameFilter={(id, name) => renameFilter(id, name)}
							filterExpandedKey={filterExpandedKey || ''}
						/>
					) : null}

					{appliedFiltersList.length === 0 ? (
						<Button
							icon={color => <FilterIcon color={color} />}
							iconPosition="last"
							onClick={() => (show ? handleCloseFilter() : setShow(true))}
							size={Button.SIZE.STANDARD}
							variant={
								appliedFiltersList.length > 0
									? Button.VARIANT.PURPLE_FILLED
									: Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE
							}
							userpilot={userpilot || 'filter-button'}
							cy={cy ? `${cy}-button` : ''}
						>
							{intl.formatMessage({id: 'common.filter-as-verb'})}
						</Button>
					) : (
						<Button
							onClick={() => (show ? handleCloseFilter() : setShow(true))}
							size={Button.SIZE.STANDARD}
							variant={
								appliedFiltersList.length > 0
									? Button.VARIANT.PURPLE_FILLED
									: Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE
							}
							userpilot={userpilot || 'filter-button'}
							cy={cy ? `${cy}-button` : ''}
						>
							{`${intl.formatMessage({id: 'common.filter-as-verb'})} ${
								appliedFiltersList.length > 0 ? `(${appliedFiltersList.length})` : ''
							}`}
						</Button>
					)}
				</FilterButtonWrapper>
			);
		}
	};

	const showSavedFilters = () => {
		return savedFilters.length > 0 || savingFilter;
	};

	const queryRenderVariables = {};
	if (projectId) {
		queryRenderVariables.projectId = projectId;
	}
	if (companyProjectGroupId) {
		queryRenderVariables.groupId = companyProjectGroupId.toString();
	}
	if (programPrefix) {
		queryRenderVariables.programPrefix = programPrefix;
	}

	queryRenderVariables.fetchAllProjectPhases = hasFeatureFlag('timesheet_remaster') && hasPhaseFilter;

	// Remove unavailable filters
	if (viewer.company && !viewer.company.skillLevelsEnabled) {
		const cleanedFilters = appliedFiltersList?.filter(filter => filter.filterType !== FILTER_TYPE.SKILL_LEVEL);
		if (cleanedFilters && cleanedFilters.length !== appliedFiltersList.length) {
			changeAppliedFilters(cleanedFilters);
		}
	}

	return (
		<>
			{renderButton()}
			{ReactDOM.createPortal(
				<ClickAwayListener onClickAway={onClickAway}>
					<Container
						ref={containerRef}
						className={show ? 'slide-in' : ''}
						width={535}
						noMenu={noMenu}
						data-cy={cy ? cy : ''}
					>
						<CloseContainer showSaved={showSavedFilters()} data-cy={'filter-close'}>
							<CrossIconOld color={'#535353'} onClick={() => handleCloseFilter()} />
						</CloseContainer>
						{showSavedFilters() && (
							<SavedFiltersSection
								intl={intl}
								savedFilters={savedFilters}
								appliedFilters={appliedFiltersList}
								appliedFilterOperators={appliedFilterOperators}
								changeAppliedFilters={(filters, operators) => loadSavedFilters(filters, operators)}
								showSaving={savingFilter}
								saveFilter={filterName => saveFilterHandler(filterName)}
								deleteFilter={filterId => deleteFilterHandler(filterId)}
								renamingFilter={renamingFilter}
								setRenamingFilter={id => setRenamingFilter(id)}
								validateRenameFilter={(id, name) => renameFilter(id, name)}
							/>
						)}
						<QueryRenderContainer>
							{show && (
								<ForecastQueryRenderer
									key="query-render-new-filter"
									query={FilterQueryContentQuery}
									variables={{...queryRenderVariables}}
									customLoader={() => <InlineLoader />}
									render={(relayProps, retry) => {
										return (
											<FilterQueryContent
												intl={intl}
												{...relayProps}
												operatorOptions={operatorOptions}
												appliedFilterOperators={appliedFilterOperators}
												primaryFilters={primaryFilters}
												onAddFilter={onAddFilter}
												onRemoveFilter={onRemoveFilter}
												appliedFiltersList={appliedFiltersList}
												selectedSection={selectedSection}
												setSelectedSection={setSelectedSection}
												filterSection={filterSection}
												taskFilters={taskFilters}
												timeRegFilters={timeRegFilters}
												invoiceFilters={invoiceFilters}
												expenseFilters={expenseFilters}
												projectFilters={projectFilters}
												skillFilters={skillFilters}
												labelFilters={labelFilters}
												peopleFilters={peopleFilters}
												placeholderFilters={placeholderFilters}
												resourceFilters={resourceFilters}
												onClearFilters={handleClearFilters}
												onRemoveFilters={onRemoveFilter}
												handleFilterModeChange={handleFilterModeChange}
												clickOnSave={() => setSavingFilter(true)}
												enableFilterMode={enableFilterMode}
											/>
										);
									}}
								/>
							)}
						</QueryRenderContainer>
					</Container>
				</ClickAwayListener>,
				document.querySelector('#root-portal-container')
			)}
		</>
	);
};

FilterWrapper.propTypes = {
	operatorOptions: operatorOptionsPropType,
	defaultSection: PropTypes.oneOf([
		FILTER_SECTIONS.PROJECTS,
		FILTER_SECTIONS.TASKS,
		FILTER_SECTIONS.PEOPLE,
		FILTER_SECTIONS.SKILLS,
		FILTER_SECTIONS.INVOICES,
		FILTER_SECTIONS.LABELS,
		FILTER_SECTIONS.PLACEHOLDERS,
		FILTER_SECTIONS.RESOURCES,
		FILTER_SECTIONS.EXPENSES,
	]).isRequired,
	show: PropTypes.bool,
	onClose: PropTypes.func,
	userpilot: PropTypes.string,
	cy: PropTypes.string,
	enableFilterMode: PropTypes.bool,
};

FilterWrapper.defaultProps = {
	onClose: () => false,
	taskFilters: [],
	projectFilters: [],
	peopleFilters: [],
	timeRegFilters: [],
	onFiltersChange: () => false,
};

export default injectIntl(FilterWrapper);
