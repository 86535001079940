import {
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedLabelOverComponent as LabelOverComponent,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {ItemCount, ItemCountText} from '../JiraProjectSyncModal.styled';
import React from 'react';
import {useIntl} from 'react-intl';

export const TotalsSection = ({states, syncingTotals}) => {
	const {formatMessage} = useIntl();

	const getTotalsCounters = () => {
		const totals = [];

		if (!states.selectedJiraProject) {
			return totals;
		}

		let phaseCount = 0;
		if (!states.syncVersionsToPhases && syncingTotals.syncEpicCount) {
			phaseCount += syncingTotals.syncEpicCount;
		}
		if (states.syncVersionsToPhases && syncingTotals.syncVersionsCount) {
			phaseCount += syncingTotals.syncVersionsCount;
		}
		if (phaseCount) {
			totals.push({
				type: formatMessage({id: 'common.phases'}),
				count: phaseCount,
			});
		}

		if (states.syncJiraSprints && syncingTotals.syncSprintCount) {
			totals.push({
				type: formatMessage({id: 'common.sprints'}),
				count: syncingTotals.syncSprintCount,
			});
		}

		if (states.syncEpicsToEpics && syncingTotals.syncEpicCount) {
			totals.push({
				type: formatMessage({id: 'integrations.jira.epics'}),
				count: syncingTotals.syncEpicCount,
			});
		}

		let taskCount = syncingTotals.syncTaskCount;

		if ((!states.syncVersionsToPhases || states.syncEpicsToEpics) && syncingTotals.syncEpicCount) {
			taskCount -= syncingTotals.syncEpicCount;
		}

		if (!states.syncJiraChildIssuesToToDos && syncingTotals.syncSubtasksCount) {
			taskCount += syncingTotals.syncSubtasksCount;
		}

		totals.push({
			type: formatMessage({id: 'common.tasks'}),
			count: taskCount,
		});

		if (states.syncJiraChildIssuesToToDos && syncingTotals.syncSubtasksCount) {
			totals.push({
				type: formatMessage({id: 'integrations.jira.todos'}),
				count: syncingTotals.syncSubtasksCount,
			});
		}

		return totals.map(total => {
			return (
				<FlexItem>
					<ItemCount>
						<ItemCountText>{`[${total.count}] ${total.type}`}</ItemCountText>
					</ItemCount>
				</FlexItem>
			);
		});
	};

	return (
		<FlexColumn gap={'m'}>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'l'}>
							{formatMessage({id: 'sync_jira_project_modal.sync.totals.label'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<Text type={'small'}>{formatMessage({id: 'sync_jira_project_modal.sync.totals.text'})}</Text>
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<LabelOverComponent label={'Totals'}>
					<FlexRow gap={'s'}>{getTotalsCounters()}</FlexRow>
				</LabelOverComponent>
			</FlexItem>
		</FlexColumn>
	);
};
