/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectDropdown_projects$ref = any;
export type APPROVAL_STATUS = "APPROVED" | "NOT_SUBMITTED" | "SUBMITTED" | "%future added value";
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type timesheetsPage_viewer$ref: FragmentReference;
declare export opaque type timesheetsPage_viewer$fragmentType: timesheetsPage_viewer$ref;
export type timesheetsPage_viewer = {|
  +id: string,
  +harvestUser: ?boolean,
  +unit4User: ?boolean,
  +actualPersonId: ?string,
  +email: ?string,
  +backendId: ?number,
  +language: ?LANGUAGE,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
        +updatedAt: ?string,
      |}
    |}>
  |},
  +calendarUser: ?{|
    +name: ?string
  |},
  +calendarTokenError: ?string,
  +calendarFetchEnabled: ?boolean,
  +enabledCalendars: ?$ReadOnlyArray<?string>,
  +company: ?{|
    +id: string,
    +useTimeApproval: ?boolean,
    +useInternalTimeApproval: ?boolean,
    +useTimeOffApproval: ?boolean,
    +harvestEnabled: ?boolean,
    +bambooHREnabled: ?boolean,
    +unit4Enabled: ?boolean,
    +characterLimit: ?number,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +idleTimes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isInternalTime: ?boolean,
          +favoured: ?boolean,
          +disabled: ?boolean,
        |}
      |}>
    |},
    +person: ?{|
      +holidayCalendar: ?{|
        +id: string,
        +holidayCalendarEntries: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +day: ?number,
              +month: ?number,
              +year: ?number,
              +name: ?string,
            |}
          |}>
        |},
        +name: ?string,
      |},
      +id: string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +firstName: ?string,
      +lastName: ?string,
      +fullName: ?string,
      +createdAt: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +systemUser: ?boolean,
      +initials: ?string,
      +active: ?boolean,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +excludeFromCompanyLockedPeriod: ?boolean,
      +submitLockedDateYear: ?number,
      +submitLockedDateMonth: ?number,
      +submitLockedDateDay: ?number,
      +harvestUser: ?boolean,
      +unit4User: ?boolean,
      +economicUser: ?boolean,
      +profilePictureId: ?string,
      +profilePictureDefaultId: ?number,
      +email: ?string,
    |},
    +customFieldDefinitions?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +key: ?string,
          +displayName: ?string,
          +entityType: ?string,
          +readOnly: ?boolean,
        |}
      |}>
    |},
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +fullAccessToProject: ?boolean,
        +companyProjectId: ?number,
        +status: ?PROJECT_STATUS,
        +billable: ?boolean,
        +projectColor: ?string,
        +projectStartYear: ?number,
        +projectStartMonth: ?number,
        +projectStartDay: ?number,
        +projectEndYear: ?number,
        +projectEndMonth: ?number,
        +projectEndDay: ?number,
        +manualProgressOnProjectEnabled: ?boolean,
        +manualProgressOnPhasesEnabled: ?boolean,
        +manualProgressOnTasksEnabled: ?boolean,
        +projectPerson: ?{|
          +role: ?{|
            +id: string,
            +name: ?string,
          |}
        |},
        +harvestProject: ?{|
          +id: string
        |},
        +unit4Project: ?{|
          +id: string,
          +activitiesEnabled: ?boolean,
        |},
        +isJiraProject: ?boolean,
      |},
      +$fragmentRefs: ProjectDropdown_projects$ref,
    |}>
  |},
  +timeRegistrations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +day: ?number,
        +month: ?number,
        +year: ?number,
        +minutesRegistered: ?number,
        +billableMinutesRegistered: ?number,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +notes: ?string,
        +invoiced: ?boolean,
        +xeroInvoiceId: ?string,
        +lockedInPeriod: ?boolean,
        +harvestTimeId: ?number,
        +allocationId: ?string,
        +harvestTaskIdInt: ?number,
        +harvestError: ?string,
        +unit4ActivityId: ?string,
        +approvalStatus: ?APPROVAL_STATUS,
        +project: ?{|
          +id: string,
          +name: ?string,
          +status: ?PROJECT_STATUS,
          +projectColor: ?string,
          +companyProjectId: ?number,
          +estimationUnit: ?string,
          +fullAccessToProject: ?boolean,
          +isProgramRevenueLocked: ?boolean,
          +programBudgetType: ?PROGRAM_BUDGET_TYPE,
          +program: ?{|
            +name: ?string,
            +prefix: ?string,
            +budgetType: ?PROGRAM_BUDGET_TYPE,
            +members: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +role: ?string,
                  +person: ?{|
                    +id: string
                  |},
                |}
              |}>
            |},
          |},
          +billable: ?boolean,
          +projectStartYear: ?number,
          +projectStartMonth: ?number,
          +projectStartDay: ?number,
          +projectEndYear: ?number,
          +projectEndMonth: ?number,
          +projectEndDay: ?number,
          +manualProgressOnProjectEnabled: ?boolean,
          +manualProgressOnPhasesEnabled: ?boolean,
          +manualProgressOnTasksEnabled: ?boolean,
          +projectPerson: ?{|
            +role: ?{|
              +id: string,
              +name: ?string,
            |}
          |},
          +harvestProject: ?{|
            +id: string,
            +name: ?string,
          |},
          +unit4Project: ?{|
            +id: string,
            +name: ?string,
            +activitiesEnabled: ?boolean,
          |},
          +client: ?{|
            +id: string,
            +name: ?string,
          |},
          +customProjectId: ?string,
        |},
        +task: ?{|
          +id: string,
          +name: ?string,
          +progress: ?number,
          +companyTaskId: ?number,
          +jiraId: ?string,
          +highPriority: ?boolean,
          +approved: ?boolean,
          +timeLeft: ?number,
          +favoured: ?boolean,
          +fullAccessToProject: ?boolean,
          +latestUiUpdateAt: ?string,
          +estimateForecastMinutes: ?number,
          +totalMinutesRegistered: ?number,
          +billable: ?boolean,
          +parentTaskId: ?string,
          +taskType: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineYear: ?number,
          +deadlineMonth: ?number,
          +deadlineDay: ?number,
          +statusColumnV2: ?{|
            +category: ?STATUS_CATEGORY
          |},
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +project: ?{|
            +id: string,
            +name: ?string,
            +status: ?PROJECT_STATUS,
            +companyProjectId: ?number,
            +isProgramRevenueLocked: ?boolean,
            +programBudgetType: ?PROGRAM_BUDGET_TYPE,
            +program: ?{|
              +name: ?string,
              +prefix: ?string,
              +budgetType: ?PROGRAM_BUDGET_TYPE,
              +members: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +role: ?string,
                    +person: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
            |},
            +billable: ?boolean,
            +projectColor: ?string,
            +estimationUnit: ?string,
            +fullAccessToProject: ?boolean,
            +projectStartYear: ?number,
            +projectStartMonth: ?number,
            +projectStartDay: ?number,
            +projectEndYear: ?number,
            +projectEndMonth: ?number,
            +projectEndDay: ?number,
            +manualProgressOnProjectEnabled: ?boolean,
            +manualProgressOnPhasesEnabled: ?boolean,
            +manualProgressOnTasksEnabled: ?boolean,
            +projectPerson: ?{|
              +role: ?{|
                +id: string,
                +name: ?string,
              |}
            |},
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
            +harvestProject: ?{|
              +id: string,
              +name: ?string,
            |},
            +unit4Project: ?{|
              +id: string,
              +name: ?string,
              +activitiesEnabled: ?boolean,
            |},
            +customProjectId: ?string,
          |},
          +phase: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
        +idleTime: ?{|
          +id: string,
          +name: ?string,
          +isInternalTime: ?boolean,
          +favoured: ?boolean,
          +disabled: ?boolean,
        |},
        +person: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +isViewer: ?boolean,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
        +customFieldValues: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +key: ?string,
              +displayName: ?string,
              +value: ?string,
              +readOnly: ?boolean,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +task: ?{|
    +id: string,
    +name: ?string,
    +companyTaskId: ?number,
    +timeLeft: ?number,
    +estimateForecastMinutes: ?number,
    +totalMinutesRegistered: ?number,
    +fullAccessToProject: ?boolean,
    +phase: ?{|
      +id: string,
      +name: ?string,
    |},
    +project: ?{|
      +id: string,
      +name: ?string,
      +projectColor: ?string,
      +companyProjectId: ?number,
      +fullAccessToProject: ?boolean,
      +harvestProject: ?{|
        +id: string
      |},
      +unit4Project: ?{|
        +id: string,
        +activitiesEnabled: ?boolean,
      |},
      +projectPerson: ?{|
        +role: ?{|
          +id: string,
          +name: ?string,
        |}
      |},
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
    +statusColumnV2: ?{|
      +id: string,
      +name: ?string,
      +category: ?STATUS_CATEGORY,
    |},
    +assignedPersons: ?$ReadOnlyArray<?{|
      +id: string,
      +fullName: ?string,
      +active: ?boolean,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +$refType: timesheetsPage_viewer$ref,
|};
export type timesheetsPage_viewer$data = timesheetsPage_viewer;
export type timesheetsPage_viewer$key = {
  +$data?: timesheetsPage_viewer$data,
  +$fragmentRefs: timesheetsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "harvestUser",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4User",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v0/*: any*/),
  (v5/*: any*/)
],
v11 = {
  "kind": "Literal",
  "name": "first",
  "value": 100000
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favoured",
  "storageKey": null
},
v13 = [
  (v0/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isInternalTime",
    "storageKey": null
  },
  (v12/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "disabled",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readOnly",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnProjectEnabled",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnPhasesEnabled",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnTasksEnabled",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "personId",
      "variableName": "personId"
    }
  ],
  "concreteType": "ProjectPerson",
  "kind": "LinkedField",
  "name": "projectPerson",
  "plural": false,
  "selections": [
    (v17/*: any*/)
  ],
  "storageKey": null
},
v39 = [
  (v0/*: any*/)
],
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "HarvestProjectType",
  "kind": "LinkedField",
  "name": "harvestProject",
  "plural": false,
  "selections": (v39/*: any*/),
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activitiesEnabled",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "concreteType": "Unit4ProjectType",
  "kind": "LinkedField",
  "name": "unit4Project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v41/*: any*/)
  ],
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProgramRevenueLocked",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programBudgetType",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "concreteType": "ProgramType",
  "kind": "LinkedField",
  "name": "program",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectServiceProgramMemberTypeConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectServiceProgramMemberTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramMemberType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": (v39/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "concreteType": "HarvestProjectType",
  "kind": "LinkedField",
  "name": "harvestProject",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "Unit4ProjectType",
  "kind": "LinkedField",
  "name": "unit4Project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    (v41/*: any*/)
  ],
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimateForecastMinutes",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalMinutesRegistered",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "googleTaskId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDateString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDateString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchCustomFields",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "customFieldDefinitions"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "timeRegistrations"
        ]
      }
    ]
  },
  "name": "timesheetsPage_viewer",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CalendarUser",
      "kind": "LinkedField",
      "name": "calendarUser",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarTokenError",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarFetchEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledCalendars",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useInternalTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bambooHREnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "characterLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "includeDisabled",
              "value": false
            }
          ],
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(includeDisabled:false)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v11/*: any*/)
          ],
          "concreteType": "IdleTimeTypeConnection",
          "kind": "LinkedField",
          "name": "idleTimes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTimeTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "idleTimes(first:100000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "personId"
            }
          ],
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HolidayCalendar",
              "kind": "LinkedField",
              "name": "holidayCalendar",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 10000
                    }
                  ],
                  "concreteType": "HolidayCalendarEntryTypeConnection",
                  "kind": "LinkedField",
                  "name": "holidayCalendarEntries",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HolidayCalendarEntryTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "HolidayCalendarEntry",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v16/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "holidayCalendarEntries(first:10000)"
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            (v0/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "systemUser",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "initials",
              "storageKey": null
            },
            (v21/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tuesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wednesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thursday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "friday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saturday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sunday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "excludeFromCompanyLockedPeriod",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitLockedDateYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitLockedDateMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitLockedDateDay",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "economicUser",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureDefaultId",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "condition": "fetchCustomFields",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "customFieldDefinitions",
              "args": null,
              "concreteType": "CustomFieldDefinitionTypeConnection",
              "kind": "LinkedField",
              "name": "__Company_customFieldDefinitions_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomFieldDefinitionTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomFieldDefinition",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v22/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityType",
                          "storageKey": null
                        },
                        (v23/*: any*/),
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                (v9/*: any*/)
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeReadOnly",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "excludeRestricted",
          "value": true
        },
        (v11/*: any*/)
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v5/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/),
                (v26/*: any*/),
                (v27/*: any*/),
                (v28/*: any*/),
                (v29/*: any*/),
                (v30/*: any*/),
                (v31/*: any*/),
                (v32/*: any*/),
                (v33/*: any*/),
                (v34/*: any*/),
                (v35/*: any*/),
                (v36/*: any*/),
                (v37/*: any*/),
                (v38/*: any*/),
                (v40/*: any*/),
                (v42/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isJiraProject",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectDropdown_projects"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(excludeReadOnly:true,excludeRestricted:true,first:100000)"
    },
    {
      "alias": "timeRegistrations",
      "args": null,
      "concreteType": "TimeRegTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_timeRegistrations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRegTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutesRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billableMinutesRegistered",
                  "storageKey": null
                },
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiced",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "xeroInvoiceId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lockedInPeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "harvestTimeId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allocationId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "harvestTaskIdInt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "harvestError",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit4ActivityId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approvalStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    (v26/*: any*/),
                    (v28/*: any*/),
                    (v25/*: any*/),
                    (v43/*: any*/),
                    (v24/*: any*/),
                    (v44/*: any*/),
                    (v45/*: any*/),
                    (v46/*: any*/),
                    (v27/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/),
                    (v31/*: any*/),
                    (v32/*: any*/),
                    (v33/*: any*/),
                    (v34/*: any*/),
                    (v35/*: any*/),
                    (v36/*: any*/),
                    (v37/*: any*/),
                    (v38/*: any*/),
                    (v47/*: any*/),
                    (v48/*: any*/),
                    (v49/*: any*/),
                    (v50/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "task",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "progress",
                      "storageKey": null
                    },
                    (v51/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jiraId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "highPriority",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approved",
                      "storageKey": null
                    },
                    (v52/*: any*/),
                    (v12/*: any*/),
                    (v24/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latestUiUpdateAt",
                      "storageKey": null
                    },
                    (v53/*: any*/),
                    (v54/*: any*/),
                    (v27/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "parentTaskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deadlineYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deadlineMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deadlineDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "statusColumnV2",
                      "plural": false,
                      "selections": [
                        (v55/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "assignedPersons",
                      "plural": true,
                      "selections": (v39/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v5/*: any*/),
                        (v26/*: any*/),
                        (v25/*: any*/),
                        (v44/*: any*/),
                        (v45/*: any*/),
                        (v46/*: any*/),
                        (v27/*: any*/),
                        (v28/*: any*/),
                        (v43/*: any*/),
                        (v24/*: any*/),
                        (v29/*: any*/),
                        (v30/*: any*/),
                        (v31/*: any*/),
                        (v32/*: any*/),
                        (v33/*: any*/),
                        (v34/*: any*/),
                        (v35/*: any*/),
                        (v36/*: any*/),
                        (v37/*: any*/),
                        (v38/*: any*/),
                        (v49/*: any*/),
                        (v47/*: any*/),
                        (v48/*: any*/),
                        (v50/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v56/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "idleTime",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isViewer",
                      "storageKey": null
                    },
                    (v17/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": "customFieldValues",
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "fetchCustomFields",
                      "variableName": "fetchCustomFields"
                    }
                  ],
                  "concreteType": "CustomFieldValueTypeConnection",
                  "kind": "LinkedField",
                  "name": "__TimeRegistrations_customFieldValues_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomFieldValueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CustomFieldValue",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v22/*: any*/),
                            (v6/*: any*/),
                            (v23/*: any*/),
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "googleTaskId"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v51/*: any*/),
        (v52/*: any*/),
        (v53/*: any*/),
        (v54/*: any*/),
        (v24/*: any*/),
        (v56/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v28/*: any*/),
            (v25/*: any*/),
            (v24/*: any*/),
            (v40/*: any*/),
            (v42/*: any*/),
            (v38/*: any*/),
            (v49/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusColumnV2",
          "kind": "LinkedField",
          "name": "statusColumnV2",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v55/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "assignedPersons",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v17/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd8daa19324195df5e610c0fdfc3bc1ec';

module.exports = node;
