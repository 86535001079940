import React, {useState} from 'react';
import Moment from 'moment';
import SecondaryNavigation from '../../navigation/secondary/SecondaryNavigation';
import {Redirect, Switch} from 'react-router-dom';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import {createFragmentContainer, graphql} from 'react-relay';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import ProjectScopingPage, {ProjectScopingPageQuerry} from './scoping-page/ProjectScopingPage';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import Util from '../../shared/util/util';
import {VersionReloadRoute} from '../../../containers/version_reload_route';
import ProjectWorkflowPage, {ProjectWorkflowPageQuery} from './workflow-page/ProjectWorkflowPage';
import ProjectSprintPageV4, {ProjectSprintPageV4Query} from './sprints-page-v4/ProjectSprintPageV4';
import InitialPlanPage, {InitialPlanPageQuery} from '../../../components/initial-plan/InitialPlanPage';
import ProjectRetainerPeriods, {projectRetainerPeriodsQuery} from '../../../containers/new-ui/project/project_retainer_periods';
import {SecondaryNavStateProvider} from '../../navigation/secondary/SecondaryNavigationStateManager';
import ProjectBudgetV3, {projectBudgetV3Query} from '../../../containers/project/project_budget_v3';
import {hasModule} from '../../shared/util/ModuleUtil';
import {BUDGET_TYPE, MODULE_TYPES} from '../../../constants';
import ProjectHealthPage, {ProjectHealthPageQuery} from '../../../containers/project/project_health/ProjectHealthPage';
import CanvasScheduling, {CanvasSchedulingQuery} from '../../../components/canvas-scheduling/canvas_scheduling';
import ProjectClient, {projectClientQuery} from '../../../containers/project/project_client';
import ProjectFiles, {projectFilesQuery} from '../../../containers/project/project_files';
import ProjectSettingsPage, {ProjectSettingsPageQuery} from '../../../containers/project/project_settings/ProjectSettingsPage';
import ProjectGroupConnectedProjects, {
	projectGroupConnectedProjectsQuery,
} from '../../../containers/project_group/project_group_connected_projects';
import ProjectGroupSettingsPage, {
	ProjectGroupSettingsPageQuery,
} from '../../../containers/project/project_settings/ProjectGroupSettingsPage';
import {projectPrefixMatcher, projectPrefixMatcherCompanyProjectIdOnly} from '../../../containers/routerv2';
import {projectUrl} from '../../../directApi';

const projectPrefix = end =>
	hasFeatureFlag('custom_project_ids')
		? `${projectPrefixMatcher}/${end}`
		: `${projectPrefixMatcherCompanyProjectIdOnly}/${end}`;

const projectGroupPrefix = end => `/connected/X-:groupId(\\d+)/${end}`;

const ProjectRoutes = ({viewer, availableFeatureFlags, modules, buyNowTime}) => {
	const [showSecondaryNavigation, setShowSecondaryNavigation] = useState(true);

	const [currentPath, updateCurrentPath] = useState(window.location.pathname + window.location.hash);

	return (
		<SecondaryNavStateProvider>
			<div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
				{showSecondaryNavigation && (
					<SecondaryNavigation
						project={viewer.project}
						projectGroup={viewer.projectGroup}
						currentPath={currentPath}
						updateCurrentPath={updateCurrentPath}
					/>
				)}
				<Switch>
					<VersionReloadRoute
						path={projectPrefix('scoping')}
						children={({match}) => {
							return (
								<ForecastQueryRenderer
									key={'query-render-projectPhases-new'}
									availableFeatureFlags={availableFeatureFlags}
									query={ProjectScopingPageQuerry}
									variables={{
										...match.params,
										withEpics: hasFeatureFlag('forecast_epics'),
										noStaleTasks: hasFeatureFlag('scoping_no_stale_tasks'),
										fetchLazyData: true,
										fetchCustomFields: Util.hasCustomFields(),
										personId: viewer.actualPersonId,
									}}
									requireProject={true}
									authorizeAccessRoute={'project-phases'}
									render={(relayProps, retry) => (
										<ProjectScopingPage {...relayProps} retry={retry} buyNowTime={buyNowTime} />
									)}
								/>
							);
						}}
					/>
					<VersionReloadRoute
						path={[projectPrefix('task-board')]}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectWorkflowPage' + match.params.projectId}
								query={ProjectWorkflowPageQuery}
								variables={{
									projectId: match.params.projectId,
								}}
								requireProject={true}
								authorizeAccessRoute={'project-faster-workflow'}
								render={(relayProps, retry) => (
									<ProjectWorkflowPage
										{...relayProps}
										matchProjectId={match.params.projectId}
										retry={retry}
										buyNowTime={buyNowTime}
									/>
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectPrefix('sprints')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectSprints' + match.params.projectId}
								query={ProjectSprintPageV4Query}
								variables={{
									...match.params,
									fetchLazyData: false,
									onlyActiveSprint: true,
									personId: viewer.actualPersonId,
								}}
								requireProject={true}
								authorizeAccessRoute={'project-sprint'}
								render={(relayProps, retry) => (
									<ProjectSprintPageV4 {...relayProps} retry={retry} buyNowTime={buyNowTime} />
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={[projectPrefix('baseline')]}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectInitialPlan' + match.params.projectId}
								query={InitialPlanPageQuery}
								variables={match.params}
								requireProject={true}
								authorizeAccessRoute={'project-initial-plan'}
								fetchPolicy={'network-only'}
								render={(relayProps, retry) => (
									<InitialPlanPage {...relayProps} retry={retry} buyNowTime={buyNowTime} />
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={[projectPrefix('periods'), projectPrefix('retainer-tracking')]}
						children={({match}) => {
							const today = Moment();
							const variables = match.params;
							variables.startDay = today.date();
							variables.startMonth = today.month() + 1;
							variables.startYear = today.year();
							variables.hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');

							return (
								<ForecastQueryRenderer
									key={'query-render-projectRetainerPeriods' + match.params.projectId}
									query={projectRetainerPeriodsQuery}
									variables={match.params}
									requireProject={true}
									authorizeAccessRoute={'project-periods'}
									render={(relayProps, retry) => <ProjectRetainerPeriods {...relayProps} retry={retry} />}
								/>
							);
						}}
					/>
					<VersionReloadRoute
						path={[projectPrefix('budget'), projectPrefix('financials')]}
						children={({match}) => {
							const variables = match.params;
							const endOfMonth = Moment().endOf('month');
							variables.currentYear = endOfMonth.year();
							variables.currentMonth = endOfMonth.month() + 1;
							variables.currentMonthLastDay = endOfMonth.date();

							return (
								<ForecastQueryRenderer
									key={'query-render-projectBudgetV3' + match.params.projectId}
									query={projectBudgetV3Query}
									variables={variables}
									requireProject={true}
									authorizeAccessRoute={'project-budget'}
									render={(relayProps, retry) => (
										<ProjectBudgetV3
											{...relayProps}
											retry={retry}
											currentPath={currentPath}
											updateCurrentPath={updateCurrentPath}
										/>
									)}
								/>
							);
						}}
					/>
					{hasModule(MODULE_TYPES.NOVA_INSIGHTS) && (
						<VersionReloadRoute
							path={projectPrefix('nova-insights')}
							children={({match}) => (
								<ForecastQueryRenderer
									key={'query-render-projectHealth' + match.params.projectId}
									query={ProjectHealthPageQuery}
									variables={match.params}
									requireProject={true}
									authorizeAccessRoute={'project-health'}
									render={(relayProps, retry) => {
										// Not currently supporting children of connected projects
										if (
											relayProps.viewer.project.projectGroupId ||
											relayProps.viewer.project.budgetType === BUDGET_TYPE.RETAINER
										) {
											return (
												<Redirect
													to={`${projectUrl(
														relayProps.viewer.project.companyProjectId,
														relayProps.viewer.project.customProjectId
													)}/workflow`}
												/>
											);
										}
										return <ProjectHealthPage {...relayProps} retry={retry} />;
									}}
								/>
							)}
						/>
					)}
					<VersionReloadRoute
						path={[projectPrefix('schedule'), projectPrefix('timeline')]}
						children={({match}) => (
							<ForecastQueryRenderer
								key="query-render-time-management"
								query={CanvasSchedulingQuery}
								variables={{
									projectId: match.params.projectId,
								}}
								match={match}
								render={(relayProps, retry) => (
									<CanvasScheduling
										key={'projectId' + match.params.projectId}
										isProjectTimeline={true}
										setShowSecondaryNavigation={setShowSecondaryNavigation}
										retry={retry}
										{...relayProps}
									/>
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectPrefix('client')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectClient' + match.params.projectId}
								query={projectClientQuery}
								variables={match.params}
								requireProject={true}
								authorizeAccessRoute={'project-client'}
								render={relayProps => <ProjectClient {...relayProps} />}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectPrefix('files')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectFiles' + match.params.projectId}
								query={projectFilesQuery}
								variables={match.params}
								requireProject={true}
								authorizeAccessRoute={'project-files'}
								render={relayProps => <ProjectFiles {...relayProps} />}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectPrefix('settings')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectSettings' + match.params.projectId}
								query={ProjectSettingsPageQuery}
								variables={{
									...match.params,
									loadCustomFields: Util.hasCustomFields(),
								}}
								requireProject={true}
								authorizeAccessRoute={'project-settings'}
								render={(relayProps, retry) => <ProjectSettingsPage {...relayProps} retry={retry} />}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectGroupPrefix('sprints')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectGroupSprints' + match.params.groupId}
								query={ProjectSprintPageV4Query}
								variables={{
									...match.params,
									fetchLazyData: false,
									onlyActiveSprint: true,
									personId: viewer.actualPersonId,
								}}
								requireProject={true}
								authorizeAccessRoute={'project-sprint'}
								render={(relayProps, retry) => (
									<ProjectSprintPageV4 {...relayProps} retry={retry} buyNowTime={buyNowTime} />
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={[projectGroupPrefix('workflow'), projectGroupPrefix('task-board')]}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectWorkflowPage' + match.params.groupId}
								query={ProjectWorkflowPageQuery}
								variables={{
									groupId: match.params.groupId,
								}}
								requireProject={true}
								authorizeAccessRoute={'project-faster-workflow'}
								render={(relayProps, retry) => (
									<ProjectWorkflowPage
										{...relayProps}
										matchGroupId={match.params.groupId}
										retry={retry}
										buyNowTime={buyNowTime}
									/>
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={[projectGroupPrefix('schedule'), projectGroupPrefix('timeline')]}
						children={({match}) => (
							<ForecastQueryRenderer
								key="query-render-time-management"
								query={CanvasSchedulingQuery}
								variables={{
									groupId: match.params.groupId,
								}}
								match={match}
								render={(relayProps, retry) => (
									<CanvasScheduling
										key={'projectId' + match.params.groupId}
										isProjectTimeline={true}
										setShowSecondaryNavigation={setShowSecondaryNavigation}
										retry={retry}
										{...relayProps}
									/>
								)}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectGroupPrefix('connected-projects')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectGroupProjects' + match.params.groupId}
								query={projectGroupConnectedProjectsQuery}
								variables={match.params}
								requireProject={true}
								authorizeAccessRoute={'connected-projects'}
								render={relayProps => <ProjectGroupConnectedProjects {...relayProps} />}
							/>
						)}
					/>
					<VersionReloadRoute
						path={projectGroupPrefix('group-settings')}
						children={({match}) => (
							<ForecastQueryRenderer
								key={'query-render-projectGroupSettings' + match.params.groupId}
								query={ProjectGroupSettingsPageQuery}
								variables={match.params}
								requireProject={true}
								authorizeAccessRoute={'group-settings'}
								render={(relayProps, retry) => <ProjectGroupSettingsPage {...relayProps} retry={retry} />}
							/>
						)}
					/>
					<Redirect from={projectPrefix('*/T:taskId')} to={projectPrefix('task-board/T:taskId')} />
					<Redirect from={projectPrefix('*')} to={projectPrefix('task-board')} />
					<Redirect from={projectGroupPrefix('*')} to={projectGroupPrefix('task-board')} />
				</Switch>
			</div>
		</SecondaryNavStateProvider>
	);
};

const ProjectRoutesQuery = graphql`
	query ProjectRoutes_Query($projectId: String, $projectGroupId: String) {
		viewer {
			actualPersonId
			component(name: "project")
			project(id: $projectId) {
				id
				fullAccessToProject
				readOnlyAccess
			}
			projectGroup(id: $projectGroupId) {
				id
				readOnlyAccess
			}
			...ProjectRoutes_viewer @arguments(projectId: $projectId, projectGroupId: $projectGroupId)
		}
	}
`;

export {ProjectRoutesQuery};

export default withSocketHandling(
	createFragmentContainer(ProjectRoutes, {
		viewer: graphql`
			fragment ProjectRoutes_viewer on Viewer
			@argumentDefinitions(projectId: {type: "String"}, projectGroupId: {type: "String"}) {
				actualPersonId
				project(id: $projectId) {
					...SecondaryNavigation_project
				}
				projectGroup(id: $projectGroupId) {
					...SecondaryNavigation_projectGroup
				}
			}
		`,
	})
);
