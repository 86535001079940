import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { ProjectIndicator } from 'web-components';
import { hasFeatureFlag } from '../../util/FeatureUtil';
import { ProjectIndicatorWrapper } from './support/DeprecatedProjectIndicator.styled';
import { getProjectLink } from './support/ProjectIndicatorLogic';
export const DeprecatedProjectIndicatorComponent = ({ companyProjectId, customProjectId, projectName, projectColor, disableLink = false, clearSubPath = false, showProjectName = false, noWidthLimit = true, noWrapper = false, small = false, }) => {
    const showCustomId = hasFeatureFlag('custom_project_ids');
    const customId = showCustomId ? customProjectId : null;
    const link = getProjectLink(showCustomId ? customProjectId : companyProjectId === null || companyProjectId === void 0 ? void 0 : companyProjectId.toString(), clearSubPath, disableLink);
    // Workaround for nested_dropdown that breaks with the 100% width added by the ProjectIndicatorWrapper
    const WrapperComponent = noWrapper ? React.Fragment : ProjectIndicatorWrapper;
    return (React.createElement(WrapperComponent, null,
        React.createElement(ProjectIndicator, { id: companyProjectId, customId: customId, noWidthLimit: showCustomId && noWidthLimit, name: projectName, link: link, disableLink: disableLink, color: projectColor || '#FFFFFF', clearSubPath: clearSubPath, isConnectedProject: false, small: small }),
        showProjectName ? React.createElement("div", { title: projectName !== null && projectName !== void 0 ? projectName : undefined }, projectName) : null));
};
const DeprecatedProjectIndicator = ({ project, disableLink = false, clearSubPath = false, showProjectName = false, noWidthLimit = true, noWrapper = false, small = false, }) => {
    return (React.createElement(DeprecatedProjectIndicatorComponent, { companyProjectId: project.companyProjectId, customProjectId: project.customProjectId, projectName: project.name, projectColor: project.projectColor, disableLink: disableLink, clearSubPath: clearSubPath, showProjectName: showProjectName, noWidthLimit: noWidthLimit, noWrapper: noWrapper, small: small }));
};
// Exported raw component to reuse it in DeprecatedProjectIndicatorJS that wraps it in a JS fragment (since JS and TS fragments are incompatible)
export { DeprecatedProjectIndicator as DeprecatedProjectIndicatorNoFragment };
export default createFragmentContainer(DeprecatedProjectIndicator, {
    project: graphql `
		fragment DeprecatedProjectIndicator_project on ProjectType {
			name
			companyProjectId
			customProjectId
			projectColor
		}
	`,
});
