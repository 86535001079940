/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAllReportSharesInput = {|
  reportId: string,
  privateAccess?: ?boolean,
  emails?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateAllReportSharesMutationVariables = {|
  input: UpdateAllReportSharesInput
|};
export type updateAllReportSharesMutationResponse = {|
  +updateAllReportShares: ?{|
    +shareEdges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +user: ?{|
          +id: string,
          +email: ?string,
        |},
      |}
    |}>,
    +savedReport: ?{|
      +id: string,
      +privateAccess: ?boolean,
      +updatedAt: ?string,
    |},
    +warnings: ?$ReadOnlyArray<?string>,
  |}
|};
export type updateAllReportSharesMutation = {|
  variables: updateAllReportSharesMutationVariables,
  response: updateAllReportSharesMutationResponse,
|};
*/


/*
mutation updateAllReportSharesMutation(
  $input: UpdateAllReportSharesInput!
) {
  updateAllReportShares(input: $input) {
    shareEdges {
      node {
        id
        user {
          id
          email
        }
      }
    }
    savedReport {
      id
      privateAccess
      updatedAt
    }
    warnings
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateAllReportSharesInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAllReportSharesPayload",
    "kind": "LinkedField",
    "name": "updateAllReportShares",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportShareTypeEdge",
        "kind": "LinkedField",
        "name": "shareEdges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportShare",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportShareUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SavedReportType",
        "kind": "LinkedField",
        "name": "savedReport",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "privateAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warnings",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateAllReportSharesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateAllReportSharesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateAllReportSharesMutation",
    "operationKind": "mutation",
    "text": "mutation updateAllReportSharesMutation(\n  $input: UpdateAllReportSharesInput!\n) {\n  updateAllReportShares(input: $input) {\n    shareEdges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n      }\n    }\n    savedReport {\n      id\n      privateAccess\n      updatedAt\n    }\n    warnings\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '594aa6c2b529823354a0f84c6b5e69fc';

module.exports = node;
