import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updatePersonCalendarMutation($input: UpdatePersonCalendarInput!) {
		updatePersonCalendar(input: $input) {
			viewer {
				enabledCalendars
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Person Calendar Updated', {_Changed: changes});

	const variables = {...input};
	// ID is needed for mutations's optimistic response, but should not be included in the variables
	const variablesNoID = omit(variables, ['id', 'viewer']);

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...variablesNoID}},
			uploadables,
			configs: getConfigs(),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit, mutationId: 'updatePersonCalendarMutation'};
