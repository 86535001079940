import { format, sub } from 'date-fns';
import { CommitMutation } from '../../../../mutations/ts/CommitMutation';
import DuplicateTimeRegistrationsInDateRangeMutation from '../../../../mutations/ts/time_registrations/DuplicateTimeRegistrationsInDateRangeMutation';
import { getWeekDateRangeFromDate } from '../../../shared/util/DateUtil';
import { notNullishAndPredicate } from '../../../shared/util/NotNullPredicate';
export const getPreviousWeekDateRangeFromDate = (selectedDate) => {
    const oneWeekAgo = sub(selectedDate, { days: 7 });
    return getWeekDateRangeFromDate(oneWeekAgo);
};
export const getPreviousDayDateRangeFromDate = (selectedDate) => {
    const oneDayAgo = sub(selectedDate, { days: 1 });
    return { startDate: oneDayAgo, endDate: oneDayAgo };
};
export const getCopyFromDateRange = (selectedDate, copyFullWeek) => {
    return copyFullWeek ? getPreviousWeekDateRangeFromDate(selectedDate) : getPreviousDayDateRangeFromDate(selectedDate);
};
export const getCopyToDateRange = (selectedDate, copyFullWeek) => {
    return copyFullWeek ? getWeekDateRangeFromDate(selectedDate) : { startDate: selectedDate, endDate: selectedDate };
};
export const copyWithoutDoneTasks = (personId, dateRangeYMD, onSuccess) => {
    CommitMutation(DuplicateTimeRegistrationsInDateRangeMutation, Object.assign(Object.assign({}, dateRangeYMD), { personId, excludeDoneTasks: true }), onSuccess);
};
export const copyAll = (personId, dateRangeYMD, onSuccess) => {
    CommitMutation(DuplicateTimeRegistrationsInDateRangeMutation, Object.assign(Object.assign({}, dateRangeYMD), { personId }), onSuccess);
};
export const dateRangeToText = (dateRange) => {
    if (dateRange.startDate === dateRange.endDate) {
        return format(dateRange.startDate, 'd MMM');
    }
    return `${format(dateRange.startDate, 'd MMM')} - ${format(dateRange.endDate, 'd MMM')}`;
};
export const getTimeRegistrationsByReason = (timeRegistrationValidations, reason) => {
    return timeRegistrationValidations
        .filter(notNullishAndPredicate(timeRegistrationValidation => timeRegistrationValidation.validationReasons.includes(reason)))
        .map(timeRegistrationValidation => timeRegistrationValidation.timeRegRequest);
};
export function getInvalidTimeRegistrations(timeRegistrationValidations) {
    return timeRegistrationValidations
        .filter(notNullishAndPredicate(timeRegistrationValidation => !timeRegistrationValidation.valid))
        .map(timeRegistrationValidation => timeRegistrationValidation.timeRegRequest);
}
export const getMinutesRegistered = (timeRegistrations) => {
    return timeRegistrations.reduce((acc, curr) => {
        return acc + curr.minutesRegistered;
    }, 0);
};
export const getBillableMinutesRegistered = (timeRegistrations) => {
    return timeRegistrations.reduce((acc, curr) => {
        var _a;
        return acc + ((_a = curr.billableMinutesRegistered) !== null && _a !== void 0 ? _a : 0);
    }, 0);
};
export const ValidationReason = {
    NOT_ALLOWED_ON_DONE_TASKS: {
        singular: 'on a done task',
        plural: 'on done tasks',
    },
    NOT_ALLOWED_ON_PROJECT: {
        singular: 'at a project level',
        plural: 'at project level',
    },
    INTERNAL_TIME_NOT_ALLOWED: {
        singular: 'on an internal time category',
        plural: 'on internal time categories',
    },
    EXCEEDING_TASK_ESTIMATE: {
        singular: 'exceeding the task estimate',
        plural: 'exceeding the task estimate',
    },
    TASK_ASSIGNMENT_REQUIRED: {
        singular: 'on a task not assigned to you',
        plural: 'on tasks not assigned to you',
    },
    OUTSIDE_TASK_DATES: {
        singular: 'outside the start and end dates of its task',
        plural: 'outside the start and end dates of their tasks',
    },
    OUTSIDE_PROJECT_DATES: {
        singular: 'outside the start and end dates of its project',
        plural: 'outside the start and end dates of their project',
    },
    NOTE_REQUIRED: {
        singular: 'without a note',
        plural: 'without a note',
    },
};
