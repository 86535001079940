/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LabelDropdown_labels$ref = any;
type ProjectHeader_project$ref = any;
type ProjectHeader_projectGroup$ref = any;
type ProjectHeader_psProject$ref = any;
type ProjectWorkflowAddTask_projectGroup$ref = any;
type SecondaryNavigation_project$ref = any;
type SecondaryNavigation_projectGroup$ref = any;
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectWorkflowPage_viewer$ref: FragmentReference;
declare export opaque type ProjectWorkflowPage_viewer$fragmentType: ProjectWorkflowPage_viewer$ref;
export type ProjectWorkflowPage_viewer = {|
  +id: string,
  +backendId: ?number,
  +actualPersonId: ?string,
  +timerStartDate: ?string,
  +timerTask: ?{|
    +id: string
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +status: ?PROJECT_STATUS,
        +companyProjectId: ?number,
        +projectColor: ?string,
      |}
    |}>
  |},
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +id: string,
    +jiraCloudEnabled: ?boolean,
    +jiraServerEnabled: ?boolean,
    +integrations: ?{|
      +jiraCloud: ?{|
        +syncSettings: ?{|
          +isJiraToForecastOneWaySync: ?boolean
        |}
      |},
      +jiraServer: ?{|
        +syncSettings: ?{|
          +isJiraToForecastOneWaySync: ?boolean
        |}
      |},
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string
        |}
      |}>
    |},
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
          +category: ?{|
            +id: string,
            +name: ?string,
          |},
          +$fragmentRefs: LabelDropdown_labels$ref,
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |},
  +project: ?{|
    +id: string,
    +taskLevels: ?number,
    +status: ?PROJECT_STATUS,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +sprintTimeBox: ?boolean,
    +useTaskFollowers: ?boolean,
    +useTaskOwner: ?boolean,
    +projectColor: ?string,
    +name: ?string,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +billable: ?boolean,
    +estimationUnit: ?string,
    +isJiraProject: ?boolean,
    +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
    +jiraCloudProject: ?{|
      +id: string
    |},
    +harvestProjectId: ?string,
    +vstsProject: ?string,
    +vstsAccount: ?string,
    +vstsArea: ?string,
    +isInProjectGroup: ?boolean,
    +useTaskHierarchy: ?boolean,
    +manualProgressOnProjectEnabled: ?boolean,
    +manualProgressOnPhasesEnabled: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +sprints: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +endDay: ?number,
          +endMonth: ?number,
          +endYear: ?number,
        |}
      |}>
    |},
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +person: ?{|
            +id: string,
            +initials: ?string,
            +firstName: ?string,
            +lastName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
          |}
        |}
      |}>
    |},
    +phases: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +order: ?number,
          +category: ?STATUS_CATEGORY,
          +encourageTimeRegistration: ?boolean,
          +jiraStatusId: ?string,
          +adoState: ?string,
          +userActions: ?{|
            +canDelete: ?boolean,
            +canRename: ?boolean,
            +canCreateTask: ?boolean,
          |},
        |}
      |}>
    |},
    +$fragmentRefs: ProjectHeader_project$ref & SecondaryNavigation_project$ref,
  |},
  +projectGroup: ?{|
    +id: string,
    +companyProjectGroupId: ?number,
    +name: ?string,
    +projectGroupPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +person: ?{|
            +id: string,
            +permissions: ?$ReadOnlyArray<?string>,
            +initials: ?string,
            +firstName: ?string,
            +lastName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
          |}
        |}
      |}>
    |},
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +estimationUnit: ?string,
          +id: string,
          +name: ?string,
          +status: ?PROJECT_STATUS,
          +billable: ?boolean,
          +projectColor: ?string,
          +companyProjectId: ?number,
          +customProjectId: ?string,
          +taskLevels: ?number,
          +projectStartYear: ?number,
          +projectStartMonth: ?number,
          +projectStartDay: ?number,
          +projectEndYear: ?number,
          +projectEndMonth: ?number,
          +projectEndDay: ?number,
          +sprintTimeBox: ?boolean,
          +useTaskOwner: ?boolean,
          +useTaskFollowers: ?boolean,
          +useTaskHierarchy: ?boolean,
          +sprints: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +projectGroupSprintId: ?string,
                +isProjectGroupSprint: ?boolean,
                +startDay: ?number,
                +startMonth: ?number,
                +startYear: ?number,
                +endDay: ?number,
                +endMonth: ?number,
                +endYear: ?number,
              |}
            |}>
          |},
          +statusColumnsV2: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +order: ?number,
                +category: ?STATUS_CATEGORY,
                +projectGroupStatusColumnId: ?number,
                +encourageTimeRegistration: ?boolean,
                +jiraStatusId: ?string,
                +adoState: ?string,
                +userActions: ?{|
                  +canDelete: ?boolean,
                  +canRename: ?boolean,
                  +canCreateTask: ?boolean,
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +$fragmentRefs: ProjectHeader_projectGroup$ref & SecondaryNavigation_projectGroup$ref & ProjectWorkflowAddTask_projectGroup$ref,
  |},
  +psProject: ?{|
    +$fragmentRefs: ProjectHeader_psProject$ref
  |},
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
      |}
    |}>
  |},
  +$refType: ProjectWorkflowPage_viewer$ref,
|};
export type ProjectWorkflowPage_viewer$data = ProjectWorkflowPage_viewer;
export type ProjectWorkflowPage_viewer$key = {
  +$data?: ProjectWorkflowPage_viewer$data,
  +$fragmentRefs: ProjectWorkflowPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JiraSyncSettingsType",
    "kind": "LinkedField",
    "name": "syncSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isJiraToForecastOneWaySync",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  (v2/*: any*/)
],
v9 = [
  (v0/*: any*/),
  (v3/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taskLevels",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sprintTimeBox",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useTaskFollowers",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useTaskOwner",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useTaskHierarchy",
  "storageKey": null
},
v24 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endMonth",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "encourageTimeRegistration",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraStatusId",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adoState",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusColumnUserActions",
  "kind": "LinkedField",
  "name": "userActions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canDelete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canRename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canCreateTask",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "statusColumnsV2"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroup",
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      }
    ]
  },
  "name": "ProjectWorkflowPage_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "timerTask",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeDoneOrHalted",
          "value": false
        },
        (v2/*: any*/)
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(excludeDoneOrHalted:false,first:1000000)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraServerEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyIntegrationsType",
          "kind": "LinkedField",
          "name": "integrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyJiraSettingsType",
              "kind": "LinkedField",
              "name": "jiraCloud",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyJiraSettingsType",
              "kind": "LinkedField",
              "name": "jiraServer",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000000)"
        },
        {
          "alias": null,
          "args": [
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "labelType",
              "value": "TASK"
            }
          ],
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LabelCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LabelDropdown_labels"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:1000000,labelType:\"TASK\")"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:1000000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v10/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v6/*: any*/),
        (v3/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        (v19/*: any*/),
        (v20/*: any*/),
        (v21/*: any*/),
        (v22/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isJiraProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEpicIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraCloudProjectType",
          "kind": "LinkedField",
          "name": "jiraCloudProject",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInProjectGroup",
          "storageKey": null
        },
        (v23/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnProjectEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnPhasesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v24/*: any*/),
          "concreteType": "SprintTypeConnection",
          "kind": "LinkedField",
          "name": "sprints",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SprintTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Sprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v25/*: any*/),
                    (v26/*: any*/),
                    (v27/*: any*/),
                    (v28/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "sprints(first:100000)"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "projectPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v31/*: any*/),
                        (v32/*: any*/),
                        (v33/*: any*/),
                        (v34/*: any*/),
                        (v35/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projectPersons(first:1000000)"
        },
        {
          "alias": null,
          "args": (v24/*: any*/),
          "concreteType": "PhaseTypeConnection",
          "kind": "LinkedField",
          "name": "phases",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhaseType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "phases(first:100000)"
        },
        {
          "alias": "statusColumnsV2",
          "args": null,
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "__Project_statusColumnsV2_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v36/*: any*/),
                    (v37/*: any*/),
                    (v38/*: any*/),
                    (v39/*: any*/),
                    (v40/*: any*/),
                    (v41/*: any*/),
                    (v42/*: any*/)
                  ],
                  "storageKey": null
                },
                (v43/*: any*/)
              ],
              "storageKey": null
            },
            (v44/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "groupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectGroupId",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "projectGroupPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "permissions",
                          "storageKey": null
                        },
                        (v31/*: any*/),
                        (v32/*: any*/),
                        (v33/*: any*/),
                        (v34/*: any*/),
                        (v35/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projectGroupPersons(first:1000000)"
        },
        {
          "alias": "projects",
          "args": null,
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__ProjectGroup_projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v22/*: any*/),
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v21/*: any*/),
                    (v6/*: any*/),
                    (v5/*: any*/),
                    (v11/*: any*/),
                    (v10/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v12/*: any*/),
                    (v14/*: any*/),
                    (v13/*: any*/),
                    (v23/*: any*/),
                    {
                      "alias": null,
                      "args": (v24/*: any*/),
                      "concreteType": "SprintTypeConnection",
                      "kind": "LinkedField",
                      "name": "sprints",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SprintTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Sprint",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "projectGroupSprintId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isProjectGroupSprint",
                                  "storageKey": null
                                },
                                (v25/*: any*/),
                                (v26/*: any*/),
                                (v27/*: any*/),
                                (v28/*: any*/),
                                (v29/*: any*/),
                                (v30/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "sprints(first:100000)"
                    },
                    {
                      "alias": "statusColumnsV2",
                      "args": null,
                      "concreteType": "StatusColumnV2TypeConnection",
                      "kind": "LinkedField",
                      "name": "__Project_statusColumnsV2_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StatusColumnV2TypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "StatusColumnV2",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v3/*: any*/),
                                (v36/*: any*/),
                                (v37/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "projectGroupStatusColumnId",
                                  "storageKey": null
                                },
                                (v38/*: any*/),
                                (v39/*: any*/),
                                (v40/*: any*/),
                                (v41/*: any*/),
                                (v42/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v43/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v44/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v42/*: any*/)
                  ],
                  "storageKey": null
                },
                (v43/*: any*/)
              ],
              "storageKey": null
            },
            (v44/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_projectGroup"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_projectGroup"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectWorkflowAddTask_projectGroup"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                (v42/*: any*/)
              ],
              "storageKey": null
            },
            (v43/*: any*/)
          ],
          "storageKey": null
        },
        (v44/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '01b251030d99b6edb7c333351840cfaa';

module.exports = node;
