import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useIntl} from 'react-intl';

import {
	AddTimeEntryHeader,
	AddTimeEntryWrapper,
	AddTimeSection,
	InnerTopSectionWrapper,
	TopSectionWrapper,
} from '../timesheets_styled';
import {TIME_PERIOD} from '../timesheets_change_date_view';
import DeprecatedTimesheetsCalendar from './deprecated/timesheets_calendar';
import TimesheetsCalendar from './TimesheetsCalendar';
import AddTimeEntry from '../../../shared/components/add-time-entry/add_time_entry_component';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

const TimesheetsTopSection = ({
	viewer,
	refetch,
	pageSize,
	showCalendar,
	showTimeEntryPortal,
	hideModal,
	timePeriod,
	currentViewingDate,
	currentViewingMonth,
	weekArray,
	selectDayFromCalendar,
	setShowTimeEntryPortal,
	selectedPerson,
	calenderLoading,
	googleTask,
	lockedDate,
	hasOpportunityAccess,
	overBudgetProgramsByCompanyProjectId,
	timeRegistrationTargetSuggestionsData,
	calendarEvents,
	isLoadingCalendarEvents,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	useEffect(() => {
		if (googleTask && (hideModal || !showCalendar)) {
			setShowTimeEntryPortal(true);
		}
	}, [googleTask]);

	const logTimeButton = document.getElementById('add-time-entry-button');
	const buttonBounds = logTimeButton ? logTimeButton.getBoundingClientRect() : null;

	const style = {
		zIndex: 99,
		position: 'absolute',
	};
	if (buttonBounds) {
		style.top = buttonBounds.top + 35;
		style.left = buttonBounds.right - (window.innerWidth > 1400 ? 476 : 446);
	}

	const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;

	const selectedViewingPeriod = isWeekView
		? currentViewingDate.clone().startOf('week').format('ll') +
		  ' - ' +
		  currentViewingDate.clone().endOf('week').format('ll')
		: currentViewingDate.format('ll');

	const hasNewCalendarFF = hasFeatureFlag('timesheet_remaster_new_calendar') || viewer.calendarFetchEnabled;

	return (
		<TopSectionWrapper>
			{showCalendar ? (
				<InnerTopSectionWrapper>
					{hasNewCalendarFF ? (
						<TimesheetsCalendar
							timeRegistrations={viewer.timeRegistrations}
							currentViewingDate={currentViewingDate.clone().toDate()}
							currentViewingMonth={currentViewingMonth.toDate()}
							currentPersonViewed={selectedPerson}
							selectDay={selectDayFromCalendar}
							timePeriod={timePeriod}
							calendarEvents={calendarEvents}
							isLoadingCalendarEvents={isLoadingCalendarEvents}
							lockedDate={lockedDate ? lockedDate.toDate() : null}
							calendarTokenError={viewer.calendarTokenError}
							calendarFetchEnabled={viewer.calendarFetchEnabled}
						></TimesheetsCalendar>
					) : (
						<DeprecatedTimesheetsCalendar
							viewer={viewer}
							intl={intl}
							currentViewingDate={currentViewingDate.clone()}
							currentViewingMonth={currentViewingMonth}
							currentPersonViewed={selectedPerson}
							selectDay={selectDayFromCalendar}
							timePeriod={timePeriod}
							loading={calenderLoading}
							lockedDate={lockedDate}
						></DeprecatedTimesheetsCalendar>
					)}
					{hideModal || hasNewCalendarFF ? null : (
						<AddTimeSection data-userpilot="timesheets-new-time-entry">
							<AddTimeEntryHeader>
								<div className="time-entry-text">
									{formatMessage({id: 'common.new_time_entry_popup_title'})}:
								</div>
								<div className="date-text">{selectedViewingPeriod}</div>
							</AddTimeEntryHeader>
							<AddTimeEntryWrapper>
								<AddTimeEntry
									viewer={viewer}
									refetch={refetch}
									pageSize={pageSize}
									selectedPerson={selectedPerson ? selectedPerson : null}
									passedDate={currentViewingDate.clone()}
									weekArray={weekArray}
									isWeekView={isWeekView}
									isTimepage={true}
									googleTask={googleTask}
									lockedDate={lockedDate}
									hasOpportunityAccess={hasOpportunityAccess}
									overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
									timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData}
								/>
							</AddTimeEntryWrapper>
						</AddTimeSection>
					)}
				</InnerTopSectionWrapper>
			) : null}
			{showTimeEntryPortal
				? ReactDOM.createPortal(
						<div style={style} className="time-entry-content-wrapper">
							<AddTimeSection>
								<AddTimeEntryHeader>
									<div className="time-entry-text">
										{formatMessage({id: 'common.new_time_entry_popup_title'})}:
									</div>
									<div className="date-text">{selectedViewingPeriod}</div>
								</AddTimeEntryHeader>
								<AddTimeEntryWrapper>
									<AddTimeEntry
										viewer={viewer}
										refetch={refetch}
										pageSize={pageSize}
										selectedPerson={selectedPerson ? selectedPerson : null}
										passedDate={currentViewingDate.clone()}
										weekArray={weekArray}
										isWeekView={isWeekView}
										isTimepage={true}
										setShowTimeEntryPortal={setShowTimeEntryPortal}
										googleTask={googleTask}
										lockedDate={lockedDate}
										showFullNotes
										hasOpportunityAccess={hasOpportunityAccess}
										overBudgetProgramsByCompanyProjectId={overBudgetProgramsByCompanyProjectId}
										timeRegistrationTargetSuggestionsData={timeRegistrationTargetSuggestionsData}
									/>
								</AddTimeEntryWrapper>
							</AddTimeSection>
						</div>,
						document.querySelector('#root-portal-container')
				  )
				: null}
		</TopSectionWrapper>
	);
};

export default TimesheetsTopSection;
