import DataExportFormatter from './DataExportFormatter';
import {APPROVAL_STATUS} from '../../../../constants';
import {CUSTOM_FIELD_PREFIX, getCustomFieldColumnName} from '../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {hasFeatureFlag} from '../FeatureUtil';

export default class TimeRegFormatter extends DataExportFormatter {
	constructor(intl, mandatoryColumns = [], columnsToIgnore = []) {
		super(intl, mandatoryColumns, columnsToIgnore, false);
	}

	_columnFormatHook(name) {
		switch (name) {
			case 'clientName':
				return 'client';
			case 'personIcon':
				return 'person';
			case 'P Id':
				return 'project_id';
			case 'project':
				return 'project_name';
			case 'reportedTime':
				return 'reported';
			case 'billableTime':
				return 'billable_time';
			case 'roleName':
				return 'role';
			case 'approvalStatus':
				return 'approved';
			case 'phaseName':
				return 'phase';
			default:
				return null;
		}
	}

	_getFormattedValueHook(column, timeReg) {
		switch (column) {
			case 'billable':
				return this._formatIntlMessageByPredicate(timeReg.billable, 'common.yes', 'common.no');
			case 'client':
				return this._formatString(this._getClientName(timeReg));
			case 'cost':
				return this._formatCurrency(timeReg.cost);
			case 'notes':
				return this._formatString(timeReg.notes);
			case 'person':
				return this._formatString(this._fullname(timeReg.person));
			case 'price':
				return this._formatCurrency(timeReg.priceBaseCurrency);
			case 'project_id':
				return this._formatString(this._getProjectId(timeReg));
			case 'project_name':
				return this._formatString(this._getProjectName(timeReg));
			case 'reported':
				return this._formatNumber(timeReg.minutesRegistered);
			case 'billable_time':
				return this._formatNumber(timeReg.billableMinutesRegistered);
			case 'role':
				return this._formatString(this._getRole(timeReg));
			case 'task':
				return this._formatString(this._getTaskName(timeReg));
			case 'phase':
				return this._formatString(this._getPhaseName(timeReg));
			case 'invoiced':
				return this._formatIntlMessageByPredicate(timeReg.invoiced, 'common.yes', 'common.no');
			case 'approved':
				return this._formatIntlMessageByPredicate(this.isTimeRegApproved(timeReg), 'common.yes', 'common.no');
			default:
				if (column.startsWith(CUSTOM_FIELD_PREFIX)) {
					const customFieldColumn = column.startsWith(CUSTOM_FIELD_PREFIX + '.')
						? column.slice(CUSTOM_FIELD_PREFIX.length + 1)
						: column;
					return timeReg.customFieldValues?.edges
						.map(edge => edge.node)
						.find(cfv => getCustomFieldColumnName('TIME_REG', cfv.key) === customFieldColumn)?.value;
				}
				return null;
		}
	}

	isTimeRegApproved(timeReg) {
		return timeReg.approvalStatus === APPROVAL_STATUS.APPROVED;
	}

	_getClientName(timeReg) {
		return this._getProjectByTaskOrProject(timeReg)?.client?.name ?? '';
	}

	_getProjectName(timeReg) {
		const project = this._getProjectByProjectOrTask(timeReg);

		if (project) {
			return project.fullAccessToProject ? project.name : this._formatIntlMessage('timesheets.no_access');
		} else if (timeReg.idleTime) {
			return this._formatIntlMessageByPredicate(
				timeReg.idleTime.isInternalTime,
				'common.internal_time',
				'common.time_off'
			);
		} else {
			return '';
		}
	}

	_getProjectByProjectOrTask(timeReg) {
		return timeReg.project ?? timeReg.task?.project;
	}

	_getTaskName(timeReg) {
		return (
			(this._getProjectByProjectOrTask(timeReg)?.fullAccessToProject ? timeReg.task?.name : timeReg.idleTime?.name) ?? ''
		);
	}

	_getProjectId(timeReg) {
		if (hasFeatureFlag('custom_project_ids')) {
			return this._getProjectByProjectOrTask(timeReg)?.customProjectId ?? '';
		} else {
			return this._getProjectByProjectOrTask(timeReg)?.companyProjectId ?? '';
		}
	}

	_getProjectByTaskOrProject(timeReg) {
		return timeReg.task?.project ?? timeReg.project;
	}

	_getRole(timeReg) {
		const project = this._getProjectByTaskOrProject(timeReg);
		const companyRole = timeReg.person.role?.name ?? '';

		if (!project) {
			return companyRole;
		}

		return companyRole; //TODO Consider finding role from project instead
	}

	_getPhaseName(timeReg) {
		return timeReg.task?.phase?.name;
	}
}
