import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createProjectServiceProjectMutation($input: CreateProjectServiceProjectInput!) {
		createProjectServiceProject(input: $input) {
			project {
				node {
					id
					serviceCompanyId
					companyProjectId
					legacyProjectAsSingleProject {
						customProjectId
					}
					name
					prefix
					color
					startDate
					endDate
					stage
					clients {
						edges {
							node {
								id
								name
								logoId
							}
						}
					}
					status {
						edges {
							node {
								id
								description
								color
								addedAt
							}
						}
					}
					currentProjectStatus {
						id
						color
						description
						person {
							id
							firstName
							lastName
							profilePictureId
						}
					}
					financialNumbers {
						allActualTimeAndExpenses
						recognitionProfitPercentage
						allTotalTimeAndExpensesAtCompletion
					}
					financialNumbersToDate {
						recognitionProfitPercentage
					}
					totalRevenueRecognition
					totalRevenueRecognitionToDate
					totalTimeRevenueRecognitionToDate
					totalBillableExpensesAtCompletion
					settings {
						edges {
							node {
								name
								value
							}
						}
					}
					userRelationTypes
				}
			}

			scProject {
				node {
					projectPersons(first: 1000000, contactsOnly: true) {
						edges {
							node {
								id
								isContactPerson
								person {
									id
									firstName
									lastName
									profilePictureId
								}
							}
						}
					}
					id
					companyProjectId
					customProjectId
					status
					name
					isJiraProject
					projectColor
					isInProjectGroup
					billable
					budgetType
					isInProjectGroup
					projectGroupId
					sprintTimeBox
					useBaseline
					harvestProject {
						id
					}
					statusColumnsV2(first: 1000000) {
						edges {
							node {
								id
								projectGroupStatusColumnId
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Viewer_psProjects',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'project',
		},
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Header_Viewer_projects',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'scProject',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
