import {MODAL_TYPE, showModal} from './forecast-app/shared/components/modals/generic_modal_conductor';
import * as Sentry from '@sentry/browser';
import {hasFeatureFlag} from './forecast-app/shared/util/FeatureUtil';

function isNetworkError(errorMessage) {
	return (
		errorMessage.includes('Failed to fetch') ||
		errorMessage.includes('TypeError: Load failed') || // Mac version of "Failed to fetch"
		errorMessage.includes('TypeError: The Internet connection appears to be offline') ||
		errorMessage.includes('TypeError: NetworkError when attempting to fetch resource') ||
		errorMessage.includes('TypeError: Nätverksanslutningen förlorades') ||
		errorMessage.includes('TypeError: The network connection was lost')
	);
}

function handleResponseBody(body) {
	let errorMessage = '';
	let correlationId = '';
	if (body) {
		if (body.message) {
			errorMessage = body.message;
		}
		if (body.correlationId) {
			correlationId = body.correlationId;
		}
		if (body.status === 404) {
			showModal({
				type: MODAL_TYPE.MUTATION_ERROR_NON_CRITICAL,
				correlationId: correlationId,
				errorType: '404',
			});
		} else {
			showModal({
				type: MODAL_TYPE.MUTATION_ERROR,
				correlationId: correlationId,
				isNetworkError: isNetworkError(errorMessage),
			});
		}
	}
}

export default class DirectApi {
	static Fetch(requestEndpoint) {
		return this.Fetch_Raw(requestEndpoint).then(result => {
			try {
				return result.json();
			} catch (e) {
				Sentry.captureException(e, {
					level: Sentry.Severity.Debug,
					extra: {
						result: result.text(),
					},
				});
				throw e;
			}
		});
	}

	static Fetch_Raw(requestEndpoint) {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const init = {headers: headers, credentials: 'include'};

		return fetch(this.graphqlServerEndpoint(requestEndpoint), init);
	}

	static Fetch_Raw_With_Init(requestEndpoint, init) {
		return fetch(this.graphqlServerEndpoint(requestEndpoint), init);
	}

	static Fetch_WithErrorHandling(requestEndpoint, init) {
		const requestUrl = this.graphqlServerEndpoint(requestEndpoint);
		return new Promise((resolve, reject) => {
			fetch(requestUrl, init)
				.then(response => {
					if (response.ok) {
						resolve(response);
					} else {
						response.json().then(handleResponseBody);
					}
				})
				.catch(err => {
					if (!('' + err).includes('abort')) {
						reject(err);
					}
				});
		});
	}

	static graphqlServerEndpoint(path) {
		const isNewTest = window.location.hostname === 'my.test.forecast.it';
		const isNewProd = window.location.hostname === 'my.forecast.app';

		const useGraphQLSandboxServer = hasFeatureFlag('use_graphql_sandbox_server');

		let graphqlServerUrl;

		if (this.getDatacenter() === 'US') {
			graphqlServerUrl = process.env.GRAPHQL_ENDPOINT_US.slice(0, -7);
		} else {
			// EU
			if (useGraphQLSandboxServer) {
				graphqlServerUrl = process.env.GRAPHQL_SANDBOX_ENDPOINT.slice(0, -7);
			} else if (isNewTest) {
				graphqlServerUrl = 'https://graphql.test.forecast.it/';
			} else if (isNewProd) {
				graphqlServerUrl = 'https://graphql.forecast.app/';
			} else {
				graphqlServerUrl = process.env.GRAPHQL_ENDPOINT.slice(0, -7);
			}
		}

		return graphqlServerUrl + path;
	}

	static socketServerEndpoint() {
		const isNewTest = window.location.hostname === 'my.test.forecast.it';
		const isNewProd = window.location.hostname === 'my.forecast.app';

		let graphqlServerUrl;
		if (this.getDatacenter() === 'US') {
			graphqlServerUrl = process.env.SOCKET_ENDPOINT_US;
		} else {
			// EU
			if (isNewTest) {
				graphqlServerUrl = 'https://graphql.test.forecast.it';
			} else if (isNewProd) {
				graphqlServerUrl = 'https://graphql.forecast.app';
			} else {
				graphqlServerUrl = process.env.SOCKET_ENDPOINT;
			}
		}

		return graphqlServerUrl;
	}

	static getDatacenter() {
		if (this.enableDatacenterSwitch()) {
			if (!localStorage.getItem('datacenter')) {
				console.log('no dc set. Setting to EU!!');
				this.setDatacenterEU();
			}
			return localStorage.getItem('datacenter');
		} else {
			return 'EU';
		}
	}

	static setDatacenterEU() {
		if (this.enableDatacenterSwitch()) {
			localStorage.setItem('datacenter', 'EU');
		}
	}

	static setDatacenterUS() {
		if (this.enableDatacenterSwitch()) {
			localStorage.setItem('datacenter', 'US');
		}
	}

	static enableDatacenterSwitch() {
		return localStorage.getItem('datacenter-selection') === 'true';
	}

	static setDatacenterBasedOnString(dc) {
		if (dc === 'US') {
			DirectApi.setDatacenterUS();
		} else if (dc === 'EU') {
			DirectApi.setDatacenterEU();
		}
	}

	static fileSrc(id) {
		let fileSrc = DirectApi.graphqlServerEndpoint('file_stream/');
		return fileSrc + id;
	}

	static programUrl(prefix, plusCustomer = false) {
		return plusCustomer ? `/program/${prefix}/schedule` : `/project-group/${prefix}/schedule`;
	}
}
export const projectUrl = (companyProjectId, customProjectId) => {
	return hasFeatureFlag('custom_project_ids') && customProjectId
		? `/project/${encodeURIComponent(customProjectId)}`
		: `/project/P-${companyProjectId}`;
};
export const projectUrlWithLastPage = (companyProjectId, customProjectId) => {
	return hasFeatureFlag('custom_project_ids') && customProjectId
		? `/project/${encodeURIComponent(customProjectId)}/${localStorage.getItem('project-section-last-viewed') || 'workflow'}`
		: `/project/P-${companyProjectId}/${localStorage.getItem('project-section-last-viewed') || 'workflow'}`;
};
export const projectGroupUrl = id => {
	return `/connected/X-${id}`;
};
export const projectGroupUrWithLastPage = id => {
	return `/connected/X-${id}/${localStorage.getItem('project-group-section-last-viewed') || 'workflow'}`;
};
export const bulkDownloadSrc = ids => {
	if (ids.length) {
		let fileSrc = DirectApi.graphqlServerEndpoint('files');
		let param = '?id=' + ids[0];
		ids.forEach((id, index) => {
			if (index !== 0) {
				param += '&id=' + id;
			}
		});
		return fileSrc + param;
	}
	return '';
};
export const profilePicSrc = id => {
	if (id) {
		let profilePicUrl = DirectApi.graphqlServerEndpoint('profilePicture/');
		return profilePicUrl + id;
	}
	return null;
};
export const profilePicRectSrc = id => {
	let profilePicUrl = DirectApi.graphqlServerEndpoint('profilePictureRect/');
	return profilePicUrl + id;
};
export const companyLogoSrc = id => {
	let companyLogoSrc = DirectApi.graphqlServerEndpoint('companyLogo/');
	return companyLogoSrc + id;
};
export const clientLogoSrc = id => {
	let clientLogoSrc = DirectApi.graphqlServerEndpoint('clientLogo/');
	return clientLogoSrc + id;
};
