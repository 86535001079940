import { hasFeatureFlag } from '../../../util/FeatureUtil';
function getLink(isProjectGroup, linkId, clearSubPath, disableLink) {
    if (!linkId || disableLink)
        return undefined;
    const path = isProjectGroup
        ? `/connected/X-${linkId}`
        : hasFeatureFlag('custom_project_ids')
            ? `/project/${linkId}`
            : `/project/P-${linkId}`;
    const curLocArr = window.location.href.split('/');
    const pageName = curLocArr[curLocArr.length - 1];
    const subPath = clearSubPath ? '' : `/${pageName}`;
    return `${path}${subPath}`;
}
export function getProjectLink(linkId, clearSubPath, disableLink) {
    return getLink(false, linkId, clearSubPath, disableLink);
}
export function getProjectGroupLink(linkId, clearSubPath, disableLink) {
    return getLink(true, linkId, clearSubPath, disableLink);
}
function withProjectPrefix(companyProjectId) {
    if (!companyProjectId)
        return '';
    return `${'P'}${companyProjectId}`;
}
/**
 * Please consider using the ProjectIndicator or at least DeprecatedProjectIndicator component instead of this function - it renders the project indicator using the project color.
 * */
export function getProjectIndicatorString(companyProjectId, customProjectId) {
    const showCustomId = hasFeatureFlag('custom_project_ids') && !!customProjectId;
    return showCustomId ? customProjectId : withProjectPrefix(companyProjectId);
}
function withProjectGroupPrefix(companyProjectId) {
    if (!companyProjectId)
        return '';
    return `${'X'}${companyProjectId}`;
}
export function getProjectGroupIndicatorString(companyProjectGroupId) {
    return withProjectGroupPrefix(companyProjectGroupId);
}
/**
 * Please consider using the ProjectGroupIndicator or at least DeprecatedProjectGroupIndicator component instead of this function - it renders the project indicator using the project color.
 * */
export function getProjectOrProjectGroupIndicatorString({ companyProjectId, customProjectId, companyProjectGroupId, name, }) {
    const id = companyProjectGroupId
        ? getProjectGroupIndicatorString(companyProjectGroupId)
        : getProjectIndicatorString(companyProjectId, customProjectId);
    return name ? `${id} - ${name}` : id;
}
