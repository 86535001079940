import React, {useEffect, useMemo, useRef, useState} from 'react';
import {injectIntl} from 'react-intl';
import {
	HoursInputContainerReplicateDesignSystemStyle,
	HoursInputContainerStyle,
	HoursInputReplicateDesignSystemStyle,
	HoursInputStyle,
} from './hours_input_styled';
import {convertIntoFloatHoursFormat, noChange} from './hours_input_logic';
import ForecastTooltip from '../../tooltips/ForecastTooltip';
import Util from '../../../util/util';
import {hasFeatureFlag} from '../../../util/FeatureUtil';

const HoursInput = ({
	id,
	intl,
	value,
	minuteLimit,
	placeholder,
	mutationValidChange,
	mutationChangeOnly,
	mutation, // function which is called when a valid change has happened
	onFocus,
	onClick,
	onBlur,
	disabled,
	nonWorkingDay,
	innerRef,
	parentKeyDown,
	focusOnMount,
	forceUpdate,
	infoText,
	hasError,
	customClassName,
	index,
	cy,
	showAI,
	userpilot,
	label,
	required,
	extension,
	invalidInput,
	noMaxWidth,
	modalView,
	taskModalStyle,
	showAsterisk,
	phaseHeaderStyle,
	withInputExtension,
	// Dirty, filthy hack to allow parent component to hook into formatting actions to pull result instead of depending on mutation.
	// Needed because input would otherwise not propagate a value formatted differently such as when granularity is applied. This component really needs to be cleansed with fire.
	onInputFormatted,
	granularityFormatter,
	// onlyFormatGranularityOnBlur is used to avoid formatting already registered time on the timesheet page
	onlyFormatGranularityOnBlur,
	replicateDesignSystem,
	width,
	CustomStyling,
	maxValueInMinutes,
	isTimeregInput = false,
}) => {
	const inputToHoursFormatter = granularityFormatter || convertIntoFloatHoursFormat;
	const HoursInputContainer = replicateDesignSystem
		? HoursInputContainerReplicateDesignSystemStyle
		: HoursInputContainerStyle;

	const StyledHoursInput = CustomStyling
		? CustomStyling
		: replicateDesignSystem
		? HoursInputReplicateDesignSystemStyle
		: HoursInputStyle;

	const formattedValue = useMemo(
		() =>
			onlyFormatGranularityOnBlur
				? convertIntoFloatHoursFormat(value, intl, minuteLimit, showAsterisk, isTimeregInput)
				: inputToHoursFormatter(value, intl, minuteLimit, showAsterisk, isTimeregInput),
		[value]
	);

	const [currentInputValue, setCurrentInputValue] = useState(formattedValue.full);
	const [error, setError] = useState(formattedValue.error);
	const [errorText, setErrorText] = useState();

	// Need to find a better name, but this is internal logic only
	const [dirtyWithError, setDirtyWithError] = useState(false);
	// Will only be true if user has entered something else inside the input
	const [isDirty, setIsDirty] = useState(false);
	const inputElem = useRef(null);

	const didMount = useRef(false);

	const isEnterBlur = useRef(false);

	const setErrorIfDirty = overwriteDirty => {
		if (overwriteDirty || dirtyWithError) {
			setError(true);
		}
	};

	// Focus input on mount if prop is passed
	useEffect(() => {
		if (focusOnMount && inputElem?.current) {
			inputElem.current.focus();
		}
	}, []);

	// If explicitly requesting forceUpdate, set all state elements back to default.
	useEffect(() => {
		setCurrentInputValue(formattedValue.full);
		setError(formattedValue.error);
		if (onInputFormatted) {
			onInputFormatted(formattedValue.float);
		}
	}, [forceUpdate]);

	useEffect(() => {
		// This logic is extremely messy and should eventually be entirely rewritten along with the rest of the component to a thin controlled input.
		// For now, this logic will block granularity formatting on first render if the onlyFormatGranularityOnBlur prop is passed.
		if (!onlyFormatGranularityOnBlur) {
			setDirtyWithError(true);
			const formattedValue = inputToHoursFormatter(value, intl, minuteLimit, showAsterisk);
			setCurrentInputValue(formattedValue.full);
			if (onInputFormatted) {
				onInputFormatted(formattedValue.float);
			}

			if (formattedValue.error !== error) {
				setError(formattedValue.error);
			}
		} else if (didMount.current) {
			setCurrentInputValue(formattedValue.full);
			setError(formattedValue.error);
			if (onInputFormatted) {
				onInputFormatted(formattedValue.float);
			}
		} else {
			didMount.current = true;
		}
	}, [value, showAsterisk, forceUpdate]);

	const handleOnChange = e => {
		setIsDirty(true);
		setCurrentInputValue(e.target.value);
	};

	const handleValidate = e => {
		const inputValue = e.target.value;
		if (onBlur) {
			onBlur(e);
		}

		const validValue = inputToHoursFormatter(inputValue, intl, minuteLimit, showAsterisk);
		if (onInputFormatted) {
			onInputFormatted(validValue.float);
		}

		const isNumber = typeof validValue.float === 'number';

		if (maxValueInMinutes !== undefined && isNumber && validValue.float * 60 > maxValueInMinutes) {
			validValue.error =
				'Value exceeds the remaining time on the task (' + Util.convertMinutesToFullHour(maxValueInMinutes, intl) + ')';
			setError(true);
			setErrorText(validValue.error);
			setErrorIfDirty(true);

			setCurrentInputValue(validValue.full);
		}

		if (validValue.error) {
			setErrorIfDirty(onlyFormatGranularityOnBlur);
		} else {
			setError(false);
			setErrorText(undefined);
			setCurrentInputValue(validValue.full);
		}

		if (mutation) {
			let hoursValue = validValue.float;
			if (isTimeregInput && hasFeatureFlag('timereg_input_conversion')) {
				hoursValue = hoursValue != null && !isNaN(hoursValue) ? `${hoursValue}h` : hoursValue;
			}
			// Check if mutation should only be called on valid change
			if (mutationValidChange) {
				if (!validValue.error && !noChange(validValue.float, value, intl)) {
					mutation(hoursValue, e, isDirty, isEnterBlur.current);
					setIsDirty(false);
				}
			} else if (mutationChangeOnly) {
				if (!noChange(validValue.float, value, intl)) {
					mutation(hoursValue, e, isDirty, isEnterBlur.current);
					setIsDirty(false);
				}
			} else {
				// else we just call the mutation;
				mutation(hoursValue, e, isDirty, isEnterBlur.current);
				setIsDirty(false);
			}
		}
		isEnterBlur.current = false;
	};

	const handleOnClick = e => {
		if (onClick) {
			onClick(e.target);
		}
	};
	const handleKeyPressInput = e => {
		if (e.key === 'Enter') {
			isEnterBlur.current = true;
			e.preventDefault();
			e.target.blur();
		}
		if (parentKeyDown) {
			parentKeyDown(e, inputToHoursFormatter(currentInputValue, intl));
		}
	};

	const handleOnFocus = e => {
		if (onFocus) {
			onFocus(e.target);
		} else {
			e.target.select();
		}
	};

	/*
	 * order of the input state priorities
	 * locked > disabled > has error > focused > hovered
	 *
	 * Locked means invoiced / Locked in period
	 *
	 * Disabled is any other reason to disable (such as multiple time regs on same thing on same day, with notes)
	 */
	const inputState = disabled ? '' : error || hasError ? ' error' : nonWorkingDay ? ' non-working-day' : '';
	return (
		<HoursInputContainer
			className={customClassName}
			modalView={modalView}
			taskModalStyle={taskModalStyle}
			noMaxWidth={noMaxWidth}
			phaseHeaderStyle={phaseHeaderStyle}
			width={width}
		>
			{label ? (
				<label className="input-title" htmlFor={id ? id : ''}>
					<span>{label}</span>
					<span className={required ? 'required-extension' : ''}>{extension}</span>
				</label>
			) : null}
			<ForecastTooltip content={errorText ? errorText : infoText} maxWidth={250}>
				<StyledHoursInput
					id={id}
					ref={innerRef ? innerRef : inputElem}
					className={inputState || ''}
					type={'text'}
					autoComplete="off"
					placeholder={placeholder}
					value={currentInputValue}
					onFocus={e => handleOnFocus(e)}
					onClick={e => handleOnClick(e)}
					onChange={e => handleOnChange(e)}
					onBlur={e => handleValidate(e)}
					onKeyDown={e => handleKeyPressInput(e)}
					disabled={disabled}
					data-cy={(cy ? cy : 'hours-input') + (index != null ? '-' + index : '')}
					data-userpilot={userpilot}
					modalView={modalView}
					taskModalStyle={taskModalStyle}
					phaseHeaderStyle={phaseHeaderStyle}
					invalid={invalidInput}
					withInputExtension={withInputExtension}
				/>
			</ForecastTooltip>
		</HoursInputContainer>
	);
};

export default injectIntl(HoursInput);
