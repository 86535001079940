import { notNullishAndPredicate } from '../../../shared/util/NotNullPredicate';
import { isInternalTimeRegistrationAllowed, isProjectTimeRegistrationAllowed, } from '../../../shared/util/cache/TimeRegistrationSettingsUtil';
import { isTaskTimeRegistrationAllowed } from '../../../shared/util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
function getSuggestionId(suggestion) {
    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.task)
        return suggestion.task.id;
    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.project)
        return suggestion.project.id;
    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.idleTime)
        return suggestion.idleTime.id;
    return null;
}
export function getSuggestedIdsFromSuggestionsList(suggestions) {
    const ids = [];
    if (suggestions) {
        for (const suggestion of suggestions) {
            const suggestionId = getSuggestionId(suggestion);
            if (suggestionId) {
                ids.push(suggestionId);
            }
        }
    }
    return ids;
}
export function getSuggestedIds(timeRegistrationTargetSuggestionsData) {
    var _a;
    const suggestions = ((_a = timeRegistrationTargetSuggestionsData === null || timeRegistrationTargetSuggestionsData === void 0 ? void 0 : timeRegistrationTargetSuggestionsData.timeRegistrationTargetSuggestions) === null || _a === void 0 ? void 0 : _a.sortedSuggestions) || null;
    return getSuggestedIdsFromSuggestionsList(suggestions);
}
const isSuggestionAllowed = (suggestion, selectedPersonId) => {
    var _a;
    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.project) {
        return isProjectTimeRegistrationAllowed();
    }
    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.idleTime) {
        return isInternalTimeRegistrationAllowed();
    }
    if (suggestion === null || suggestion === void 0 ? void 0 : suggestion.task) {
        const statusCategory = (_a = suggestion.task.statusColumnV2) === null || _a === void 0 ? void 0 : _a.category;
        const assignedPersonIds = suggestion.task.assignedPersons
            ? suggestion.task.assignedPersons.filter(notNullishAndPredicate()).map(person => person.id)
            : [];
        if (statusCategory) {
            return isTaskTimeRegistrationAllowed(statusCategory, selectedPersonId, assignedPersonIds, suggestion.task.project);
        }
    }
    return false;
};
export function getValidSuggestions(timeRegistrationTargetSuggestionsData, selectedPersonId) {
    var _a, _b;
    const suggestions = ((_a = timeRegistrationTargetSuggestionsData === null || timeRegistrationTargetSuggestionsData === void 0 ? void 0 : timeRegistrationTargetSuggestionsData.timeRegistrationTargetSuggestions) === null || _a === void 0 ? void 0 : _a.sortedSuggestions) || null;
    return (_b = suggestions === null || suggestions === void 0 ? void 0 : suggestions.filter(suggestion => isSuggestionAllowed(suggestion, selectedPersonId))) !== null && _b !== void 0 ? _b : null;
}
