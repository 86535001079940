import React, { forwardRef, useMemo } from 'react';
import { FakeHoursInputStyle } from '../../../../../shared/components/inputs/hours-input/hours_input_styled';
import { convertIntoFloatHoursFormat } from '../../../../../shared/components/inputs/hours-input/hours_input_logic';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PopoverHandler } from '@forecast-it/design-system';
import { hasFeatureFlag } from '../../../../../shared/util/FeatureUtil';
const IconWrapper = styled.div `
	opacity: ${({ onlyShowIconOnHover }) => (onlyShowIconOnHover ? '0' : '1')};
`;
const StyledHoursInputButton = styled(FakeHoursInputStyle) `
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;

	&:hover ${IconWrapper} {
		opacity: 1;
	}

	[data-state='open'] & {
		border-color: #6e0fea;
	}
`;
const HoursInput = styled.div `
	padding: 6px 15px 6px 0;
`;
const getValueInFloatHoursFormat = (minutesRegistered, intl) => {
    const hasValue = minutesRegistered !== null && minutesRegistered !== undefined;
    if (hasValue) {
        return convertIntoFloatHoursFormat(minutesRegistered / 60, intl, 0, false);
    }
    return '';
};
const getValueInFloatHoursFormat_timeRegInputConversion = (minutesRegistered, intl) => {
    const hasValue = minutesRegistered != null && !isNaN(minutesRegistered);
    if (hasValue) {
        return convertIntoFloatHoursFormat(`${minutesRegistered}m`, intl, 0, false, true);
    }
    return '';
};
const HoursInputButton = forwardRef(({ children, title, minutesRegistered, disabled, nonWorkingDay, onlyShowIconOnHover }, ref) => {
    const { open, setOpen } = PopoverHandler.usePopoverState();
    const toggle = () => setOpen(!open);
    const intl = useIntl();
    const hasTimeRegInputConversion = hasFeatureFlag('timereg_input_conversion');
    const value = useMemo(() => hasTimeRegInputConversion
        ? getValueInFloatHoursFormat_timeRegInputConversion(minutesRegistered, intl)
        : getValueInFloatHoursFormat(minutesRegistered, intl), [minutesRegistered, hasTimeRegInputConversion]);
    return (React.createElement(StyledHoursInputButton, { "data-cy": 'hours-input-popup-toggle', title: title, disabled: disabled, className: nonWorkingDay ? 'non-working-day' : '', onClick: toggle, ref: ref },
        React.createElement(IconWrapper, { onlyShowIconOnHover: onlyShowIconOnHover }, children),
        React.createElement(HoursInput, null, value.full)));
});
export default HoursInputButton;
