import {graphql} from 'react-relay';
import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateCompanyMutationModernMutation($input: UpdateCompanyInput!) {
		updateCompany(input: $input) {
			company {
				id
				name
				currency
				timezone
				logoId
				defaultRateCard {
					id
				}
				defaultHolidayCalendar {
					id
				}
				defaultExpenseCategory {
					id
				}
				defaultRevenueMarginLow
				defaultRevenueMarginHigh
				vstsEstimateField
				disableNotifications
				slackEnabled
				githubEnabled
				githubUnauthorizedInstallation
				gitlabEnabled
				gitlabServerEnabled
				gitlabDeleteIssues
				economicEnabled
				timeRegNotificationSchedule
				projectOverviewNotificationSchedule
				harvestEnabled
				calendarEnabled
				calendarApiEnabled
				jiraServerUrl
				jiraPrefixType
				jiraServerPrefixType
				jiraPrefixLoading
				jiraServerPrefixLoading
				tempoOauthClientId
				tempoOauthClientSecret
				xeroEnabled
				salesforceAutoCreate
				vstsEnabled
				oktaClientId
				oktaClientSecret
				oktaUrl
				oktaClientId
				oktaClientSecret
				oktaUrl
				isUsingSchedulingPlanMode
				userSeats
				virtualSeats
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				useTimeApproval
				useInternalTimeApproval
				useTimeOffApproval
				allUsersModifyTimeOff
				lockedPeriodYear
				lockedPeriodMonth
				lockedPeriodDay
				street
				zip
				city
				country
				vatId
				contactPerson {
					id
					fullName
					email
				}
				contactName
				contactEmail
				contactPhone
				oneloginUrl
				oneloginClientId
				oneloginClientSecret
				AADEnabled
				AADTenant
				AADClientId
				AADClientSecret
				unit4Enabled
				unit4TwoWay
				unit4SyncDateLimit
				timesheetReminderEnabled
				timesheetReminderDay
				timesheetReminderHour
				timesheetReminderIncludeToday
				projectDigestNotificationEnabled
				projectDigestNotificationDay
				projectDigestNotificationHour
				integrations {
					userCanUnlinkInvoice
				}
				skillLevelsEnabled
				revenueEnabled
				timesheetsEnabled
				clientsEnabled
				betaOptIn
				weekendDisplayPerUser
				weekendDisplayShowAlways
				isUsingProjectAllocation
				isUsingMixedAllocation
				invoiceQuantityDecimals
				isSSOMandatory
				sageIntacctTimeRegsFromForecast
				sageIntacctSyncOnlyApproved
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const company = {id: input.id};
	if (input.name) {
		company.name = input.name;
	}
	if (input.street) {
		company.street = input.street;
	}
	if (input.zip) {
		company.zip = input.zip;
	}
	if (input.city) {
		company.city = input.city;
	}
	if (input.country) {
		company.country = input.country;
	}
	if (input.currency) {
		company.currency = input.currency;
	}
	if (input.timezone) {
		company.timezone = input.timezone;
	}
	if (input.vatId) {
		company.vatId = input.vatId;
	}
	if (input.contactName) {
		company.contactName = input.contactName;
	}
	if (input.contactEmail) {
		company.contactEmail = input.contactEmail;
	}
	if (input.contactPhone) {
		company.contactPhone = input.contactPhone;
	}
	if (input.harvestDeactivate !== undefined) {
		company.harvestEnabled = false;
	}
	if (input.gitlabServerDeactivate !== undefined) {
		company.gitlabServerEnabled = false;
	}
	if (input.gitlabDeactivate !== undefined) {
		company.gitlabEnabled = false;
	}
	if (input.economicDeactivate !== undefined) {
		company.economicEnabled = false;
	}
	if (input.gitlabDeleteIssues) {
		company.gitlabDeleteIssues = input.gitlabDeleteIssues;
	}
	if (input.calendarEnabled !== undefined) {
		company.calendarEnabled = input.calendarEnabled;
	}
	if (input.calendarApiEnabled !== undefined) {
		company.calendarApiEnabled = input.calendarApiEnabled;
	}
	if (input.monday) {
		company.monday = input.monday;
	}
	if (input.tuesday) {
		company.tuesday = input.tuesday;
	}
	if (input.wednesday) {
		company.wednesday = input.wednesday;
	}
	if (input.thursday) {
		company.thursday = input.thursday;
	}
	if (input.friday) {
		company.friday = input.friday;
	}
	if (input.saturday) {
		company.saturday = input.saturday;
	}
	if (input.sunday) {
		company.sunday = input.sunday;
	}
	if (input.jiraServerUrl) {
		company.jiraServerUrl = input.jiraServerUrl;
	}
	if (input.jiraPrefixType) {
		company.jiraPrefixType = input.jiraPrefixType;
		company.jiraPrefixLoading = true;
	}
	if (input.jiraServerPrefixType) {
		company.jiraServerPrefixType = input.jiraServerPrefixType;
		company.jiraServerPrefixLoading = true;
	}
	if (input.tempoOauthClientId) {
		company.tempoOauthClientId = input.tempoOauthClientId;
	}
	if (input.tempoOauthClientSecret) {
		company.tempoOauthClientSecret = input.tempoOauthClientSecret;
	}
	if (input.salesforceAutoCreate !== undefined) {
		company.salesforceAutoCreate = input.salesforceAutoCreate;
	}
	if (input.oktaClientId) {
		company.oktaClientId = input.oktaClientId;
	}
	if (input.oktaClientSecret) {
		company.oktaClientSecret = input.oktaClientSecret;
	}
	if (input.oktaUrl) {
		company.oktaUrl = input.oktaUrl;
	}
	if (input.oneloginClientId) {
		company.oneloginClientId = input.oneloginClientId;
	}
	if (input.oneloginClientSecret) {
		company.oneloginClientSecret = input.oneloginClientSecret;
	}
	if (input.oneloginUrl) {
		company.oneloginUrl = input.oneloginUrl;
	}
	if (input.AADClientId) {
		company.AADClientId = input.AADClientId;
	}
	if (input.AADClientSecret) {
		company.AADClientSecret = input.AADClientSecret;
	}
	if (input.AADTenant) {
		company.AADTenant = input.AADTenant;
	}
	if (input.defaultHolidayCalendarId !== undefined) {
		company.defaultHolidayCalendar = input.defaultHolidayCalendarId ? {id: input.defaultHolidayCalendarId} : null;
	}
	if (input.defaultExpenseCategoryId !== undefined) {
		company.defaultExpenseCategory = input.defaultExpenseCategoryId ? {id: input.defaultExpenseCategoryId} : null;
	}
	if (input.defaultRevenueMarginLow !== undefined) {
		company.defaultRevenueMarginLow = input.defaultRevenueMarginLow;
	}
	if (input.defaultRevenueMarginHigh !== undefined) {
		company.defaultRevenueMarginHigh = input.defaultRevenueMarginHigh;
	}
	if (input.isUsingSchedulingPlanMode !== undefined) {
		company.isUsingSchedulingPlanMode = input.isUsingSchedulingPlanMode;
	}
	if (input.userSeats) {
		company.userSeats = input.userSeats;
	}
	if (input.virtualSeats) {
		company.virtualSeats = input.virtualSeats;
	}
	if (input.vstsEstimateField) {
		company.vstsEstimateField = input.vstsEstimateField;
	}
	if (input.unit4Enabled !== undefined) {
		company.unit4Enabled = input.unit4Enabled;
	}
	if (input.unit4TwoWay !== undefined) {
		company.unit4TwoWay = input.unit4TwoWay;
	}
	if (input.unit4SyncDateLimit !== undefined) {
		company.unit4SyncDateLimit = input.unit4SyncDateLimit;
	}
	if (input.contactPersonId) {
		company.contactPerson = {id: input.contactPersonId};
	}
	if (input.timesheetReminderEnabled !== undefined) {
		company.timesheetReminderEnabled = input.timesheetReminderEnabled;
	}
	if (input.timesheetReminderDay !== undefined) {
		company.timesheetReminderDay = input.timesheetReminderDay;
	}
	if (input.timesheetReminderHour !== undefined) {
		company.timesheetReminderHour = input.timesheetReminderHour;
	}
	if (input.timesheetReminderIncludeToday !== undefined) {
		company.timesheetReminderIncludeToday = input.timesheetReminderIncludeToday;
	}
	if (input.projectDigestNotificationEnabled !== undefined) {
		company.projectDigestNotificationEnabled = input.projectDigestNotificationEnabled;
	}
	if (input.projectDigestNotificationDay !== undefined) {
		company.projectDigestNotificationDay = input.projectDigestNotificationDay;
	}
	if (input.projectDigestNotificationHour !== undefined) {
		company.projectDigestNotificationHour = input.projectDigestNotificationHour;
	}
	if (input.companyTarget !== undefined) {
		company.companyTarget = input.companyTarget;
	}
	if (input.revenueEnabled !== undefined) {
		company.revenueEnabled = input.revenueEnabled;
	}
	if (input.timesheetsEnabled !== undefined) {
		company.timesheetsEnabled = input.timesheetsEnabled;
	}
	if (input.clientsEnabled !== undefined) {
		company.clientsEnabled = input.clientsEnabled;
	}

	if (input.userCanUnlinkInvoice !== undefined) {
		company.integrations = {userCanUnlinkInvoice: input.userCanUnlinkInvoice};
	}
	if (input.isUsingProjectAllocation !== undefined) {
		company.isUsingProjectAllocation = input.isUsingProjectAllocation;
	}
	if (input.isUsingMixedAllocation !== undefined) {
		company.isUsingMixedAllocation = input.isUsingMixedAllocation;
	}

	if (input.betaOptIn !== undefined) {
		company.betaOptIn = input.betaOptIn;
	}

	if (input.weekendDisplayPerUser !== undefined) {
		company.weekendDisplayPerUser = input.weekendDisplayPerUser;
	}

	if (input.weekendDisplayShowAlways !== undefined) {
		company.weekendDisplayShowAlways = input.weekendDisplayShowAlways;
	}

	if (input.isSSOMandatory !== undefined) {
		company.isSSOMandatory = input.isSSOMandatory;
	}
	if (input.allUsersModifyTimeOff !== undefined) {
		company.allUsersModifyTimeOff = input.allUsersModifyTimeOff;
	}
	if (input.sageIntacctTimeRegsFromForecast !== undefined) {
		company.sageIntacctTimeRegsFromForecast = input.sageIntacctTimeRegsFromForecast;
	}
	if (input.sageIntacctSyncOnlyApproved !== undefined) {
		company.sageIntacctSyncOnlyApproved = input.sageIntacctSyncOnlyApproved;
	}

	return {
		updateCompany: {
			company: company,
		},
	};
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Company Updated', {_Changed: changes});

	const variables = {...input};
	// ID is needed for updateCompanyMutation's optimistic response, but should not be included in the variables
	const variablesNoID = omit(variables, ['id']);

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...variablesNoID}},
			uploadables,
			configs: getConfigs(),
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
