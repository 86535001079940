import {
	DeprecatedDropdown as Dropdown,
	DeprecatedDropdownMenuText as DropdownMenuText,
	FlexColumn,
	FlexItem,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {BasicTextInput} from 'web-components';
import React from 'react';
import DateRangePicker from '../../../../../date-picker/date_picker_v3';
import {DATE_PICKER_STYLE} from '../../../../../../../../constants';
import {useIntl} from 'react-intl';

export const JiraSection = ({forecastProject, jiraProject, jiraProjectList, forecastClientList, setters, states}) => {
	const {formatMessage} = useIntl();
	const handleDateChange = (startDate, endDate) => {
		setters.setStartDate(startDate);
		setters.setEndDate(endDate);
	};

	return (
		<FlexColumn gap={'xl'}>
			<FlexItem>
				<Text>{formatMessage({id: 'sync_jira_project_modal.jira_section.headline'})}</Text>
			</FlexItem>
			<FlexItem>
				<Text type={'heading'} variant={'l'}>
					{formatMessage({id: 'sync_jira_project_modal.jira_section.header'})}
				</Text>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'common.jira_project'})}
						</Text>
					</FlexItem>
					<FlexItem>
						{jiraProject ? (
							<Text>{jiraProject.name}</Text>
						) : (
							<Dropdown
								initialValue={undefined}
								onSelect={selection => setters.handleJiraProjectSelected(selection)}
							>
								<DropdownMenuText values={jiraProjectList} />
							</Dropdown>
						)}
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'common.project-name'})}
						</Text>
					</FlexItem>
					<FlexItem>
						{forecastProject ? (
							<Text>{states.forecastProjectName}</Text>
						) : (
							<BasicTextInput
								value={states.forecastProjectName}
								onChange={value => setters.setForecastProjectName(value)}
							/>
						)}
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'common.client_name'})}
						</Text>
					</FlexItem>
					<FlexItem>
						{forecastProject && states.forecastProjectClient ? (
							<Text>{forecastClientList.find(client => client.value === states.forecastProjectClient).text}</Text>
						) : (
							<Dropdown
								initialValue={undefined}
								onSelect={selection => setters.setForecastProjectClient(selection)}
							>
								<DropdownMenuText values={forecastClientList} />
							</Dropdown>
						)}
					</FlexItem>
				</FlexColumn>
			</FlexItem>
			<FlexItem>
				<FlexColumn gap={'xs'}>
					<FlexItem>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'common.select_dates'})}
						</Text>
					</FlexItem>
					<FlexItem>
						<DateRangePicker
							handleDateRangeChange={(startDate, endDate) => handleDateChange(startDate, endDate)}
							disabled={!!forecastProject}
							startDate={states.startDate}
							endDate={states.endDate}
							isNewDateRange={true}
							datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						/>
					</FlexItem>
				</FlexColumn>
			</FlexItem>
		</FlexColumn>
	);
};
