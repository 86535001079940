import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';

export const useProgramCalculatedFields = programIds => {
	const [calculatedFieldsData, setCalculatedFieldsData] = useState();

	const calculateProgramFieldsQuery = graphql`
		query useProgramCalculatedFieldsHookQuery($programIds: [ID]) {
			viewer {
				component(name: "useProgramCalculatedFields")
				actualPersonId
				company {
					id
					name
					programCalculatedFields(first: 1000, programIds: $programIds) {
						edges {
							node {
								programId
								progress {
									progressType
									value
								}
							}
						}
					}
				}
			}
		}
	`;

	const loadData = () =>
		fetchQuery(environment.getInstance(), calculateProgramFieldsQuery, {programIds}).then(data => {
			return setCalculatedFieldsData(
				data.viewer.company.programCalculatedFields.edges.map(programData => programData.node)
			);
		});

	useEffect(() => {
		loadData();
	}, []);

	/**
	 * This will return the calculated fields for a program
	 *
	 * @param programId The program ID
	 * @returns {{progress: *}} Calculated Fields Available
	 */
	const programCalcData = programId => {
		let programData = calculatedFieldsData?.find(program => program.programId === programId);
		return {
			progress: programData ? Math.round(programData.progress.value * 100) : 0, // In percent
		};
	};

	return {
		programCalcData,
		reloadData: loadData,
	};
};
