var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getTotalPrice } from '../../../../../../../components/insights/invoicing/line-generation/invoicing_generate_lines_fixedhours_retainer';
import { addQuantityDecimals, convertPriceToInvoiceCurrency, } from '../../../../../../../components/insights/invoicing/line-generation/invoicing_generate_lines_utils';
import { getTimeRegsForPeriod } from '../../../../../../../components/new-ui/retainer_period/RetainerPeriodUtil';
import { CreateMomentDate, dateFragmentPropsFromIsoDateString } from '../../../../../util/DateUtil';
import Util from '../../../../../../shared/util/util';
import CreateInvoiceMutation from '../../../../../../../mutations/create_invoice_mutation';
import { invoiceLineConverter } from '../../../../../../../containers/modal/create_invoice_modal_v2/create_invoice_modal_v2';
import { notNullishAndPredicate } from '../../../../../util/NotNullPredicate';
import { addDays, format, formatISO } from 'date-fns';
function getDueDate(period) {
    if (!(period === null || period === void 0 ? void 0 : period.endYear) || !period.endMonth || !period.endDay) {
        return null;
    }
    return formatISO(addDays(new Date(period.endYear, period.endMonth - 1, period.endDay), 30), { representation: 'date' });
}
function getInvoiceDate(period) {
    if (!(period === null || period === void 0 ? void 0 : period.endYear) || !period.endMonth || !period.endDay) {
        return null;
    }
    return formatISO(new Date(period.endYear, period.endMonth - 1, period.endDay), { representation: 'date' });
}
function getPeriodQuantity(period) {
    if ((period === null || period === void 0 ? void 0 : period.periodBudgetType) === 'FIXED_HOURS') {
        return (period === null || period === void 0 ? void 0 : period.periodHoursAmount) || 0;
    }
    else if ((period === null || period === void 0 ? void 0 : period.periodBudgetType) === 'FIXED_PRICE') {
        return 1;
    }
    return 0;
}
export function getUnitPrice({ period, invoiceCurrency, rateCard, company, timeRegEdges }) {
    if ((period === null || period === void 0 ? void 0 : period.periodBudgetType) === 'FIXED_HOURS') {
        const projectCurrency = (rateCard === null || rateCard === void 0 ? void 0 : rateCard.currency) || (company === null || company === void 0 ? void 0 : company.currency) || '';
        const quantity = period.periodHoursAmount;
        const periodStartDate = CreateMomentDate(period.startYear, period.startMonth, period.startDay);
        const periodEndDate = CreateMomentDate(period.endYear, period.endMonth, period.endDay);
        const unInvoicedPeriodTimeRegs = getTimeRegsForPeriod(timeRegEdges === null || timeRegEdges === void 0 ? void 0 : timeRegEdges.filter(timeRegEdge => { var _a; return !((_a = timeRegEdge === null || timeRegEdge === void 0 ? void 0 : timeRegEdge.node) === null || _a === void 0 ? void 0 : _a.invoiced); }), period).map(e => e.node);
        const totalPrice = convertPriceToInvoiceCurrency(getTotalPrice(unInvoicedPeriodTimeRegs || [], periodStartDate, periodEndDate, quantity, rateCard), projectCurrency, company, { currency: invoiceCurrency });
        return !!quantity && totalPrice ? Math.round((totalPrice / quantity) * 100) / 100 : 0;
    }
    else if ((period === null || period === void 0 ? void 0 : period.periodBudgetType) === 'FIXED_PRICE') {
        return (period === null || period === void 0 ? void 0 : period.periodPriceAmount) || 0;
    }
    return 0;
}
export function getCurrencyItems(company) {
    var _a;
    if (!((_a = company === null || company === void 0 ? void 0 : company.exchangeRates) === null || _a === void 0 ? void 0 : _a.edges)) {
        return [];
    }
    const currencyItems = [];
    if (company.currency) {
        currencyItems.push({ id: company.currency, currencyCode: company.currency });
    }
    company.exchangeRates.edges.forEach(edge => {
        const i = edge === null || edge === void 0 ? void 0 : edge.node;
        if (i && !!i.currency) {
            currencyItems.push({ id: i.currency, currencyCode: i.currency });
        }
    });
    return currencyItems;
}
export function getInvoicePeriodData({ periods, currency, project, company }) {
    if (!company || !project || !periods) {
        return [];
    }
    return (periods.filter(notNullishAndPredicate()).map(period => {
        var _a;
        return {
            period: period,
            name: period.name,
            quantity: getPeriodQuantity(period),
            unitPrice: getUnitPrice({
                period: period,
                invoiceCurrency: currency,
                rateCard: project.rateCard,
                company: company,
                timeRegEdges: (_a = project.timeRegistrations) === null || _a === void 0 ? void 0 : _a.edges,
            }),
            invoiceDate: getInvoiceDate(period),
            dueDate: getDueDate(period),
        };
    }) || []);
}
function getInvoiceEntryDescription(period) {
    if (!period) {
        return '';
    }
    return `${period.name} (${format(Util.CreateDate(period.startYear, period.startMonth, period.startDay), 'PP')} - ${format(Util.CreateDate(period.endYear, period.endMonth, period.endDay), 'PP')})`;
}
export function createInvoices({ invoices, project, invoiceQuantityDecimals, onSuccess, }) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (!invoices) {
            return;
        }
        const commitInvoiceMutation = invoice => {
            return new Promise(resolve => {
                Util.CommitMutation(CreateInvoiceMutation, invoice, resolve);
            });
        };
        for (let index = 0; index < invoices.length; index++) {
            const invoice = invoices[index];
            const invoiceDate = dateFragmentPropsFromIsoDateString(invoice.invoiceDate);
            const dueDate = dateFragmentPropsFromIsoDateString(invoice.dueDate);
            if (invoice && invoice.period) {
                const invoiceEntryDescription = getInvoiceEntryDescription(invoice.period);
                const newInvoiceData = {
                    clientId: (_a = project === null || project === void 0 ? void 0 : project.client) === null || _a === void 0 ? void 0 : _a.id,
                    projectId: project === null || project === void 0 ? void 0 : project.id,
                    invoiceType: null,
                    name: invoice.name,
                    currency: invoice.currency,
                    invoiceReference: null,
                    status: 'DRAFT',
                    dueDay: dueDate.day,
                    dueMonth: dueDate.month,
                    dueYear: dueDate.year,
                    createdDay: invoiceDate.day,
                    createdMonth: invoiceDate.month,
                    createdYear: invoiceDate.year,
                    notes: '',
                    entries: [
                        invoiceLineConverter({
                            description: invoiceEntryDescription,
                            quantity: addQuantityDecimals(invoice.quantity, { invoiceQuantityDecimals }),
                            unitPrice: invoice.unitPrice,
                            projectId: project === null || project === void 0 ? void 0 : project.id,
                            tax: invoice.tax,
                            discount: 0,
                            periodIds: [invoice.period.id],
                        }),
                    ],
                };
                yield commitInvoiceMutation(newInvoiceData);
            }
        }
        onSuccess();
    });
}
