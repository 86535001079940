/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LabelDropdown_labels$ref = any;
type ProjectHeader_project$ref = any;
type ProjectHeader_psProject$ref = any;
type RoleDropdown_roles$ref = any;
type SecondaryNavigation_project$ref = any;
type genericTaskContextMenu_company$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type CANT_DELETE_TASK_REASON = "BLOCKED_BY_SUBTASK" | "GITHUB" | "HAS_INVOICED_TIME" | "HAS_LOCKED_TIME" | "HAS_OTHER_PEOPLES_TIME_REGS" | "HAS_TIME_REGS" | "JIRA" | "NO_USER_TYPE" | "UNIT4" | "%future added value";
export type DEPENDENCY_TYPE = "CANNOT_BE_COMPLETED" | "CANNOT_START" | "%future added value";
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
export type INHERIT_DATES_FROM_TYPE = "BASELINE" | "%future added value";
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectScopingPage_viewer$ref: FragmentReference;
declare export opaque type ProjectScopingPage_viewer$fragmentType: ProjectScopingPage_viewer$ref;
export type ProjectScopingPage_viewer = {|
  +id: string,
  +language: ?LANGUAGE,
  +email: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +timerStartDate: ?string,
  +actualPersonId: ?string,
  +harvestUser: ?boolean,
  +unit4User: ?boolean,
  +excludeFromCompanyLockedPeriod: ?boolean,
  +submitLockedDateYear: ?number,
  +submitLockedDateMonth: ?number,
  +submitLockedDateDay: ?number,
  +startDate: ?string,
  +endDate: ?string,
  +createdAt: ?string,
  +monday: ?number,
  +tuesday: ?number,
  +wednesday: ?number,
  +thursday: ?number,
  +friday: ?number,
  +saturday: ?number,
  +sunday: ?number,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +timerTask: ?{|
    +id: string
  |},
  +company: ?{|
    +jiraCloudEnabled: ?boolean,
    +jiraServerEnabled: ?boolean,
    +integrations: ?{|
      +jiraCloud: ?{|
        +syncSettings: ?{|
          +isJiraToForecastOneWaySync: ?boolean
        |}
      |},
      +jiraServer: ?{|
        +syncSettings: ?{|
          +isJiraToForecastOneWaySync: ?boolean
        |}
      |},
    |},
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +person: ?{|
      +id: string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
    +isUsingProjectAllocation: ?boolean,
    +isUsingMixedAllocation: ?boolean,
    +holidayCalendars: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +holidayCalendarEntries: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +year: ?number,
                +month: ?number,
                +day: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |}
      |}>
    |},
    +id: string,
    +currency: ?string,
    +harvestEnabled: ?boolean,
    +unit4Enabled: ?boolean,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
          +category: ?{|
            +id: string,
            +name: ?string,
          |},
          +$fragmentRefs: LabelDropdown_labels$ref,
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: RoleDropdown_roles$ref,
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +customFieldDefinitions?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +key: ?string,
          +displayName: ?string,
          +entityType: ?string,
          +readOnly: ?boolean,
        |}
      |}>
    |},
    +$fragmentRefs: genericTaskContextMenu_company$ref,
  |},
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
        +updatedAt: ?string,
      |}
    |}>
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +name: ?string,
        +status: ?PROJECT_STATUS,
        +isInProjectGroup: ?boolean,
        +projectColor: ?string,
      |}
    |}>
  |},
  +project: ?{|
    +projectFirstDateYear: ?number,
    +projectFirstDateMonth: ?number,
    +projectFirstDateDay: ?number,
    +projectLastDateYear: ?number,
    +projectLastDateMonth: ?number,
    +projectLastDateDay: ?number,
    +baselineTargetPrice: ?number,
    +baselineTargetPriceNoExpenses: ?number,
    +baselineTargetMinutes: ?number,
    +id: string,
    +isNoStaleTasksAllowed: ?boolean,
    +name: ?string,
    +demo: ?boolean,
    +budgetWork: ?number,
    +budgetType: ?BUDGET_TYPE,
    +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
    +budget: ?number,
    +billable: ?boolean,
    +synchBaselineAndScopingDates: ?boolean,
    +program: ?{|
      +name: ?string,
      +prefix: ?string,
      +budgetType: ?PROGRAM_BUDGET_TYPE,
      +members: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +role: ?string,
            +person: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
    +sprintTimeBox: ?boolean,
    +isInProjectGroup: ?boolean,
    +useBaseline: ?boolean,
    +projectGroupId: ?string,
    +status: ?PROJECT_STATUS,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +estimationUnit: ?string,
    +minutesPerEstimationPoint: ?number,
    +timeRegistrations?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +minutesRegistered: ?number,
          +billableMinutesRegistered: ?number,
          +invoiced: ?boolean,
          +price: ?number,
          +updatedAt: ?string,
          +year: ?number,
          +month: ?number,
          +day: ?number,
          +retainerConflictHandled: ?boolean,
          +task: ?{|
            +id: string
          |},
          +person: ?{|
            +id: string
          |},
        |}
      |}>
    |},
    +retainerPeriods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +endYear: ?number,
          +endMonth: ?number,
          +endDay: ?number,
          +available: ?number,
          +periodLength: ?number,
          +periodPeriodicity: ?PERIODICITY,
          +periodPriceAmount: ?number,
          +periodHoursAmount: ?number,
          +periodBudgetType: ?PERIOD_BUDGET_TYPE,
          +periodSettingIgnoreForBilling: ?boolean,
          +periodSettingSubtractValue: ?boolean,
          +periodSettingRollValue: ?boolean,
          +periodSettingAddExpenses: ?boolean,
          +periodDifferencePriceAmount: ?number,
          +periodDifferenceHoursAmount: ?number,
          +sharedPeriodDifferenceHoursAmount: ?number,
          +sharedPeriodDifferencePriceAmount: ?number,
          +ignoredRolloverHours: ?number,
          +ignoredRolloverPrice: ?number,
          +periodLocked: ?boolean,
          +periodLockedTime: ?string,
        |}
      |}>
    |},
    +projectColor: ?string,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +useManualAllocations: ?boolean,
    +remainingAutoCalculated: ?boolean,
    +isJiraProject: ?boolean,
    +useTaskOwner: ?boolean,
    +useTaskFollowers: ?boolean,
    +taskLevels: ?number,
    +useTaskHierarchy: ?boolean,
    +vstsProject: ?string,
    +vstsAccount: ?string,
    +harvestProjectId: ?string,
    +vstsTwoWaySync: ?boolean,
    +jiraSubtaskType: ?string,
    +progress: ?number,
    +manualProgressOnProjectEnabled: ?boolean,
    +manualProgressOnPhasesEnabled: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +financialSourceSettings: ?{|
      +plannedRevenue: ?string,
      +plannedCost: ?string,
      +actualRevenue: ?string,
      +actualCost: ?string,
      +forecastRevenue: ?string,
    |},
    +jiraCloudProject: ?{|
      +id: string
    |},
    +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
    +jiraServerProject: ?{|
      +id: string
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +order: ?number,
          +jiraStatusId: ?string,
          +category: ?STATUS_CATEGORY,
        |}
      |}>
    |},
    +rateCard: ?{|
      +id: string,
      +currency: ?string,
      +rates: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +rate: ?number,
            +defaultRate: ?boolean,
            +startDate: ?string,
            +role: ?{|
              +id: string
            |},
          |}
        |}>
      |},
      +disabledRoles: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |},
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +initials: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +active: ?boolean,
            +monday: ?number,
            +tuesday: ?number,
            +wednesday: ?number,
            +thursday: ?number,
            +friday: ?number,
            +saturday: ?number,
            +sunday: ?number,
            +holidayCalendar: ?{|
              +id: string
            |},
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
            +client: ?{|
              +id: string
            |},
            +allocations: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +monday: ?number,
                  +tuesday: ?number,
                  +wednesday: ?number,
                  +thursday: ?number,
                  +friday: ?number,
                  +saturday: ?number,
                  +sunday: ?number,
                  +startYear: ?number,
                  +startMonth: ?number,
                  +startDay: ?number,
                  +endYear: ?number,
                  +endMonth: ?number,
                  +endDay: ?number,
                  +idleTime: ?{|
                    +isInternalTime: ?boolean
                  |},
                |}
              |}>
            |},
          |},
        |}
      |}>
    |},
    +phases: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +baselineTargetPrice: ?number,
          +baselineTargetPriceNoExpenses: ?number,
          +baselineTargetMinutes: ?number,
          +baselineCost: ?number,
          +inheritDatesFrom: ?INHERIT_DATES_FROM_TYPE,
          +progress: ?number,
          +progressDetails: ?{|
            +progress: ?number
          |},
          +phaseBaselineRoles: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +role: ?{|
                  +id: string
                |},
                +baselineMinutes: ?number,
                +baselinePrice: ?number,
              |}
            |}>
          |},
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +startFrom: ?string,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +deadlineFrom: ?string,
          +baselineStartYear: ?number,
          +baselineStartMonth: ?number,
          +baselineStartDay: ?number,
          +baselineDeadlineDay: ?number,
          +baselineDeadlineMonth: ?number,
          +baselineDeadlineYear: ?number,
          +unit4Id: ?string,
          +jiraId: ?string,
          +phasePersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +availableMinutes: ?number,
                +scheduledMinutes: ?number,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
          +customFieldValues?: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +key: ?string,
                +displayName: ?string,
                +value: ?string,
                +readOnly: ?boolean,
              |}
            |}>
          |},
        |}
      |}>
    |},
    +baselineFinancialNumbers: ?$ReadOnlyArray<?{|
      +baselineRevenue: ?number,
      +baselineTimeAndExpenses: ?number,
      +baselineCost: ?number,
      +baselineProfit: ?number,
      +baselineMargin: ?number,
      +baselineRatePerHour: ?number,
      +baselineCostPerHour: ?number,
      +baselineMinutes: ?number,
      +phaseBaselineRoleId: ?number,
      +phaseBaselineExpenseId: ?number,
      +phaseId: ?number,
    |}>,
    +sprints: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +endYear: ?number,
          +endMonth: ?number,
          +endDay: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
        |}
      |}>
    |},
    +tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +readOnly: ?{|
            +isReadOnly: ?boolean
          |},
          +userCanDeleteTask: ?boolean,
          +userCantDeleteTaskReason: ?CANT_DELETE_TASK_REASON,
          +hasInvoicedTime: ?boolean,
          +hasLockedTime: ?boolean,
          +hasTimeRegistrations: ?boolean,
          +approved: ?boolean,
          +name: ?string,
          +description: ?string,
          +sortOrder: ?number,
          +taskType: ?string,
          +done: ?boolean,
          +billable: ?boolean,
          +blocked: ?boolean,
          +progress?: ?number,
          +currentPrice?: ?number,
          +actualCost?: ?number,
          +plannedCost?: ?number,
          +timeLeft?: ?number,
          +timeRegistrations?: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +minutesRegistered: ?number,
                +billableMinutesRegistered: ?number,
                +price: ?number,
                +invoiced: ?boolean,
                +year: ?number,
                +month: ?number,
                +day: ?number,
                +retainerConflictHandled: ?boolean,
                +person: ?{|
                  +id: string,
                  +role: ?{|
                    +id: string
                  |},
                |},
              |}
            |}>
          |},
          +bug: ?boolean,
          +latestUiUpdateAt: ?string,
          +startFrom: ?string,
          +deadlineFrom: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +estimateForecast: ?number,
          +estimateForecastMinutes: ?number,
          +estimateForecastPrice: ?number,
          +totalPriceAtCompletion: ?number,
          +totalCostAtCompletion: ?number,
          +allTotalTimeAndExpensesAtCompletion: ?number,
          +companyTaskId: ?number,
          +canStart: ?boolean,
          +canBeSetToDone: ?boolean,
          +highPriority: ?boolean,
          +hasDependency: ?boolean,
          +jiraId: ?string,
          +vstsId: ?string,
          +isUnit4Task: ?boolean,
          +subTaskCount: ?number,
          +parentTaskId: ?string,
          +hasChildren: ?boolean,
          +favoured: ?boolean,
          +progressDetails: ?{|
            +progress: ?number
          |},
          +owner: ?{|
            +id: string,
            +permissions: ?$ReadOnlyArray<?string>,
          |},
          +followers: ?$ReadOnlyArray<?{|
            +id: string,
            +permissions: ?$ReadOnlyArray<?string>,
          |}>,
          +phase: ?{|
            +id: string,
            +name: ?string,
            +startYear: ?number,
            +startMonth: ?number,
            +startDay: ?number,
            +startFrom: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +deadlineFrom: ?string,
          |},
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +sprint: ?{|
            +id: string,
            +name: ?string,
            +startYear: ?number,
            +startMonth: ?number,
            +startDay: ?number,
            +endYear: ?number,
            +endMonth: ?number,
            +endDay: ?number,
          |},
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +project: ?{|
            +id: string,
            +demo: ?boolean,
            +billable: ?boolean,
            +estimationUnit: ?string,
            +minutesPerEstimationPoint: ?number,
            +projectStartYear: ?number,
            +projectStartMonth: ?number,
            +projectStartDay: ?number,
            +projectEndYear: ?number,
            +projectEndMonth: ?number,
            +projectEndDay: ?number,
            +budgetType: ?BUDGET_TYPE,
            +projectPerson: ?{|
              +role: ?{|
                +id: string,
                +name: ?string,
              |}
            |},
            +program: ?{|
              +name: ?string,
              +prefix: ?string,
              +budgetType: ?PROGRAM_BUDGET_TYPE,
              +members: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +role: ?string,
                    +person: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
            |},
            +name: ?string,
            +status: ?PROJECT_STATUS,
            +isJiraProject: ?boolean,
            +vstsProject: ?string,
            +vstsAccount: ?string,
            +vstsTwoWaySync: ?boolean,
            +projectColor: ?string,
            +companyProjectId: ?number,
            +customProjectId: ?string,
            +harvestProject: ?{|
              +id: string
            |},
            +unit4Project: ?{|
              +id: string,
              +activitiesEnabled: ?boolean,
            |},
            +sprintTimeBox: ?boolean,
          |},
          +taskLabels: ?$ReadOnlyArray<?{|
            +id: string,
            +label: ?{|
              +id: string,
              +name: ?string,
              +color: ?string,
            |},
          |}>,
          +taskDismissedNotifications: ?{|
            +taskOverrunPredictionDismissed: ?boolean
          |},
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +active: ?boolean,
            +email: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +permissions: ?$ReadOnlyArray<?string>,
          |}>,
          +thisTaskDependsOn: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +type: ?DEPENDENCY_TYPE,
                +thisDependsOnTask: ?{|
                  +id: string,
                  +name: ?string,
                  +deadlineYear: ?number,
                  +deadlineMonth: ?number,
                  +deadlineDay: ?number,
                  +startYear: ?number,
                  +startMonth: ?number,
                  +startDay: ?number,
                |},
              |}
            |}>
          |},
          +customFieldValues?: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +key: ?string,
                +displayName: ?string,
                +value: ?string,
                +readOnly: ?boolean,
              |}
            |}>
          |},
        |}
      |}>
    |},
    +allocations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +monday: ?number,
          +tuesday: ?number,
          +wednesday: ?number,
          +thursday: ?number,
          +friday: ?number,
          +saturday: ?number,
          +sunday: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +endYear: ?number,
          +endMonth: ?number,
          +endDay: ?number,
          +person: ?{|
            +id: string,
            +role: ?{|
              +id: string
            |},
            +holidayCalendar: ?{|
              +id: string
            |},
          |},
          +project: ?{|
            +id: string
          |},
          +isSoft: ?boolean,
        |}
      |}>
    |},
    +expenseFinancialNumbers: ?{|
      +allTotalTimeAndExpensesAtCompletion: ?number
    |},
    +totalFinancialNumbers: ?{|
      +totalCostAtCompletion: ?number,
      +totalRevenueRecognition: ?number,
      +totalRevenueProfitAtCompletion: ?number,
      +totalRevenueMarginAtCompletion: ?number,
      +forecastMinutes: ?number,
      +registeredMinutes: ?number,
      +billableActualMinutes: ?number,
      +nonBillableActualMinutes: ?number,
      +billableTotalTimeAndExpensesAtCompletion: ?number,
      +baselineMinutes: ?number,
    |},
    +$fragmentRefs: ProjectHeader_project$ref & SecondaryNavigation_project$ref,
  |},
  +psProject: ?{|
    +$fragmentRefs: ProjectHeader_psProject$ref
  |},
  +$refType: ProjectScopingPage_viewer$ref,
|};
export type ProjectScopingPage_viewer$data = ProjectScopingPage_viewer;
export type ProjectScopingPage_viewer$key = {
  +$data?: ProjectScopingPage_viewer$data,
  +$fragmentRefs: ProjectScopingPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v14 = [
  (v1/*: any*/)
],
v15 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JiraSyncSettingsType",
    "kind": "LinkedField",
    "name": "syncSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isJiraToForecastOneWaySync",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = [
  (v1/*: any*/),
  (v16/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v26 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v28 = [
  (v26/*: any*/)
],
v29 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readOnly",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProjectGroup",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineTargetPrice",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineTargetPriceNoExpenses",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineTargetMinutes",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "demo",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "concreteType": "ProgramType",
  "kind": "LinkedField",
  "name": "program",
  "plural": false,
  "selections": [
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefix",
      "storageKey": null
    },
    (v44/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectServiceProgramMemberTypeConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectServiceProgramMemberTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramMemberType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                (v30/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sprintTimeBox",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesPerEstimationPoint",
  "storageKey": null
},
v50 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endMonth",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isJiraProject",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsProject",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsAccount",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsTwoWaySync",
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedCost",
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualCost",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v71 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v72 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v75 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v76 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v77 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayCalendar",
  "kind": "LinkedField",
  "name": "holidayCalendar",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v78 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineCost",
  "storageKey": null
},
v79 = [
  (v67/*: any*/)
],
v80 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineMinutes",
  "storageKey": null
},
v81 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startFrom",
  "storageKey": null
},
v82 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v83 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v84 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v85 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineFrom",
  "storageKey": null
},
v86 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraId",
  "storageKey": null
},
v87 = {
  "condition": "fetchCustomFields",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomFieldValueTypeConnection",
      "kind": "LinkedField",
      "name": "customFieldValues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldValueTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomFieldValue",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v13/*: any*/),
                (v31/*: any*/),
                (v33/*: any*/),
                (v32/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v88 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v89 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCostAtCompletion",
  "storageKey": null
},
v90 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allTotalTimeAndExpensesAtCompletion",
  "storageKey": null
},
v91 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v92 = [
  (v1/*: any*/),
  (v91/*: any*/)
],
v93 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesRegistered",
  "storageKey": null
},
v94 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableMinutesRegistered",
  "storageKey": null
},
v95 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v96 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v97 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retainerConflictHandled",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "withEpics",
      "type": "Boolean"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noStaleTasks",
      "type": "Boolean"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchLazyData",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchCustomFields",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "holidayCalendars"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "allPersons"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "customFieldDefinitions"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "timeRegistrations"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "phases"
        ]
      },
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "tasks"
        ]
      }
    ]
  },
  "name": "ProjectScopingPage_viewer",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "harvestUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit4User",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludeFromCompanyLockedPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateDay",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        (v13/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "timerTask",
      "plural": false,
      "selections": (v14/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraServerEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyIntegrationsType",
          "kind": "LinkedField",
          "name": "integrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyJiraSettingsType",
              "kind": "LinkedField",
              "name": "jiraCloud",
              "plural": false,
              "selections": (v15/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyJiraSettingsType",
              "kind": "LinkedField",
              "name": "jiraServer",
              "plural": false,
              "selections": (v15/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "personId"
            }
          ],
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v18/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingMixedAllocation",
          "storageKey": null
        },
        {
          "alias": "holidayCalendars",
          "args": null,
          "concreteType": "HolidayCalendarTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_holidayCalendars_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HolidayCalendarTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HolidayCalendar",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": "holidayCalendarEntries",
                      "args": null,
                      "concreteType": "HolidayCalendarEntryTypeConnection",
                      "kind": "LinkedField",
                      "name": "__HolidayCalendar_holidayCalendarEntries_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "HolidayCalendarEntryTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "HolidayCalendarEntry",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v16/*: any*/),
                                (v19/*: any*/),
                                (v20/*: any*/),
                                (v21/*: any*/),
                                (v22/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v23/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v24/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v22/*: any*/)
                  ],
                  "storageKey": null
                },
                (v23/*: any*/)
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "allPersons",
          "args": null,
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_allPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v22/*: any*/)
                  ],
                  "storageKey": null
                },
                (v23/*: any*/)
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        (v25/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v26/*: any*/),
            {
              "kind": "Literal",
              "name": "labelType",
              "value": "TASK"
            }
          ],
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v16/*: any*/),
                    (v27/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LabelCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": (v17/*: any*/),
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LabelDropdown_labels"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:1000000,labelType:\"TASK\")"
        },
        {
          "alias": null,
          "args": (v28/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v17/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoleDropdown_roles"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:1000000)"
        },
        {
          "alias": null,
          "args": (v28/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v16/*: any*/),
                    {
                      "alias": null,
                      "args": (v29/*: any*/),
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "teamPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v30/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "teamPersons(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000000)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "genericTaskContextMenu_company"
        },
        {
          "condition": "fetchCustomFields",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "customFieldDefinitions",
              "args": null,
              "concreteType": "CustomFieldDefinitionTypeConnection",
              "kind": "LinkedField",
              "name": "__Company_customFieldDefinitions_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomFieldDefinitionTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomFieldDefinition",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v13/*: any*/),
                        (v31/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityType",
                          "storageKey": null
                        },
                        (v32/*: any*/),
                        (v22/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v23/*: any*/)
                  ],
                  "storageKey": null
                },
                (v24/*: any*/)
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v16/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                (v33/*: any*/),
                (v34/*: any*/),
                (v22/*: any*/)
              ],
              "storageKey": null
            },
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        (v24/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeRestricted",
          "value": true
        },
        (v26/*: any*/)
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v35/*: any*/),
                (v36/*: any*/),
                (v16/*: any*/),
                (v37/*: any*/),
                (v38/*: any*/),
                (v39/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(excludeRestricted:true,first:1000000)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateDay",
          "storageKey": null
        },
        (v40/*: any*/),
        (v41/*: any*/),
        (v42/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isNoStaleTasksAllowed",
          "storageKey": null
        },
        (v16/*: any*/),
        (v43/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetWork",
          "storageKey": null
        },
        (v44/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodBudgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budget",
          "storageKey": null
        },
        (v45/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "synchBaselineAndScopingDates",
          "storageKey": null
        },
        (v46/*: any*/),
        (v47/*: any*/),
        (v38/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useBaseline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupId",
          "storageKey": null
        },
        (v37/*: any*/),
        (v35/*: any*/),
        (v36/*: any*/),
        (v48/*: any*/),
        (v49/*: any*/),
        {
          "alias": null,
          "args": (v50/*: any*/),
          "concreteType": "RetainerPeriodTypeConnection",
          "kind": "LinkedField",
          "name": "retainerPeriods",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RetainerPeriodTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RetainerPeriodType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v16/*: any*/),
                    (v51/*: any*/),
                    (v52/*: any*/),
                    (v53/*: any*/),
                    (v54/*: any*/),
                    (v55/*: any*/),
                    (v56/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "available",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodLength",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodPeriodicity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodPriceAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodHoursAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodBudgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingIgnoreForBilling",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingSubtractValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingRollValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingAddExpenses",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodDifferencePriceAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodDifferenceHoursAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sharedPeriodDifferenceHoursAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sharedPeriodDifferencePriceAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ignoredRolloverHours",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ignoredRolloverPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodLocked",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodLockedTime",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "retainerPeriods(first:100000)"
        },
        (v39/*: any*/),
        (v57/*: any*/),
        (v58/*: any*/),
        (v59/*: any*/),
        (v60/*: any*/),
        (v61/*: any*/),
        (v62/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useManualAllocations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remainingAutoCalculated",
          "storageKey": null
        },
        (v63/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskFollowers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskLevels",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskHierarchy",
          "storageKey": null
        },
        (v64/*: any*/),
        (v65/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestProjectId",
          "storageKey": null
        },
        (v66/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraSubtaskType",
          "storageKey": null
        },
        (v67/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnProjectEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnPhasesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialSourceSettings",
          "kind": "LinkedField",
          "name": "financialSourceSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenue",
              "storageKey": null
            },
            (v68/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenue",
              "storageKey": null
            },
            (v69/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenue",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraCloudProjectType",
          "kind": "LinkedField",
          "name": "jiraCloudProject",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEpicIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraServerProjectType",
          "kind": "LinkedField",
          "name": "jiraServerProject",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v28/*: any*/),
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "statusColumnsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v16/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jiraStatusId",
                      "storageKey": null
                    },
                    (v70/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "statusColumnsV2(first:1000000)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v25/*: any*/),
            {
              "alias": null,
              "args": (v71/*: any*/),
              "concreteType": "RateTypeConnection",
              "kind": "LinkedField",
              "name": "rates",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RateTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Rate",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "defaultRate",
                          "storageKey": null
                        },
                        (v5/*: any*/),
                        (v72/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "rates(first:10000)"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "disabledRoles",
              "plural": true,
              "selections": (v14/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v28/*: any*/),
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "projectPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v18/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v73/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "initials",
                          "storageKey": null
                        },
                        (v74/*: any*/),
                        (v75/*: any*/),
                        (v76/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v12/*: any*/),
                        (v77/*: any*/),
                        (v18/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v14/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            (v26/*: any*/),
                            {
                              "kind": "Literal",
                              "name": "idleTimeOnly",
                              "value": true
                            }
                          ],
                          "concreteType": "AllocationTypeConnection",
                          "kind": "LinkedField",
                          "name": "allocations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AllocationTypeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Allocation",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v6/*: any*/),
                                    (v7/*: any*/),
                                    (v8/*: any*/),
                                    (v9/*: any*/),
                                    (v10/*: any*/),
                                    (v11/*: any*/),
                                    (v12/*: any*/),
                                    (v51/*: any*/),
                                    (v52/*: any*/),
                                    (v53/*: any*/),
                                    (v54/*: any*/),
                                    (v55/*: any*/),
                                    (v56/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "IdleTime",
                                      "kind": "LinkedField",
                                      "name": "idleTime",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "isInternalTime",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "allocations(first:1000000,idleTimeOnly:true)"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projectPersons(first:1000000)"
        },
        {
          "alias": "phases",
          "args": null,
          "concreteType": "PhaseTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_phases_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhaseType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v40/*: any*/),
                    (v41/*: any*/),
                    (v42/*: any*/),
                    (v78/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "inheritDatesFrom",
                      "storageKey": null
                    },
                    (v67/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PhaseProgress",
                      "kind": "LinkedField",
                      "name": "progressDetails",
                      "plural": false,
                      "selections": (v79/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PhaseBaselineRoleTypeConnection",
                      "kind": "LinkedField",
                      "name": "phaseBaselineRoles",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PhaseBaselineRoleTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PhaseBaselineRoleType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v72/*: any*/),
                                (v80/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "baselinePrice",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v16/*: any*/),
                    (v51/*: any*/),
                    (v52/*: any*/),
                    (v53/*: any*/),
                    (v81/*: any*/),
                    (v82/*: any*/),
                    (v83/*: any*/),
                    (v84/*: any*/),
                    (v85/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineStartYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineStartMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineStartDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineDeadlineDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineDeadlineMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineDeadlineYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unit4Id",
                      "storageKey": null
                    },
                    (v86/*: any*/),
                    {
                      "alias": null,
                      "args": (v71/*: any*/),
                      "concreteType": "PhasePersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "phasePersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PhasePersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PhasePerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "availableMinutes",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "scheduledMinutes",
                                  "storageKey": null
                                },
                                (v30/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "phasePersons(first:10000)"
                    },
                    (v22/*: any*/),
                    (v87/*: any*/)
                  ],
                  "storageKey": null
                },
                (v23/*: any*/)
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "baselineFinancialNumbers",
          "args": [
            (v88/*: any*/),
            {
              "kind": "Literal",
              "name": "groupBy",
              "value": [
                "PHASE_BASELINE_ROLE",
                "PHASE"
              ]
            }
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "groupedFinancialNumbers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineTimeAndExpenses",
              "storageKey": null
            },
            (v78/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineRatePerHour",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineCostPerHour",
              "storageKey": null
            },
            (v80/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phaseBaselineRoleId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phaseBaselineExpenseId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phaseId",
              "storageKey": null
            }
          ],
          "storageKey": "groupedFinancialNumbers(convertToProjectCurrency:true,groupBy:[\"PHASE_BASELINE_ROLE\",\"PHASE\"])"
        },
        {
          "alias": null,
          "args": (v28/*: any*/),
          "concreteType": "SprintTypeConnection",
          "kind": "LinkedField",
          "name": "sprints",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SprintTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Sprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v16/*: any*/),
                    (v54/*: any*/),
                    (v55/*: any*/),
                    (v56/*: any*/),
                    (v51/*: any*/),
                    (v52/*: any*/),
                    (v53/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "sprints(first:1000000)"
        },
        {
          "alias": "tasks",
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_tasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReadOnly",
                      "kind": "LinkedField",
                      "name": "readOnly",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isReadOnly",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userCanDeleteTask",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userCantDeleteTaskReason",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasInvoicedTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasLockedTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasTimeRegistrations",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approved",
                      "storageKey": null
                    },
                    (v16/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sortOrder",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "done",
                      "storageKey": null
                    },
                    (v45/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "blocked",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latestUiUpdateAt",
                      "storageKey": null
                    },
                    (v81/*: any*/),
                    (v85/*: any*/),
                    (v51/*: any*/),
                    (v52/*: any*/),
                    (v53/*: any*/),
                    (v82/*: any*/),
                    (v83/*: any*/),
                    (v84/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecast",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecastMinutes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecastPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalPriceAtCompletion",
                      "storageKey": null
                    },
                    (v89/*: any*/),
                    (v90/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyTaskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canStart",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canBeSetToDone",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "highPriority",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasDependency",
                      "storageKey": null
                    },
                    (v86/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "vstsId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isUnit4Task",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subTaskCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "parentTaskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasChildren",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "favoured",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskProgress",
                      "kind": "LinkedField",
                      "name": "progressDetails",
                      "plural": false,
                      "selections": (v79/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "owner",
                      "plural": false,
                      "selections": (v92/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "followers",
                      "plural": true,
                      "selections": (v92/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PhaseType",
                      "kind": "LinkedField",
                      "name": "phase",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v16/*: any*/),
                        (v51/*: any*/),
                        (v52/*: any*/),
                        (v53/*: any*/),
                        (v81/*: any*/),
                        (v82/*: any*/),
                        (v83/*: any*/),
                        (v84/*: any*/),
                        (v85/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v18/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Sprint",
                      "kind": "LinkedField",
                      "name": "sprint",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v16/*: any*/),
                        (v51/*: any*/),
                        (v52/*: any*/),
                        (v53/*: any*/),
                        (v54/*: any*/),
                        (v55/*: any*/),
                        (v56/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "statusColumnV2",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v16/*: any*/),
                        (v70/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v43/*: any*/),
                        (v45/*: any*/),
                        (v48/*: any*/),
                        (v49/*: any*/),
                        (v57/*: any*/),
                        (v58/*: any*/),
                        (v59/*: any*/),
                        (v60/*: any*/),
                        (v61/*: any*/),
                        (v62/*: any*/),
                        (v44/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "personId",
                              "variableName": "personId"
                            }
                          ],
                          "concreteType": "ProjectPerson",
                          "kind": "LinkedField",
                          "name": "projectPerson",
                          "plural": false,
                          "selections": [
                            (v18/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v46/*: any*/),
                        (v16/*: any*/),
                        (v37/*: any*/),
                        (v63/*: any*/),
                        (v64/*: any*/),
                        (v65/*: any*/),
                        (v66/*: any*/),
                        (v39/*: any*/),
                        (v35/*: any*/),
                        (v36/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "HarvestProjectType",
                          "kind": "LinkedField",
                          "name": "harvestProject",
                          "plural": false,
                          "selections": (v14/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Unit4ProjectType",
                          "kind": "LinkedField",
                          "name": "unit4Project",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "activitiesEnabled",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v47/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskLabel",
                      "kind": "LinkedField",
                      "name": "taskLabels",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Label",
                          "kind": "LinkedField",
                          "name": "label",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v16/*: any*/),
                            (v27/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskDismissedNotifications",
                      "kind": "LinkedField",
                      "name": "taskDismissedNotifications",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "taskOverrunPredictionDismissed",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "assignedPersons",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v73/*: any*/),
                        (v76/*: any*/),
                        (v2/*: any*/),
                        (v74/*: any*/),
                        (v75/*: any*/),
                        (v91/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": (v29/*: any*/),
                      "concreteType": "DependencyTypeConnection",
                      "kind": "LinkedField",
                      "name": "thisTaskDependsOn",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DependencyTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "DependencyType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "type",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Task",
                                  "kind": "LinkedField",
                                  "name": "thisDependsOnTask",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v16/*: any*/),
                                    (v84/*: any*/),
                                    (v83/*: any*/),
                                    (v82/*: any*/),
                                    (v51/*: any*/),
                                    (v52/*: any*/),
                                    (v53/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "thisTaskDependsOn(first:1000)"
                    },
                    (v22/*: any*/),
                    {
                      "condition": "fetchLazyData",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        (v67/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "currentPrice",
                          "storageKey": null
                        },
                        (v69/*: any*/),
                        (v68/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "timeLeft",
                          "storageKey": null
                        },
                        {
                          "alias": "timeRegistrations",
                          "args": null,
                          "concreteType": "TimeRegTypeConnection",
                          "kind": "LinkedField",
                          "name": "__Task_timeRegistrations_connection",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TimeRegTypeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "TimeRegType",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v93/*: any*/),
                                    (v94/*: any*/),
                                    (v95/*: any*/),
                                    (v96/*: any*/),
                                    (v19/*: any*/),
                                    (v20/*: any*/),
                                    (v21/*: any*/),
                                    (v97/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Person",
                                      "kind": "LinkedField",
                                      "name": "person",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        (v72/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    (v22/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v23/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v24/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ]
                    },
                    (v87/*: any*/)
                  ],
                  "storageKey": null
                },
                (v23/*: any*/)
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v50/*: any*/),
          "concreteType": "AllocationTypeConnection",
          "kind": "LinkedField",
          "name": "allocations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AllocationTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Allocation",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v51/*: any*/),
                    (v52/*: any*/),
                    (v53/*: any*/),
                    (v54/*: any*/),
                    (v55/*: any*/),
                    (v56/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v72/*: any*/),
                        (v77/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": (v14/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isSoft",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allocations(first:100000)"
        },
        {
          "alias": "expenseFinancialNumbers",
          "args": [
            (v88/*: any*/),
            {
              "kind": "Literal",
              "name": "searchQuery",
              "value": {
                "filters": [
                  {
                    "field": "ROLE",
                    "operator": "IS",
                    "value": "Um9sZTotMQ=="
                  }
                ]
              }
            }
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            (v90/*: any*/)
          ],
          "storageKey": "financialNumbers(convertToProjectCurrency:true,searchQuery:{\"filters\":[{\"field\":\"ROLE\",\"operator\":\"IS\",\"value\":\"Um9sZTotMQ==\"}]})"
        },
        {
          "alias": "totalFinancialNumbers",
          "args": [
            (v88/*: any*/)
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            (v89/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registeredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableActualMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nonBillableActualMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableTotalTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            (v80/*: any*/)
          ],
          "storageKey": "financialNumbers(convertToProjectCurrency:true)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        },
        {
          "condition": "fetchLazyData",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "timeRegistrations",
              "args": null,
              "concreteType": "TimeRegTypeConnection",
              "kind": "LinkedField",
              "name": "__Project_timeRegistrations_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeRegTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TimeRegType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v93/*: any*/),
                        (v94/*: any*/),
                        (v96/*: any*/),
                        (v95/*: any*/),
                        (v34/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/),
                        (v97/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Task",
                          "kind": "LinkedField",
                          "name": "task",
                          "plural": false,
                          "selections": (v14/*: any*/),
                          "storageKey": null
                        },
                        (v30/*: any*/),
                        (v22/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v23/*: any*/)
                  ],
                  "storageKey": null
                },
                (v24/*: any*/)
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c8a9e045f34577f6eee278842fe995f';

module.exports = node;
