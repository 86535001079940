import React from 'react';
import { Modal } from '@forecast-it/design-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
const DeleteReportModal = ({ closeModal, reportName, reportCount, callback }) => {
    const deleteReport = () => {
        callback();
        closeModal();
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'report.delete-report', values: {
                    reportCount: reportCount,
                } })),
        React.createElement(Modal.Content, null,
            reportName ? (React.createElement(FormattedHTMLMessage, { id: 'report.delete-report-description', values: {
                    reportName: reportName,
                } })) : null,
            !reportName ? (React.createElement(FormattedHTMLMessage, { id: 'report.bulk-delete-report-description', values: {
                    reportCount: reportCount,
                } })) : null),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: deleteReport, dataCy: 'modal-btn-confirm-delete', disabled: false },
                React.createElement(FormattedMessage, { id: "common.delete" })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                React.createElement(FormattedMessage, { id: "common.cancel" })))));
};
export default DeleteReportModal;
