/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query CalendarEventsFetch_Query(
  $start: String
  $end: String
) {
  viewer {
    id
    component(name: "google_calendar_events")
    calendarEvents(start: $start, end: $end) {
      edges {
        node {
          id
          name
          description
          start
          end
          calendarTimeZone
          allDay
          calendarId
          foregroundColor
          backgroundColor
          attendees
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "start",
            "type": "String"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "end",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "name",
                            "value": "google_calendar_events"
                        }
                    ],
                    "kind": "ScalarField",
                    "name": "component",
                    "storageKey": "component(name:\"google_calendar_events\")"
                },
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "end",
                            "variableName": "end"
                        },
                        {
                            "kind": "Variable",
                            "name": "start",
                            "variableName": "start"
                        }
                    ],
                    "concreteType": "CalendarEventTypeConnection",
                    "kind": "LinkedField",
                    "name": "calendarEvents",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CalendarEventTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CalendarEvent",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "description",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "start",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "end",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "calendarTimeZone",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "allDay",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "calendarId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "foregroundColor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "backgroundColor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "attendees",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CalendarEventsFetch_Query",
            "selections": (v2 /*: any*/),
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CalendarEventsFetch_Query",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "CalendarEventsFetch_Query",
            "operationKind": "query",
            "text": "query CalendarEventsFetch_Query(\n  $start: String\n  $end: String\n) {\n  viewer {\n    id\n    component(name: \"google_calendar_events\")\n    calendarEvents(start: $start, end: $end) {\n      edges {\n        node {\n          id\n          name\n          description\n          start\n          end\n          calendarTimeZone\n          allDay\n          calendarId\n          foregroundColor\n          backgroundColor\n          attendees\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '1498563cf02e54f2fdffde97fb12c1d9';
export default node;
