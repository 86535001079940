import React from 'react';
import Util from '../../../../../util/util';
import {useIntl} from 'react-intl';
import ActionsMenu from '../../../../action-menu/actions_menu';
import {createToast} from '../../../../toasts/another-toast/toaster';
import DeletePhaseMutation from '../../../../../../../mutations/delete_phase_mutation';
import {MODAL_TYPE, showModal} from '../../../../modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../../constants';
import DuplicatePhaseMutation from '../../../../../../../mutations/duplicate_phase_mutation';
import MergePhaseMutation from '../../../../../../../mutations/MergePhaseMutation';
import SendMilestoneToJiraMutationModern from '../../../../../../../mutations/send_milestone_to_jira_mutation.modern';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasPermission} from '../../../../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../../Permissions';
import {isJiraToForecastOneWaySync} from '../../../../../util/JiraUtil';

/**
 * This component is used for building the Action Menu for phases.
 *
 * @param project The project the phase belongs to.
 * @param phase The phase to show the menu for.
 * @param cy
 * @param backgroundColor The background color.
 * @param disabled Should the menu be disabled.
 * @returns {JSX.Element} The Component for the Action Menu.
 */
export const PhaseContextMenu = React.memo(({company, project, phase, cy, backgroundColor, disabled}) => {
	const {formatMessage} = useIntl();
	const isWhite = Util.getTextColor(backgroundColor) === '#ffffff';

	/**
	 * Action for deleting a phase.
	 * 1. Show warning modal
	 * 2. Delete phase
	 * 3. Show toast on success
	 */
	const phaseDeleteAction = () => {
		const onSuccess = () => {
			createToast({duration: 5000, message: formatMessage({id: 'scope.has-been-deleted'})});
		};

		const callbackPositive = params => {
			Util.CommitMutation(
				DeletePhaseMutation,
				{
					id: phase.id,
					projectId: project.id,
				},
				onSuccess
			);
		};

		const warningInfoId = !!project.useBaseline
			? 'baseline.delete_confirmation_information'
			: 'project_scoping.delete_confirmation_information';

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'project_scoping.delete_confirmation',
			warningInformation: [
				formatMessage({id: warningInfoId}),
				formatMessage({id: 'common.warning.this_action_can_not_be_undone'}),
			],
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					cy: 'modal-btn-confirm-delete',
				},
			],
		});
	};

	/**
	 * Action for duplicating a phase.
	 * 1. Duplicate phase.
	 * 2. Refetch data for new Phase.
	 * 3. Show toast on success.
	 */
	const phaseDuplicateAction = () => {
		const onSuccess = () => {
			// if (refetch) {
			// 	refetch();
			// }

			createToast({duration: 5000, message: formatMessage({id: 'project_scoping.duplicated_phase'})});
		};

		Util.CommitMutation(
			DuplicatePhaseMutation,
			{
				id: phase.id,
				projectId: project.id,
			},
			onSuccess
		);
	};

	/**
	 * Action for merging phase into another phase.
	 * 1. Show modal with selection of phase to merge to.
	 * 2. Merge of phases.
	 * 3. Show toast on success.
	 */
	const phaseMergeAction = () => {
		const currentPhase = phase;

		const callbackPositive = selected => {
			const onSuccess = result => {
				createToast({duration: 5000, message: formatMessage({id: 'project_scoping.merge_phase_toast'})});
			};
			Util.CommitMutation(
				MergePhaseMutation,
				{
					id: currentPhase.id,
					mergeFromPhaseId: selected ? selected.value : null,
					projectId: project.id,
				},
				onSuccess
			);
		};

		const filterSameIntegration = phase => {
			return (
				phase.node.id !== currentPhase.id &&
				!(currentPhase.jiraId && phase.node.jiraId) &&
				!(currentPhase.unit4Id && phase.node.unit4Id)
			);
		};

		const phase_select_options = project.phases.edges.filter(filterSameIntegration).map(phase => ({
			value: phase.node.id,
			label: phase.node.name,
		}));

		showModal({
			type: MODAL_TYPE.SELECT_V2,
			title: formatMessage({id: 'project_scoping.merge_phase'}),
			subTitle: formatMessage({id: 'project_scoping.merge_phase_description'}, {phase: currentPhase.name}),
			defaultCallback: callbackPositive,
			options: phase_select_options,
			emptyText: formatMessage({id: 'project_scoping.merge_phase_empty'}),
			label: formatMessage({id: 'common.scope-group'}),
			multi: false,
			initialValue: null,
		});
	};

	/**
	 * Action for moving phase into another project.
	 * 1. Show modal with selection for project.
	 * 2. Move phase into selected project.
	 */
	const phaseMoveAction = () => {
		showModal({
			type: MODAL_TYPE.PHASE_LOCATION,
			currentProjectId: project.id,
			phaseId: phase.id,
			isJiraPhase: !!phase.jiraId,
		});
	};

	/**
	 * Action for sending phase to Jira.
	 * 1. Show modal
	 * 2. Send Phase to Jira
	 * 3. Show toast on success / failure
	 */
	const phaseMoveToJiraAction = () => {
		const callbackPositive = (selectedCustomFieldValues, jiraProjectId) => {
			const onSuccess = result => {
				if (!result.sendMilestoneToJira.phase) {
					createToast({
						duration: 5000,
						message: formatMessage({id: 'project_scoping.jira.phase.notmoved'}),
					});
				} else {
					createToast({duration: 5000, message: formatMessage({id: 'project_scoping.jira.phase.moved'})});
				}
			};

			const customFields = selectedCustomFieldValues
				? Object.keys(selectedCustomFieldValues).map(key => ({
						key: key,
						value: !Array.isArray(selectedCustomFieldValues[key])
							? selectedCustomFieldValues[key]
							: Array.prototype.map.call(selectedCustomFieldValues[key], s => s.value).toString(),
				  }))
				: undefined;

			Util.CommitMutation(
				SendMilestoneToJiraMutationModern,
				{
					id: phase.id,
					customFields: customFields,
					jiraProjectId: jiraProjectId.value,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.CREATE_JIRA_MILESTONE,
			defaultCallback: callbackPositive,
			projectId: project.id,
			milestoneId: phase.id,
		});
	};

	/**
	 * This function builds the options for the Action Menu.
	 *
	 * @returns The Action Menu
	 */
	const actionMenuOptions = () => {
		const phaseTasks = project.tasks.edges.map(taskNode => taskNode.node).filter(task => task.phase?.id === phase.id);

		const invoicedTimeReg = phaseTasks.find(task => task.hasInvoicedTime);
		const lockedTimeReg = phaseTasks.find(task => task.hasLockedTime);
		const phaseJira = phase.jiraId;
		const phaseUnit4Id = phase.unit4Id;
		const {vstsProject, isJiraProject, jiraCloudProject, jiraCloudEpicIds, jiraServerProject} = project;

		//Menu entries
		const actionMenu = [];

		const movePhaseDisabled =
			vstsProject ||
			(jiraCloudProject && !jiraCloudEpicIds) ||
			jiraServerProject ||
			invoicedTimeReg ||
			lockedTimeReg ||
			phaseUnit4Id ||
			(phaseJira && !jiraCloudEpicIds);

		if (!movePhaseDisabled) {
			actionMenu.push({
				text: formatMessage({id: 'task_location_modal.title'}),
				onClick: phaseMoveAction,
				callback: phaseMoveAction,
				cy: 'move-btn',
			});
		}

		actionMenu.push({
			text: formatMessage({id: 'common.duplicate'}),
			onClick: phaseDuplicateAction,
			callback: phaseDuplicateAction,
			cy: 'duplicate-btn',
		});

		actionMenu.push({
			text: formatMessage({id: 'project_scoping.merge_phase'}),
			onClick: phaseMergeAction,
			callback: phaseMergeAction,
		});

		if (!isJiraToForecastOneWaySync(company) && isJiraProject && (jiraCloudProject || jiraCloudEpicIds) && !phaseJira) {
			actionMenu.push({
				text: formatMessage({id: 'common.move_to_jira'}),
				onClick: phaseMoveToJiraAction,
				callback: phaseMoveToJiraAction,
			});
		}

		if (!phaseUnit4Id && hasPermission(PERMISSION_TYPE.PHASE_DELETE)) {
			actionMenu.push({
				text: formatMessage({id: 'common.delete'}),
				onClick: phaseDeleteAction,
				callback: phaseDeleteAction,
				cy: 'delete-btn',
			});
		}

		return actionMenu;
	};

	return (
		<Wrapper>
			<ActionsMenu
				useBlackIcon={!isWhite}
				isWhite={isWhite}
				whiteInner={true}
				options={actionMenuOptions()}
				cy={cy}
				disabled={disabled}
			/>
		</Wrapper>
	);
});

PhaseContextMenu.propTypes = {
	project: PropTypes.object,
	phase: PropTypes.object,
	cy: PropTypes.string,
	backgroundColor: PropTypes.string,
	disabled: PropTypes.bool,
};

export const Wrapper = Styled.div`    
    padding: 0px 10px 0px 10px;   
`;
