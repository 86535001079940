import { MODAL_TYPE, showModal } from '../../../shared/components/modals/generic_modal_conductor';
import { createToast } from '../../../shared/components/toasts/another-toast/toaster';
import RenameSavedReportMutation from '../../../../mutations/rename_saved_report_mutation';
import Util from '../../../shared/util/util';
const renameReportMutation = (report, intl, name) => {
    const onSuccess = () => {
        createToast({
            duration: 5000,
            message: intl.formatMessage({ id: 'save_report.report_renamed' }, { oldName: report.name, newName: name }),
        });
    };
    Util.CommitMutation(RenameSavedReportMutation, {
        id: report.id,
        reportService: report.reportService,
        name: name,
    }, onSuccess);
};
export const renameReport = (report, intl) => {
    showModal({
        type: MODAL_TYPE.RENAME_REPORT_MODAL,
        reportName: report.name,
        renameReport: renameReportMutation.bind(this, report, intl),
    });
};
