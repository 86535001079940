import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeprecatedScrollbar as Scrollbar, PopoverHandler } from '@forecast-it/design-system';
import { isTimeRegistrationNoteInvalid } from '../../../../../shared/util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import { workingHourForTheDay } from '../../../timesheets_person_data';
import AdvancedTimeRegistrationContent, { PopupFocusItem } from './AdvancedTimeRegistrationContent';
import { CancelButton, Column, Popup, PrimaryButton as UpdateButton, Row } from './AdvancedTimeRegistrationStyled';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import Moment from 'moment/moment';
import { useTaskEstimateValidation } from '../../../../../shared/util/time-registration/time-registration-settings/useTaskEstimateValidation';
import { useIntl } from 'react-intl';
import { isBillableSplitAllowed } from '../../../../../shared/util/cache/TimeRegistrationSettingsUtil';
function updateTimeRegistrationById(id, updatedProperties, timeRegistrations) {
    return timeRegistrations.map(timeRegistration => {
        if (timeRegistration.id === id) {
            // Set a changed flag in order to only run mutations on the changed time registrations in the end
            return Object.assign(Object.assign(Object.assign({}, timeRegistration), updatedProperties), { shouldUpdate: true });
        }
        return timeRegistration;
    });
}
function getInitialTimeRegistrationList(timeRegistrationsOnEntityOnDay) {
    return timeRegistrationsOnEntityOnDay
        .filter(timeRegistration => {
        return timeRegistration.fullAccessToProject;
    })
        .map(timeRegistration => (Object.assign(Object.assign({}, timeRegistration), { initialMinutesRegistered: timeRegistration.minutesRegistered, billableMinutesRegistered: timeRegistration.minutesRegistered !== timeRegistration.billableMinutesRegistered
            ? timeRegistration.billableMinutesRegistered
            : null, noteInvalid: false, shouldUpdate: false })));
}
function areAnyBillable(timeRegNodes) {
    return timeRegNodes.some(timeReg => timeReg.billable);
}
function canAnyHaveRole(timeRegNodes) {
    return timeRegNodes.some(timeReg => timeReg.canHaveRole);
}
function UpdateAdvancedTimeRegistrationContent({ timeRegistrationsOnEntityOnDay, selectedPerson, dayOfInput, mutation, projectId, isCompanyTimesheet, }) {
    useEffect(() => {
        trackEvent('Update Advanced Time Registration Popup', 'Opened');
    }, []);
    const intl = useIntl();
    const { setOpen } = PopoverHandler.usePopoverState();
    const [timeRegistrations, setTimeRegistrations] = useState(getInitialTimeRegistrationList(timeRegistrationsOnEntityOnDay));
    const [timeRegistrationFocus, setTimeRegistrationFocus] = useState({ id: null, focusItem: null }); // Used to propagate focus control down to children
    const updateButtonRef = useRef(null);
    const onTimeChange = useCallback((id, minutes, isEnterBlur) => {
        var _a;
        setTimeRegistrations(updateTimeRegistrationById(id, { minutesRegistered: minutes }, timeRegistrations));
        if (isEnterBlur) {
            (_a = updateButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [timeRegistrations]);
    const onBillableTimeChange = useCallback((id, minutes, isEnterBlur) => {
        var _a;
        setTimeRegistrations(updateTimeRegistrationById(id, { billableMinutesRegistered: minutes }, timeRegistrations));
        if (isEnterBlur) {
            (_a = updateButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [timeRegistrations]);
    const onNotesChange = useCallback((id, notes) => {
        setTimeRegistrations(updateTimeRegistrationById(id, { notes: notes, noteInvalid: false }, timeRegistrations));
    }, [timeRegistrations]);
    const onRoleChange = useCallback((id, role) => {
        setTimeRegistrations(updateTimeRegistrationById(id, { role: role }, timeRegistrations));
    }, [timeRegistrations]);
    function updateTimeRegistrations(timeRegistrations) {
        const timeRegistrationsToUpdate = timeRegistrations.filter(timeRegistration => !!timeRegistration.shouldUpdate);
        const totalNewMinutesRegisteredByTaskId = {};
        let firstInvalidMinutesTimeReg = undefined;
        for (const timeRegistration of timeRegistrationsToUpdate) {
            const taskId = timeRegistration.taskId || 'noTask';
            const { minutesRegistered, initialMinutesRegistered, taskTotalMinutesRegistered, taskEstimateForecastMinutes } = timeRegistration;
            /*
             * We don't use the initialMinutes here as we want the totalNewMinutesRegisteredByTaskId to account for new values being lower than initial
             * We can have multiple timeregs for the same task in the list so if you lower the first registration you should be able to increase the subsequent ones
             **/
            const { validateTimeInputValue } = useTaskEstimateValidation(taskEstimateForecastMinutes, taskTotalMinutesRegistered, 0, intl);
            if (!totalNewMinutesRegisteredByTaskId[taskId]) {
                totalNewMinutesRegisteredByTaskId[taskId] = 0;
            }
            totalNewMinutesRegisteredByTaskId[taskId] += initialMinutesRegistered
                ? minutesRegistered - initialMinutesRegistered
                : minutesRegistered;
            timeRegistration.timeError = validateTimeInputValue(totalNewMinutesRegisteredByTaskId[taskId]);
            if (timeRegistration.timeError) {
                firstInvalidMinutesTimeReg = timeRegistration;
                break;
            }
        }
        if (firstInvalidMinutesTimeReg) {
            setTimeRegistrationFocus({ id: firstInvalidMinutesTimeReg.id, focusItem: PopupFocusItem.Hours });
            setTimeRegistrations(timeRegistrations.map(timeRegistration => (Object.assign({}, timeRegistration))));
            return;
        }
        // In case time registrations exist from before the setting was enabled without notes, we only want to require a note in case the time registration itself is being changed.
        const firstInvalidNoteTimeReg = timeRegistrationsToUpdate.find(timeRegistration => isTimeRegistrationNoteInvalid(timeRegistration.notes));
        if (firstInvalidNoteTimeReg) {
            setTimeRegistrationFocus({ id: firstInvalidNoteTimeReg.id, focusItem: PopupFocusItem.Notes });
            setTimeRegistrations(timeRegistrations.map(timeRegistration => (Object.assign(Object.assign({}, timeRegistration), { noteInvalid: timeRegistration.shouldUpdate ? isTimeRegistrationNoteInvalid(timeRegistration.notes) : false }))));
            return;
        }
        timeRegistrationsToUpdate.forEach(timeRegistration => {
            var _a;
            const billable = timeRegistration.billable;
            const hoursRegistered = timeRegistration.minutesRegistered ? timeRegistration.minutesRegistered / 60 : 0;
            const billableHoursRegistered = billable
                ? timeRegistration.billableMinutesRegistered != null
                    ? timeRegistration.billableMinutesRegistered / 60
                    : null
                : undefined;
            mutation(hoursRegistered, billableHoursRegistered, timeRegistration.notes, timeRegistration.id, (_a = timeRegistration.role) === null || _a === void 0 ? void 0 : _a.id);
            trackEvent('Advanced Time Registration', 'Updated', {
                initialValues: getInitialTimeRegistrationList(timeRegistrationsOnEntityOnDay).find(t => t.id === timeRegistration.id),
                updatedValues: timeRegistration,
            });
        });
        setOpen(false);
    }
    const workingHours = useMemo(() => workingHourForTheDay(selectedPerson, Moment(dayOfInput)), [selectedPerson, dayOfInput]);
    const makeWider = areAnyBillable(timeRegistrations) && isBillableSplitAllowed() && canAnyHaveRole(timeRegistrations);
    return (React.createElement(Popup, { makeWider: makeWider },
        React.createElement(Scrollbar, { maxHeight: '270px' },
            React.createElement(Column, null, timeRegistrations.map(timeRegistration => {
                const { id, minutesRegistered, billableMinutesRegistered, billable, notes, noteInvalid, role, timeError, initialMinutesRegistered, taskTotalMinutesRegistered, taskEstimateForecastMinutes, canHaveRole, } = timeRegistration;
                const { remainingInfoMessage } = useTaskEstimateValidation(taskEstimateForecastMinutes, taskTotalMinutesRegistered, initialMinutesRegistered, intl);
                return (React.createElement(AdvancedTimeRegistrationContent, { id: id, key: id, timeInputMinutes: minutesRegistered, onTimeChange: (minutes, isEnterBlur) => onTimeChange(id, minutes, isEnterBlur), billableTimeInputMinutes: billableMinutesRegistered, onBillableTimeChange: (minutes, isEnterBlur) => onBillableTimeChange(id, minutes, isEnterBlur), notes: notes, onNotesChange: notes => onNotesChange(id, notes), billable: billable, noteError: noteInvalid, timeError: timeError, remainingInfoMessage: remainingInfoMessage, workingHours: workingHours, timeRegistrationFocus: timeRegistrationFocus, role: role, onRoleChange: role => onRoleChange(id, role), firstTimeRegistration: timeRegistration === timeRegistrations[0], canHaveRole: canHaveRole, projectId: projectId, isCompanyTimesheet: isCompanyTimesheet }));
            }))),
        React.createElement(Row, { style: { justifyContent: 'flex-end' } },
            React.createElement(CancelButton, { onClick: () => {
                    setTimeRegistrations(getInitialTimeRegistrationList(timeRegistrationsOnEntityOnDay));
                    trackEvent('Update Advanced Time Registration Popup', 'Closed');
                    setOpen(false);
                }, "z-index": 10 }, "Cancel"),
            React.createElement(UpdateButton, { "z-index": 11, ref: updateButtonRef, "data-cy": "update-time-entry-button", onClick: () => updateTimeRegistrations(timeRegistrations) }, "Update"))));
}
export default UpdateAdvancedTimeRegistrationContent;
