import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
import { differenceInMinutes } from 'date-fns';
import { getEndDate, getStartDate } from '../TimesheetsCalendarUtil';
import { hasFeatureFlag } from '../../../../shared/util/FeatureUtil';
export const openTimeRegModal = (person, calendarEvent, markEventAsConverted) => {
    const start = getStartDate(calendarEvent) || new Date();
    const end = getEndDate(calendarEvent) || new Date();
    const duration = differenceInMinutes(end, start);
    const calendarEventName = calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.name;
    const taskIdRegex = /(?:^|\s)T-?(\d{1,6})(?:$|\s)/i;
    const taskMatch = calendarEventName ? taskIdRegex.exec(calendarEventName) : null;
    const taskId = taskMatch ? taskMatch[1] : undefined;
    const taskProjectIdRegex = /(?:^|\s)P-?(\d{1,6})(?:$|\s)/i;
    const projectMatch = calendarEventName ? taskProjectIdRegex.exec(calendarEventName) : null;
    const projectId = projectMatch ? projectMatch[1] : undefined;
    const hasCustomProjectIds = hasFeatureFlag('custom_project_ids');
    const companyProjectId = taskId || !projectId ? undefined : hasCustomProjectIds ? `P-${projectId}` : projectId;
    showModal({
        type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
        personId: person.id,
        companyTaskId: taskId,
        companyProjectId: companyProjectId,
        initialNote: calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.name,
        initialDate: start,
        initialMinutes: duration,
        onCreateSuccess: () => markEventAsConverted(calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.id),
    });
};
export const openCreateTaskModal = (person, calendarEvent, markEventAsConverted) => {
    var _a;
    const start = getStartDate(calendarEvent) || new Date();
    const end = getEndDate(calendarEvent) || new Date();
    const duration = differenceInMinutes(end, start);
    let description = (calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.description) ? '<p>' + (calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.description) + '</p>' : null;
    if (description) {
        description = description.replace(/(\r\n|\r|\n)/g, '<br>');
    }
    showModal({
        type: MODAL_TYPE.TASK_LOCATION,
        viewerId: person.id,
        currentProjectGroupId: null,
        currentProjectGroupName: null,
        name: calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.name,
        description: description,
        startYear: start.getFullYear(),
        startMonth: start.getMonth() + 1,
        startDay: start.getDate(),
        deadlineYear: end.getFullYear(),
        deadlineMonth: end.getMonth() + 1,
        deadlineDay: end.getDate(),
        estimate: duration,
        assignedPersons: [person.id],
        roleId: (_a = person.role) === null || _a === void 0 ? void 0 : _a.id,
        onCreateSuccess: () => markEventAsConverted(calendarEvent === null || calendarEvent === void 0 ? void 0 : calendarEvent.id),
    });
};
