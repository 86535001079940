import React from 'react';
import { Modal } from '@forecast-it/design-system';
import { createToast } from '../../../shared/components/toasts/another-toast/toaster';
import { dateRangeToYMD } from '../../../shared/util/DateUtil';
import { copyAll, copyWithoutDoneTasks } from './TimesheetsCopyLastWeekModalLogic';
function getCopyToastMessage(duplicatedTimeRegsAmount) {
    if (duplicatedTimeRegsAmount === 0) {
        return `No time registrations have been copied`;
    }
    else if (duplicatedTimeRegsAmount === 1) {
        return `${duplicatedTimeRegsAmount} time registration has been copied`;
    }
    else {
        return `${duplicatedTimeRegsAmount} time registrations have been copied`;
    }
}
function onCopySuccess(mutationResponse, closeModal) {
    var _a, _b;
    const duplicatedTimeRegsAmount = ((_b = (_a = mutationResponse.duplicateTimeRegsInDateRange) === null || _a === void 0 ? void 0 : _a.timeRegs) === null || _b === void 0 ? void 0 : _b.length) || 0;
    createToast({
        duration: 5000,
        message: getCopyToastMessage(duplicatedTimeRegsAmount),
    });
    closeModal();
}
function handleCopyAll(personId, copyDateRange, onCopyCallback, closeModal) {
    onCopyCallback();
    copyAll(personId, copyDateRange, mutationResponse => onCopySuccess(mutationResponse, closeModal));
}
function handleCopyWithoutDoneTasks(personId, copyDateRange, onCopyCallback, closeModal) {
    onCopyCallback();
    copyWithoutDoneTasks(personId, copyDateRange, mutationResponse => onCopySuccess(mutationResponse, closeModal));
}
export function TimesheetsCopyLastWeekModalFooter({ personId, copyDateRange, closeModal, onCopyCallback, copyDone, copyFullWeek, }) {
    const copyDateRangeYMD = dateRangeToYMD(copyDateRange);
    return (React.createElement(Modal.Footer, null,
        React.createElement(Modal.PrimaryFooterButton, { dataCy: "copy-last-week-primary-button", key: 'modal_primaryFooterButton_copy_wo_done_tasks_key', onClick: () => copyDone
                ? handleCopyAll(personId, copyDateRangeYMD, onCopyCallback, closeModal)
                : handleCopyWithoutDoneTasks(personId, copyDateRangeYMD, onCopyCallback, closeModal) }, `Copy from previous ${copyFullWeek ? 'week' : 'day'}`)));
}
