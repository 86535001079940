import React from 'react';
import {Element, TooltipContainer, TooltipTitle, TooltipValue} from './ProjectScopingTotals_styled';
import {useProjectTotals} from './ProjectScopingTotalsContext';
import {useIntl} from 'react-intl';
import {hasFeatureFlag} from '../../../../shared/util/FeatureUtil';

/**
 * Creating Time entries tooltip content
 * @returns JSX element with tooltip content
 */
export const ProjectScopingTotalsTooltips = () => {
	const intl = useIntl();
	const {totalsData} = useProjectTotals();
	const hasFinancialServiceInScoping = hasFeatureFlag('use_financial_service_in_scoping');

	return (
		<TooltipContainer>
			<Element split>
				<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_actual_revenue_on_task'}) + ':'}</TooltipTitle>
				<TooltipValue>
					{hasFinancialServiceInScoping ? totalsData.totalPriceAtCompletionForTasks : totalsData.actualRevenue}
				</TooltipValue>
			</Element>
			<Element split>
				<TooltipTitle>
					{intl.formatMessage({id: 'project_scoping.tooltip_actual_revenue_on_project'}) + ':'}
				</TooltipTitle>
				<TooltipValue>
					{hasFinancialServiceInScoping
						? totalsData.totalPriceAtCompletionForProject
						: totalsData.actualRevenueForProject}
				</TooltipValue>
			</Element>
		</TooltipContainer>
	);
};

/**
 * Creating Actual Revenue tooltip content
 * @returns JSX element with tooltip content
 */
export const TimeEntriesValueTooltip = () => {
	const intl = useIntl();
	const {totalsData} = useProjectTotals();

	return (
		<TooltipContainer>
			<Element split>
				<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_time_entries_on_task'}) + ':'}</TooltipTitle>
				<TooltipValue>{totalsData.timeEntriesOnTasks}</TooltipValue>
			</Element>
			{hasFeatureFlag('forecast_epics') && Number.isInteger(totalsData.timeEntriesOnEpics) && (
				<Element split>
					<TooltipTitle>
						{intl.formatMessage({id: 'project_scoping.tooltip_time_entries_on_epics'}) + ':'}
					</TooltipTitle>
					<TooltipValue>{totalsData.timeEntriesOnEpics}</TooltipValue>
				</Element>
			)}
			<Element split>
				<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_time_entries_on_project'}) + ':'}</TooltipTitle>
				<TooltipValue>{totalsData.timeEntriesOnProject}</TooltipValue>
			</Element>
		</TooltipContainer>
	);
};

/**
 * Creating Remaining tooltip content
 * @returns JSX element with tooltip content
 */
export const RemainingValueTooltip = () => {
	const intl = useIntl();
	const {totalsData} = useProjectTotals();

	return (
		<TooltipContainer>
			<Element split>
				<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_remaining_on_task'}) + ':'}</TooltipTitle>
				<TooltipValue>{totalsData.remainingOnTasks}</TooltipValue>
			</Element>
			{hasFeatureFlag('forecast_epics') && Number.isInteger(totalsData.remainingOnEpics) && (
				<Element split>
					<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_remaining_on_epics'}) + ':'}</TooltipTitle>
					<TooltipValue>{totalsData.remainingOnEpics}</TooltipValue>
				</Element>
			)}
		</TooltipContainer>
	);
};

/**
 * Creating Estimate tooltip content
 * @returns JSX element with tooltip content
 */
export const EstimateValueTooltip = () => {
	const intl = useIntl();
	const {totalsData} = useProjectTotals();

	return (
		<TooltipContainer>
			<Element split>
				<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_estimate_on_task'}) + ':'}</TooltipTitle>
				<TooltipValue>{totalsData.estimateOnTasks}</TooltipValue>
			</Element>
			{hasFeatureFlag('forecast_epics') && Number.isInteger(totalsData.estimateOnEpics) && (
				<Element split>
					<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_estimate_on_epics'}) + ':'}</TooltipTitle>
					<TooltipValue>{totalsData.estimateOnEpics}</TooltipValue>
				</Element>
			)}
		</TooltipContainer>
	);
};

/**
 * Creating Variance tooltip content
 * @returns JSX element with tooltip content
 */
export const VarianceValueTooltip = () => {
	const intl = useIntl();
	const {totalsData} = useProjectTotals();

	return (
		<TooltipContainer>
			<Element split>
				<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_variance_on_task'}) + ':'}</TooltipTitle>
				<TooltipValue>{totalsData.varianceOnTasks}</TooltipValue>
			</Element>
			{hasFeatureFlag('forecast_epics') && Number.isInteger(totalsData.varianceOnEpics) && (
				<Element split>
					<TooltipTitle>{intl.formatMessage({id: 'project_scoping.tooltip_variance_on_epics'}) + ':'}</TooltipTitle>
					<TooltipValue>{totalsData.varianceOnEpics}</TooltipValue>
				</Element>
			)}
		</TooltipContainer>
	);
};
