import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {getStoreNodeById, replaceEdgesOnConnection} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation updateAllReportSharesMutation($input: UpdateAllReportSharesInput!) {
		updateAllReportShares(input: $input) {
			shareEdges {
				node {
					id
					user {
						id
						email
					}
				}
			}
			savedReport {
				id
				privateAccess
				updatedAt
			}
			warnings
		}
	}
`;

function customUpdater(store, reportId) {
	const payload = store.getRootField('updateAllReportShares');
	const resultEdges = payload.getLinkedRecords('shareEdges');
	const reportNode = getStoreNodeById({store, id: reportId});
	replaceEdgesOnConnection({
		store,
		edges: resultEdges,
		typeName: 'ShareEdge',
		parentNode: reportNode,
		connectionName: 'Report_shares',
	});
}

function commit(environment, input, onSuccess, onError) {
	const cleanedInput = omit(input, []);
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		updater: store => {
			customUpdater(store, input.reportId);
		},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
