import Styled from 'styled-components';

export const ItemCount = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 12px;
	gap: 8px;
	height: 44px;
	background: #F5F5F8;
	border-radius: 100px;
`;

export const ItemCountText = Styled.text`
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #393946;
`;

export const SectionLine = Styled.div`
	border-bottom: 2px solid #E7E7F3;
`;

export const FeatureWrapper = Styled.div`
	border-bottom: 1px solid #D3D3DF;
	padding-bottom: 8px;
`;

export const FeatureHeaderWrapper = Styled.div`
	padding-bottom: 8px;
`;

export const TextWrapper = Styled.div`
	width: 60%;
	padding-bottom: 8px;
`;

export const MissingBoard = Styled.text`
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0px;
	line-height: 20px;
	cursor: pointer;
	color: #00589E;
`;
