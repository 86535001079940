import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import * as tracking from '../../tracking';
import UpdateCompanySubscriptionDataMutation from '../../mutations/update_company_subscription_data_mutation';
import CustomScrollDiv from '../shared/components/scroll-bars/custom_scroll_div';
import Button from '../shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {createToast} from '../shared/components/toasts/another-toast/toaster';
import Util from '../shared/util/util';
import {MODAL_TYPE, showModal} from '../shared/components/modals/generic_modal_conductor';
import {withRouter} from 'react-router-dom';
import {personIsClientUser, personIsCollaboratorUser, personIsVirtualUser} from '../shared/util/PermissionsUtil';
import {TopHeaderBar, TopHeaderBarWrapper} from '../shared/components/headers/top-header-bar/TopHeaderBar';
import DirectApi from '../../directApi';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import InlineLoader from '../shared/components/inline-loader/inline_loader';
import {hasFeatureFlag} from '../shared/util/FeatureUtil';

class settingsSubscription extends Component {
	constructor(props) {
		super(props);

		this.state = {
			invoiceEmail: props.viewer.company.subscription ? this.props.viewer.company.subscription.email : null,
			contactPhone: props.viewer.company.contactPhone,
			contactName: props.viewer.company.contactName,
			loading: false,
			isUpdatingSeats: false,
			removeSeatsReasonSpecified: false,
		};

		this.superPropertyChecksum = trackPage('Settings Subscription');
	}

	componentDidMount() {
		document.title = 'Subscription - Account - Forecast';
		// Segment
		tracking.trackPage('settings-subscription');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.viewer.company.contactName !== this.state.contactName) {
			this.setState({contactName: nextProps.viewer.company.contactName});
		}
		if (nextProps.viewer.company.contactPhone !== this.state.contactPhone) {
			this.setState({contactPhone: nextProps.viewer.company.contactPhone});
		}
		if (nextProps.viewer.company.subscription && nextProps.viewer.company.subscription.email !== this.state.invoiceEmail) {
			this.setState({invoiceEmail: nextProps.viewer.company.subscription.email});
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleRemoveSeatRequest(seats, newCollaboratorSeats, virtualSeats, reason, reasonElaborated) {
		if (process.env.CIRCLE_BRANCH === 'production') {
			if (reason !== '') {
				this.setState({removeSeatsReasonSpecified: true});
			}
			this.setState({isUpdatingSeats: true});
			const onSuccess = seatRequestSuccess => {
				this.setState({isUpdatingSeats: false});
				createToast({
					duration: 5000,
					message: seatRequestSuccess ? 'Seat Update Request has been sent' : 'Request could not be sent',
				});
			};

			const seatRequest = {
				companyName: this.props.viewer.company.name,
				userEmail: this.props.viewer.email,
				removeSeats: seats !== '' ? seats : 0,
				removeVirtualSeats: virtualSeats !== '' ? virtualSeats : 0,
				reason: reason,
				reasonElaborated: reasonElaborated,
			};

			const headers = new Headers();
			headers.append('Content-Type', 'application/json');
			const init = {
				method: 'POST',
				headers,
				credentials: 'include',
				body: JSON.stringify(seatRequest),
			};

			fetch(DirectApi.graphqlServerEndpoint('remove_seat_request'), init)
				.then(res => res.json())
				.then(() => {
					onSuccess(true);
				})
				.catch(() => {
					onSuccess(false);
				});
		}
	}

	updateSeats(newSeats, newCollaboratorSeats, newVirtualSeats, shouldAdd) {
		// Show toast
		if (
			!(newSeats + newCollaboratorSeats + newVirtualSeats) ||
			newSeats > 100 ||
			newCollaboratorSeats > 100 ||
			newVirtualSeats > 100
		)
			return;
		this.setState({isUpdatingSeats: true});
		const onSuccess = result => {
			this.setState({isUpdatingSeats: false});
			createToast({duration: 5000, message: shouldAdd ? 'Seats Added' : 'Seats Removed'});
		};

		const subscription = this.props.viewer.company.subscription;
		if (newSeats + newCollaboratorSeats + newVirtualSeats > 0) {
			const uSeats = shouldAdd ? subscription.seats + Number(newSeats) : undefined;
			const mutationObject = {id: this.props.viewer.company.id};
			if (uSeats) {
				mutationObject.userSeats = uSeats;
			}

			mutationObject.collaboratorSeats = shouldAdd
				? subscription.collaboratorSeats + Number(newCollaboratorSeats)
				: subscription.collaboratorSeats - Number(newCollaboratorSeats);

			mutationObject.virtualSeats = shouldAdd
				? subscription.virtualSeats + Number(newVirtualSeats)
				: subscription.virtualSeats - Number(newVirtualSeats);

			Util.CommitMutation(UpdateCompanySubscriptionDataMutation, mutationObject, onSuccess, true);
		}
	}

	handleSeatsUpdate(newSeats, newCollaboratorSeats, newVirtualSeats, shouldAdd, reason, reasonElaborated) {
		if (!shouldAdd) {
			if (newSeats > 0) {
				this.handleRemoveSeatRequest(newSeats, newCollaboratorSeats, 0, reason, reasonElaborated);
			}
			if (newVirtualSeats > 0) {
				this.updateSeats(0, newCollaboratorSeats, newVirtualSeats, shouldAdd);
			}
		} else {
			this.updateSeats(newSeats, newCollaboratorSeats, newVirtualSeats, shouldAdd);
		}
	}

	showSeatsModal() {
		const {formatMessage} = this.props.intl;
		const isCore = this.props.viewer.company.tier === 'CORE';
		const isAdd = true;

		const subscription = this.props.viewer.company.subscription;

		const numSeats = subscription.seats;
		const numCollaboratorSeats = subscription.collaboratorSeats;
		const numVirtualSeats = subscription.virtualSeats;

		const allPersons = this.props.viewer.company.allPersons.edges;

		let activated = allPersons.filter(person => person.node.active);
		activated = activated.map(p => p.node);

		let users = activated.filter(
			person => !personIsVirtualUser(person) && !personIsClientUser(person) && !personIsCollaboratorUser(person)
		).length;
		let collaboratorUsers = activated.filter(person => personIsCollaboratorUser(person)).length;
		let virtualUsers = activated.filter(person => personIsVirtualUser(person) && person.client == null).length;

		showModal({
			type: MODAL_TYPE.SEAT_MANAGEMENT,
			isAdd: isAdd,
			isCore: isCore,
			numSeats: isCore ? numSeats : numSeats + numCollaboratorSeats,
			curSeats: isCore ? users : users + collaboratorUsers,
			numCollaboratorSeats: isCore ? numCollaboratorSeats : 0,
			curCollaboratorSeats: isCore ? collaboratorUsers : 0,
			numVirtualSeats: numVirtualSeats,
			curVirtualSeats: virtualUsers,
			minNumSeats: Math.max(this.props.viewer.company.minimumSeats, users),
			minNumVirtualSeats: Math.max(this.props.viewer.company.minimumVirtualSeats, virtualUsers),
			removeSeatsReason: this.state.removeSeatsReasonSpecified ? 'PLEASE SELECT A REASON' : '',
			title: isAdd
				? formatMessage({id: 'settings_subscription.seats-add'})
				: formatMessage({id: 'settings_subscription.seats-remove-request'}),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'cancel-modal-button',
				},
				isAdd
					? {
							text: formatMessage({id: 'common.add'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
							cy: 'add-seats-modal-button',
							callback: (newSeats, newCollaboratorSeats, newVirtualSeats) =>
								this.handleSeatsUpdate(newSeats, newCollaboratorSeats, newVirtualSeats, true),
							mayBeLocked: true,
					  }
					: {
							text: formatMessage({id: 'settings_subscription.send-remove-request'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
							cy: 'remove-seats-modal-button',
							callback: (newSeats, newCollaboratorSeats, newVirtualSeats, reason, reasonElaborated) =>
								this.handleSeatsUpdate(
									newSeats,
									newCollaboratorSeats,
									newVirtualSeats,
									false,
									reason,
									reasonElaborated
								),
							mayBeLocked: true,
					  },
			],
		});
	}

	getHeader() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'settings.subscription'})} />
			</TopHeaderBarWrapper>
		);
	}

	render() {
		const {formatDate, formatMessage} = this.props.intl;
		const subscription = this.props.viewer.company.subscription;
		const numSeats = (subscription && subscription.seats) || 0;
		const numVirtualSeats = (subscription && subscription.virtualSeats) || 0;
		const numCollaboratorSeats = (subscription && subscription.collaboratorSeats) || 0;
		const seats = (numSeats ? numSeats : 0) + (numCollaboratorSeats ? numCollaboratorSeats : 0);

		let users = this.props.viewer.company.allPersons.edges.filter(
			edge => !personIsVirtualUser(edge.node) && !personIsClientUser(edge.node) && !personIsCollaboratorUser(edge.node)
		).length;
		let collaboratorUsers = this.props.viewer.company.allPersons.edges.filter(edge =>
			personIsCollaboratorUser(edge.node)
		).length;
		let virtualUsers = this.props.viewer.company.allPersons.edges.filter(
			edge => personIsVirtualUser(edge.node) && edge.node.client == null
		).length;

		const hasCoreTierSwitch = hasFeatureFlag('core_tier_switch');

		const planText =
			hasCoreTierSwitch && subscription.planId.toUpperCase().includes('_CORE_')
				? formatMessage({id: 'select_plan.core_plan_name'}).toUpperCase()
				: subscription.planId.toUpperCase().includes('_LITE_')
				? formatMessage({id: 'select_plan.lite_plan_name'}).toUpperCase()
				: subscription.planId.toUpperCase().includes('_PRO_')
				? formatMessage({id: 'select_plan.pro_plan_name'}).toUpperCase()
				: subscription.planId.toUpperCase().includes('PREMIUM')
				? formatMessage({id: 'select_plan.premium_plan_name'}).toUpperCase()
				: subscription.planId.toUpperCase().includes('PROFESSIONAL')
				? formatMessage({id: 'select_plan.professional_plan_name'}).toUpperCase()
				: formatMessage({id: 'select_plan.enterprise_plan_name'}).toUpperCase();

		return (
			<div className="section-content settings-app settings-subscription-v2">
				{this.props.children}
				{this.getHeader()}
				{this.state.loading || this.state.isUpdatingSeats ? (
					<InlineLoader overlayed={true} loaderText="Updating subscription details. This might take a few minutes." />
				) : null}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<div className="card">
								<div className="plan">
									<div className="plan-type-wrapper">
										<div className="your-plan">
											{formatMessage({id: 'settings_subscription.your_plan'})}
										</div>
										<div data-cy="subscription-header" className="subscription-header small-top-margin">
											{planText}
										</div>
									</div>

									{subscription.customerSupportTier !== 'T4' ? (
										<div className="contact-csm-info">
											For any commercial changes (upgrades, additional seats, training etc) please reach
											out to your commercial CSM or email support@forecast.app
										</div>
									) : (
										<div className="contact-csm-info">
											<a href={`https://support.forecast.app/hc/en-us/articles/22567752639761`}>
												Please click here for FAQs
											</a>
										</div>
									)}
								</div>
								<hr />
								<div className="payment">
									<div className="payment-tile-wrapper">
										<div className="data-tile white-space" />
										<div className="data-tile">
											<label>{formatMessage({id: 'settings_subscription.contract_start_date'})}</label>
											<span className="tile-value">
												{formatDate(new Date(subscription.contractStartDate), {
													day: 'numeric',
													month: 'long',
													year: 'numeric',
												})}
											</span>
										</div>
										<div className="data-tile">
											<label>{formatMessage({id: 'settings_subscription.contract_end_date'})}</label>
											<span className="tile-value">
												{formatDate(new Date(subscription.contractEndDate), {
													day: 'numeric',
													month: 'long',
													year: 'numeric',
												})}
											</span>
										</div>
										<div className="data-tile">
											<label>{formatMessage({id: 'settings_subscription.contract_term'})}</label>
											<span className="tile-value">
												{subscription.contractTerm} {formatMessage({id: 'common.months'}).toLowerCase()}
											</span>
										</div>
										{subscription.customerSupportTier !== 'T4' ? (
											<div className="data-tile">
												<label>{formatMessage({id: 'settings_subscription.your_csm'})}</label>
												<span className="tile-value">
													{subscription.csmName}
													<br />
													{subscription.csmEmail ? (
														<a href={`mailto:${subscription.csmEmail}`}>{subscription.csmEmail}</a>
													) : (
														''
													)}
												</span>
											</div>
										) : (
											<div className="data-tile white-space" />
										)}
										<div className="data-tile white-space" />
									</div>
								</div>
							</div>
							<div className="seats">
								<div className="licensing">
									<div className="header-wrapper">
										<div className="subscription-header">
											{formatMessage({id: 'select_plan.licensing'})}
										</div>
										{!subscription.applyTieredDiscount && !subscription.inRenewal && (
											<div className="buttons">
												<Button
													className={'seats-button'}
													buttonStyle={BUTTON_STYLE.FILLED}
													colorTheme={BUTTON_COLOR.WHITE}
													text={formatMessage({
														id: 'settings_subscription.seats-add',
													}).toUpperCase()}
													onClick={this.showSeatsModal.bind(this)}
													cy={'add-seats-button'}
													isDisabled={this.state.isUpdatingSeats}
												/>
											</div>
										)}
										{/*<div className="buttons">Addition of seats is temporarily unavailable</div>*/}
									</div>
									<table className={'license-table'} data-cy={'license-table'}>
										<thead>
											<tr>
												<th className={'white-space'} />
												<th>{formatMessage({id: 'settings_subscription.seat_type'})}</th>
												<th>
													{formatMessage({
														id: 'settings_subscription.total_seats',
													}).toUpperCase()}
												</th>
												<th>{formatMessage({id: 'common.assigned'}).toUpperCase()}</th>
												<th>{formatMessage({id: 'cards.unassigned'}).toUpperCase()}</th>
												<th className={'white-space'} />
											</tr>
										</thead>
										{subscription.customerSupportTier === 'T4' ? (
											<tbody>
												<tr>
													<td className={'white-space'} />
													<td>{formatMessage({id: 'settings_subscription.users'})}</td>
													<td data-cy={'licensed-seats'}>{seats}</td>
													<td data-cy={'filled-seats'}>{users}</td>
													<td data-cy={'available-seats'}>{seats - users}</td>
													<td className={'white-space'} />
												</tr>

												<tr>
													<td className={'white-space'} />
													<td>{formatMessage({id: 'settings_subscription.virtual_users'})}</td>
													<td data-cy={'licensed-virtual-seats'}>{numVirtualSeats}</td>
													<td data-cy={'filled-virtual-seats'}>{virtualUsers}</td>
													<td data-cy={'available-virtual-seats'}>
														{numVirtualSeats - virtualUsers}
													</td>
													<td className={'white-space'} />
												</tr>
											</tbody>
										) : (
											<tbody>
												<tr>
													<td className={'white-space'} />
													<td>
														{this.props.viewer.company.tier === 'CORE'
															? formatMessage({id: 'settings_people.seat.core'})
															: formatMessage({id: 'settings_people.seat.full'})}
													</td>
													<td data-cy={'licensed-seats'}>
														{this.props.viewer.company.tier === 'CORE'
															? numSeats
															: numSeats + numCollaboratorSeats}
													</td>
													<td data-cy={'filled-seats'}>
														{this.props.viewer.company.tier === 'CORE'
															? users
															: users + collaboratorUsers}
													</td>
													<td data-cy={'available-seats'}>
														{this.props.viewer.company.tier === 'CORE'
															? numSeats - users
															: numSeats + numCollaboratorSeats - users - collaboratorUsers}
													</td>
													<td className={'white-space'} />
												</tr>
												{this.props.viewer.company.tier === 'CORE' ? (
													<tr>
														<td className={'white-space'} />
														<td>{formatMessage({id: 'settings_people.seat.collaborator'})}</td>
														<td data-cy={'licensed-seats'}>{numCollaboratorSeats}</td>
														<td data-cy={'filled-seats'}>{collaboratorUsers}</td>
														<td data-cy={'available-seats'}>
															{numCollaboratorSeats - collaboratorUsers}
														</td>
														<td className={'white-space'} />
													</tr>
												) : null}
												<tr>
													<td className={'white-space'} />
													<td>{formatMessage({id: 'settings_people.seat.virtual'})}</td>
													<td data-cy={'licensed-virtual-seats'}>{numVirtualSeats}</td>
													<td data-cy={'filled-virtual-seats'}>{virtualUsers}</td>
													<td data-cy={'available-virtual-seats'}>
														{numVirtualSeats - virtualUsers}
													</td>
													<td className={'white-space'} />
												</tr>
											</tbody>
										)}
									</table>
								</div>
							</div>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const settingsSubscriptionQuery = graphql`
	query settingsSubscription_Query {
		viewer {
			actualPersonId
			component(name: "settings_subscription")
			company {
				isChargebeeCustomer
			}
			...settingsSubscription_viewer
		}
	}
`;

graphql`
	fragment settingsSubscription_subscription on Subscription {
		planId
		currency
		email
		seats
		virtualSeats
		collaboratorSeats
		seatPrice
		applyTieredDiscount
		inRenewal
		contractStartDate
		contractEndDate
		contractTerm
		csmName
		csmEmail
		customerSupportTier
	}
`;

export {settingsSubscriptionQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(settingsSubscription, {
			viewer: graphql`
				fragment settingsSubscription_viewer on Viewer {
					id
					email
					company {
						id
						name
						street
						zip
						city
						country
						vatId
						trialEndYear
						trialEndMonth
						trialEndDay
						trialEndTime
						tier
						minimumSeats
						minimumVirtualSeats
						userSeats
						virtualSeats
						contactPhone
						contactName
						contactEmail
						currency
						allPersons(first: 10000, onlyActive: true) {
							edges {
								node {
									id
									permissions
									active
									seat
									client {
										id
									}
								}
							}
						}
						subscription {
							...settingsSubscription_subscription @relay(mask: false)
						}
					}
				}
			`,
		})
	)
);
