import React, {useEffect, useMemo, useState} from 'react';
import {
	DeprecatedAvatar as Avatar,
	DeprecatedButton as Button,
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedFavorite as Favorite,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedInput as Input,
	DeprecatedMainContainer as MainContainer,
	DeprecatedMultiColorIndicator as MultiColorIndicator,
	DeprecatedPageContainer as PageContainer,
	DeprecatedProgressBar as ProgressBar,
	DeprecatedTableCell as TableCell,
	DeprecatedTableGroup as TableGroup,
	DeprecatedTableHeaderCell as TableHeaderCell,
	DeprecatedTableHeaderRow as TableHeaderRow,
	DeprecatedTableRow as TableRow,
	DeprecatedText as Text,
	DeprecatedVirtualizedTable as VirtualizedTable,
} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl, useIntl} from 'react-intl';
import {useHistory, withRouter} from 'react-router-dom';
import {withSimpleSocketHandling} from '../../../../socket/withSimpleSocketHandling';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ProgramContextMenu from './ProgramContextMenu';
import {hasPermission, hasSomePermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import GroupingDropdown, {GROUPING} from '../../../shared/components/dropdowns/GroupingDropdown';
import {useExpandableGroups} from '../hooks/useExpandableGroups';
import Util from '../../../shared/util/util';
import {useProgramCalculatedFields} from '../hooks/useProgramCalculatedFields';
import {useProgram} from '../hooks/useProgram';
import {
	HIDDEN_FEATURES,
	MODULE_TYPES,
	PROJECT_STATUS,
	SOCKET_ACTION,
	SOCKET_EVENT_TYPE,
	STAGE_SORTING,
} from '../../../../constants';
import useSort from '../../../shared/hooks/useSort';
import useDate from '../../../shared/hooks/useDate';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {getDefaultSmallClientLogo} from '../../../../components/default_avatars';
import useGrouping from '../../../shared/hooks/useGrouping';
import {FeedbackButton} from '../../../shared/components/buttons/feedback-button';
import StatusWrapper, {RAG_STATUS, ragStatusSortOrder} from '../../../shared/components/status/StatusWrapper';
import {
	convertRagStatusToWarningVariant,
	getTextFromVariant,
} from '../../../../containers/project/project_health/project_health_status/StatusIndicator';
import Checkbox from '../../../../components/inputs/checkbox';
import ProgramUtil from '../../../shared/util/ProgramUtil';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {clientLogoSrc} from '../../../../directApi';

const BASE_KEY = 'program-list-page';
const LOCAL_SORT_KEY = `${BASE_KEY}-sort`;
const LOCAL_GROUPING_KEY = `${BASE_KEY}-grouping`;

const ProgramListPage = ({viewer, setSocketConfig}) => {
	useEffect(() => {
		document.title = 'My programs - Forecast';
	}, []);
	useTrackPage('Program List');

	const history = useHistory();
	const {formatMessage} = useIntl();
	const _sort = useSort();
	const _date = useDate();
	const _grouping = useGrouping();

	const programs = viewer.company.programs?.edges?.map(program => program.node);
	const companyId = viewer.company.id;

	const programIds = programs?.map(program => program.id);
	const {programCalcData} = useProgramCalculatedFields(programIds);

	const hasClients = program => program.clients.edges?.length > 0;
	const getClient = program => hasClients(program) && program.clients.edges[0].node;
	const isFavorite = program => program.userRelationTypes.includes('FAVORITE');

	const entityIds = programIds.map(id => Util.getUUIdFromBase64String(id));
	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.UPDATE,
		},
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.DELETE,
			entityIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.CREATE,
		},
	];
	setSocketConfig(socketEvents);

	const ragStatus = program => program.currentProgramStatus?.ragStatus || RAG_STATUS.GREEN;

	const [showAllPrograms, setShowAllPrograms] = useState(false);

	const formattedPrograms = programs
		.map(program => ({
			...program,
			progress: programCalcData(program.id)?.progress,
			status: {
				name: ragStatus(program),
				text: getTextFromVariant(convertRagStatusToWarningVariant(ragStatus(program))),
				order: ragStatusSortOrder(ragStatus(program)),
			},
		}))
		.filter(
			program =>
				showAllPrograms || (program.stage.name !== PROJECT_STATUS.DONE && program.stage.name !== PROJECT_STATUS.HALTED)
		);

	// Context menu variables
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [selectedProgram, setSelectedProgram] = useState({});
	const [bounds, setBounds] = useState({});

	const [searchValue, setSearchValue] = useState('');

	const [sortValue, setSortValue] = useState(
		JSON.parse(Util.localStorageGetItemWithDefault(LOCAL_SORT_KEY, '{"key": "", "direction": 0}'))
	);
	const updateSortValue = nextSortValue => {
		Util.localStorageSetItem(LOCAL_SORT_KEY, JSON.stringify(nextSortValue));
		setSortValue(nextSortValue);
	};

	const [grouping, setGrouping] = useState(Util.localStorageGetItemWithDefault(LOCAL_GROUPING_KEY, ''));
	const updateGrouping = nextGrouping => {
		trackEvent('Program List', 'Grouped By', {groupingBefore: grouping, groupingAfter: nextGrouping});
		Util.localStorageSetItem(LOCAL_GROUPING_KEY, nextGrouping);
		setGrouping(nextGrouping);
	};

	const _expandableGroups = useExpandableGroups(grouping, BASE_KEY);

	/**
	 * Prepping the context menu, giving it the related program,
	 * and the coordinates for the button it should be shown next to.
	 * @param e the click event
	 * @param program the selected program
	 */
	const toggleContextMenu = (e, program) => {
		setShowContextMenu(!showContextMenu);
		setSelectedProgram(program);
		setBounds(document.elementFromPoint(e.pageX, e.pageY).getBoundingClientRect());
	};

	const openCreateProgramModal = () => {
		showModal({
			type: MODAL_TYPE.CREATE_PROGRAM,
		});
	};
	const ProgramRow = ({program}) => {
		const _program = useProgram(program, companyId);
		const canManage = _program.canManage(viewer.actualPersonId);

		return (
			<TableRow
				onClick={() => history.push(`${ProgramUtil.programLink()}/${program.prefix}/overview`)}
				key={program.id}
				data={program}
			>
				<TableCell fixed={'20px'}>
					<MultiColorIndicator color={program.color} size={'s'} />
				</TableCell>
				<TableCell fixed={'60px'}>
					<Text variant={'bold'}>{program.prefix}</Text>
				</TableCell>
				<TableCell size={'l'}>{program.name}</TableCell>
				<TableCell fixed={'80px'}>{program.startDate ? _date.formatDate(program.startDate) : ''}</TableCell>
				<TableCell fixed={'80px'}>{program.endDate ? _date.formatDate(program.endDate) : ''}</TableCell>
				<TableCell align={'right'} fixed={'35px'}>
					{program.progress}%
				</TableCell>
				<TableCell fixed={'80px'}>
					<ProgressBar progress={program.progress} />
				</TableCell>
				{!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) && (
					<TableCell fixed={'200px'}>{getClient(program) ? getClient(program).name : ''}</TableCell>
				)}
				<TableCell fixed={'200px'}>
					{formatMessage({
						id: 'program_stage.' + program.stage.name.toLowerCase(),
						defaultMessage: program.stage.name,
					})}
				</TableCell>
				<TableCell fixed={'100px'}>
					<StatusWrapper programPrefix={program.prefix} currentStatusRag={program.currentProgramStatus?.ragStatus} />
				</TableCell>
				<TableCell align={'left'} fixed={'80px'}>
					<Favorite
						onClick={() => _program.updateProgramFavorite()}
						favorite={_program.isFavorite}
						size={'m'}
						title={formatMessage(
							{id: _program.isFavorite ? 'common.action.remove_from_entity' : 'common.action.add_to_entity'},
							{entity: ProgramUtil.programText(formatMessage)}
						)}
					/>
					{canManage && (
						<ButtonIconOnly
							title={formatMessage({id: 'project_settings.more_settings'})}
							type={'ghost'}
							icon={'more'}
							size={'s'}
							onClick={e => toggleContextMenu(e, program)}
							disabled={!hasSomePermission([PERMISSION_TYPE.PROGRAMS_DELETE, PERMISSION_TYPE.PROGRAMS_UPDATE])}
						/>
					)}
				</TableCell>
			</TableRow>
		);
	};

	const groupPrograms = () => {
		let groupedProjectsMap = {};
		switch (grouping) {
			case GROUPING.CLIENT:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedPrograms,
					'clients.edges.0.node',
					client => client && client.id,
					client =>
						client ? (
							<>
								<Avatar src={client.logoId ? clientLogoSrc(client.logoId) : getDefaultSmallClientLogo()} />
								{client.name}
							</>
						) : (
							`${formatMessage({id: 'common.no_entity'}, {entity: formatMessage({id: `common.${grouping}`})})}`
						),
					client => client && client.name
				);
				break;
			case GROUPING.STAGE:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedPrograms,
					'stage',
					({id}) => id,
					({name}) => `${formatMessage({id: `project_status.${name.toLowerCase()}`})}`,
					({name}) => STAGE_SORTING[name] || '5'
				);
				break;
			case GROUPING.STATUS:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedPrograms,
					'status',
					status => status.name,
					status => status.text,
					status => status.order
				);
				break;
			case GROUPING.FAVORITE:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedPrograms,
					'',
					program => isFavorite(program),
					program =>
						isFavorite(program)
							? `${formatMessage({id: 'common.favorites'})}`
							: `${formatMessage({id: 'common.non_favorites'})}`,
					program => (isFavorite(program) ? '0' : '1')
				);
				break;
		}
		return Object.entries(groupedProjectsMap);
	};

	const filterPredicate = program =>
		program.status.text.toLowerCase().includes(searchValue.toLowerCase()) ||
		program.stage?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
		program.clients?.edges.find(client => client.node?.name?.toLowerCase().includes(searchValue.toLowerCase())) ||
		program.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
		program.prefix?.toLowerCase().includes(searchValue.toLowerCase()) ||
		`${program.prefix.split('-')[1]}`.toLowerCase().includes(searchValue.toLowerCase());

	const getGroupSize = programGroup => programGroup.filter(filterPredicate).length;

	const createTableContent = programs =>
		programs
			.filter(filterPredicate)
			.sort((p1, p2) => Number(isFavorite(p2)) - Number(isFavorite(p1)))
			.sort((p1, p2) => _sort.sort(sortValue, p1, p2))
			.map(program => <ProgramRow program={program} data={program} key={program.id} />);

	const groupView = () => {
		return groupPrograms()
			.sort(([_, groupInfoA], [__, groupInfoB]) => _sort.sortGroupings(groupInfoA.sortOrder, groupInfoB.sortOrder))
			.map(([group, groupInfo]) => {
				return [
					<TableGroup
						expanded={_expandableGroups.groupExpanded(group)}
						onToggle={expanded => _expandableGroups.setGroupExpanded(group, expanded)}
					>
						{groupInfo.display}
						{` (${getGroupSize(groupInfo.elements)})`}
					</TableGroup>,
					_expandableGroups.groupExpanded(group) && createTableContent(groupInfo.elements),
				];
			});
	};

	return (
		<PageContainer data-cy={'program-list-page'}>
			<MainContainer>
				<FlexColumn gap={'l'}>
					<FlexRow alignItems={'flex-start'} gap={'l'}>
						<Text type={'heading'} variant={'xl'}>
							{ProgramUtil.programText(formatMessage, true)}
						</Text>
						{hasModule(MODULE_TYPES.PROGRAMS_PLUS) ? (
							<FeedbackButton key={'programs-feedback-button'} link={'https://www.forecast.app/programs'} />
						) : (
							<FeedbackButton
								key={'project-group-feedback-button'}
								link={'https://www.forecast.app/project-groups'}
							/>
						)}
					</FlexRow>
					<FlexRow justifyContent={'space-between'} gap={'s'}>
						<FlexItem>
							<FlexRow gap={'s'}>
								<FlexItem>
									<Input
										icon={'search'}
										size={'m'}
										placeholder={formatMessage(
											{id: 'search.search_entity'},
											{entity: ProgramUtil.programText(formatMessage, true).toLowerCase()}
										)}
										onChange={e => setSearchValue(e.target.value)}
										onBlur={() => trackEvent('Program List', 'Searched', {searchString: searchValue})}
									/>
								</FlexItem>
								<FlexItem>
									{useMemo(
										() => (
											<GroupingDropdown onSelectGroup={updateGrouping} initialGroup={grouping} />
										),
										[grouping]
									)}
								</FlexItem>
								<FlexItem>
									<FlexRow gap={'s'}>
										<Checkbox
											checked={showAllPrograms}
											onChange={() =>
												setShowAllPrograms(prevState => {
													trackEvent('Program List', 'Toggle Show Done/Halted Programs', {
														state: !prevState ? 'With done/halted' : 'Without done/halted',
													});
													return !prevState;
												})
											}
										></Checkbox>
										<Text>
											{formatMessage(
												{id: 'common.show.done-halted'},
												{entity: ProgramUtil.programText(formatMessage, true)}
											)}
										</Text>
									</FlexRow>
								</FlexItem>
							</FlexRow>
						</FlexItem>
						<FlexItem>
							{hasPermission(PERMISSION_TYPE.PROGRAMS_CREATE) && (
								<Button type={'creative'} onClick={openCreateProgramModal}>
									{formatMessage(
										{id: 'common.action.create_entity'},
										{entity: ProgramUtil.programText(formatMessage)}
									)}
								</Button>
							)}
						</FlexItem>
					</FlexRow>
				</FlexColumn>
				<VirtualizedTable>
					<TableHeaderRow>
						<TableHeaderCell fixed={'20px'} />
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('prefix', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('prefix', sortValue, updateSortValue)}
							fixed={'60px'}
						>
							{formatMessage({id: 'common.id'})}
						</TableHeaderCell>
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('name', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('name', sortValue, updateSortValue)}
							size={'l'}
						>
							{formatMessage({id: 'common.name'})}
						</TableHeaderCell>
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('startDate', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('startDate', sortValue, updateSortValue)}
							fixed={'80px'}
						>
							{formatMessage({id: 'common.start'})}
						</TableHeaderCell>
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('endDate', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('endDate', sortValue, updateSortValue)}
							fixed={'80px'}
						>
							{formatMessage({id: 'common.deadline'})}
						</TableHeaderCell>
						{/*
						Merging progress number and progress bar
						Progress number: 35px
						Gap: 16px
						Progress bar: 80px
						*/}
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('progress', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('progress', sortValue, updateSortValue)}
							fixed={`${35 + 16 + 80}px`}
						>
							{formatMessage({id: 'common.progress'})}
						</TableHeaderCell>
						{!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) && (
							<TableHeaderCell
								isSorted={_sort.isSortedColumn('clients.edges.0.node.name', sortValue)}
								direction={_sort.getDirection(sortValue)}
								onClick={() => _sort.updateSort('clients.edges.0.node.name', sortValue, updateSortValue)}
								fixed={'200px'}
							>
								{formatMessage({id: 'common.client'})}
							</TableHeaderCell>
						)}
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('stage.name', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('stage.name', sortValue, updateSortValue)}
							fixed={'200px'}
						>
							{formatMessage({id: 'common.stage'})}
						</TableHeaderCell>
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('status.order', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('status.order', sortValue, updateSortValue)}
							fixed={'100px'}
						>
							{formatMessage({id: 'common.status'})}
						</TableHeaderCell>
						<TableHeaderCell align={'right'} fixed={'80px'} />
					</TableHeaderRow>
					{!grouping ? createTableContent(formattedPrograms) : groupView()}
				</VirtualizedTable>
				{showContextMenu ? (
					<ProgramContextMenu
						program={selectedProgram}
						companyId={companyId}
						closeContextMenu={() => setShowContextMenu(false)}
						bounds={bounds}
					/>
				) : null}
			</MainContainer>
		</PageContainer>
	);
};

const ProgramListPageQuery = graphql`
	query ProgramListPage_Query {
		viewer {
			actualPersonId
			component(name: "program_list_page")
			...ProgramListPage_viewer
		}
	}
`;

export {ProgramListPageQuery};

export default withSimpleSocketHandling(
	injectIntl(
		withRouter(
			createFragmentContainer(ProgramListPage, {
				viewer: graphql`
					fragment ProgramListPage_viewer on Viewer {
						id
						backendId
						actualPersonId
						company {
							id
							programs(first: 10000) @connection(key: "Company_programs", filters: []) {
								edges {
									node {
										id
										name
										description
										prefix
										color
										startDate
										endDate
										userRelationTypes
										stage {
											id
											name
											type
										}
										clients {
											edges {
												node {
													id
													name
													logoId
												}
											}
										}
										members(first: 10000) @connection(key: "Program_members", filters: []) {
											edges {
												node {
													id
													role
													person {
														id
														profilePictureId
														fullName
														email
														initials
													}
												}
											}
										}
										currentProgramStatus {
											id
											ragStatus
										}
									}
								}
							}
						}
					}
				`,
			})
		)
	)
);
