import React, {useState} from 'react';
import {
	DeprecatedAvatar as Avatar,
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedColorIndicator as ColorIndicator,
	DeprecatedConnectedColorIndicator as ConnectedColorIndicator,
	DeprecatedFavorite as Favorite,
	DeprecatedProgressBar as ProgressBar,
	DeprecatedTableCell as TableCell,
	DeprecatedTableGroup as TableGroup,
	DeprecatedTableHeaderCell as TableHeaderCell,
	DeprecatedTableHeaderRow as TableHeaderRow,
	DeprecatedTableRow as TableRow,
	DeprecatedTableRowNested as TableRowNested,
	DeprecatedText as Text,
	DeprecatedVirtualizedTable as VirtualizedTable,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {cloneDeep} from 'lodash';
import {useProjectCalculatedFields} from '../hooks/useProjectCalculatedFields';
import {useExpandableGroups} from '../hooks/useExpandableGroups';
import {GROUPING} from '../../../shared/components/dropdowns/GroupingDropdown';
import {useHistory} from 'react-router-dom';
import Util from '../../../shared/util/util';
import UnmarkProjectAsFavoriteMutation from '../../../../mutations/project-service/unmark_project_as_favorite_mutation';
import {createToast} from '../../../shared/components/toasts/toast';
import {HIDDEN_FEATURES, STAGE_SORTING, STATUS_COLOR_DESCRIPTION, STATUS_COLOR_INT} from '../../../../constants';
import MarkProjectAsFavoriteMutation from '../../../../mutations/project-service/mark_project_as_favorite_mutation';
import useSort from '../../../shared/hooks/useSort';
import useDate from '../../../shared/hooks/useDate';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {getDefaultSmallClientLogo} from '../../../../components/default_avatars';
import useGrouping from '../../../shared/hooks/useGrouping';
import StatusWrapper, {convertStatusColorToRagStatus} from '../../../shared/components/status/StatusWrapper';
import {clientLogoSrc, projectGroupUrWithLastPage, projectUrlWithLastPage} from '../../../../directApi';
import {getProjectIdentifier} from './ProgramContextProvider';

const LOCAL_SORT_KEY = prefKey => `${prefKey}-sort`;

const ProjectTable = ({projects, connectedProjects, searchValue, grouping, noHeader, ContextMenu, prefKey}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();
	const _sort = useSort();
	const _date = useDate();
	const _grouping = useGrouping();
	const _expandableGroups = useExpandableGroups(grouping, prefKey);

	// Context menu variables
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [selectedProject, setSelectedProject] = useState({});
	const [bounds, setBounds] = useState();

	let projectTree = cloneDeep(projects);

	const intl = useIntl();

	// Re-structure if Connected project are used
	!grouping &&
		connectedProjects &&
		connectedProjects
			?.map(cp => cloneDeep(cp))
			.forEach(cp => {
				const projects = projectTree.filter(project => {
					return project.settings?.edges
						.map(setting => setting.node)
						.find(
							setting =>
								setting.name === 'connectedProjectId' &&
								Number(setting.value) === Util.getIdFromBase64String(cp.id)
						);
				});

				cp.children = projects;

				// Set start and end-date to min date and max date of all projects
				cp.startDate = projects
					.map(p => p.startDate)
					.filter(date => date)
					.reduce((pre, cur) => (!pre || pre > cur ? cur : pre), cp.startDate);
				cp.endDate = projects
					.map(p => p.endDate)
					.filter(date => date)
					.reduce((pre, cur) => (!pre || pre < cur ? cur : pre), cp.endDate);

				projectTree = projectTree.filter(project => !projects.map(p => p.id).includes(project.id));
				projectTree.push(cp);
			});

	const isConnectedProject = project => project.__typename === 'ProjectGroupType';

	const [sortValue, setSortValue] = useState(
		JSON.parse(Util.localStorageGetItemWithDefault(LOCAL_SORT_KEY(prefKey), '{"key": "", "direction": 0}'))
	);
	const updateSortValue = nextSortValue => {
		Util.localStorageSetItem(LOCAL_SORT_KEY(prefKey), JSON.stringify(nextSortValue));
		setSortValue(nextSortValue);
	};

	const getLatestStatusColor = project => {
		const statusList = project.status?.edges?.length > 0 ? project.status.edges.map(status => status.node) : [];
		statusList.sort((a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime());
		return statusList.length > 0 && statusList[0].color;
	};

	const isFavorite = project => project.userRelationTypes?.includes('FAVORITE');

	const budgetType = project =>
		Util.getBudgetTypeTranslation(
			project.settings?.edges.find(setting => setting.node.name === 'budgetType')?.node.value,
			intl
		);

	// Default sort on the prefix - Including children (Connected projects)
	const defaultPrefixSort = projects => {
		projects.sort((p1, p2) => p1.prefix.localeCompare(`${p2.prefix}`, undefined, {sensitivity: 'base', numeric: true}));
		projects.forEach(project => {
			if (project.children) {
				defaultPrefixSort(project.children);
			}
		});
	};
	defaultPrefixSort(projectTree);

	// Project Calculated Fields data
	const flattenProjects = array =>
		array.flatMap(({__typename, id, children}) => [{__typename, id}, ...flattenProjects(children || [])]);
	const {getProgress} = useProjectCalculatedFields(
		flattenProjects(projectTree)
			?.filter(project => !isConnectedProject(project))
			.map(project => project.id)
	);

	const formattedProjects = (projects, level = 0) => {
		return projects.map(project => ({
			...project,
			progress: getProgress(project),
			statusCurrent: STATUS_COLOR_INT[getLatestStatusColor(project)],
			children: project.children ? formattedProjects(project.children, level + 1, project.children.length - 1) : [],
			level: level,
			budgetType: !isConnectedProject(project) && budgetType(project),
		}));
	};

	const groupProjects = () => {
		let groupedProjectsMap = {};
		switch (grouping) {
			case GROUPING.CLIENT:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedProjects(projectTree),
					'clients.edges.0.node',
					client => client && client.id,
					client =>
						client ? (
							<>
								<Avatar src={client.logoId ? clientLogoSrc(client.logoId) : getDefaultSmallClientLogo()} />
								{client.name}
							</>
						) : (
							`${formatMessage({id: 'common.no_entity'}, {entity: formatMessage({id: `common.${grouping}`})})}`
						),
					client => client && client.name
				);
				break;
			case GROUPING.STAGE:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedProjects(projectTree),
					'stage',
					stage => stage,
					stage => `${formatMessage({id: `project_status.${stage.toLowerCase()}`})}`,
					stage => STAGE_SORTING[stage] || '5'
				);
				break;
			case GROUPING.FAVORITE:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedProjects(projectTree),
					'',
					project => isFavorite(project),
					project =>
						isFavorite(project)
							? `${formatMessage({id: 'common.favorites'})}`
							: `${formatMessage({id: 'common.non_favorites'})}`,
					project => (isFavorite(project) ? '0' : '1')
				);
				break;
			case GROUPING.STATUS:
				groupedProjectsMap = _grouping.groupByToMap(
					formattedProjects(projectTree),
					'',
					project => getLatestStatusColor(project),
					project => STATUS_COLOR_DESCRIPTION[getLatestStatusColor(project)],
					project => STATUS_COLOR_INT[getLatestStatusColor(project)]
				);
				break;
		}
		return Object.entries(groupedProjectsMap);
	};

	const openContextMenu = (e, project) => {
		setBounds(document.elementFromPoint(e.pageX, e.pageY).getBoundingClientRect());
		setSelectedProject(project);
		setShowContextMenu(true);
	};

	const unmarkProjectAsFavorite = projectId =>
		Util.CommitMutation(UnmarkProjectAsFavoriteMutation, {projectId: projectId}, () =>
			createToast({
				duration: 5000,
				message: formatMessage(
					{id: 'common.action.unfavorite_entity'},
					{entity: formatMessage({id: 'common.project'})}
				),
			})
		);

	const markProjectAsFavorite = projectId =>
		Util.CommitMutation(MarkProjectAsFavoriteMutation, {projectId: projectId}, () =>
			createToast({
				duration: 5000,
				message: formatMessage({id: 'common.action.favorite_entity'}, {entity: formatMessage({id: 'common.project'})}),
			})
		);

	const updateProjectFavorite = project => {
		if (isFavorite(project)) {
			trackEvent('Project', 'Unfavorited');
			unmarkProjectAsFavorite(project.id);
		} else {
			trackEvent('Project', 'Favorited');
			markProjectAsFavorite(project.id);
		}
	};

	const projectUrl = project => {
		return isConnectedProject(project)
			? projectGroupUrWithLastPage(project.prefix.slice(2), project.legacyProjectAsSingleProject.customProjectId)
			: projectUrlWithLastPage(project.prefix.slice(2), project.legacyProjectAsSingleProject.customProjectId);
	};

	const projectIndicator = project =>
		isConnectedProject(project) ? (
			<ConnectedColorIndicator color={project.color} />
		) : (
			<ColorIndicator color={project.color} />
		);

	const clientName = project => {
		return project.clients?.edges && project.clients.edges?.length === 1 && project.clients.edges[0].node?.name;
	};

	const ProjectRowContent = ({project}) => (
		<>
			<TableCell fixed={'20px'} data-cy={'project-indicator-cell'}>
				{projectIndicator(project)}
			</TableCell>
			<TableCell fixed={'60px'} data-cy={'project-prefix-cell'}>
				<Text variant={'bold'}>{getProjectIdentifier(project)}</Text>
			</TableCell>
			<TableCell size={'l'} data-cy={'project-name-cell'}>
				{project.name}
			</TableCell>
			<TableCell fixed={'80px'} data-cy={'project-start-date-cell'}>
				{project.startDate ? _date.formatDate(project.startDate) : ''}
			</TableCell>
			<TableCell fixed={'80px'} data-cy={'project-end-date-cell'}>
				{project.endDate ? _date.formatDate(project.endDate) : ''}
			</TableCell>
			<TableCell fixed={'35px'} align={'right'} data-cy={'project-progress-cell'}>
				{project.progress}%
			</TableCell>
			<TableCell fixed={'80px'} data-cy={'project-progress-bar-cell'}>
				<ProgressBar progress={project.progress} />
			</TableCell>
			<TableCell fixed={'130px'} data-cy={'project-budget-type'}>
				{project.budgetType}
			</TableCell>
			{!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) && (
				<TableCell fixed={'200px'} data-cy={'project-client-cell'}>
					{!isConnectedProject(project) && clientName(project)}
				</TableCell>
			)}
			<TableCell fixed={'200px'} data-cy={'project-stage-cell'}>
				{!isConnectedProject(project) && formatMessage({id: `project_status.${project.stage.toLowerCase()}`})}
			</TableCell>
			<TableCell fixed={'100px'} data-cy={'project-status-cell'}>
				{!isConnectedProject(project) ? (
					<StatusWrapper
						noHeader={noHeader}
						projectId={project.legacyProjectAsSingleProject.id}
						currentStatusRag={convertStatusColorToRagStatus(project.currentProjectStatus?.color)}
					/>
				) : null}
			</TableCell>
			<TableCell align={'right'} fixed={'80px'} data-cy={'project-favorite-cell'}>
				{!isConnectedProject(project) && (
					<Favorite
						onClick={() => updateProjectFavorite(project)}
						favorite={isFavorite(project)}
						size={'m'}
						title={formatMessage(
							{id: isFavorite(project) ? 'common.action.remove_from_entity' : 'common.action.add_to_entity'},
							{entity: formatMessage({id: 'common.favorites'}).toLowerCase()}
						)}
					/>
				)}
				{!isConnectedProject(project) && ContextMenu && (
					<ButtonIconOnly
						title={formatMessage({id: 'project_settings.more_settings'})}
						type={'ghost'}
						icon={'more'}
						onClick={e => openContextMenu(e, project)}
					/>
				)}
			</TableCell>
		</>
	);

	const ProjectRow = ({project}) => (
		<TableRow
			onClick={() => history.push(projectUrl(project))}
			key={project.id}
			data={project}
			data-cy={`projects-table-row-${project.prefix}`}
		>
			<ProjectRowContent project={project} />
		</TableRow>
	);

	const ConnectedProjectRow = ({project, isLast}) => (
		<TableRowNested
			leftOffset={36}
			isLast={isLast}
			onClick={() => history.push(projectUrl(project))}
			key={project.id}
			data={project}
		>
			<ProjectRowContent project={project} />
		</TableRowNested>
	);

	const filterPredicate = project =>
		STATUS_COLOR_DESCRIPTION[getLatestStatusColor(project)]?.toLowerCase().includes(searchValue.toLowerCase()) ||
		project.stage?.toLowerCase().includes(searchValue.toLowerCase()) ||
		project.clients?.edges.find(client => client.node?.name?.toLowerCase().includes(searchValue.toLowerCase())) ||
		project.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
		project.prefix?.toLowerCase().includes(searchValue.toLowerCase()) ||
		(project.budgetType && project.budgetType.toLowerCase().includes(searchValue.toLowerCase())) ||
		`${project.companyProjectId}`.toLowerCase().includes(searchValue.toLowerCase()) ||
		project.children?.some(childProject => filterPredicate(childProject));

	const getGroupSize = projectGroup => projectGroup.filter(filterPredicate).length;

	const buildProjectTable = projects => {
		const sortedAndFilteredProjects = projects
			.filter(filterPredicate)
			.sort((p1, p2) => {
				if (p1.companyProjectGroupId) {
					const px1 = p1.children.some(child => isFavorite(child));
					const px2 = p2.children.some(child => isFavorite(child));
					return px2 - px1;
				} else {
					return Number(isFavorite(p2)) - Number(isFavorite(p1));
				}
			})
			.sort((p1, p2) => _sort.sort(sortValue, p1, p2));

		for (let i = 0; i < sortedAndFilteredProjects.length; i++) {
			const project = sortedAndFilteredProjects[i];
			if (project.children) {
				const children = buildProjectTable(project.children);
				sortedAndFilteredProjects.splice(i + 1, 0, ...children);
			}
		}

		return sortedAndFilteredProjects;
	};

	const createProjectTable = projects => {
		const builtProjectTable = buildProjectTable(projects);
		return builtProjectTable.map((project, index) =>
			project.level > 0 ? (
				<ConnectedProjectRow
					project={project}
					data={project}
					key={project.id}
					isLast={!builtProjectTable[index + 1] || builtProjectTable[index + 1].level !== project.level}
				/>
			) : (
				<ProjectRow project={project} data={project} key={project.id} />
			)
		);
	};

	const groupView = () =>
		groupProjects()
			.sort(([_, groupInfoA], [__, groupInfoB]) => _sort.sortGroupings(groupInfoA.sortOrder, groupInfoB.sortOrder))
			.map(([group, groupInfo]) => {
				return [
					<TableGroup
						expanded={_expandableGroups.groupExpanded(group)}
						onToggle={expanded => _expandableGroups.setGroupExpanded(group, expanded)}
						key={group}
						data-cy={`project-group-${group}`}
					>
						{groupInfo.display}
						{` (${getGroupSize(groupInfo.elements)})`}
					</TableGroup>,
					_expandableGroups.groupExpanded(group) && createProjectTable(groupInfo.elements),
				];
			});

	return (
		<>
			<VirtualizedTable>
				<TableHeaderRow>
					<TableHeaderCell fixed={'20px'} />
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('prefix', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('prefix', sortValue, updateSortValue)}
						fixed={'60px'}
					>
						{formatMessage({id: 'common.id'})}
					</TableHeaderCell>
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('name', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('name', sortValue, updateSortValue)}
						size={'l'}
					>
						{formatMessage({id: 'common.name'})}
					</TableHeaderCell>
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('startDate', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('startDate', sortValue, updateSortValue)}
						fixed={'80px'}
					>
						{formatMessage({id: 'common.start'})}
					</TableHeaderCell>
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('endDate', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('endDate', sortValue, updateSortValue)}
						fixed={'80px'}
					>
						{formatMessage({id: 'common.deadline'})}
					</TableHeaderCell>
					{/*<TableHeaderCell fixed={'35px'} />*/}
					{/*
						Merging progress number and progress bar
						Progress number: 35px
						Gap: 16px
						Progress bar: 80px
					*/}
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('progress', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('progress', sortValue, updateSortValue)}
						fixed={`${35 + 16 + 80}px`}
					>
						{formatMessage({id: 'common.progress'})}
					</TableHeaderCell>
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('budgetType', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('budgetType', sortValue, updateSortValue)}
						fixed={'130px'}
					>
						{formatMessage({id: 'new_project_modal.budget_type'})}
					</TableHeaderCell>
					{!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) && (
						<TableHeaderCell
							isSorted={_sort.isSortedColumn('clients.edges.0.node.name', sortValue)}
							direction={_sort.getDirection(sortValue)}
							onClick={() => _sort.updateSort('clients.edges.0.node.name', sortValue, updateSortValue)}
							fixed={'200px'}
						>
							{formatMessage({id: 'common.client'})}
						</TableHeaderCell>
					)}
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('stage', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('stage', sortValue, updateSortValue)}
						fixed={'200px'}
					>
						{formatMessage({id: 'common.stage'})}
					</TableHeaderCell>
					<TableHeaderCell
						isSorted={_sort.isSortedColumn('statusCurrent', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('statusCurrent', sortValue, updateSortValue)}
						fixed={'100px'}
					>
						{formatMessage({id: 'common.status'})}
					</TableHeaderCell>
					<TableHeaderCell align={'right'} fixed={'80px'} />
				</TableHeaderRow>
				{!grouping ? createProjectTable(formattedProjects(projectTree)) : groupView()}
			</VirtualizedTable>
			{showContextMenu && ContextMenu && (
				<ContextMenu project={selectedProject} bounds={bounds} closeContextMenu={() => setShowContextMenu(false)} />
			)}
		</>
	);
};

export default ProjectTable;
