import {TIME_REG_TYPES} from '../../time_row_utils';
import Util from '../../../../../shared/util/util';
import CreateTimeRegistration from '../../../../../../mutations/create_time_registration_mutation.modern';
import {MODAL_TYPE, showModal} from '../../../../../shared/components/modals/generic_modal_conductor';
import UpdateTimeRegistration from '../../../../../../mutations/update_time_registration_mutation.modern';
import {dispatch, EVENT_ID} from '../../../../../../containers/event_manager';
import {createToast} from '../../../../../shared/components/toasts/another-toast/toaster';
import DeleteAllTimeRegistrationsForWeek from '../../../../../../mutations/delete_all_time_registrations_for_week_mutation';
import DeleteTimeRegistration from '../../../../../../mutations/delete_time_registration_mutation.modern';
import SendRecommenderFeedbackMutation from '../../../../../../mutations/send_recommender_feedback_mutation';
import {roundToNDecimals} from '../../../../../shared/util/NumberUtil';
import {trackTimeRegistrationTargetSuggestionFeedback} from '../../../../../../tracking/tracking_types/TrackTimeRegistrationTargetSuggestion';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';
import DeleteAllTimeRegistrationsForDay from '../../../../../../mutations/delete_all_time_registrations_for_day_mutation';
import {getSuggestedSwitchTasks} from './DataBundlingUtil';
import {canLoggedInPersonManageProgram} from '../../../../../shared/util/ProgramFinancialLogic';
import {ACTION} from '../../../../../shared/components/modals/program-management/ProgramBudgetErrorMessage';

export function handleNotesFieldBlur(
	lineItem,
	value,
	isTimeReg,
	viewer,
	date,
	selectedPerson,
	onTimeCreateSuccess,
	getIsHarvestLineItem,
	setForceUpdate,
	getIsUnit4LineItem,
	addMovedIdleTime,
	addMovedTask,
	onTimeUpdateSuccess
) {
	if (lineItem.notes === value || (lineItem && !lineItem.notes && !value)) return; //Don't do anything if value did not change
	const val = value === '' ? null : value;
	const isSwitchIdleTime = lineItem.switchIdleTime;
	let taskId;
	let parentTaskId;
	let projectId;
	let idleTimeId;
	let canRegister = true;
	switch (lineItem.type) {
		case TIME_REG_TYPES.TASK_TIME: {
			taskId = lineItem.id;
			parentTaskId = lineItem.parentTaskId;
			break;
		}
		case TIME_REG_TYPES.PROJECT_TIME: {
			projectId = lineItem.id;
			if (lineItem.status === 'DONE' || lineItem.status === 'HALTED') {
				canRegister = false;
			}
			break;
		}
		case TIME_REG_TYPES.IDLE_TIME: {
			idleTimeId = lineItem.id;
			break;
		}
		default: {
			// Tasks from moved tasks to timregs
			if (lineItem.companyTaskId) {
				taskId = lineItem.id;
				parentTaskId = lineItem.parentTaskId;
				projectId = lineItem.project.id;
			} else if (isSwitchIdleTime) {
				idleTimeId = lineItem.id;
			}
		}
	}
	if (canRegister) {
		if (!isTimeReg && val) {
			const createNoteTimeReg = (harvestTask, unit4Activity) => {
				Util.CommitMutation(
					CreateTimeRegistration,
					{
						source: 'Time-row-table',
						viewer: viewer,
						projectId: projectId,
						taskId: taskId,
						subTask: taskId ? !!parentTaskId : null,
						idleTimeId: idleTimeId,
						minutesRegistered: 0,
						notes: val,
						day: date.date(),
						month: date.month() + 1,
						year: date.year(),
						personId: selectedPerson.id,
						harvestTaskId: harvestTask ? harvestTask : null,
						unit4ActivityId: unit4Activity ? unit4Activity.value : null,
						unit4ActivityName: unit4Activity ? unit4Activity.label : null,
					},
					onTimeCreateSuccess
				);
			};
			if (viewer.company.harvestEnabled && viewer.harvestUser && getIsHarvestLineItem(lineItem)) {
				showModal({
					value: 0,
					type: MODAL_TYPE.HARVEST_TASK_SELECT,
					timeRegName: lineItem.name,
					timeReg: lineItem,
					project: lineItem.task ? lineItem.task.project : lineItem.project ? lineItem.project : lineItem,
					callback: harvestTask => createNoteTimeReg(harvestTask, null),
					defaultCallback: setForceUpdate.bind(this),
					cancelCallback: setForceUpdate.bind(this), //this.forceUpdate.bind(this)
				});
			} else if (viewer.company.unit4Enabled && selectedPerson.unit4User && getIsUnit4LineItem(lineItem)) {
				showModal({
					value: 0,
					type: MODAL_TYPE.UNIT4_ACTIVITY_SELECT,
					timeRegName: lineItem.name,
					timeReg: lineItem,
					project: lineItem.task ? lineItem.task.project : lineItem.project ? lineItem.project : lineItem,
					callback: unit4Activity => createNoteTimeReg(null, unit4Activity),
					defaultCallback: setForceUpdate.bind(this),
					cancelCallback: setForceUpdate.bind(this), //this.forceUpdate.bind(this)
				});
			} else {
				createNoteTimeReg();
			}
			if (isSwitchIdleTime) {
				addMovedIdleTime(idleTimeId);
			} else {
				addMovedTask(taskId);
			}
		} else {
			Util.CommitMutation(
				UpdateTimeRegistration,
				{source: 'Time-row-table', id: lineItem.id, notes: val, newFormat: true},
				onTimeUpdateSuccess
			);
		}
	}
}

export function onTimeCreateSuccess(result, intl, program, actualPersonId) {
	if (result.createTimeReg.errors && result.createTimeReg.errors.length === 1) {
		if (result.createTimeReg.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
			showModal({
				type: MODAL_TYPE.WARNING_DATE_LOCKED,
			});
		} else if (Util.checkForSageErrorAndShowModal(result.createTimeReg.errors)) {
			return;
		} else if (result.createTimeReg.errors[0] === 'PROGRAM_REVENUE_LOCKED') {
			showProgramRevenueLockedMessage(actualPersonId, program);
		}
	} else {
		dispatch(EVENT_ID.TIME_REGISTRATION_MUTATION_SUCCESS, {timeRegisteredInHours: null, wasClickEvent: false});
		createToast({duration: 2500, message: intl.formatMessage({id: 'time_page.time_reg_created'})});
	}
}

function showProgramRevenueLockedMessage(actualPersonId, program) {
	const canManageProgram = canLoggedInPersonManageProgram(program?.members, actualPersonId);

	showModal({
		type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
		action: ACTION.MARK_TASK_BILLABLE,
		programName: program?.name,
		canManageProgram,
		programPrefix: program?.prefix,
	});
}

export function onTimeUpdateSuccess(result, intl, overBudgetProgramsByCompanyProjectId, actualPersonId) {
	if (result.updateTimeReg.errors && result.updateTimeReg.errors.length === 1) {
		if (result.updateTimeReg.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
			showModal({
				type: MODAL_TYPE.WARNING_DATE_LOCKED,
			});
		} else if (Util.checkForSageErrorAndShowModal(result.updateTimeReg.errors)) {
			return;
		} else if (result.updateTimeReg.errors[0] === 'PROGRAM_REVENUE_LOCKED') {
			const resultProject = result.updateTimeReg.timeReg.project
				? result.updateTimeReg.timeReg.project
				: result.updateTimeReg.timeReg.task.project;
			const program = overBudgetProgramsByCompanyProjectId.find(
				project => project.companyProjectId === resultProject?.companyProjectId
			)?.programInfo;
			showProgramRevenueLockedMessage(actualPersonId, program);
		}
	} else {
		createToast({duration: 2500, message: intl.formatMessage({id: 'time_page.time_reg_updated'})});
	}
}

export function onTimeDeleteSuccess(result, intl) {
	const showErrorModal = errors => {
		if (Util.checkForSageErrorAndShowModal(errors)) {
			return true;
		}
		return false;
	};

	if (!showErrorModal(result.deleteMultipleTimeRegs?.errors) && !showErrorModal(result.deleteTimeReg?.errors)) {
		createToast({duration: 2500, message: intl.formatMessage({id: 'time_page.time_reg_deleted'})});
	}
}

export function deleteTimeRegistrationsForWeek(dayRegsForWeek, onTimeDeleteSuccess) {
	const ids = [];
	dayRegsForWeek.map(day => day.map(reg => ids.push(reg.node.id)));
	Util.CommitMutation(DeleteAllTimeRegistrationsForWeek, {ids}, onTimeDeleteSuccess);
}

export function deleteTimeRegistration(lineItem, viewer, onTimeDeleteSuccess) {
	Util.CommitMutation(
		DeleteTimeRegistration,
		{
			id: lineItem.timeRegId ? lineItem.timeRegId : lineItem.id,
			taskId: lineItem.task ? lineItem.task.id : undefined,
			projectId: lineItem.project ? lineItem.project.id : undefined,
			idleTimeId: lineItem.idleTime ? lineItem.idleTime.id : undefined,
			companyId: viewer.company.id,
		},
		onTimeDeleteSuccess
	);
}

export function handleHoursInputChange(
	hours,
	selectedPerson,
	viewer,
	isWeekView,
	currentViewingDate,
	idx,
	timeRegMap,
	lineItem,
	setTimeRegToFocus,
	addMovedIdleTime,
	addMovedTask,
	getIsHarvestLineItem,
	createTimeReg,
	billableHours,
	roleId,
	notes,
	setForceUpdate,
	getIsUnit4LineItem,
	usingAdvancedTimeRegistration,
	timeRegId,
	onTimeUpdateSuccess,
	onTimeDeleteSuccess
) {
	const companyProjectId = lineItem.project?.companyProjectId ? lineItem.project.companyProjectId : lineItem.companyProjectId;

	trackEvent('Lower Timesheets Time Row Hours Input', 'Changed', {
		inputHours: hours,
		timeRegPerson: selectedPerson
			? selectedPerson.id === viewer.actualPersonId
				? 'myself'
				: selectedPerson.email
			: 'myself',
		date: isWeekView
			? currentViewingDate
					.clone()
					.endOf('week')
					.subtract(6 - idx, 'days')
					.format('YYYY-MM-DD')
			: currentViewingDate.format('YYYY-MM-DD'),
	});
	if (isWeekView) {
		const timeForDay = timeRegMap.get(lineItem) ? timeRegMap.get(lineItem)[idx] : [];
		const hasTimeForDay = timeForDay.length > 0;
		let canRegister = true;
		let taskId;
		let parentTaskId;
		let projectId;
		let idleTimeId;
		switch (lineItem.type) {
			case TIME_REG_TYPES.TASK_TIME: {
				taskId = lineItem.id;
				parentTaskId = lineItem.parentTaskId;
				projectId = lineItem.project.id;
				if (lineItem.project.status === 'DONE' || lineItem.project.status === 'HALTED') {
					canRegister = false;
				}
				break;
			}
			case TIME_REG_TYPES.PROJECT_TIME: {
				projectId = lineItem.id;
				if (lineItem.status === 'DONE' || lineItem.status === 'HALTED') {
					canRegister = false;
				}
				break;
			}
			case TIME_REG_TYPES.IDLE_TIME: {
				idleTimeId = lineItem.id;
				break;
			}
			// TASK - Not a time reg yet
			default: {
				if (lineItem.switchIdleTime) {
					idleTimeId = lineItem.id;
					// For animations
					setTimeRegToFocus(idleTimeId);
					addMovedIdleTime(idleTimeId);
				} else {
					taskId = lineItem.id;
					parentTaskId = lineItem.parentTaskId;
					// For animations
					setTimeRegToFocus(taskId);
					addMovedTask(taskId);
				}
			}
		}
		if (!canRegister) {
			return;
		}
		const indexedDate = currentViewingDate
			.clone()
			.endOf('week')
			.subtract(6 - idx, 'days');
		// Don't create, always delete time reg when user types specifically 0
		if (!hasTimeForDay) {
			if (viewer.company.harvestEnabled && viewer.harvestUser && getIsHarvestLineItem(lineItem) && hours > 0) {
				showModal({
					value: hours,
					type: MODAL_TYPE.HARVEST_TASK_SELECT,
					timeRegName: lineItem.name,
					timeReg: lineItem,
					project: lineItem.task ? lineItem.task.project : lineItem.project ? lineItem.project : lineItem,
					callback: harvestTask =>
						createTimeReg(
							indexedDate,
							hours,
							billableHours,
							roleId,
							notes,
							taskId,
							parentTaskId,
							projectId,
							companyProjectId,
							idleTimeId,
							selectedPerson,
							harvestTask,
							null
						),
					defaultCallback: setForceUpdate.bind(this),
					cancelCallback: setForceUpdate.bind(this), //this.forceUpdate.bind(this)
				});
			} else if (viewer.company.unit4Enabled && selectedPerson.unit4User && getIsUnit4LineItem(lineItem) && hours > 0) {
				showModal({
					value: hours,
					type: MODAL_TYPE.UNIT4_ACTIVITY_SELECT,
					timeRegName: lineItem.name,
					timeReg: lineItem,
					project: lineItem.task ? lineItem.task.project : lineItem.project ? lineItem.project : lineItem,
					callback: unit4Activity =>
						createTimeReg(
							indexedDate,
							hours,
							billableHours,
							roleId,
							notes,
							taskId,
							parentTaskId,
							projectId,
							companyProjectId,
							idleTimeId,
							selectedPerson,
							null,
							unit4Activity
						),
					defaultCallback: setForceUpdate.bind(this),
					cancelCallback: setForceUpdate.bind(this), //this.forceUpdate.bind(this)
				});
			} else {
				createTimeReg(
					indexedDate,
					hours,
					billableHours,
					roleId,
					notes,
					taskId,
					parentTaskId,
					projectId,
					companyProjectId,
					idleTimeId,
					selectedPerson
				);
			}
		} else {
			const isLocked = timeForDay.some(
				el => el.node.invoiced || el.node.economicTimeId || el.node.xeroInvoiceId || el.node.lockedInPeriod
			);
			if (isLocked) {
				return;
			} else if (hours === null && !usingAdvancedTimeRegistration) {
				const onSuccess = result => {
					if (
						result.deleteAllTimeRegsForDay.errors &&
						result.deleteAllTimeRegsForDay.errors.length === 1 &&
						result.deleteAllTimeRegsForDay.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD'
					) {
						showModal({
							type: MODAL_TYPE.WARNING_DATE_LOCKED,
						});
					}
				};
				Util.CommitMutation(DeleteAllTimeRegistrationsForDay, {ids: timeForDay.map(tReg => tReg.node.id)}, onSuccess);
			} else {
				const anyNote = timeForDay.length > 1 && timeForDay.some(el => el.node.notes && el.node.notes.length > 0);
				if (anyNote && !usingAdvancedTimeRegistration) {
					return;
				}
				Util.CommitMutation(
					UpdateTimeRegistration,
					{
						source: 'Time-row-table',
						id: timeRegId || timeForDay[0].node.id,
						day: indexedDate.date(),
						month: indexedDate.clone().month() + 1,
						year: indexedDate.year(),
						projectId: projectId,
						taskId: taskId,
						idleTimeId: idleTimeId,
						minutesRegistered: hours * 60,
						billableMinutesRegistered:
							billableHours === null
								? null
								: billableHours === undefined || isNaN(billableHours)
								? undefined
								: billableHours * 60,
						roleId: roleId,
						notes: notes ? notes : undefined,
						removeOthersForTheDay: !usingAdvancedTimeRegistration,
					},
					onTimeUpdateSuccess
				);
			}
		}
	} else {
		const isTimeReg = lineItem.type != null;
		const isExistingTimeReg = isTimeReg && lineItem.minutesRegistered !== undefined && lineItem.id;
		const date = currentViewingDate.clone();
		const isSwitchIdleTime = lineItem.switchIdleTime;
		const task = !isSwitchIdleTime ? lineItem : null;
		const project = !isSwitchIdleTime ? lineItem.project : null;
		const idleTime = !isSwitchIdleTime ? lineItem.idleTime : lineItem;
		if (isExistingTimeReg) {
			const timeReg = lineItem;
			if (hours === null) {
				// TODO, validate this is okay?
				Util.CommitMutation(
					DeleteTimeRegistration,
					{
						id: timeReg.id,
						taskId: task ? task.id : undefined,
						projectId: project ? project.id : undefined,
						idleTimeId: idleTime ? idleTime.id : undefined,
						companyId: viewer.company.id,
					},
					onTimeDeleteSuccess
				);
			} else {
				Util.CommitMutation(
					UpdateTimeRegistration,
					{
						source: 'Time-row-table',
						id: timeReg.id,
						projectId: timeReg.task ? timeReg.task.project.id : timeReg.project ? timeReg.project.id : null,
						taskId: timeReg.task ? timeReg.task.id : null,
						idleTimeId: timeReg.idleTime ? timeReg.idleTime.id : null,
						minutesRegistered: hours * 60,
						billableMinutesRegistered:
							billableHours === null
								? null
								: billableHours === undefined || isNaN(billableHours)
								? undefined
								: billableHours * 60,
						roleId: roleId,
						notes: notes ? notes : undefined,
					},
					onTimeUpdateSuccess
				);
			}
		} else {
			const createTimeCallback = (harvestTask, unit4Activity) => {
				if (!isTimeReg) {
					if (isSwitchIdleTime) {
						addMovedIdleTime(idleTime.id);
					} else {
						addMovedTask(task.id);
					}
				}
				createTimeReg(
					date,
					hours,
					billableHours,
					roleId,
					notes,
					task ? task.id : null,
					task ? task.parentTaskId : null,
					project ? project.id : null,
					companyProjectId,
					idleTime ? idleTime.id : null,
					selectedPerson,
					harvestTask,
					unit4Activity
				);
			};
			if (viewer.company.harvestEnabled && viewer.harvestUser && getIsHarvestLineItem(lineItem) && hours > 0) {
				showModal({
					value: hours,
					type: MODAL_TYPE.HARVEST_TASK_SELECT,
					timeRegName: lineItem.task ? lineItem.task.name : lineItem.project ? lineItem.project.name : '',
					timeReg: lineItem,
					project: lineItem.task ? lineItem.task.project : lineItem.project,
					callback: harvestTask => createTimeCallback(harvestTask, null),
					defaultCallback: setForceUpdate.bind(this),
					cancelCallback: setForceUpdate.bind(this), //this.forceUpdate.bind(this)
				});
			} else if (viewer.company.unit4Enabled && selectedPerson.unit4User && getIsUnit4LineItem(lineItem) && hours > 0) {
				showModal({
					value: hours,
					type: MODAL_TYPE.UNIT4_ACTIVITY_SELECT,
					timeRegName: lineItem.task ? lineItem.task.name : lineItem.project ? lineItem.project.name : '',
					timeReg: lineItem,
					project: lineItem.task ? lineItem.task.project : lineItem.project,
					callback: unit4Activity => createTimeCallback(null, unit4Activity),
					defaultCallback: setForceUpdate.bind(this),
					cancelCallback: setForceUpdate.bind(this),
				});
			} else {
				createTimeCallback(null);
			}
		}
	}
}

export function createTimeReg(
	selectedSwitch,
	viewer,
	taskId,
	idleTimeId,
	projectId,
	companyProjectId,
	parentTaskId,
	hours,
	billableHours,
	roleId,
	notes,
	date,
	selectedPerson,
	harvestTask,
	unit4Activity,
	onTimeCreateSuccess,
	timeRegistrationTargetSuggestionsData
) {
	const _suggestedSwitchTasks = getSuggestedSwitchTasks(viewer);
	if (selectedSwitch === 'suggested' && viewer.taskSuggestionsRecommender) {
		const isTaskInTopSuggestions =
			_suggestedSwitchTasks &&
			_suggestedSwitchTasks.some(
				suggestion =>
					(suggestion.task && suggestion.task.id === taskId) ||
					(suggestion.idleTime && suggestion.idleTime.id === idleTimeId)
			);
		// Send positive or negative feedback to the task suggestion algorithm on the backend
		Util.CommitMutation(SendRecommenderFeedbackMutation, {
			recommendationInstanceId: viewer.taskSuggestionsRecommender.recommendationInstanceId,
			recommendationId: viewer.taskSuggestionsRecommender.recommendationId,
			generation: viewer.taskSuggestionsRecommender.generation,
			timeStep: viewer.taskSuggestionsRecommender.timeStep,
			feedbackValue: isTaskInTopSuggestions ? 1.0 : 0.0,
		});
	}
	Util.CommitMutation(
		CreateTimeRegistration,
		{
			viewer: viewer,
			source: 'Time-row-table',
			projectId: projectId ? projectId : null,
			taskId: taskId ? taskId : null,
			subTask: taskId ? !!parentTaskId : null,
			idleTimeId: idleTimeId,
			minutesRegistered: roundToNDecimals(hours * 60, 0),
			billableMinutesRegistered: roundToNDecimals(billableHours * 60, 0),
			roleId: roleId,
			notes: notes,
			day: date.date(),
			month: date.month() + 1,
			year: date.year(),
			personId: selectedPerson
				? selectedPerson.actualPersonId != null
					? selectedPerson.actualPersonId
					: selectedPerson.id
				: null,
			harvestTaskId: harvestTask ? harvestTask : null,
			unit4ActivityId: unit4Activity ? unit4Activity.value : null,
			unit4ActivityName: unit4Activity ? unit4Activity.label : null,
		},
		result => onTimeCreateSuccess(result, companyProjectId)
	);
	if (timeRegistrationTargetSuggestionsData?.timeRegistrationTargetSuggestions) {
		const timeRegistrationTargetSuggestions = timeRegistrationTargetSuggestionsData?.timeRegistrationTargetSuggestions;
		const oldTimeRegistrationTargetSuggestions =
			timeRegistrationTargetSuggestionsData?.oldTimeRegistrationTargetSuggestions;
		trackTimeRegistrationTargetSuggestionFeedback({
			companyId: viewer.company.id,
			viewerId: viewer.actualPersonId,
			personId: selectedPerson
				? selectedPerson.actualPersonId != null
					? selectedPerson.actualPersonId
					: selectedPerson.id
				: null,
			uiSource: 'timesheet-table',
			modelVersion: timeRegistrationTargetSuggestions.modelVersion,
			timeRegistrationTargetTaskId: taskId,
			timeRegistrationTargetProjectId: projectId,
			timeRegistrationTargetIdleTimeId: idleTimeId,
			timeRegistrationDate: date.format('YYYY-MM-DD'),
			timeRegistrationTargetSuggestions: timeRegistrationTargetSuggestions?.sortedSuggestions,
			oldTimeRegistrationTargetSuggestions: oldTimeRegistrationTargetSuggestions,
		});
	}
}
