import Util from '../../../../../shared/util/util';
import { getTimeRegType, TIME_REG_TYPES, timeRegEntityInIdList, timeRegMatchesEntityId, } from './TimeRegUtil';
export const getEntityFromSuggestion = (suggestion) => {
    const rawEntity = (suggestion === null || suggestion === void 0 ? void 0 : suggestion.task) || (suggestion === null || suggestion === void 0 ? void 0 : suggestion.idleTime) || (suggestion === null || suggestion === void 0 ? void 0 : suggestion.project);
    return Object.assign(Object.assign({}, rawEntity), { disabled: false, type: getTimeRegType(suggestion), isSuggestion: true, minutesRegistered: undefined, billableMinutesRegistered: undefined, showAsSuggestion: true }); // FIXME: This typecast is a bit illegal. The object we get from the function isn't actually guaranteed to match the TimeRegEntity type. This should probably be refactored to be more type-safe.
};
export function getHasTimeRegistrationFromSuggestion(suggestionEntity, timeRegistrations) {
    if (!timeRegistrations)
        return false;
    return timeRegistrations.some(timeReg => timeRegMatchesEntityId(timeReg === null || timeReg === void 0 ? void 0 : timeReg.node, suggestionEntity.id));
}
function addTimeRegToSuggestionEntityInDayViewFormat(suggestionEntity, timeReg) {
    suggestionEntity.id = timeReg.id;
    suggestionEntity.timeRegId = timeReg.id;
    suggestionEntity.type = getTimeRegType(timeReg);
    suggestionEntity.showAsSuggestion = true;
    suggestionEntity.notes = timeReg.notes;
    suggestionEntity.minutesRegistered = timeReg.minutesRegistered;
    suggestionEntity.billableMinutesRegistered = timeReg.billableMinutesRegistered;
}
/*
 * When the user has added a time reg to a suggestion in this setting, we want to add
 * the time reg to the suggestion while still showing it in the same place.
 * So given a list of all suggestion entities and a list of all time regs as well as a list of the suggestions that had no time reg when the page was loaded
 * We will update the suggestion Entities with time registrations
 * */
export function addTimeRegsToMovedSuggestions(suggestionEntities, timeRegistrations, suggestionsNotHavingTimeRegsAtLoad, isDayView) {
    const suggestionEntitiesWithAddedTimeRegs = new Map([...suggestionEntities]);
    const timeRegistrationsAddedSincePageLoad = timeRegistrations.filter(timeReg => {
        return timeRegEntityInIdList(timeReg === null || timeReg === void 0 ? void 0 : timeReg.node, suggestionsNotHavingTimeRegsAtLoad);
    });
    timeRegistrationsAddedSincePageLoad.forEach(timeReg => {
        var _a;
        const suggestionEntity = [...suggestionEntities.keys()].find(suggestionEntity => timeRegMatchesEntityId(timeReg === null || timeReg === void 0 ? void 0 : timeReg.node, suggestionEntity.id));
        if (suggestionEntity) {
            const suggestionEntityTimeRegs = timeRegistrations.filter(timeRegEdge => timeRegMatchesEntityId(timeRegEdge === null || timeRegEdge === void 0 ? void 0 : timeRegEdge.node, suggestionEntity.id));
            const dayRegsForEntity = [[], [], [], [], [], [], []];
            if (isDayView) {
                const timeReg = (_a = suggestionEntityTimeRegs[0]) === null || _a === void 0 ? void 0 : _a.node;
                addTimeRegToSuggestionEntityInDayViewFormat(suggestionEntity, timeReg);
                suggestionEntitiesWithAddedTimeRegs.set(suggestionEntity, dayRegsForEntity);
            }
            else {
                suggestionEntityTimeRegs.forEach(timeRegEdge => {
                    const timeReg = timeRegEdge === null || timeRegEdge === void 0 ? void 0 : timeRegEdge.node;
                    const dayOfWeek = Util.CreateNonUtcMomentDate(timeReg.year, timeReg.month, timeReg.day).weekday();
                    dayRegsForEntity[dayOfWeek].push(timeRegEdge);
                });
                suggestionEntitiesWithAddedTimeRegs.set(suggestionEntity, dayRegsForEntity);
            }
        }
    });
    return suggestionEntitiesWithAddedTimeRegs;
}
export function getTimeRegsWithoutMovedSuggestions(_filteredTimeRegs, suggestionIdsAtLoad, timeRegsNotMatchedToSuggestions) {
    let _filteredTimeRegsWithoutMovedSuggestions = _filteredTimeRegs;
    if (suggestionIdsAtLoad.length > 0) {
        _filteredTimeRegsWithoutMovedSuggestions = _filteredTimeRegs.filter(timeRegEdge => !timeRegEntityInIdList(timeRegEdge === null || timeRegEdge === void 0 ? void 0 : timeRegEdge.node, suggestionIdsAtLoad) ||
            timeRegsNotMatchedToSuggestions.includes(timeRegEdge === null || timeRegEdge === void 0 ? void 0 : timeRegEdge.node.id));
    }
    return _filteredTimeRegsWithoutMovedSuggestions;
}
export function formatForDayView(suggestionEntitiesWithTimeRegs) {
    const returnValue = [];
    [...suggestionEntitiesWithTimeRegs.keys()].forEach(suggestionEntity => {
        if (suggestionEntity.type === TIME_REG_TYPES.TASK_TIME) {
            returnValue.push({
                node: {
                    id: suggestionEntity.id,
                    timeRegId: suggestionEntity.timeRegId,
                    name: suggestionEntity.name,
                    notes: suggestionEntity.notes,
                    minutesRegistered: suggestionEntity.minutesRegistered,
                    billableMinutesRegistered: suggestionEntity.billableMinutesRegistered,
                    task: suggestionEntity,
                    type: undefined,
                    showAsSuggestion: suggestionEntity.showAsSuggestion,
                },
            });
        }
        else if (suggestionEntity.type === TIME_REG_TYPES.IDLE_TIME) {
            returnValue.push({
                node: {
                    id: suggestionEntity.id,
                    timeRegId: suggestionEntity.timeRegId,
                    name: suggestionEntity.name,
                    switchIdleTime: suggestionEntity.isSuggestion,
                    idleTime: suggestionEntity,
                    notes: suggestionEntity.notes,
                    type: undefined,
                    minutesRegistered: suggestionEntity.minutesRegistered,
                    billableMinutesRegistered: suggestionEntity.billableMinutesRegistered,
                    showAsSuggestion: suggestionEntity.showAsSuggestion,
                    isInternalTime: suggestionEntity.isInternalTime,
                },
            });
        }
    });
    return returnValue;
}
/* We should make a week-view to day-view adapter, this stuff should not be necessary */
export function getTimeRegsNotMatchedToSuggestionsInDayView(suggestionEntities, timeRegistrations, suggestionsNotHavingTimeRegsAtLoad) {
    const timeRegsNotMatchedToSuggestions = [];
    const timeRegistrationsWithMovedSuggestions = timeRegistrations.filter(timeReg => {
        return timeRegEntityInIdList(timeReg === null || timeReg === void 0 ? void 0 : timeReg.node, suggestionsNotHavingTimeRegsAtLoad);
    });
    timeRegistrationsWithMovedSuggestions.forEach(timeReg => {
        const suggestionEntity = [...suggestionEntities.keys()].find(suggestionEntity => timeRegMatchesEntityId(timeReg === null || timeReg === void 0 ? void 0 : timeReg.node, suggestionEntity.id));
        if (suggestionEntity) {
            const suggestionEntityTimeRegs = timeRegistrations.filter(timeRegEdge => timeRegMatchesEntityId(timeRegEdge === null || timeRegEdge === void 0 ? void 0 : timeRegEdge.node, suggestionEntity.id));
            if (suggestionEntityTimeRegs.length > 1) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_firstElement, ...restOfArray] = suggestionEntityTimeRegs;
                timeRegsNotMatchedToSuggestions.push(...restOfArray.map(e => { var _a; return (_a = e === null || e === void 0 ? void 0 : e.node) === null || _a === void 0 ? void 0 : _a.id; }));
            }
        }
    });
    return timeRegsNotMatchedToSuggestions;
}
