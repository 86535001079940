import React, {useEffect, useMemo, useRef, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {getSearchQueryFiltersValue, getSecondDropdownOptionsByGroupings} from './TimeRegistrationReportPageUtil';
import {
	filterObjectEyeOptionsWithCustomFields,
	theEyeOptionsFilteredWithCustomFields,
	theEyeOptionsNoGrouping,
} from './TimeRegistrationReportPageEyeUtil';
import {noGroupingFirstDropdownOptions} from './TimeRegistrationReportTableUtil';
import {buildHeaderBar} from '../../../shared/components/headers/header-bar/header_bar';
import {useGenericReportContext} from '../../saved-report/GenericReportContext';
import {getFiltersAlphabetically} from '../../../shared/components/filters/filter_util';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import tracking from '../../../../tracking';
import {FILTER_SECTIONS} from '../../../shared/components/filters/FilterWrapper';
import ReportedTimeTable, {TimeRegistrationReportTableQuery} from './TimeRegistrationReportTable';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_PICKER_STYLE,
	ELEMENT_TYPE,
	FILTER_SECTION,
	FILTER_TYPE,
	GLOBAL_FILTER_FIELD,
	GLOBAL_FILTER_OPERATOR,
	MODULE_TYPES,
	REPORT_GROUPINGS,
} from '../../../../constants';
import Util from '../../../shared/util/util';
import hash from 'object-hash';
import {HeaderContainer} from '../task-report-page/TaskReport.styled';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {trackCSVExport, trackEvent} from '../../../../tracking/amplitude/TrackingV2';

const TimeRegistrationReportPage = ({intl, viewer, history}) => {
	//state variables
	const genericTable = useRef(null);
	const {
		reportName,
		startDate,
		handleSetStartDate,
		endDate,
		handleSetEndDate,
		theEyeOptionsChecked,
		setTheEyeOptionsChecked,
		filterValue,
		firstDropdownValue,
		handleSetFirstDropdownValue,
		secondDropdownValue,
		handleSetSecondDropdownValue,
		handleSetFilterValue,
		handleSetFilters,
		getTopReportHeader,
		getBottomReportHeader,
		isSharedView,
		reportId,
		reportShares,
		sharedId,
		getHasOwnerPermission,
		getIsReportOwner,
		reportOwner,
		privateAccess,
	} = useGenericReportContext();

	const isReportOwner = getIsReportOwner(viewer.actualPersonId);
	const hasOwnerPermission = getHasOwnerPermission(viewer.actualPersonId);

	const firstDropdownOptions = noGroupingFirstDropdownOptions(intl);
	const [secondDropdownOptions, setSecondDropdownOptions] = useState(
		getSecondDropdownOptionsByGroupings(firstDropdownValue, intl)
	);

	const timeRegSettings = viewer?.company?.timeRegistrationSettings;

	const defaultSearchQueryFilters = [
		{
			field: GLOBAL_FILTER_FIELD.WITH_TIME_REG,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: true.toString(),
		},
		{
			field: GLOBAL_FILTER_FIELD.START_DATE_TIME_REG,
			operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
			value: [startDate.format('YYYY-MM-DD')],
		},
		{
			field: GLOBAL_FILTER_FIELD.END_DATE_TIME_REG,
			operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
			value: [endDate.format('YYYY-MM-DD')],
		},
	];
	const initialSearchQueryFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filterValue);
	const [searchQueryFilters, setSearchQueryFilters] = useState(initialSearchQueryFilters);

	const customFieldDefinitions =
		hasModule(MODULE_TYPES.CUSTOM_FIELDS) || isSharedView
			? viewer.reportData.company.reportCustomFieldDefinitions
					.filter(n => n.entityType === 'TIME_REG')
					.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
			: [];

	const groupings = [];
	if (firstDropdownValue !== null) {
		groupings.push(firstDropdownValue);
		if (secondDropdownValue !== null) {
			groupings.push(secondDropdownValue);
		}
	}
	groupings.push(REPORT_GROUPINGS.TIME_REG);

	//Handling of showing of columns
	const enabledOptions = filterObjectEyeOptionsWithCustomFields(
		theEyeOptionsChecked,
		groupings,
		isSharedView,
		customFieldDefinitions,
		timeRegSettings
	);
	useMemo(() => {
		setTheEyeOptionsChecked(enabledOptions);
	}, [...groupings, hash(theEyeOptionsChecked)]);

	useTrackPage('Time Registration Report');

	// Authorization. Same restrictions as project-budget
	useEffect(() => {
		if (!isSharedView && !Util.AuthorizeViewerAccess('time-report')) {
			history.push('/not-authorized');
			Util.localStorageSetItem('project-section-last-viewed', 'workflow');
		} else {
			tracking.trackPage('Time Registrations Report');
		}
	}, []);

	let searchQuery = useMemo(() => {
		return {
			filters: searchQueryFilters,
		};
	}, searchQueryFilters);

	const handleFirstDropdownChange = option => {
		trackEvent('First Grouping', 'Changed', {value: option?.value});
		handleSetFirstDropdownValue(option.value);
		handleSetSecondDropdownValue(null);
		setSecondDropdownOptions(getSecondDropdownOptionsByGroupings(option.value, intl));
	};

	const handleSecondDropdownChange = option => {
		trackEvent('Second Grouping', 'Changed', {value: option?.value});
		handleSetSecondDropdownValue(option.value);
	};

	const handleDateRangeChange = (startDate, endDate) => {
		handleSetStartDate(startDate);
		handleSetEndDate(endDate);

		const updatedSearchQueryFilters = searchQueryFilters.map(filter => {
			const updatedFilterObject = {};
			if (filter.field === GLOBAL_FILTER_FIELD.START_DATE_TIME_REG) {
				updatedFilterObject.field = GLOBAL_FILTER_FIELD.START_DATE_TIME_REG;
				updatedFilterObject.operator = GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL;
				updatedFilterObject.value = [startDate.format('YYYY-MM-DD')];
			} else if (filter.field === GLOBAL_FILTER_FIELD.END_DATE_TIME_REG) {
				updatedFilterObject.field = GLOBAL_FILTER_FIELD.END_DATE_TIME_REG;
				updatedFilterObject.operator = GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL;
				updatedFilterObject.value = [endDate.format('YYYY-MM-DD')];
			} else {
				updatedFilterObject.field = filter.field;
				updatedFilterObject.operator = filter.operator;
				updatedFilterObject.value = filter.value;
			}
			return updatedFilterObject;
		});
		setSearchQueryFilters(updatedSearchQueryFilters);
		handleSetFilters(updatedSearchQueryFilters);
	};

	const onFilterChange = filters => {
		handleSetFilterValue(filters);
		const updatedFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filters);
		handleSetFilters(updatedFilters);
		setSearchQueryFilters(updatedFilters);
	};

	const handleCsvDownload = () => {
		trackCSVExport('Time Registration Report');
		showModal({
			type: MODAL_TYPE.DOWNLOADING_QUERY_DATA,
			//you can remove the searchQuery and viewer after PR approved
			searchQuery: searchQuery,
			viewer: viewer,
			searchQueryFilters: searchQueryFilters,
			reportName,
			enabledColumns: enabledOptions,
			groupings: groupings,
			filterValue: filterValue,
			customFieldDefinitions,
		});
	};

	const openShareModal = () => {
		if (hasFeatureFlag('reports_redesign')) {
			showModal({
				type: MODAL_TYPE.SHARE_REPORT_MODAL,
				reportId: reportId,
				reportSharesInput: reportShares.edges.map(reportShare => {
					return {
						email: reportShare.node.user.email,
						sharedId: reportShare.node.id,
					};
				}),
				privateAccessInput: privateAccess,
				reportOwner: reportOwner,
			});
		} else {
			showModal({
				type: MODAL_TYPE.EXTERNAL_REPORT_SHARE,
				reportShares: reportShares.edges.map(reportShare => {
					return {
						email: reportShare.node.user.email,
						sharedId: reportShare.node.id,
					};
				}),
				reportId: reportId,
			});
		}
	};

	const getTopHeader = () => {
		let content = [];

		if (hasOwnerPermission) {
			const reportNameProps = {
				defaultTitle:
					intl.formatMessage({id: 'reported_time_table.time_registered'}) +
					', ' +
					intl.formatMessage({id: 'common.untitled'}),
				placeholder: intl.formatMessage({id: 'time_reg_report.report_name'}),
			};
			content.push(...getTopReportHeader(reportNameProps));
		}

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={hasOwnerPermission ? null : reportName} content={content} />
			</TopHeaderBarWrapper>
		);
	};

	const getBottomHeader = () => {
		let leftContent = [];
		let rightContent = [];

		leftContent.push({
			type: ELEMENT_TYPE.DATE_PICKER,
			userpilot: 'timereg-date-picker',
			isNewDateRange: true,
			startDate: startDate,
			endDate: endDate,
			handleDateRangeChange: (startDate, endDate) => handleDateRangeChange(startDate, endDate),
			colorInherited: true,
			compactShowYear: true,
			datePickerStyle: DATE_PICKER_STYLE.SLIM,
			calendarOffsetX: 0,
			clearable: false,
		});

		rightContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCsvDownload,
		});

		rightContent.push({
			sameTypeOrder: 1,
			type: ELEMENT_TYPE.DROPDOWN,
			userpilot: 'timereg-first-grouping',
			dropdownOptions: firstDropdownOptions,
			value: firstDropdownValue,
			callback: option => {
				handleFirstDropdownChange(option);
			},
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
		});

		if (secondDropdownOptions !== null) {
			rightContent.push({
				sameTypeOrder: 2,
				type: ELEMENT_TYPE.DROPDOWN,
				dropdownOptions: secondDropdownOptions,
				value: secondDropdownValue,
				callback: option => {
					handleSecondDropdownChange(option);
				},
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		const eyeProps = {
			eyeOptions: theEyeOptionsFilteredWithCustomFields(
				theEyeOptionsNoGrouping(isSharedView, timeRegSettings),
				groupings,
				isSharedView,
				customFieldDefinitions,
				timeRegSettings
			),
		};

		const additionalProps = {eyeProps: eyeProps};

		const filteredHeaderElements = getBottomReportHeader(additionalProps);

		rightContent.push(...filteredHeaderElements);

		if (isReportOwner) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: 'Share',
				callback: openShareModal,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		const projectFilters = [],
			peopleFilters = [],
			taskFilters = [];
		const timeRegFilters = [
			FILTER_TYPE.BILLABLE,
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.DEPARTMENT,
			FILTER_TYPE.PERSON,
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.TEAM,
			FILTER_TYPE.INTERNAL_TIME,
			FILTER_TYPE.TIME_OFF,
			FILTER_TYPE.LABEL_TIME_REG,
			FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT,
			FILTER_TYPE.PROJECT_TYPE,
			FILTER_TYPE.INVOICED,
			FILTER_TYPE.APPROUVED,
		];

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			operatorOptions: {
				allowExclude: hasFeatureFlag('unified_filters_reports'),
				allowRequireAll: hasFeatureFlag('unified_filters_reports'),
			},
			defaultSection: FILTER_SECTIONS.TIMEREGS,
			projectFilters,
			peopleFilters,
			timeRegFilters: getFiltersAlphabetically(timeRegFilters, intl.formatMessage),
			taskFilters,
			viewer: viewer,
			filterSection: FILTER_SECTION.REPORTED_TIME_TABLE,
			appliedFiltersName: `reported-time-filter-v4`,
			noMenu: false,
			useSavedReport: true,
			preAppliedFilters: filterValue,
			onFiltersChange: onFilterChange.bind(this),
			userpilot: 'timereg-filter-button',
		});

		return buildHeaderBar(leftContent, rightContent);
	};

	const getSharedViewHeaderTitleBar = () => {
		const sDate = intl.formatDate(startDate, {year: 'numeric', month: 'short', day: 'numeric'});
		const eDate = intl.formatDate(endDate, {year: 'numeric', month: 'short', day: 'numeric'});
		const title = `${reportName} (${sDate} - ${eDate})`;

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={title} content={[]} />
			</TopHeaderBarWrapper>
		);
	};

	return (
		// <PageWrapper>
		<>
			<HeaderContainer ref={genericTable}>
				{isSharedView ? getSharedViewHeaderTitleBar() : getTopHeader()}
			</HeaderContainer>
			<HeaderContainer ref={genericTable}>{!isSharedView && getBottomHeader()}</HeaderContainer>
			<ForecastQueryRenderer
				key="query-render-reportedTimeTable"
				query={TimeRegistrationReportTableQuery}
				// This is basically initialVariables
				variables={{
					searchQuery: searchQuery,
					reportId,
					sharedId,
				}}
				renderIfNotLoggedIn={true}
				render={relayProps => {
					return (
						<ReportedTimeTable
							{...relayProps}
							relayProps={relayProps}
							enabledColumns={enabledOptions}
							groupings={groupings}
							searchQuery={searchQuery}
							currency={viewer.reportData.company.currency}
							customFieldDefinitions={customFieldDefinitions}
						/>
					);
				}}
			/>
		</>
		// </PageWrapper>
	);
};

const TimeRegistrationReportPageQuery = graphql`
	query TimeRegistrationReportPage_Query($savedReportId: String, $sharedId: String) {
		viewer {
			actualPersonId
			component(name: "reported_time_page_v2")
			...TimeRegistrationReportPage_viewer @arguments(savedReportId: $savedReportId, sharedId: $sharedId)
		}
	}
`;

export {TimeRegistrationReportPageQuery};

export default injectIntl(
	createFragmentContainer(TimeRegistrationReportPage, {
		viewer: graphql`
			fragment TimeRegistrationReportPage_viewer on Viewer
			@argumentDefinitions(savedReportId: {type: "String"}, sharedId: {type: "String"}) {
				actualPersonId
				id
				email
				company {
					timeRegistrationSettings {
						allowBillableSplit
					}
				}
				filters(first: 10000, filterSection: REPORTED_TIME_TABLE) @connection(key: "Viewer_filters", filters: []) {
					edges {
						node {
							id
							name
							section
							value
							updatedAt
						}
					}
				}
				reportData(id: $savedReportId, sharedId: $sharedId) {
					company {
						currency
						reportCustomFieldDefinitions {
							id
							key
							entityType
							displayName
						}
					}
				}
			}
		`,
	})
);
