var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { hasFeatureFlag } from '../../util/FeatureUtil';
import { DeprecatedScrollbar, Scrollbar } from '@forecast-it/design-system';
const DeprecatedScrollToNativeScroll = (_a) => {
    var { forceDeprecatedScroll, nativeMaxHeight, isPageScroller, hasFocusableContent, onNativeScroll, children } = _a, props = __rest(_a, ["forceDeprecatedScroll", "nativeMaxHeight", "isPageScroller", "hasFocusableContent", "onNativeScroll", "children"]);
    if (!forceDeprecatedScroll && hasFeatureFlag('native_scrollbar_replacement')) {
        return (React.createElement(Scrollbar, { maxHeight: nativeMaxHeight, onScroll: onNativeScroll, isPageScroller: isPageScroller, hasFocusableContent: hasFocusableContent }, children));
    }
    return React.createElement(DeprecatedScrollbar, Object.assign({}, props), children);
};
export default DeprecatedScrollToNativeScroll;
