import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import Util from '../../../shared/util/util';
import ReorderProjectBoardTaskMutation from '../../../../mutations/ReorderProjectBoardTaskMutation';
import {parseTaskFilters} from '../../../shared/components/filters/filter_logic';
import CreateTaskMutation from '../../../../mutations/create_task_mutation';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {isClientUser} from '../../../shared/util/PermissionsUtil';
import {isTaskTimeRegistrationAllowed} from '../../../shared/util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {toEntityId} from '../../../shared/components/modals/time-registration/time-registration-modal/util/TimeRegistrationModalUtil';

export const getSearchQueryForStatusCol = (statusColumnId, searchQueryValue, filters, isConnectedParent, noStatusFilter) => {
	const searchQuery = {filters: []};
	const parsedFilters = parseTaskFilters(filters, isConnectedParent, false);
	if (!noStatusFilter) {
		if (isConnectedParent) {
			searchQuery.filters = searchQuery.filters.concat([
				{
					field: GLOBAL_FILTER_FIELD.PROJECT_GROUP_STATUS,
					operator: GLOBAL_FILTER_OPERATOR.IS,
					value: [statusColumnId.toString()],
				},
			]);
		} else {
			searchQuery.filters = searchQuery.filters.concat([
				{
					field: GLOBAL_FILTER_FIELD.PROJECT_STATUS,
					operator: GLOBAL_FILTER_OPERATOR.IS,
					value: [statusColumnId.toString()],
				},
			]);
		}
	}
	searchQuery.filters = searchQuery.filters.concat(parsedFilters);
	if (searchQueryValue && searchQueryValue !== '') {
		const queryNameSearch = {
			operator: GLOBAL_FILTER_OPERATOR.CONTAINS,
			field: GLOBAL_FILTER_FIELD.NAME,
			value: [searchQueryValue],
		};
		searchQuery.filters.push(queryNameSearch);
	}
	return searchQuery;
};

export const promptTimeRegistration = (
	sourceDroppableId,
	destinationDroppableId,
	draggableId,
	taskMapRef,
	projects,
	isProjectGroup,
	personId
) => {
	if (isClientUser()) {
		return;
	}
	const sourceId = isProjectGroup ? parseInt(sourceDroppableId) : sourceDroppableId;
	const targetId = isProjectGroup ? parseInt(destinationDroppableId) : destinationDroppableId;
	const targetColumn = projects[0].statusColumnsV2.edges.find(col =>
		isProjectGroup ? col.node.projectGroupStatusColumnId === targetId : col.node.id === targetId
	);
	if (sourceId === targetId || !targetColumn.node.encourageTimeRegistration) return;

	const taskId = draggableId.split('-')[0];
	const taskMap = taskMapRef.current;
	const timerTask = taskMap.has(sourceId) ? taskMap.get(sourceId).find(task => task.id === taskId) : null;

	if (timerTask) {
		const statusCategory = timerTask.statusColumnV2?.category;
		const assignedPersonIds = timerTask.assignedPersons ? timerTask.assignedPersons.map(person => person.id) : [];

		if (!isTaskTimeRegistrationAllowed(statusCategory, personId, assignedPersonIds, null)) return;
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
				personId,
				entityId: toEntityId({taskId: timerTask.id}),
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				timerTask: null,
				timerProject: null,
				useTimer: false,
				personId,
				taskId: timerTask.id,
			});
		}
	}
};

/**
 *
 * @param {any} e
 * @param {any} taskMapRef
 * @param {object} project
 * @param {object} projectGroup
 * @param {number} companyId
 * @param {func} onSuccess
 * @returns {boolean} true if moving is being done to another column, and not the same column
 */
export const moveTaskToColumn = (
	sourceDroppableId,
	destinationDroppableId,
	draggableId,
	placeholderId,
	taskMapRef,
	project,
	projectGroup,
	companyId,
	onSuccess,
	selectedTasks,
	setCardReorderInProgress
) => {
	if (placeholderId == null) return;
	const draggedTaskId = draggableId.split('-')[0];
	const isDraggingSelectedTask = selectedTasks.length > 0 && selectedTasks.some(task => task.node.id === draggedTaskId);

	const taskMap = taskMapRef.current;
	if (!destinationDroppableId) return;
	const sourceId = projectGroup ? parseInt(sourceDroppableId) : sourceDroppableId;
	const targetId = projectGroup ? parseInt(destinationDroppableId) : destinationDroppableId;

	const targetColTasks = taskMap.has(targetId) ? taskMap.get(targetId) : null;

	let aboveTask = null;
	if (targetColTasks && targetColTasks.length > 0) {
		if (placeholderId === 'END_OF_LIST') {
			aboveTask = targetColTasks[targetColTasks.length - 1];
		} else {
			const placeholderTaskId = placeholderId.split('-')[0];
			const placeholderTaskIndex = targetColTasks.findIndex(task => task.id === placeholderTaskId);
			if (placeholderTaskIndex <= 0) {
				aboveTask = null;
			} else {
				aboveTask = targetColTasks[placeholderTaskIndex - 1];
			}
		}
	}

	// If task did not move, do nothing
	if (aboveTask && aboveTask.id === draggedTaskId) return false;

	let targetStatusColumn = null;
	try {
		if (project) {
			targetStatusColumn = project.statusColumnsV2.edges.find(edge => edge.node.id === targetId).node;
		} else if (projectGroup) {
			targetStatusColumn = projectGroup.projects.edges[0].node.statusColumnsV2.edges.find(
				col => col.node.projectGroupStatusColumnId === targetId
			).node;
		}
	} catch {
		console.error('Could not find target status column');
	}

	const selectedTaskIds = [];
	const adoTaskIdsToMove = [];
	const jiraTaskIdsToMove = [];

	let hasParent = false;

	if (isDraggingSelectedTask) {
		selectedTasks.forEach(task => {
			selectedTaskIds.push(task.node.id);
			if (project?.isJiraProject && targetStatusColumn?.jiraStatusId && !task.node.jiraId) {
				jiraTaskIdsToMove.push(task.node.id);
				if (task.node.parentTaskId) {
					hasParent = true;
				}
			} else if (project?.vstsProject && targetStatusColumn?.adoState && !task.node.vstsId) {
				adoTaskIdsToMove.push(task.node.id);
			}
		});
	} else {
		const draggedTask = taskMap.get(sourceId).find(task => task.id === draggedTaskId);
		if (project?.isJiraProject && targetStatusColumn?.jiraStatusId && !draggedTask.jiraId) {
			jiraTaskIdsToMove.push(draggedTaskId);
			if (draggedTask.parentTaskId) {
				hasParent = true;
			}
		} else if (project?.vstsProject && targetStatusColumn?.adoState && !draggedTask.vstsId) {
			adoTaskIdsToMove.push(draggedTaskId);
		}
	}

	const prevStatusColumnIdMap = isDraggingSelectedTask
		? selectedTasks.reduce((map, task) => {
				map.set(task.node.id, {
					statusColumnId: task.node.statusColumnV2.id,
					projectGroupStatusColumnId: task.node.statusColumnV2.projectGroupStatusColumnId,
				});
				return map;
		  }, new Map())
		: new Map([
				[
					draggedTaskId,
					{
						statusColumnId: projectGroup ? null : sourceId,
						projectGroupStatusColumnId: projectGroup ? sourceId : null,
					},
				],
		  ]);

	const mutationObject = {
		ids: isDraggingSelectedTask ? selectedTaskIds : [draggedTaskId],
		companyId: companyId,
		aboveTaskId: aboveTask ? aboveTask.id : null,
		optimisticTaskOrder: aboveTask ? aboveTask.sortOrder + 0.1 : 0.1,
		prevStatusColumnIdMap: prevStatusColumnIdMap,
	};
	if (projectGroup) {
		mutationObject.projectGroupStatusColumnId = targetId;
		mutationObject.statusColumnCategory = targetStatusColumn?.category;
	} else {
		mutationObject.statusColumnId = targetId;
		mutationObject.statusColumnCategory = targetStatusColumn?.category;
	}

	const doMutation = () => {
		setCardReorderInProgress(true);
		Util.CommitSchedulingModalUpdate(ReorderProjectBoardTaskMutation, mutationObject, res =>
			onSuccess(res, isDraggingSelectedTask)
		);
	};

	if (adoTaskIdsToMove.length > 0) {
		showModal({
			type: MODAL_TYPE.CREATE_ADO_WORKITEM,
			projectId: project.id,
			vstsAccount: project.vstsAccount,
			vstsArea: project.vstsArea,
			taskIds: adoTaskIdsToMove,
			callback: doMutation, // After tasks have been created in ADO, run the mutation as normal.
		});
	} else if (jiraTaskIdsToMove.length > 0) {
		showModal({
			type: MODAL_TYPE.CREATE_JIRA_ISSUE,
			projectId: project.id,
			taskIds: jiraTaskIdsToMove,
			jiraType: project.jiraCloudProject || project.jiraCloudEpicIds?.length ? 'cloud' : 'server',
			useTaskHierarchy: project.useTaskHierarchy,
			hasParent,
			callback: doMutation, // After tasks have been created in Jira, run the mutation as normal.
		});
	} else {
		doMutation();
	}
	return true;
};

export const createTaskInColumn = (columnId, projectId, taskName, companyId) => {
	Util.CommitSchedulingModalUpdate(CreateTaskMutation, {
		statusColumnId: columnId,
		projectId: projectId,
		placementOfTask: 'TOP',
		name: taskName,
		companyId: companyId,
	});
};

export const mapDraggableIdToTask = (draggableId, taskMap) => {
	const splitId = draggableId.split('-');
	const taskId = splitId[0];
	const statusColumnId = parseInt(splitId[1]) || splitId[1];

	if (taskId && statusColumnId && taskMap.has(statusColumnId)) {
		return taskMap.get(statusColumnId).find(task => task.id === taskId);
	}
};
