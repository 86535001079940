import {hasFeatureFlag} from './FeatureUtil';

export const getProjectIdFromPath = currentPath => {
	if (hasFeatureFlag('custom_project_ids')) {
		if (!currentPath.startsWith('/project/')) {
			return null;
		}
		const match = /\/project\/([^/]*)\//g.exec(currentPath);
		return match ? match[1] : null;
	} else {
		return currentPath.startsWith('/project/P') ? currentPath.match(/\d+/g)[0] : null;
	}
};

export const getConnectProjectIDFromPath = currentPath =>
	currentPath.startsWith('/connected/X') ? currentPath.match(/\d+/g)[0] : null;
