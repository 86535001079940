import React from 'react';
import {
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedColorIndicator as ColorIndicator,
	DeprecatedTableCell as TableCell,
	DeprecatedTableHeaderRow as TableHeaderRow,
	DeprecatedText as Text,
	DeprecatedVirtualizedTable as VirtualizedTable,
} from '@forecast-it/design-system';
import {borderRadius, colors, font, spacing} from '@forecast-it/design-system/src/deprecated/shared/styles';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import Util from '../../../../forecast-app/shared/util/util';
import styled from 'styled-components';
import {NUMBER_TYPE} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {trackComplexEvent} from '../../../../tracking/amplitude/TrackingV2';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {getTotalCost} from './ProgramBudgetTypeProfitBox';
import {getProjectIdentifier} from '../program-overview-page/ProgramContextProvider';

const formatNumber = (value, numberType, currencySymbol, intl) => {
	switch (numberType) {
		case NUMBER_TYPE.MONEY:
			return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
		case NUMBER_TYPE.PERCENTAGE:
			return Util.getFormattedPercentage(value, intl);
		default:
			break;
	}
	return value;
};

const ProjectBudgetTable = ({program, projects, company, retry}) => {
	const {formatMessage} = useIntl();
	const intl = useIntl();
	const history = useHistory();
	const currency = Util.GetCurrencySymbol(company.currency);
	const isCappedProgram = program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED;

	const formattedProjects = projects.map(project => ({
		...project,
		prefix: `P-${project.companyProjectId}`,
		budgetTypeTranslated: Util.getBudgetTypeTranslation(project.budgetType, useIntl()),
	}));

	/*
	Remove the following styled components once the additions/modifications
	have been made in the design system repo
	*/

	const alignment = {
		left: 'start',
		right: 'end',
	};

	const sizes = {
		s: '10%',
		m: '15%',
		l: '50%',
	};

	// Copy of BasicRowWrapper from design systems.
	const BasicRowWrapper = styled.div`
		display: flex;
		align-items: center;
		box-sizing: border-box;
		background-color: ${colors.background.primary};
		${font.base.regular}
		border-radius: ${borderRadius.s};
		padding: ${spacing.xs};
		color: ${colors.text.primary};
		gap: ${spacing.s};
	`;

	// Copy of TableRowWrapper from design systems. The only differences are the cursor which is
	// no longer there as it's not required for totals and the added style property background-color and a right margin to
	// make room for the scrollbar
	const TableRowTotals = styled(BasicRowWrapper)`
		margin-right: ${spacing.m};
		border: 1px solid ${colors.border.primary.default};
		.hide-able {
			opacity: 0;
		}
		background-color: ${colors.background.secondary.default};
		:hover {
			background-color: ${colors.background.secondary.default};

			.hide-able {
				opacity: 1;
			}
		}
	`;

	// Copy of TableRowWrapper from design systems. The only differences are the cursor which is
	// no longer there if there is no link as it's not required for totals and the added style property background-color.
	// and a right margin to make room for the scrollbar and a top margin since the page compressed after adding the scrollbar
	const TableRowData = styled(BasicRowWrapper)`
		margin-right: ${spacing.m};
		margin-top: 2px;
		border: 1px solid ${colors.border.primary.default};
		.hide-able {
			opacity: 1;
		}
		cursor: ${({onClick}) => (onClick ? 'pointer' : 'default')};
		:hover {
			background-color: ${colors.background.secondary.default};

			.hide-able {
				opacity: 1;
				background-color: ${colors.background.secondary.default};
				border-color: ${colors.background.secondary.default};
			}
		}
	`;

	// Copy of TableCell with added onClick property
	// We'll put this on the list of design system change proposals to handle once people are back from vacation
	const TableCellWrapperOnClick = styled.div`
		display: flex;
		cursor: ${({onClick}) => (onClick ? 'pointer' : 'default')};
		justify-content: ${({align}) => alignment[align]};
		align-items: center;
		flex: 1 0 ${({size}) => sizes[size]};
	`;
	const TableCellWithOnClick = ({children, size, align, onClick}) => {
		return (
			<TableCellWrapperOnClick size={size} align={align} onClick={onClick}>
				{children}
			</TableCellWrapperOnClick>
		);
	};

	// Copy of TableHeaderCellWrapper from design systems. The only differences are the cursor which is
	// no longer there as in our case it's not required for headers and the added style property text-align..
	const TableHeaderCell = styled.div`
		display: flex;
		min-height: 18px;
		justify-content: ${({align}) => alignment[align]};
		text-align: ${({align}) => alignment[align]};
		align-items: center;
		flex: 1 0 ${({size}) => sizes[size]};
		max-height: 40px;
	`;

	// Copy of TableCellWrapper from design systems. The only difference is the flex-grow which was changed from 1 to 0.3.
	// This is only used for the more button at the end of each row.
	const TableCellCustom = styled.div`
		display: flex;
		justify-content: ${({align}) => alignment[align]};
		align-items: center;
		flex: 0.3 0 0;
	`;

	const createUnallocatedRow = program => {
		return (
			<TableRowData>
				<TableCellWithOnClick></TableCellWithOnClick>
				<TableCell size={'m'}>
					<Text>{formatMessage({id: 'program_budget.unallocated_revenue'})}</Text>
				</TableCell>
				<TableCell></TableCell>
				{isCappedProgram && <TableCell></TableCell>}
				<TableCell></TableCell>
				<TableCell></TableCell>
				<TableCell></TableCell>
				<TableCell align={'right'}>
					<Text data-cy={'uncallocatedRevenue'}>
						{formatNumber(program.budgetValue - program.totalAllocation, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell></TableCell>
				<TableCell></TableCell>
				<TableCellCustom size={'m'} align={'right'}></TableCellCustom>
			</TableRowData>
		);
	};

	const getProgramTotalRevenue = program => {
		if (program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE) {
			return program.budgetValue;
		} else if (program.projectsFinancialsTotals.recognitionForecastRevenue) {
			return program.projectsFinancialsTotals.recognitionForecastRevenue;
		} else {
			return 0;
		}
	};

	const totalCost = getTotalCost(program.projects.edges);

	const createTotalsRow = program => {
		return (
			<TableRowTotals>
				<TableCell>{/* ID */}</TableCell>
				<TableCell size={'m'}>
					{/* Project */}
					<Text variant={'bold'}>{formatMessage({id: 'common.totals'})}</Text>
				</TableCell>
				<TableCell>{/* Budget Type */}</TableCell>
				{isCappedProgram && (
					<TableCell align={'right'}>
						<Text variant={'bold'} data-cy={'totalsRow.confirmedRevenue'}>
							{formatNumber(program.totalAllocation, NUMBER_TYPE.MONEY, currency, intl)}
						</Text>
					</TableCell>
				)}
				<TableCell align={'right'}>
					<Text variant={'bold'} data-cy={'totalsRow.revenueRecognitionToDate'}>
						{program.projectsFinancialsToDate.totalActualRevenueRecognition
							? formatNumber(
									program.projectsFinancialsToDate.totalActualRevenueRecognition,
									NUMBER_TYPE.MONEY,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					{/* Total value of service to date */}
					<Text variant={'bold'} data-cy={'totalsRow.valueOfServiceToDate'}>
						{program.projectsFinancialsToDate.allActualTimeAndExpenses
							? formatNumber(
									program.projectsFinancialsToDate.allActualTimeAndExpenses,
									NUMBER_TYPE.MONEY,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					{/* Total Profit Margin to date */}
					<Text variant={'bold'} data-cy={'totalsRow.profitMarginToDate'}>
						{program.projectsFinancialsToDate.recognitionActualProfitPercentage
							? formatNumber(
									program.projectsFinancialsToDate.recognitionActualProfitPercentage,
									NUMBER_TYPE.PERCENTAGE,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.PERCENTAGE, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					{/* Total revenue */}
					<Text variant={'bold'} data-cy={'totalsRow.revenueRecognitionAtCompletion'}>
						{formatNumber(getProgramTotalRevenue(program), NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text variant={'bold'} data-cy={'totalsRow.valueOfServiceAtCompletion'}>
						{program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion
							? formatNumber(
									program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion,
									NUMBER_TYPE.MONEY,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text variant={'bold'} data-cy={'totalsRow.profitMarginAtCompletion'}>
						{program.projectsFinancialsTotals.recognitionProfitPercentage
							? formatNumber(
									program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
										? (getProgramTotalRevenue(program) - totalCost) / getProgramTotalRevenue(program)
										: program.projectsFinancialsTotals.recognitionProfitPercentage,
									NUMBER_TYPE.PERCENTAGE,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.PERCENTAGE, currency, intl)}
					</Text>
				</TableCell>
				<TableCellCustom align={'right'}></TableCellCustom>
			</TableRowTotals>
		);
	};

	const goToBudget = (project, element) => {
		history.push(`/project/${project.prefix}/budget`);
		trackComplexEvent(`Project Budget Page Link`, 'Clicked', {location: `From ${element}`});
	};

	const createProjectTable = projects =>
		projects.map(project => (
			<TableRowData key={project.id} data={project}>
				<TableCellWithOnClick onClick={() => goToBudget(project, 'Project ID')}>
					<ColorIndicator color={project.color} />
					<Text> </Text>
					<Text variant={'bold'}>{getProjectIdentifier(project)}</Text>
				</TableCellWithOnClick>
				<TableCell size={'m'}>{project.name}</TableCell>
				<TableCell>
					<Text data-cy={project.name + '.budgetType'}>{project.budgetTypeTranslated}</Text>
				</TableCell>
				{isCappedProgram && (
					<TableCell align={'right'}>
						{/* Confirmed revenue */}
						<Text variant={'bold'} data-cy={project.name + '.confirmedRevenue'}>
							{formatNumber(ProjectUtil.getProjectConfirmedRevenue(project), NUMBER_TYPE.MONEY, currency, intl)}
						</Text>
					</TableCell>
				)}
				<TableCell align={'right'}>
					<Text data-cy={project.name + '.revenueRecognitionToDate'}>
						{project.totalRevenueRecognitionToDate
							? formatNumber(project.totalRevenueRecognitionToDate, NUMBER_TYPE.MONEY, currency, intl)
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text data-cy={project.name + '.valueOfServiceToDate'}>
						{project.financialNumbersToDate.allActualTimeAndExpenses
							? formatNumber(
									project.financialNumbersToDate.allActualTimeAndExpenses,
									NUMBER_TYPE.MONEY,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text data-cy={project.name + '.profitMarginToDate'}>
						{project.financialNumbersToDate.recognitionActualProfitPercentage
							? formatNumber(
									project.financialNumbersToDate.recognitionActualProfitPercentage,
									NUMBER_TYPE.PERCENTAGE,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.PERCENTAGE, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text data-cy={project.name + '.revenueRecognitionAtCompletion'}>
						{project.totalRevenueRecognition
							? formatNumber(project.totalRevenueRecognition, NUMBER_TYPE.MONEY, currency, intl)
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text variant={'bold'} data-cy={project.name + '.valueOfServiceAtCompletion'}>
						{project.financialNumbers.allTotalTimeAndExpensesAtCompletion
							? formatNumber(
									project.financialNumbers.allTotalTimeAndExpensesAtCompletion,
									NUMBER_TYPE.MONEY,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.MONEY, currency, intl)}
					</Text>
				</TableCell>
				<TableCell align={'right'}>
					<Text variant={'bold'} data-cy={project.name + '.profitMarginAtCompletion'}>
						{project.financialNumbers.recognitionProfitPercentage
							? formatNumber(
									project.financialNumbers.recognitionProfitPercentage,
									NUMBER_TYPE.PERCENTAGE,
									currency,
									intl
							  )
							: formatNumber(0, NUMBER_TYPE.PERCENTAGE, currency, intl)}
					</Text>
				</TableCell>
				<TableCellCustom size={'m'} align={'right'}>
					<ButtonIconOnly
						size={'s'}
						type={'ghost'}
						icon={'newTab'}
						onClick={() => goToBudget(project, 'New Tab Icon')}
					/>
				</TableCellCustom>
			</TableRowData>
		));

	const calculatedHeight = 62 + 62 * (projects.length + 2);

	React.useEffect(() => {
		// Refetch data (especially for financials) in case it's been updated
		retry();
	}, []);

	return (
		<div style={{height: calculatedHeight}}>
			<VirtualizedTable scroll={false}>
				<TableHeaderRow>
					<TableHeaderCell>{formatMessage({id: 'common.id'})}</TableHeaderCell>
					<TableHeaderCell size={'m'}>{formatMessage({id: 'common.project'})}</TableHeaderCell>
					<TableHeaderCell>{formatMessage({id: 'new_project_modal.budget_type'})}</TableHeaderCell>
					{isCappedProgram && (
						<TableHeaderCell align={'right'}>{formatMessage({id: 'common.confirmed_revenue'})}</TableHeaderCell>
					)}
					<TableHeaderCell align={'right'}>
						{formatMessage({id: 'common.revenue_recognition_to_date'})}
					</TableHeaderCell>
					<TableHeaderCell align={'right'}>
						{formatMessage({id: 'project_budget.actual_value_of_service_to_date'})}
					</TableHeaderCell>
					<TableHeaderCell align={'right'}>
						{formatMessage({id: 'project_budget.profit_margin_to_date'})}
					</TableHeaderCell>
					<TableHeaderCell align={'right'}>
						{program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
							? formatMessage({id: 'common.total_revenue'})
							: formatMessage({id: 'common.revenue_recognition_at_completion'})}
					</TableHeaderCell>
					<TableHeaderCell align={'right'}>
						{formatMessage({id: 'project_budget.total_value_of_service_at_completion'})}
					</TableHeaderCell>
					<TableHeaderCell align={'right'}>
						{formatMessage({id: 'project_budget.profit_margin_at_completion'})}
					</TableHeaderCell>
					<TableCellCustom align={'right'}></TableCellCustom>
				</TableHeaderRow>
				{createProjectTable(formattedProjects)}
				{program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE && createUnallocatedRow(program)}
				{createTotalsRow(program)}
			</VirtualizedTable>
		</div>
	);
};

export default ProjectBudgetTable;
