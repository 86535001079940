import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import * as tracking from '../../../tracking';
import {withRouter} from 'react-router-dom';
import loadScript from 'load-script';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import DirectApi from '../../../directApi';
import styled from 'styled-components';
import {Button, RadioButton} from 'web-components';
import DataImporterUtil from './settings_data_importer_util';
import {FlexRow, DeprecatedText as Text} from '@forecast-it/design-system';
import TooltipContainer from '../../shared/components/tooltips/tooltip_container';
import DataImportValidator from './DataImportValidator';

/**
 * Important information about this module:
 *
 * We are currently using a deprecated version of nuvo-vanilla-js: 2.8.0
 *
 * Version 2.9 has some major API changes mainly around initialization of validation hooks.
 * We have two options to upgrade:
 *
 * 	- Switch to the react version
 * 	- Upgrade to the latest version of the vanilla version
 *
 * 	The react version currently crashes at the last page in the import - but we can check back on this at a later date.
 *
 * Updating to 2.9 vanilla, requires some changes to the way we load the script as it is now a module:
 * loadScript('https://unpkg.com/nuvo-vanilla-js@2.9.0', {type: 'module'}, this.onApiLoad);
 *
 * We might also need to introduce a custom script loader to handle the new module type.
 *
 * see https://socket.dev/npm/package/nuvo-vanilla-js
 *
 * A script could be
 *
 * <script type="module">
 *   import { launchNuvoImporter } from 'https://unpkg.com/nuvo-vanilla-js';
 *   window.launchNuvoImporter = launchNuvoImporter;
 * </script>
 * and then we can access the launchNuvoImporter function from the window object in the react code
 *
 * Would be much nicer to just use the react component.
 *
 *
 * for reference this is the syntax to lazy load the react module:
 *
 *  const NuvoImporter = lazy(() =>
 *  import('nuvo-react').then(module => {
 *    return {default: module.NuvoImporter};
 *  }
 *
 *  and then wrap the NuvoImporter component in a Suspense component in render.
 *
 *  it shows up but crashes at the last page.
 *
 * */
export const DescriptionStyled = styled.div`
	.description {
		padding-left: 38px;
		padding-right: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.header {
		display: flex;
		padding-left: 38px;
		padding-right: 0px;
		padding-top: 20px;
		padding-bottom: 0px;
	}
	.header-title {
		display: flex;
		color: #535353;
		font-size: 20px;
		font-weight: 500;
	}
	.sub-description {
		padding-left: 38px;
		padding-right: 0px;
		padding-top: 15px;
	}
	.bold {
		font-weight: bold;
		padding-top: 15px;
		margin: 0px;
	}
	.button {
		display: flex;
		justify-content: flex-start;
		padding-left: 38px;
		margin-top: 20px;
		gap: 20px;
	}
	.my-nuvo-style1 {
		position: relative;
		max-height: 500px;
		max-width: 80vw;
		overflow: scroll;
	}
`;

export const RateCardsStyle = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 10px;
`;

export const Section = styled.div`
	display: flex;
	max-width: 500px;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 8px;
`;

export const SubSection = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 12px;
	padding-left: 8px;
	padding-top: 8px;
`;

export const ImportMode = {
	ImportNewProjects: 'ImportNewProjects',
	ImportPhasesTasksTimeRegs: 'ImportPhasesTasksTimeRegs',
	ImportTimeRegs: 'ImportTimeRegs',
};
class settingsDataImporter extends Component {
	constructor(props) {
		super(props);
		this.onApiLoad = this.onApiLoad.bind(this);
		this.initializeDataImport = this.initializeDataImport.bind(this);
		this.state = {
			personsToCreate: [],
			personsToCreateString: '',
			clientsToCreate: [],
			clientsToCreateString: '',
			rateCardsToCreate: [],
			rateCardsToCreateString: '',
			projectsToCreate: [],
			tasksToCreate: [],
			numberOfTimeEntriesToCreate: [],
			isNuvoDone: false,
			nuvoResult: {},
			migrationStatus: null,
			nuvoReady: false,
			disableButtons: false,
			importMode: ImportMode.ImportNewProjects,
			nuvoImporter: null,
			nuvoId: null,
		};
		this.superPropertyChecksum = trackPage('Data Importer');
	}

	componentDidMount() {
		document.title = 'Data Importer - Account - Forecast';

		tracking.trackPage('settings-dataImporter');

		if (!this.state.nuvoReady) {
			loadScript('https://unpkg.com/nuvo-vanilla-js@2.8.0', this.onApiLoad);
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	getTopHeader() {
		return (
			<div className="header">
				<TooltipContainer
					infoText={[
						{
							unstyledList: [
								'Here you can import / migrate data to your account. For details see our guide ',
								<a target="_blank" href="https://support.forecast.app/hc/en-us">
									https://support.forecast.app
								</a>,
							],
							noBox: true,
						},
					]}
					tooltipInfinteDuration={true}
					edgeOffset={350}
				>
					<a className="header-title">Data Import</a>
				</TooltipContainer>
			</div>
		);
	}

	setImportMode(importMode) {
		this.setState({importMode: importMode});
		const target = document.getElementById(this.state.nuvoId);
		target.remove();
		this.initializeDataImport(importMode);
	}

	getImportSelection() {
		return (
			<Section>
				<SubSection>
					<FlexRow gap={'s'}>
						<RadioButton
							checked={this.state.importMode === ImportMode.ImportNewProjects}
							onClick={this.setImportMode.bind(this, ImportMode.ImportNewProjects)}
						/>
						<Text>
							Import{' '}
							<b>
								<u>new</u>
							</b>{' '}
							projects, tasks and time entries.
						</Text>
					</FlexRow>
					<FlexRow gap={'s'}>
						<RadioButton
							checked={this.state.importMode === ImportMode.ImportTimeRegs}
							onClick={this.setImportMode.bind(this, ImportMode.ImportTimeRegs)}
						/>
						<Text>
							Import time entries into{' '}
							<b>
								<u>existing</u>
							</b>{' '}
							tasks or projects.
						</Text>
					</FlexRow>
					<FlexRow gap={'s'}>
						<RadioButton
							checked={this.state.importMode === ImportMode.ImportPhasesTasksTimeRegs}
							onClick={this.setImportMode.bind(this, ImportMode.ImportPhasesTasksTimeRegs)}
						/>
						<Text>
							Import phases, tasks or time entries into{' '}
							<b>
								<u>existing</u>
							</b>{' '}
							projects.
						</Text>
					</FlexRow>
				</SubSection>
			</Section>
		);
	}

	onApiLoad() {
		this.initializeDataImport(ImportMode.ImportNewProjects);
	}
	initializeDataImport(importMode) {
		if (!importMode) {
			return;
		}
		const emails = this.props.viewer.company.allPersons.edges.map(e =>
			e.node.email !== null ? e.node.email.toLowerCase() : ''
		);
		const clientOptions = this.props.viewer.company.clients.edges.map(e => {
			return {
				label: e.node.name,
				value: e.node.name,
				type: 'string',
			};
		});
		const rateCardOptions = this.props.viewer.company.rateCards.edges.map(e => {
			return {
				label: e.node.name,
				value: e.node.name,
				type: 'string',
			};
		});

		const projects = this.props.viewer.company.allCompanyProjectIds.map(id => 'P' + id);
		const tasks = this.props.viewer.company.allCompanyTaskIds.map(id => 'T' + id);

		let settings;
		if (importMode === ImportMode.ImportNewProjects) {
			settings = DataImporterUtil.settingsForNewProjectsAndTasks(clientOptions, rateCardOptions);
		} else if (importMode === ImportMode.ImportTimeRegs) {
			settings = DataImporterUtil.settingsForExistingProjectsAndTasks(clientOptions, rateCardOptions);
		} else if (importMode === ImportMode.ImportPhasesTasksTimeRegs) {
			settings = DataImporterUtil.settingsForNewPhasesTasksAndTimeRegsOnExistingProjects(clientOptions, rateCardOptions);
		}

		let key = '';
		if (process.env.CIRCLE_BRANCH === 'production' && !this.props.viewer.email.includes('@forecast.')) {
			key = '+8GEeJk1ivE5xGFO6UiXIXwBOCkqqusXpqsvW3ilc78=';
			settings.developerMode = false;
		} else {
			key = 'KunfteTHBK0NElFNkgtu3ap0g/m4SJz6qtbLHXME1U8=';
		}

		const nuvoImporter = new window.nuvo.NuvoImporter(key, settings);
		nuvoImporter.onResults((values, _, complete) => {
			// Reserve the second parameter for the upcoming 'error' parameter.
			if (importMode === ImportMode.ImportNewProjects) {
				this.handleResultForNewProjectsAndTasks(values);
			} else if (importMode === ImportMode.ImportTimeRegs) {
				this.handleResultForExistingProjectsAndTasks(values);
			} else if (importMode === ImportMode.ImportPhasesTasksTimeRegs) {
				this.handleResultForNewProjectsAndTasks(values);
			}
			complete();
		});

		nuvoImporter.onCancel(() => {
			this.cancelDataUpload.bind(this);
		});

		const validator = new DataImportValidator({
			nuvoImporter,
			importMode,
			projects,
			tasks,
			emails,
			companyIdBase64: this.props.viewer.company.id,
		});

		if (importMode === ImportMode.ImportNewProjects) {
			validator.registerColumnHooksTaskEmails();
		} else if (importMode === ImportMode.ImportTimeRegs) {
			validator.registerValidationForExistingTasksAndProjects();
		} else if (importMode === ImportMode.ImportPhasesTasksTimeRegs) {
			validator.registerColumnHooksForExistingProjects();
			validator.registerColumnHooksTaskEmails();
		}

		validator.registerColumnHooksForTimeRegEmails();
		validator.registerEntryChange();

		try {
			nuvoImporter.launch();
		} catch (e) {}
		const target = document.getElementById(nuvoImporter.getId());
		let c = document.getElementById('nuvo-container');

		c.append(target);

		let f = document.getElementsByTagName('iframe');

		if (window.innerHeight < 600) {
			f[0].style.height = '60vh';
		} else if (window.innerHeight < 900) {
			f[0].style.height = '70vh';
		} else {
			f[0].style.height = '80vh';
		}

		this.setState({nuvoReady: true, nuvoImporter: nuvoImporter, nuvoId: nuvoImporter.getId()});
	}

	handleResize() {
		let f = document.getElementsByTagName('iframe');
		if (window.innerHeight < 600) {
			f[0].style.height = '60vh';
		} else if (window.innerHeight < 900) {
			f[0].style.height = '70vh';
		} else {
			f[0].style.height = '80vh';
		}
	}

	async handleResultForExistingProjectsAndTasks(nuvoResult) {
		this.setState({nuvoResult});
		const emails = this.props.viewer.company.allPersons.edges.map(e => e.node.email);
		nuvoResult.forEach(r => {
			if (
				r.time_registration_person_email !== null &&
				r.time_registration_person_email !== '' &&
				!emails.includes(r.time_registration_person_email) &&
				!this.state.personsToCreate.includes(r.time_registration_person_email)
			) {
				const personsToCreate = this.state.personsToCreate;
				personsToCreate.push(r.time_registration_person_email);
				this.setState({personsToCreate});
			}

			if (r.time_registration_minutes !== null && r.time_registration_minutes !== '' && r.time_registration_minutes > 0) {
				const numberOfTimeEntriesToCreate = this.state.numberOfTimeEntriesToCreate;
				numberOfTimeEntriesToCreate.push(r.time_registration_minutes);
				this.setState({numberOfTimeEntriesToCreate});
			}
		});

		if (this.state.personsToCreate.length > 0) {
			let ptc = '';
			this.state.personsToCreate.forEach(p => {
				ptc = ptc + ' ' + p + ',';
			});
			ptc = ptc.slice(0, -1);
			this.setState({personsToCreateString: ptc});
		}

		this.setState({isNuvoDone: true});
		document.getElementById('nuvo-root-container').remove();
	}

	async handleResultForNewProjectsAndTasks(nuvoResult) {
		this.setState({nuvoResult});

		const emails = this.props.viewer.company.allPersons.edges.map(e => e.node.email);
		const clients = this.props.viewer.company.clients.edges.map(e => e.node.name);
		const rateCards = this.props.viewer.company.rateCards.edges.map(e => e.node.name);
		const projects = this.props.viewer.company.allCompanyProjectIds.map(id => '' + id);

		nuvoResult.forEach(r => {
			let existingCompanyProjectId = null;
			const regex = /^\d+$/;
			if (
				r.project_name &&
				r.project_name.length > 2 &&
				r.project_name[0] === 'P' &&
				r.project_name[1] === '-' &&
				r.project_name.substring(2).match(regex) !== null
			) {
				existingCompanyProjectId = r.project_name.substring(2);
			} else if (
				r.project_name &&
				r.project_name.length > 1 &&
				r.project_name[0] === 'P' &&
				r.project_name.substring(1).match(regex) !== null
			) {
				existingCompanyProjectId = r.project_name.substring(1);
			}

			if (existingCompanyProjectId != null && projects.includes(existingCompanyProjectId)) {
				// Not new project. Matched to existing companyProjectId.
			} else if (r.project_name && !this.state.projectsToCreate.includes(r.project_name)) {
				const projectsToCreate = this.state.projectsToCreate;
				projectsToCreate.push(r.project_name);
				this.setState({projectsToCreate});
			}

			if (
				r.project_rate_card !== null &&
				r.project_rate_card !== '' &&
				!rateCards.includes(r.project_rate_card) &&
				!this.state.rateCardsToCreate.includes(r.project_rate_card)
			) {
				const rateCardsToCreate = this.state.rateCardsToCreate;
				rateCardsToCreate.push(r.project_rate_card);
				this.setState({rateCardsToCreate});
			}
			if (
				r.project_client !== null &&
				r.project_client !== '' &&
				!clients.includes(r.project_client) &&
				!this.state.clientsToCreate.includes(r.project_client)
			) {
				const clientsToCreate = this.state.clientsToCreate;
				clientsToCreate.push(r.project_client);
				this.setState({clientsToCreate});
			}

			if (r.task_assigned_comma_seperated_emails !== null && r.task_assigned_comma_seperated_emails !== '') {
				const personsToCreate = this.state.personsToCreate;
				r.task_assigned_comma_seperated_emails.split(',').forEach(emailSplit => {
					if (!emails.includes(emailSplit) && !this.state.personsToCreate.includes(emailSplit)) {
						personsToCreate.push(emailSplit);
					}
				});
				this.setState({personsToCreate});
			}

			if (
				r.task_owner_email !== null &&
				r.task_owner_email !== '' &&
				!emails.includes(r.task_owner_email) &&
				!this.state.personsToCreate.includes(r.task_owner_email)
			) {
				const personsToCreate = this.state.personsToCreate;
				personsToCreate.push(r.task_owner_email);
				this.setState({personsToCreate});
			}
			if (
				r.time_registration_person_email !== null &&
				r.time_registration_person_email !== '' &&
				!emails.includes(r.time_registration_person_email) &&
				!this.state.personsToCreate.includes(r.time_registration_person_email)
			) {
				const personsToCreate = this.state.personsToCreate;
				personsToCreate.push(r.time_registration_person_email);
				this.setState({personsToCreate});
			}

			const projectTask = r.project_name + '#####' + r.task_name;
			if (r.task_name !== null && r.task_name !== '' && !this.state.tasksToCreate.includes(projectTask)) {
				const tasksToCreate = this.state.tasksToCreate;
				tasksToCreate.push(projectTask);
				this.setState({tasksToCreate});
			}
			if (r.time_registration_minutes !== null && r.time_registration_minutes !== '' && r.time_registration_minutes > 0) {
				const numberOfTimeEntriesToCreate = this.state.numberOfTimeEntriesToCreate;
				numberOfTimeEntriesToCreate.push(r.time_registration_minutes);
				this.setState({numberOfTimeEntriesToCreate});
			}
		});

		if (this.state.rateCardsToCreate.length > 0) {
			let rtc = '';
			this.state.rateCardsToCreate.forEach(r => {
				rtc = rtc + ' ' + r + ',';
			});
			rtc = rtc.slice(0, -1);
			this.setState({rateCardsToCreateString: rtc});
		}

		if (this.state.personsToCreate.length > 0) {
			let ptc = '';
			this.state.personsToCreate.forEach(p => {
				ptc = ptc + ' ' + p + ',';
			});
			ptc = ptc.slice(0, -1);
			this.setState({personsToCreateString: ptc});
		}

		if (this.state.clientsToCreate.length > 0) {
			let ctc = '';
			this.state.clientsToCreate.forEach(p => {
				ctc = ctc + ' ' + p + ',';
			});
			ctc = ctc.slice(0, -1);
			this.setState({clientsToCreateString: ctc});
		}

		this.setState({isNuvoDone: true});
		document.getElementById('nuvo-root-container').remove();
	}

	async confirmDataUpload() {
		this.setState({disableButtons: true});
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const data = {
			method: 'POST',
			headers,
			credentials: 'include',
			body: JSON.stringify(this.state.nuvoResult),
		};
		await fetch(DirectApi.graphqlServerEndpoint(`data_migration_upload`), data).then(response => {
			if (response.ok) {
				this.setState({migrationStatus: true});
			} else {
				this.setState({migrationStatus: false});
			}
		});
	}

	async cancelDataUpload() {
		this.setState({
			personsToCreate: [],
			clientsToCreate: [],
			rateCardsToCreate: [],
			personsToCreateString: '',
			clientsToCreateString: '',
			rateCardsToCreateString: '',
			projectsToCreate: [],
			tasksToCreate: [],
			numberOfTimeEntriesToCreate: [],
			isNuvoDone: false,
			nuvoResult: {},
		});
		window.location.reload();
	}

	getResultText() {
		if (this.state.migrationStatus === true) {
			return (
				<div className="bold">
					<p>Data successfully uploaded and will now be added to your account.</p>
					<p>
						The actual time it takes before you can see the data can vary, but smaller data sets should not take
						more than a couple of minutes to process, whereas larger data sets can potentially take hours before
						everything is ready for you.
					</p>
					<p>For reference 1000 time entries will take approximately 5 minutes to import.</p>
				</div>
			);
		} else {
			return (
				<div>
					<p>The import failed. Please reach out to our support for further assistance.</p>
				</div>
			);
		}
	}

	render() {
		const importNewProjects = this.state.importMode === ImportMode.ImportNewProjects;
		return (
			<div>
				<DescriptionStyled>
					{this.getTopHeader()}
					<p className="description">{this.getImportSelection()}</p>
					{this.state.isNuvoDone ? (
						this.state.migrationStatus === null ? (
							<div>
								<div className="sub-description">
									<a className="bold">{this.state.personsToCreate.length}</a> deactivated person(s) will be
									created.
									{this.state.personsToCreate.length > 0 ? <br></br> : null}
									{this.state.personsToCreate.length > 0 ? <a>{this.state.personsToCreateString}</a> : null}
								</div>
								{importNewProjects ? (
									<div className="sub-description">
										<a className="bold">{this.state.clientsToCreate.length}</a> client(s) will be created.
										{this.state.clientsToCreate.length > 0 ? <br></br> : null}
										{this.state.clientsToCreate.length > 0 ? (
											<a>{this.state.clientsToCreateString}</a>
										) : null}
									</div>
								) : null}
								{importNewProjects ? (
									<div className="sub-description">
										<a className="bold">{this.state.rateCardsToCreate.length}</a> rate card(s) will be
										created.
										{this.state.rateCardsToCreate.length > 0 ? <br></br> : null}
										{this.state.rateCardsToCreate.length > 0 ? (
											<a>{this.state.rateCardsToCreateString}</a>
										) : null}
									</div>
								) : null}
								{importNewProjects ? (
									<div className="sub-description">
										<a className="bold">{this.state.projectsToCreate.length}</a> projects will be created.
									</div>
								) : null}
								{[ImportMode.ImportNewProjects, ImportMode.ImportPhasesTasksTimeRegs].includes(
									this.state.importMode
								) ? (
									<div className="sub-description">
										<a className="bold">{this.state.tasksToCreate.length}</a> tasks will be created.
									</div>
								) : null}
								<div className="sub-description">
									<a className="bold">{this.state.numberOfTimeEntriesToCreate.length}</a> time entries will be
									created.
								</div>
								<div className="button">
									<Button
										onClick={this.confirmDataUpload.bind(this)}
										size={Button.SIZE.STANDARD}
										variant={Button.VARIANT.GREEN_FILLED}
										isDisabled={this.state.disableButtons}
									>
										Confirm and upload data
									</Button>
									<Button
										onClick={this.cancelDataUpload.bind(this)}
										size={Button.SIZE.STANDARD}
										variant={Button.VARIANT.RED_OUTLINE}
										isDisabled={this.state.disableButtons}
									>
										Cancel & restart
									</Button>
								</div>
							</div>
						) : (
							<div className="sub-description">{this.getResultText()}</div>
						)
					) : (
						<div className="my-nuvo-style" id="nuvo-container"></div>
					)}
				</DescriptionStyled>
			</div>
		);
	}
}

const settingsDataImporterQuery = graphql`
	query settingsDataImporter_Query {
		viewer {
			actualPersonId
			component(name: "data_importer")
			...settingsDataImporter_viewer
		}
	}
`;

export {settingsDataImporterQuery};

export default withRouter(
	injectIntl(
		createFragmentContainer(settingsDataImporter, {
			viewer: graphql`
				fragment settingsDataImporter_viewer on Viewer {
					id
					availableFeatureFlags {
						key
					}
					email
					company {
						id
						tier
						allCompanyTaskIds
						allCompanyProjectIds
						allPersons(first: 10000) {
							edges {
								node {
									id
									firstName
									lastName
									email
									profilePictureId
									profilePictureDefaultId
									active
								}
							}
						}
						clients(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						rateCards(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						roles(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
				}
			`,
		})
	)
);
