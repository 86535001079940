import React, { useMemo } from 'react';
import { Button, FlexItem, FlexRow, Heading, Table, Text, theme } from '@forecast-it/design-system';
import Styled from 'styled-components';
import { useIntl } from 'react-intl';
import Util from '../../shared/util/util';
import { MODAL_TYPE, showModal } from '../../shared/components/modals/generic_modal_conductor';
import InvoicingIntegrationIcon from '../../../components/settings-finance/InvocingIntegrationIcon';
const BillToSectionContainer = Styled.div `
	width: 100%;
	border-left: 1px solid ${theme.colors.border.separator};
	border-right: 1px solid ${theme.colors.border.separator};
	margin-bottom: 25px;
	border-radius: ${theme.border.radius['2']};
	border-top: 1px solid ${theme.colors.border.separator}`;
const BillFromEntityBar = Styled.div `
	display: flex;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid ${theme.colors.border.separator};
	align-items: center;
	padding-left: ${theme.spacing[400]};
	padding-top: ${theme.spacing[600]};
	padding-bottom: ${theme.spacing[600]};
`;
const BillToTableWrapper = Styled.div `
	width: 100%;
	height: 100%;
`;
const TableBarWrapper = Styled.div `
	margin-top: 32px;
	margin-bottom: 15px;
`;
const NoBillToEntitiesContainer = Styled.div `;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${theme.border.radius['2']};
	border: 1px solid ${theme.colors.border.separator}
`;
const mappedBillFroms = (viewer) => { var _a; return (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.billFroms; };
const mappedClientBillTos = (viewer) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.clientBillTos) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(clientBillTo => clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.node); };
const constructBillTos = (billFrom, clientBillTos) => {
    const billTos = [];
    const filteredClientBillTos = clientBillTos === null || clientBillTos === void 0 ? void 0 : clientBillTos.filter(clientBillTo => { var _a; return ((_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.billFrom) === null || _a === void 0 ? void 0 : _a.id) === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.id); });
    for (const clientBillTo of filteredClientBillTos) {
        if (clientBillTo) {
            const obj = {
                clientBillToId: clientBillTo.id,
                address: clientBillTo.billTo.address,
                name: clientBillTo.billTo.name,
                taxId: clientBillTo.billTo.taxId,
                id: clientBillTo.billTo.id,
                invoiced: clientBillTo.invoiced,
                billFromId: clientBillTo.billFrom.id,
            };
            billTos.push(obj);
        }
    }
    return billTos;
};
const formatData = (billFroms, clientBillTos) => billFroms === null || billFroms === void 0 ? void 0 : billFroms.map(billFrom => ({
    billFrom: billFrom,
    billTos: constructBillTos(billFrom, clientBillTos),
})).filter(data => { var _a; return ((_a = data.billTos) === null || _a === void 0 ? void 0 : _a.length) !== 0; }); // If the bill to array is empty, it means this client has no bill to entity for a specific bill from
function SettingsClientAssociatedBillTosSection({ viewer }) {
    var _a, _b, _c, _d, _e;
    const { formatMessage } = useIntl();
    const billFroms = useMemo(() => mappedBillFroms(viewer), []); // All the available bill froms. We need this for the bill from info. Name and integration.
    const clientBillTos = useMemo(() => mappedClientBillTos(viewer), [viewer]); // These are the associations from the client to the bill to entities.
    const formattedData = useMemo(() => formatData(billFroms, clientBillTos), [viewer]);
    const clientId = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.id;
    const hasIntegrationEnabled = ((_c = viewer.company) === null || _c === void 0 ? void 0 : _c.economicEnabled) || ((_d = viewer.company) === null || _d === void 0 ? void 0 : _d.newXeroEnabled) || ((_e = viewer.company) === null || _e === void 0 ? void 0 : _e.quickbooksEnabled);
    const unAssociateBillToEntity = row => {
        if (row.invoiced) {
            showModal({
                type: MODAL_TYPE.INVOICED_BILL_TO_WARNING,
                billToId: row.id,
                clientId,
            });
        }
        else {
            showModal({
                type: MODAL_TYPE.REMOVE_CLIENT_BILL_TO,
                clientBillToId: row.clientBillToId,
                clientId,
            });
        }
    };
    const associateNewBillToEntity = () => {
        showModal({
            type: MODAL_TYPE.ASSOCIATE_BILL_TO_ENTITY,
            clientId,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableBarWrapper, null,
            React.createElement(FlexRow, { justifyContent: 'space-between', gap: 's' },
                React.createElement(Heading, { size: '4' }, "Associated 'Bill To' entities"),
                hasIntegrationEnabled && (React.createElement(Button, { emphasis: 'low', onClick: () => associateNewBillToEntity() }, "Associate a new 'Bill To' entity")))),
        formattedData.length > 0 ? (formattedData === null || formattedData === void 0 ? void 0 : formattedData.map(data => {
            var _a, _b, _c, _d;
            return (React.createElement(BillToSectionContainer, null,
                React.createElement(BillFromEntityBar, null,
                    React.createElement(FlexRow, { gap: 'l' },
                        React.createElement(FlexItem, null,
                            React.createElement(InvoicingIntegrationIcon, { integration: (_a = data === null || data === void 0 ? void 0 : data.billFrom) === null || _a === void 0 ? void 0 : _a.integration })),
                        React.createElement(FlexItem, null, (_b = data === null || data === void 0 ? void 0 : data.billFrom) === null || _b === void 0 ? void 0 : _b.name),
                        React.createElement(FlexItem, null,
                            "(", (_c = data === null || data === void 0 ? void 0 : data.billTos) === null || _c === void 0 ? void 0 :
                            _c.length,
                            ")"))),
                React.createElement(FlexRow, null,
                    React.createElement(BillToTableWrapper, null,
                        React.createElement(Table, { data: data === null || data === void 0 ? void 0 : data.billTos, width: '100%' },
                            React.createElement(Table.TextColumn, { accessorKey: 'name', header: formatMessage({ id: 'settings_finance.bill_to_entity_name' }), width: '350px' }),
                            React.createElement(Table.TextColumn, { accessorKey: 'address', header: formatMessage({ id: 'settings_finance.address' }) }),
                            Util.shouldDisplayTaxIdColumn((_d = data === null || data === void 0 ? void 0 : data.billFrom) === null || _d === void 0 ? void 0 : _d.integration) && (React.createElement(Table.TextColumn, { accessorKey: 'taxId', header: formatMessage({ id: 'settings.client.vat_number' }), width: '200px' })),
                            React.createElement(Table.ButtonColumn, { align: Table.Align.right, header: formatMessage({ id: 'settings_finance.un_associate_button' }), hideHeader: true, button: row => ({
                                    label: 'Un-associate',
                                    onClick: () => unAssociateBillToEntity(row),
                                }), width: '150px' }))))));
        })) : (React.createElement(NoBillToEntitiesContainer, null,
            React.createElement(Text, { color: 'default' }, formatMessage({ id: 'settings_finance.no_bill_to_entities_container_description' }))))));
}
export default SettingsClientAssociatedBillTosSection;
