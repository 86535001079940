/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StartSageIntacctRecipesInput = {|
  sageIntacctTimeRegsFromForecast?: ?boolean,
  sageServiceForTimeRegsFeatureFlag?: ?boolean,
  sageServiceForTasksFeatureFlag?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type startSageIntacctRecipesMutationVariables = {|
  input: StartSageIntacctRecipesInput
|};
export type startSageIntacctRecipesMutationResponse = {|
  +startSageIntacctRecipes: ?{|
    +outcome: ?string
  |}
|};
export type startSageIntacctRecipesMutation = {|
  variables: startSageIntacctRecipesMutationVariables,
  response: startSageIntacctRecipesMutationResponse,
|};
*/


/*
mutation startSageIntacctRecipesMutation(
  $input: StartSageIntacctRecipesInput!
) {
  startSageIntacctRecipes(input: $input) {
    outcome
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "StartSageIntacctRecipesInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StartSageIntacctRecipesPayload",
    "kind": "LinkedField",
    "name": "startSageIntacctRecipes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "outcome",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "startSageIntacctRecipesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "startSageIntacctRecipesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "startSageIntacctRecipesMutation",
    "operationKind": "mutation",
    "text": "mutation startSageIntacctRecipesMutation(\n  $input: StartSageIntacctRecipesInput!\n) {\n  startSageIntacctRecipes(input: $input) {\n    outcome\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccfb72ca74df6552fcd29d748b71a1cf';

module.exports = node;
