import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateGoogleCalendarTokenMutation($input: UpdateGoogleCalendarTokenInput!) {
		updateGoogleCalendarToken(input: $input) {
			viewer {
				calendarUser {
					name
					email
					picture
				}
				calendarTokenError
				calendarFetchEnabled
				calendarViewPrivateEvents
				enabledCalendars
				calendars {
					edges {
						node {
							id
							name
							description
							backgroundColor
							foregroundColor
						}
					}
				}
			}
			errors
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
