import styled from 'styled-components';
export const ContentWrapper = styled.div `
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-top: 3px;
	&:not(:first-child) {
		border-top: 1px solid #e6e6e6;
		padding-top: 16px;
	}
`;
export const Column = styled.div `
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
export const Row = styled.div `
	display: flex;
	gap: 12px;
`;
export const Popup = styled.div `
	display: flex;
	flex-direction: column;
	gap: 16px;

	width: ${p => (p.makeWider ? '400px' : '320px')};

	padding: 16px;

	.simplebar-content-wrapper:focus-visible {
		outline: none;
	}
`;
export const Label = styled.div `
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 500;
	color: #a1a1a1;
	white-space: nowrap;
	letter-spacing: 1px;
	margin: 0;
`;
export const Notes = styled.textarea `
	resize: none;
	padding: 8px 10px 0 10px;
	border: solid 1px #dbdbdb;
	border-radius: 4px;
	background-color: #fff;
	height: ${({ rows }) => (rows === 3 ? '70px' : '42px')};
	width: 100%;
	font-size: 12px;
	line-height: normal;

	&::placeholder {
		color: #a1a1a1;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	&:hover {
		border-color: #a1a1a1;
	}

	&:focus {
		outline: none;
		border-color: #6e0fea;
	}

	&.disabled {
		background: #eee;
		color: #a1a1a1;
		cursor: default;

		&:hover {
			border-color: #dbdbdb;
		}

		&:focus {
			border-color: #dbdbdb;
		}
	}
`;
export const PrimaryButton = styled.button `
	all: unset;
	color: #fff;
	background-color: #05e298;
	border-color: #05e298;
	height: 28px;
	padding: 0 16px 0 16px;
	cursor: pointer;
	font-size: 13px;
	&:hover {
		background-color: #05e298;
		border-color: #05e298;
	}
	&:focus {
		box-shadow: 0 0 0 1px #000;
	}
`;
export const CancelButton = styled.button `
	all: unset;
	height: 28px;
	padding: 0 16px 0 16px;
	cursor: pointer;
	font-size: 13px;
	&:hover {
		background-color: #f5f5f5;
		border-color: #f5f5f5;
	}
	&:focus {
		box-shadow: 0 0 0 1px #6e0fea;
	}
`;
