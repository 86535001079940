import { graphql } from 'react-relay';
export const CalendarEventsQuery = graphql `
	query CalendarEventsFetch_Query($start: String, $end: String) {
		viewer {
			id
			component(name: "google_calendar_events")
			calendarEvents(start: $start, end: $end) {
				edges {
					node {
						id
						name
						description
						start
						end
						calendarTimeZone
						allDay
						calendarId
						foregroundColor
						backgroundColor
						attendees
					}
				}
			}
		}
	}
`;
