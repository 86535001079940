import { graphql } from 'react-relay';
export const TimeRegistrationTargetSuggestionsQuery = graphql `
	query TimeRegistrationTargetSuggestionsFetch_Query($timePeriod: String, $currentViewingDate: String) {
		viewer {
			id
			component(name: "time_registration_target_suggestions")
			timeRegistrationTargetSuggestions(timePeriod: $timePeriod, currentViewingDate: $currentViewingDate) {
				modelVersion
				sortedSuggestions {
					id
					rank
					task {
						id
						name
						progress
						companyTaskId
						highPriority
						approved
						favoured
						fullAccessToProject
						latestUiUpdateAt
						startDay
						startMonth
						startYear
						deadlineYear
						deadlineMonth
						deadlineDay
						timeLeft
						estimateForecastMinutes
						totalMinutesRegistered
						parentTaskId
						taskType
						billable
						assignedPersons {
							id
						}
						statusColumnV2 {
							category
						}
						project {
							id
							name
							status
							companyProjectId
							projectColor
							estimationUnit
							fullAccessToProject
							manualProgressOnProjectEnabled
							manualProgressOnPhasesEnabled
							manualProgressOnTasksEnabled
							client {
								id
								name
							}
							harvestProject {
								id
								name
							}
							unit4Project {
								id
								name
							}
						}
						phase {
							id
							name
						}
					}
					project {
						id
						name
						status
						companyProjectId
						projectColor
						estimationUnit
						fullAccessToProject
						client {
							id
							name
						}
						harvestProject {
							id
							name
						}
						unit4Project {
							id
							name
						}
					}
					idleTime {
						id
						name
						isInternalTime
						favoured
						disabled
					}
					total
				}
			}
			oldTimeRegistrationTargetSuggestions(timePeriod: $timePeriod, currentViewingDate: $currentViewingDate) {
				task {
					id
				}
				idleTime {
					id
				}
				total
			}
		}
	}
`;
